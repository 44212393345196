import React from 'react'
import Header from './Header';
import { useLocation,useParams, Link } from "react-router-dom";

import MenuCustomer from './MenuCustomer';
import Footer from './Footer';
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Moment from 'react-moment';
import moment from 'moment';
import ReactStarsRating from 'react-awesome-stars-rating';
import ReactLoading from 'react-loading';

import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalTitle } from "react-bootstrap"




const Profile_carrier = () => {
  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    exportValue.headers.usertuid = userdata.user_id;
    console.log("userData  -  ",userdata);
    //navigate('/home') 
  }
    const {transporter_id} = useParams();
  const [state, setState] = React.useState({
    transporter_detail: [],
    dstatus: 1,
    isLoading:true
      })


  console.log("pp", state)

  const[countstate,setCountState]= React.useState({count_customer_review:[]})
  const[likecountstate,setLikeCountState]= React.useState({count_customer_likes:[]})
  const[notlikecountstate,setnotLikeCountState]= React.useState({count_customer_notlikes:[]})
  const[modals,setModals] = React.useState({show: false})
  const [report, setReport] =  React.useState({  reportOn_transporterId:transporter_id , reportBy_userId:userdata.user_id , report_type:"2",reason:"", description:""  })
   
const[category,setCategoryState] = React.useState({category:[],count:[]})
  

  const axios_get_api = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/transporter_detail`;
    let sendData = {
      transporter_id: transporter_id,
      dstatus: 1,
      

    };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setState({ ...state,  transporter_detail: res.data.output , review:res.data.review , customer_review:res.data.customer_review, total_likes:res.data.total_likes,total_notlikes:res.data.total_notlikes ,isLoading:false});
      console.log("response", res);
    }).catch((e) => {

      console.log("----error:   ", e);
    })
  }

  const review_count = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/review_count_api`;
    let sendData = {
    
      dstatus: 1,
      transporter_id:transporter_id

    };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setCountState({ ...countstate, count_customer_review:res.data.count_customer_review , count_shipments:res.data.count_shipments,count_completed_shipments:res.data.count_completed_shipments});
      console.log("response_count", res);
    }).catch((e) => {

      console.log("----error:   ", e);
    })
  }

  const like_count = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/liked_count_api`;
    let sendData = {
    
      isLiked: 1,
      transporter_id:transporter_id

    };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setLikeCountState({ ...likecountstate, count_customer_likes:res.data.count_customer_review});
      console.log("response_count_like", res);
    }).catch((e) => {

      console.log("----error:   ", e);
    })
  }
  const top_category = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/transporter_top_category`;
    let sendData = {
    
      
      transporter_id:transporter_id

    };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
       setCategoryState({ ...category, category:res.data.output,count:res.data.count});
      console.log("category", res);
    }).catch((e) => {

      console.log("----error:   ", e);
    })
  }

  const notlike_count = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/notliked_count_api`;
    let sendData = {
    
      notLiked: 1,
      transporter_id:transporter_id

    };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setnotLikeCountState({ ...notlikecountstate, count_customer_notlikes:res.data.count_customer_review});
      console.log("response_count_notlike", res);
    }).catch((e) => {

      console.log("----error:   ", e);
    })
  }

  React.useEffect(() => {
    axios_get_api()
    review_count()
    like_count()
    notlike_count()
    top_category()
  }, [])

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
   function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  
    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }
  
    const { height, width } = useWindowDimensions();

    const handleClick=()=>{
      setModals({show:false})
    }
    const handleReport = () => {
      
      console.log("submit1");
     
      let state_detailes = {reportOn_transporterId: transporter_id , reportBy_userId:"" , report_type:2 , reason: report.reason, description:report.description}
      if(userdata.user_id) {
        state_detailes.reportBy_userId = userdata.user_id;
      }
    
      console.log("report detailes is ",state_detailes);
      if(report.reason!="" && report.description!=""){
      Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/report/add_report`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
              console.log("result is i ",result);
             
              if(result && result.data != undefined){
                 
                      toast.configure()
                      toast(result.data.message) 
                      setModals({show:false})
                      // shipmentDetail()
                  }
   
           }).catch((e) => {
         
   
            toast.configure();
            toast.error("Some thing went wrong");
            console.log("----error:   ", e);
          });}
          else{
            toast.configure()
            toast.error("Please Select Reason And Enter Message") 
          }
  }
  
  const stateChangeHandler = (event) => {
    console.log(event);  
    setReport({
        ...report,
        [event.target.name]: event.target.value,
      });
    
  }
  
  
  
  return (
    <div>
      <Modal show={modals.show} >
        <Modal.Header closeButton onClick={() => handleClick()}>
          <ModalTitle>
            <h4>Report</h4>
          </ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>
          <h6>Report Reason</h6>
            <select name="reason" className="form-select"  onChange={(e) => stateChangeHandler(e)}
                               value={report.reason} >
                              <option value="" selected="selected">Please Select</option>
                              <option value="Pornography or Repulsive">Pornography or Repulsive</option>
                              <option value="Violent or Hateful">Violent or Hateful</option>
                              <option value="Spam or Misleading ">Spam or Misleading </option>
                              <option value="Unlawful ">Unlawful </option>
                              <option value="Others">Others</option>
                              
                            </select>
          </div>
        
          <h6>Message</h6>
          <textarea className='form-control' rows="5" cols="3" name="description" placeholder='Please Enter Message' onChange={(e) => stateChangeHandler(e)}
                               value={report.description}></textarea>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={()=>handleReport( )} >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
  
         <Header />
  {state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
        
        <section class=" bg-light p-5 ">
        { state.transporter_detail.map((subscriber,index) => (
    <div class="container ">
       {state.transporter_detail.map((sub)=>(
 <section>
  { sub.dstatus==3   ?
       
       <div className='  mb-4'><div class="alert alert-danger text-center" role="alert" >
       Your Account is not Verified
       
   </div>
     </div>:""}
  </section>
  ))}
          <div class="row" style={width<=768?{marginLeft:"-50px",marginRight:"-50px"}:{marginLeft:"0px"}}>
            <div class="col-12 col-md-3">
              <section class="text-center profile_box">
                <div class="card shadow-sm">
                  <div class="card-body">
                    <img src={"https://shippxcdn.fniix.com/web/"+subscriber.logo} class="img-fluid rounded-circle border" />
                    <h1 class="mt-3 text-uppercase">{subscriber.branch_name}</h1>
                    <span class="badge bg-secondary rounded-pill">Carrier</span>
                    <p class="mt-3 text-primary">
                    
                   <ReactStarsRating value={subscriber.total_ratings!==0 && countstate.count_customer_review!==0?(subscriber.total_ratings/ countstate.count_customer_review):"0"} isEdit={false} isHalf={true}/> {subscriber.total_ratings!==0 && countstate.count_customer_review!==0?parseFloat(subscriber.total_ratings/countstate.count_customer_review).toFixed(1):"0"}</p>
                    
                      <small class="text-muted">{subscriber.total_reviews} Reviews</small>
                      <h2 class="mt-4"><small><i class="fa fa-thumbs-o-up text-primary" aria-hidden="true"></i></small> {likecountstate.count_customer_likes!==0 && countstate.count_shipments!==0?parseInt(likecountstate.count_customer_likes/countstate.count_shipments*100):0}%</h2>
                      <small class="text-muted">of shippers would use again</small>
                  </div>
                  </div>
                
              </section>
            
              <section class="text-center  mt-4">
                <div class="card shadow-sm">
                  {subscriber.images.length>0?
                  <div class="card-body">
                  
                    <h6 class="m-3">Photos (4)</h6>
                    
                    {subscriber.images.map((items, indexItem) => (
                    <img src={`https://shippxcdn.fniix.com/web/${items}`} class="img-fluid img-thumbnail " />
                    ))}
                    <small><a href="" data-bs-toggle="modal" data-bs-target="#myModal">View all photos</a></small>
                    
                  </div>
                  :<h5 className='text-muted m-1'>No Photos Uploaded</h5>}
                  </div>
              </section>
  
              <section class="profile_box_busi_info mt-4  p-3">
                <h3 class="mb-4">Business Info</h3>
                {!subscriber.address?"":<h6><small>Address:</small>{subscriber.address}</h6>}
                {!subscriber.contact_no?"":<h6><small>Mobile:</small> {subscriber.contact_no}</h6>}
                {!subscriber.email?"":<h6><small>Email:</small>{subscriber.email}</h6>}
                 {!subscriber.website?"":<h6><small>Web:</small> <a href="www.google.com" target="_blank">{subscriber.website}</a> </h6>}
              </section>
  
              <section class="profile_box_busi_info mt-4  p-3">
                <p><small><Link to={`/carrier_terms/${subscriber.transporter_id}`}><a href="" class="fs-6 text-uppercase">{subscriber.branch_name} TERMS AND CONDITIONS</a></Link></small></p>
               {subscriber.viewer_type==1? <small  className="text-primary " style={{cursor:"pointer"}} onClick={() => setModals({ show: true })}>Report Profile </small>:""}
              </section>
              
  
              
  
            </div>
            <div class="col-12 col-md-9 profile_box_right">
              <section class="p-2">
                
                <div class="row">
                  <div class="col-10 col-md">
                    <h1 className='text-uppercase'>{subscriber.branch_name}</h1>
                    {(subscriber.user_id == userdata.user_id) ? 
                    <Link to={`/update_carrier/${subscriber.transporter_id}`}><a  class={width<=768?"btn btn-primary":"d-block d-none"}>Update Profile</a></Link>
:""}
                   {!subscriber.about?"": <h6>about THE COMPANY</h6>}
                  </div>
                  <div class={  width<=768 ?"d-block d-none ":"col-2 col-md text-end" } >
                    {(subscriber.user_id == userdata.user_id) ? 
                    <Link to={`/update_carrier/${subscriber.transporter_id}`}><a  class="btn btn-primary">Update Profile</a></Link>
:""}
                  </div>
                </div>
                
                <p>{subscriber.about}</p>
                  



                <section>
                <div class="row">
                  {!subscriber.registered_year?"":
                    <div class="col-12 col-md-4">
                     
                    <h6>Registered Since</h6>
                      <p><i class="fa fa-globe text-primary" aria-hidden="true"></i> {subscriber.registered_year}</p>
                      </div>}
                     
                     {!subscriber.employes_no?"":
                    <div class="col-12 col-md-4">
                      <h6>No. of employees</h6>
                      <p><i class="fa fa-globe text-primary" aria-hidden="true"></i> {subscriber.employes_no}</p>
                    </div>
}
                     {!subscriber.owner_type?"":
                    <div class="col-12 col-md-4">
                      <h6>Ownership Type</h6>
                      <p><i class="fa fa-globe text-primary" aria-hidden="true"></i> {subscriber.owner_type}</p>
                    </div>}

                    {subscriber.certifications==""?"":
                    <div class="col-12 col-md-6">
                      <h6>Certification</h6>
                     
                      <p><i class="fa fa-globe text-primary" aria-hidden="true"></i> {subscriber.certifications.join(" , ")}</p>
                
                    </div>
}
                  </div>
                </section>
  
  
                <section>
                    <div class="row">
                    {!subscriber.trucks_count?"":
                    <div class="col-12 col-md-4">
                      <h6>Number of Trucks</h6>
                      <p><i class="fa fa-globe text-primary" aria-hidden="true"></i> {subscriber.trucks_count}</p>
                    </div>}

                    { subscriber.trucks_type==""?"":
                    <div class="col-12 col-md">
                      <h6>Truck Types</h6>
                   
                      <p className=''><i class="fa fa-globe text-primary" aria-hidden="true"></i> {subscriber.trucks_type.join(" , ")}      </p>
                   </div>}
                  </div>
                </section>
  
                
              </section>
              <section class="p-2">
                <h6>SHIPMENT INSIGHTS </h6>
                <div class="row text-center">
                  <div class="col-12 col-md-4 mb-5">
                    <div class="card " >
                      
                      <div class="card-body p-5 profile_box_right_boxes ">
                        <h5>Completed Shipments</h5>
                        <h2><i class="fa fa-cubes text-primary" aria-hidden="true"></i> {countstate.count_completed_shipments!==0 &&  countstate.count_shipments!==0?parseInt(countstate.count_completed_shipments/countstate.count_shipments*100):"0"}%</h2>
                        <small class="text-muted">
                         </small>
                      </div>

                     
                    </div>
                  </div>

                 
                  <div class="col-12 col-md-4 mb-5">
                    <div class="card" >
                      <div class="card-body p-5 profile_box_right_boxes  ">
                        <h5>Top Category  </h5>
                       

                        <h2 style={{display:"inline-block"}}><i class="fa fa-car text-primary" aria-hidden="true"></i>{category.count}</h2>
                       

                        {category.category.length>0? category.category.map((sub)=>( <small class="text-muted d-none d-sm-block"> shipments were <a href="/ship">{sub.category_name}</a></small>)):""}
                      </div>

                     
                    </div>
                  </div>

                 <div class="col-12 col-md-4 mb-5">
                    <div class="card">
                      <div class="card-body p-5 profile_box_right_boxes ">
                        <h5>Shipments Not Liked</h5>
                        <h2><i class="fa fa-thumbs-o-down text-primary" aria-hidden="true"></i>{notlikecountstate.count_customer_notlikes!==0 && countstate.count_shipments!==0?parseInt(notlikecountstate.count_customer_notlikes/countstate.count_shipments*100):"0"}%</h2>
                        <small class="text-muted d-none d-sm-block">Look through reviews for more context</small>
                      </div>

                     
                    </div>
                  </div>
                </div>
              </section>
               
              <section class="p-2 profile_box_reviews">
            

                <div class="card">
                  <div class="card-body">
                 
                    <h1>REVIEWS | {subscriber.total_reviews}</h1>
                   
                    <hr/>

                   {  state.customer_review!==undefined && state.customer_review.length>0 ?
                    <div>
                    { state.customer_review.map((sub,index) => (
                  
                    <section>
                    
                      <div class="d-flex justify-content-between">
                        <div class=" ">
                          <h3>{sub.full_name}</h3>
                          <p class="text-primary"><ReactStarsRating value={sub.rating} isEdit={false}/></p>
                          <p></p>
                        </div>
                        <div class=" ">
                          <span class="text-muted"><Moment format="YYYY/MM/DD" unix>{sub.created}</Moment></span>
                        </div>
                      </div>
                      <h2>{sub.title}</h2>
                    
                      <p>{sub.review_description}</p>
                      <p class="text-end text-muted"><small> Was this helpful? <i class="fa fa-thumbs-o-up text-primary" aria-hidden="true"></i> {sub.isLiked?(sub.isLiked):"0"} &nbsp;&nbsp;&nbsp; <i class="fa fa-thumbs-o-down text-primary" aria-hidden="true"></i> {sub.notLiked?(sub.notLiked):"0" }</small></p>
                    
                      {sub.transporter_review.map((subscriber)=>(
                      <div class="p-3 ml-30 mb-5 ml-5 bg-light  border-start border-primary border-5">
                        

                        { state.transporter_detail.map((subscriber,index) => (
                        <h3>{subscriber.branch_name}</h3>
                        ))}
                        <small>{subscriber.transporter_review_description}</small>
                        
                        </div>
                       ))} 
                       
                    </section>
                     ))}
                     </div>
                     :<h4 className='text-muted'>NO REVIEWS </h4>}
                    <hr/>
                   
                    
                  </div>
                </div>
                
              </section>
             
            </div>
          </div>
    </div>
        ))}
  </section>
}
        
  
  {/* <?php include 'footer.php';?> */}
  
  
  
  {/* <!-- Photos Box Start --> */}
  <div class="modal profile_modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
  
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <div class="modal-body">
          
          {/* <!-- Carousel --> */}
  <div id="demo" class="carousel slide" data-bs-ride="carousel">
  
    {/* <!-- Indicators/dots --> */}
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
      <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
      <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
    </div>
  
    {/* <!-- The slideshow/carousel --> */}
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="img/service/service-1.jpg" alt="Los Angeles" class="d-block w-100"/>
      </div>
      <div class="carousel-item">
        <img src="img/service/service-2.jpg" alt="Chicago" class="d-block w-100"/>
      </div>
      <div class="carousel-item">
        <img src="img/service/service-3.jpg" alt="New York" class="d-block w-100"/>
      </div>
    </div>
  
    {/* <!-- Left and right controls/icons --> */}
    <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
      <span class="carousel-control-next-icon"></span>
    </button>
  </div>
  
        </div>
  
       
  
      </div>
    </div>
    
  </div>

  <Footer />
  
  </div>
  )
}

export default Profile_carrier