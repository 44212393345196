import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate,useLocation,useParams, Link } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import { NextSeo } from 'next-seo';
import Head from 'next/head'
// import {Helmet} from "react-helmet";
import { Helmet } from 'react-helmet-async';
import Moment from 'react-moment';
import ReactLoading from 'react-loading';

import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Footer from "./Footer";
import Header from "./Header";
import Map from "./components/Map";

import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalTitle } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Mapsecond from "./components/Mapsecond";
import ReactStarsRating from 'react-awesome-stars-rating';
const humanizeDuration = require("humanize-duration");




const Ship_detail = () => {
  let navigate = useNavigate();
  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    exportValue.headers.usertuid = userdata.user_id;   
  }

  //const [disable, setDisable] = React.useState({dis:false,foruseinfinity:true });

//   if(userdata.transporter_id == undefined && disable.foruseinfinity == true){
//     setDisable({...disable,dis:true,foruseinfinity:false})
//   } 
  const {invoice_id} = useParams();
  console.log("invoice_id ", invoice_id)
  const [state, setState] = useState({ shipmentDetail: { item_imgs: [] },questions: [], quotes: { question_detail:[]},transporter_detail:[],admin_commission:[],transporter_commission:[], isLoading: true,detailFound:false  })
  const [questate, setqueState] = useState({invoice_id:invoice_id,question:"",answer:""});
  console.log("ttt",state)
  const [stateSave, setSaveState] = useState({transporter_id:"",pickup_date:6,delivery_date:6,cost:"",service_type:"Open Transport",payment_accepted:"At Pickup",transport_mode:"Trucking",payment_method:"Paypal",vehicle_type:"Multi-car open trailer",sendQuoteButton:false});
  const[dimensionState,setdimensionState] = useState({companyDetail:[]})
  console.log("state save is ",stateSave);
  const [quetState, setQuetState] = useState({});
  const [currentState, setCurrentState] = useState({time1:[],time2:[],time3:[],time4:[],time5:[]});
  const [reviewstate, setReviewState] = useState({invoice_id:"",transporter_id:"",review_description:"",rating:0,user_id:""});
const[modals,setModals] = useState({show: false})
const[stateModal,setStateModal] = useState({show: false})
const [report, setReport] = useState({  reportOn_shipmentId:invoice_id , reportBy_transporterId:userdata.transporter_id , report_type:"1",reason:"", description:""  })
  

  
  
  
    const [answstate, setanswState] = useState({question_id:"",answer:"",invoice_id:""})
    console.log("answr  is ", answstate)

   

  console.log("quotations quotes is ", quetState)
  
  

  
 // console.log("state is ",state);
 

    

 const dimension_detail = () =>{
  let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/get_company_info_api`;
  let sendData = {};

  Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
    setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
    console.log("rnmmmmm", res);
  }).catch((e) => {
    // toast.configure()
     //toast.error("Some thing went wrong")
    console.log("----error:   ", e);
  })
}
React.useEffect(() => {
  dimension_detail();
  currentshipmenttimeDetail()
 
 
}, [])



  const shipmentDetail = (question_id) => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/shipment_detail`;
    let sendData = { invoice_id: invoice_id ,question_id:question_id ,transporter_id:userdata.transporter_id};
    console.log("bb",sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res -> ",res);
     
      if (res.data.output.length == 1) {
        setState({ ...state, shipmentDetail: res.data.output[0], questions: res.data.questions, quotes: res.data.quotes, transporter_detail:res.data.transporter_detail,transporter_commission:res.data.transporter_commission,admin_commission:res.data.admin_commission, detailFound:true,isLoading:false })
        if (res.data.acceptedquotes) {
           setQuetState(res.data.acceptedquotes[0])
          }
    }
    else {
      setState({...state,isLoading:false})
    }
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };
  useEffect(() => {
    shipmentDetail();
  }, [])

  const currentshipmenttimeDetail = () => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/current_status_detail`;
    let sendData = { invoice_id: invoice_id };
    console.log("bb",sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res current -> ",res);
     
      if (res) {
        setCurrentState({ ...currentState, time1: res.data.output1, time2: res.data.output2, time3: res.data.output3, time4:res.data.output4, time5:res.data.output5})
     
    }
   
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };

  

  const handleChange = (event) => {
    console.log(event); 
    setSaveState({
        ...stateSave,
        [event.target.name]: event.target.value,
      });
      //console.log("setSaveState is ",setSaveState);
  }

  const addNewshipmentQuote = () => {
    console.log(stateSave);
   
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/shipment_new_quotes`;
    if(stateSave.cost !="" && stateSave.cost != 0 ){
      setSaveState({...stateSave,sendQuoteButton:true})
      let sendData = { invoice_id: invoice_id,transporter_id:"",pickup_date:stateSave.pickup_date,delivery_date:stateSave.delivery_date,cost:stateSave.cost ,service_type:stateSave.service_type,vehicle_type:stateSave.vehicle_type,payment_accepted:stateSave.payment_accepted,payment_method:stateSave.payment_method,transport_mode:stateSave.transport_mode};
      if(userdata.transporter_id) {
        sendData.transporter_id = userdata.transporter_id;
      }
      console.log("vvvv",sendData);
      console.log("vv",stateSave.cost);

      Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
         console.log("lll",res);
          
        // if(res.data.output.quotes_id) {
          if(userdata.transporter_id!==undefined){
          toast.configure();
          toast(res.data.message);

          setSaveState({...stateSave,cost:"",sendQuoteButton:false})

          shipmentDetail();
          }
          else{
            toast.configure();
            setSaveState({...stateSave,sendQuoteButton:false})
            toast.error("You are not allowed yet! Please First verify your Transporter account ");
            navigate("/profile_update_carrier")
          }
       
       // }
      }).catch((e) => {
        setSaveState({...stateSave,sendQuoteButton:false})
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });

  } else {
    toast.configure();
    toast.error("Please insert cost");
  }
  };

  const declinehandleQuotation = (data_array) => {

    console.log("this is second invoice is ", data_array);
    //setState({...state,invoice_id:invoice_id})

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/accept_decline_shipment_quote`;
    let sendData = data_array;
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res issssd ", res);
      if (res && res.data.status == '200')
      
        if (data_array.status == 3) {
          let stateShipdetail = state.shipmentDetail;
          stateShipdetail.status = 3;
          setState({ ...state, shipmentDetail: stateShipdetail });
        }
      setQuetState(res.data.output)
      toast.configure();
      toast.success("Quotation Declined Successfully");
      shipmentDetail()
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });

  }
  const acceptQuotation = (Quote,transporter_commission,admin_commission) =>{
    console.log("transporter_commission",transporter_commission)
    navigate(`/payment/${state.shipmentDetail.invoice_id}`,{state:{quotation:Quote,transporter_commission:transporter_commission,admin_commission:admin_commission}})
  }
  var currentDate = Math.abs((new Date().getTime() / 1000).toFixed(0));
  let endDate = ""; //1650969569;
  let days = ""; // currentDate - endDate;
  let day = ""; // parseInt(days /86400);


    
 const addquestion = (event) => {
  let transporter=userdata.transporter_id;
  console.log("id transporter",transporter)

  console.log("hh",questate);
  if( questate.invoice_id != "" && questate.question != "" ){
    
    if(transporter!==undefined){
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/question_create`;
      let sendData = { invoice_id:invoice_id,question:questate.question ,answer:questate.answer,transporter_id:userdata.transporter_id};
      console.log("svv",sendData)
           
      Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
       console.log('ttt',res)
       if(res && res.data != undefined){
                  
         toast.configure()
         toast(res.data.message)
         setqueState({...quetState,question:""})
         shipmentDetail()
        console.log("ssss",res);
       // navigate('/driver_list')
       }
       
     }).catch((e) => {
       
 
       toast.configure();
       toast.error("Some thing went wrong");
       console.log("----error:   ", e);
     });
    }else{
      toast.configure();
      toast.error("You are not allowed yet! Please First verify your Transporter account  ");
      navigate("/profile_update_carrier")

    }
   } else {
     toast.configure();
       toast.error("Error");
       
   }
   };
    const handleChange3 = (event) => {
     console.log(event);  
     setqueState({
         ...questate,
         [event.target.name]: event.target.value,
       });
     
   }
   const {question}= questate

   const handleSubmit = (question_id) => {
    
    console.log("submit1");
    state.isLoading = true
    let state_detailes = {answer:answstate.answer,question_id,invoice_id:invoice_id, user_id:""}
    if(userdata.user_id) {
      state_detailes.user_id = userdata.user_id;
    }
    console.log("qyyy",question_id)
    console.log("state detailes is ",state_detailes);
    
    Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/answer_create`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
            console.log("result is i ",result);
            state.isLoading = false
            if(result && result.data != undefined){
               
                    toast.configure()
                    toast(result.data.message) 
                    setanswState({...answstate,answer:""})
                    shipmentDetail()
                }
 
              }).catch((e) => {
       
 
                toast.configure();
                toast.error("Some thing went wrong");
                console.log("----error:   ", e);
              });
}

const handleChange4 = (event) => {
  console.log(event);  
  setanswState({
      ...answstate,
      [event.target.name]: event.target.value,
    });
  
}
const{answer}=answstate

const handleSubmitreview = (invoice_id,transporter_id) => {
    
  console.log("submit1");
  //state.isLoading = true
  let state_detailes = {review_description:reviewstate.review_description,transporter_id:transporter_id,invoice_id:invoice_id, user_id:"",rating:reviewstate.rating}
  if(userdata.user_id) {
    state_detailes.user_id = userdata.user_id;
  }
  
  console.log("state detailes is ",state_detailes);
  
  Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/customer_review_create`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
          console.log("result is i ",result);
         // state.isLoading = false
          if(result && result.data != undefined){
             
                  toast.configure()
                  toast(result.data.message) 
                  shipmentDetail()
              }

            }).catch((e) => {
       
 
              toast.configure();
              toast.error("Some thing went wrong");
              console.log("----error:   ", e);
            });
}

const handleLiked = (transporter_id,invoice_id) => {
  
console.log("submitlike");
//state.isLoading = true
let state_detailes = {transporter_id:transporter_id,invoice_id:invoice_id}


console.log("state liked",state_detailes);

Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/liked_create`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
        
       // state.isLoading = false
        if(result && result.data != undefined){
          console.log("result is i ",result.data);
                toast.configure()
                toast(result.data.message) 
                shipmentDetail()
                //setLikeState({...likestate, isLiked:likestate.isLiked+1})
              
            }

          }).catch((e) => {
       
 
            toast.configure();
            toast.error("Some thing went wrong");
            console.log("----error:   ", e);
          });
}


const handlenotLiked = (transporter_id,invoice_id) => {
  
console.log("submitlike");
//state.isLoading = true
let state_detailes = {transporter_id:transporter_id,invoice_id:invoice_id}


console.log("state liked",state_detailes);

Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/notliked_create`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
        console.log("result is i ",result);
       // state.isLoading = false
        if(result && result.data != undefined){
           
                toast.configure()
                toast(result.data.message) 
                shipmentDetail()
                
              
            }

    }) .catch((e) => {
       
 
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
}

const handleChange5 = (event) => {
console.log(event);  
setReviewState({
    ...reviewstate,
    [event.target.name]: event.target.value,
  });

}

const onChange = (value) => {
setReviewState({
  ...reviewstate,rating:value
})
console.log(`React Stars Rating value is ${value}`);
};

const LastSeen = (value) => {
  // console.log("val =>   ", value);
  let newDate = new Date();
  let io = newDate.getTime() - (value.time * 1000);
  //console.log("io   ",io)
  let yu = humanizeDuration(io, { units: ["d", "h", 'm', 's'], maxDecimalPoints: 0 });
  // console.log("yu",yu);
  return io ? "last seen " + yu : ""
}
const {rating}=reviewstate

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

 function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

  const { height, width } = useWindowDimensions();

  const handleClick=()=>{
    setModals({show:false})
  }
  const handleReport = () => {
    
    console.log("submit1");
   
    let state_detailes = {reportOn_shipmentId: invoice_id , reportBy_transporterId:"" , report_type:1 , reason: report.reason, description:report.description}
    if(userdata.transporter_id) {
      state_detailes.reportBy_transporterId = userdata.transporter_id;
    }
  
    console.log("report detailes is ",state_detailes);
    if(report.reason!="" && report.description!=""){
    Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/report/add_report`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
            console.log("result is i ",result);
           
            if(result && result.data != undefined){
               
                    toast.configure()
                    toast(result.data.message) 
                    setModals({show:false})
                    shipmentDetail()
                }
 
         }).catch((e) => {
       
 
          toast.configure();
          toast.error("Some thing went wrong");
          console.log("----error:   ", e);
        });}
        else{
          toast.configure()
          toast.error("Please Select Reason And Enter Message") 
        }
}

const stateChangeHandler = (event) => {
  console.log(event);  
  setReport({
      ...report,
      [event.target.name]: event.target.value,
    });
  
}
const helmetContext = {};

  return (<>
  
 
  {/* <NextSeo
      title="Simple Usage Example"
      description="A short description goes here."
    />
    <Head>
        <title>KNG-shipment</title>
        <meta name='description' content="A short description goes here" />
      </Head> */}
  <Header/>
  
  {state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
  
    
    <section className=" p-5 bg-light">
        <div className="">
       {(!state.detailFound  ) ? 
       <section className="text-center p-5 h-100">
          <h4 className="text-muted fw-bold">RECORD NOT FOUND</h4>
          
        </section>
  :
    <div className="container">
  {(state.shipmentDetail.viewer_type == 0)?
   
    <section>
      <div className="card">
        <div className="card-body ad_card_top shadow-sm">
          <div className="row">
              <div className="col-12 col-md-3 text-center">
                <img src="../../img/cat_icon/vehicles_desktop2x.png" className="img-fluid " />
              </div>
              <div className="col-12 col-md-6">
                  <h4>HAVE SOMETHING SIMILAR TO SHIP?</h4>
                  <p className="text-muted">Start by getting quotes from our network of trusted service providers.</p>
              </div>
              <div className="col-12  col-md-2 align-middle m-3">
                <a  className="btn btn-outline-success btn-block">Get Started <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
              </div>
          </div>
        </div>
      </div>
    </section>
    :""}
    {state.quotes.map((quote,index)=>
    
  <section>
    {( state.shipmentDetail.viewer_type==2 &&state.shipmentDetail.status==1 && quote.status==2)?
    <div className='row'>
      <div className='col-md-3'></div>
      {/* <div class="alert alert-danger" role="alert">
      Quotes Closed Now - Shipment Booked
</div> */}
      <div className='col-12 col-md-12 alert alert-danger' style={width<=768?{marginRight:"-10px",marginLeft:"-5px"}:{marginLeft:"10px"}} role="alert" ><h5 style={width<=768?{marginLeft:"50px"}:{margin:"0px"}} className="">Quotes Closed Now - Shipment Accepted</h5></div>
    <div className='col-md-3'></div>
    </div>
    :""}

   
  </section>
)}
 {state.transporter_detail.map((sub)=>(
 <section>
  { sub.dstatus==3  && state.shipmentDetail.viewer_type==2 ?
       
       <div className='col-12 col-md-6  mt-4' style={width<=768?{marginRight:"-10px",marginLeft:"-5px"}:{marginLeft:"10px"}}><div class="alert alert-danger text-center" role="alert"><h5 style={width<=768?{marginLeft:"50px"}:{margin:"0px"}}>
       Your Account is not Verified
       </h5>
   </div>
     </div>:""}
  </section>
  ))}

    <section >
      <div className="row">
   
        <div className="col">
          <div className="row itme_detail_head p-3 ">
                 
                
                <div className="col-7 col-md-6 "  >
                  <h2 style={width<=768?{marginLeft:"-20px"}:{marginLeft:"0px"}}>{state.shipmentDetail.title}</h2>
                  

                  <p className="text-muted" style={width<=768?{marginLeft:"-20px"}:{marginLeft:"0px"}}><a href="" className='text-decoration-none'><Link  to={"/profile/"+state.shipmentDetail.user_id}>{state.shipmentDetail.full_name}</Link></a> ID {state.shipmentDetail.invoice_id}</p>

                  {state.shipmentDetail.viewer_type==1 && state.shipmentDetail.status>=1 && dimensionState.companyDetail[0].chat_now ?
                  <Link to={`/chat-box/${quetState && quetState.user_id1!==undefined?quetState.user_id1:""}#!`}>
      <button className='btn btn-success' style={width<=768?{marginLeft:"-20px",marginRight:"-20px"}:{marginLeft:"0px"}}>Chat Now</button></Link>
              :""} 
              
              {state.shipmentDetail.viewer_type==2 && state.shipmentDetail.status>=1 && dimensionState.companyDetail[0].chat_now?
                   <Link to={`/chat-box/${state.shipmentDetail && state.shipmentDetail.user_id!==undefined?state.shipmentDetail.user_id:""}#!`}>
                   <button className='btn btn-primary' style={width<=768?{marginLeft:"-20px",marginRight:"-20px"}:{marginLeft:"0px"}}>Chat Now</button></Link>
              :""}
         {state.shipmentDetail.viewer_type==2 || state.shipmentDetail.viewer_type==1?
         <>
           {state.shipmentDetail.current_shipment_status!=undefined && state.shipmentDetail.current_shipment_status!="" && state.shipmentDetail.current_shipment_status >=1?
                   <Link style={{ color: 'white' }} to={"/tracking/" + state.shipmentDetail.invoice_id}>
                   <button className='btn btn-primary' style={width<=768?{marginLeft:"30px"}:{marginLeft:"20px"}}>Tracking</button></Link>
              :""}
         </>
         :""}
        


               </div>
                   
                

               
                <div className="col-5 col-md-6 text-end ">
                  <div style={width<=768?{marginRight:"-20px"}:{marginLeft:"0px"}}><span className={state.shipmentDetail.dstatus==1?"badge bg-success fw-normal":"badge bg-danger fw-normal"}>{state.shipmentDetail.dstatus==1?"Active":state.shipmentDetail.dstatus==2?"Inactive":""}</span></div>

                  <p style={width<=768?{marginRight:"-20px"}:{marginLeft:"0px"}}> {(currentDate < state.shipmentDetail.pickup_datetime_to) ?<small>{ endDate = state.shipmentDetail.pickup_datetime_to ,
                                       days =  endDate - currentDate ,
                                      day = parseInt(days /86400) , day != 0 ?  `${day} days :`  : "" } {Math.floor(days / 3600) % 24} hours : {Math.floor(days / 60) % 60 } mins 
                    left |</small> :""}<small> Listed  <Moment format="MMMM DD, yyyy" unix>{state.shipmentDetail.pickup_datetime_to}</Moment></small></p>
                  <small style={width<=768?{marginLeft:"-30px",marginRight:"-20px"}:{marginLeft:"0px"}}><b>{state.shipmentDetail.category_name}</b></small><br/>
                  <small style={width<=768?{marginLeft:"-30px",marginRight:"-20px"}:{marginLeft:"0px"}}>{state.shipmentDetail.subcategory_name}</small>
                </div>
                    
                 
          </div>


        {/* {state.shipmentDetail.viewer_type==1?
         <div>
     <div className="mx-2">{state.shipmentDetail.status>=1?<div className='fs-3'><a className='text-primary' href="" data-bs-toggle="collapse" data-bs-target="#info_box_">Click Here to Chat with your Booking Transport</a></div>:"" }</div>
     <div className='row collapse mx-2 mt-3' id="info_box_">
      <div className='col-md-3 text-muted fs-4 mt-1'>{(quetState && quetState.branch_name != undefined) ? quetState.branch_name : ""}</div>
    <div className='col-md-5 mt-2'> <p className="text-muted">{(quetState && quetState.last_seen!==undefined && quetState.last_seen != "online") ? <LastSeen time={quetState && quetState.last_seen!==undefined &&quetState.last_seen} /> : <span className="text-success"><i className="fa fa-circle" aria-hidden="true"></i> Online</span>}</p>
    </div>
    <div className='col-md-2'>
    <Link to={`/chat-box/${quetState && quetState.user_id1!==undefined?quetState.user_id1:""}#!`}>
      <button className='btn btn-success'>Chat Now</button></Link>
      </div>
     </div> 
     </div> :""}   

     {state.shipmentDetail.viewer_type==2?
         <div>
     <div className="mx-2">{state.shipmentDetail.status>=1?<div className='fs-3'><a className='text-primary' href="" data-bs-toggle="collapse" data-bs-target="#info_box_">Click Here to Chat with your Customer</a></div>:"" }</div>
     <div className='row collapse mx-2 mt-3' id="info_box_">
      <div className='col-md-3 mt-1 text-muted fs-4'>{(state.shipmentDetail && state.shipmentDetail.full_name != undefined) ? state.shipmentDetail.full_name : ""}</div>
    <div className='col-md-5 mt-2'> <p className="text-muted">{(state.shipmentDetail && state.shipmentDetail.last_seen!==undefined && state.shipmentDetail.last_seen != "online") ? <LastSeen time={state.shipmentDetail && state.shipmentDetail.last_seen!==undefined &&state.shipmentDetail.last_seen} /> : <span className="text-success"><i className="fa fa-circle" aria-hidden="true"></i> Online</span>}</p>
    </div>
    <div className='col-md-2'>
    <Link to={`/chat-box/${state.shipmentDetail && state.shipmentDetail.user_id!==undefined?state.shipmentDetail.user_id:""}#!`}>
      <button className='btn btn-primary'>Chat Now</button></Link>
      </div>
     </div> 
     </div> :""}    */}
  <hr/>
  {(state.shipmentDetail.viewer_type==1 && state.shipmentDetail.dstatus == 1)?
     <ul className="nav nav-pills nav-fill c_breadcrumb" style={width<=768?{marginLeft:"-20px",marginRight:"-20px"}:{marginLeft:"10px"}}>
                  {( state.shipmentDetail.status >= 0) ?
                    <li className="nav-item">
                      <a className={"nav-link "+(state.shipmentDetail.status == 0 ? "active":"")} data-bs-toggle="pill" href="#active">Active</a>
                    </li>
                    : ""}
                  {(state.shipmentDetail.status >= 1 && state.shipmentDetail.current_shipment_status >= 0) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.current_shipment_status == 0  || state.shipmentDetail.current_shipment_status == 1 ? "active":"")} data-bs-toggle="pill" href="#booked">Booked</a>
                    </li>
                    : ""}
                    {(state.shipmentDetail.status >= 1 && state.shipmentDetail.current_shipment_status >= 2) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.current_shipment_status == 2   ? "active":"")} data-bs-toggle="pill" href="#pickupinprogress">Pick Up In Progress </a>
                    </li>
                    : ""}
                  {(state.shipmentDetail.status >= 1 && state.shipmentDetail.current_shipment_status >= 3) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.current_shipment_status == 3  ? "active":"")} data-bs-toggle="pill" href="#pickedup">Picked Up</a>
                    </li>
                    : ""}
                    {(state.shipmentDetail.status >= 1&& state.shipmentDetail.current_shipment_status >= 4) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.current_shipment_status == 4  ? "active":"")} data-bs-toggle="pill" href="#intransit">In Transit</a>
                    </li>
                    : ""}
                     {(state.shipmentDetail.status >= 1&& state.shipmentDetail.current_shipment_status> 4|| state.shipmentDetail.current_shipment_status==8) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.current_shipment_status == 8  ? "active":"")} data-bs-toggle="pill" href="#ontheway">Out For Delivery</a>
                    </li>
                    : ""}
                    {(state.shipmentDetail.status >= 1&& state.shipmentDetail.current_shipment_status == 5) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.current_shipment_status == 5  ? "active":"")} data-bs-toggle="pill" href="#delivered">Delivered</a>
                    </li>
                    : ""}

                    {/* {(state.shipmentDetail.status >= 1&& state.shipmentDetail.current_shipment_status >= 6) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.current_shipment_status == 6  ? "active":"")} data-bs-toggle="pill" href="#delivered">Cancelled</a>
                    </li>
                    : ""}
                    

                  {(state.shipmentDetail.status >= 1&& state.shipmentDetail.current_shipment_status >= 7) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.current_shipment_status == 6  ? "active":"")} data-bs-toggle="pill" href="#returned">Returned</a>
                    </li>
                    : ""} */}
                     
                </ul>
                     
:""}


{(state.shipmentDetail.viewer_type==1)?
                <div className="tab-content card mt-3 shadow-sm" style={width<=768?{marginLeft:"-10px",marginRight:"-10px"}:{marginLeft:"10px"}}>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.status == 0?"active":"fade")} id="active">
                    {(state.questions.length > 0) ? 
                    <div className="alert alert-info">
                      <strong><i className="fa fa-question-circle" aria-hidden="true"></i></strong> You have <a  className="alert-link"> questions</a> to answer.
                    </div>
                    :""}
                      {dimensionState.companyDetail.map((subscriber)=>(
                    <div className="alert alert-warning">
                 
                      <strong><i className="fa fa-info-circle" aria-hidden="true"></i></strong> You have received <a  className="alert-link">{state.shipmentDetail.total_quotes}quotes</a> {(state.shipmentDetail.total_quotes>0)? "as low as" :""}{subscriber.set_currency}{state.shipmentDetail.lowest_quote}
                 
                    </div>
                      ))}
                    {/* <div className="alert alert-primary">
                      <strong><i className="fa fa-map-marker" aria-hidden="true"></i></strong> <a href="#" className="alert-link">Confirm</a> your exact street address
                    </div> */}
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.status == 1 && state.shipmentDetail.current_shipment_status==0 || state.shipmentDetail.current_shipment_status==1?"active":"fade")} id="booked">

                  <h3>Your shipment has been booked with <Link to={`/carrier/${state.shipmentDetail.transporter_id}`}>{(quetState && quetState.branch_name != undefined) ? quetState.branch_name : ""}</Link>
                  </h3>

                 <h6>Tracking #{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0}  {currentState.time1.map((sub)=>( <small>Date: {sub.current_date}     Time: {sub.time}</small>))}</h6>
                   <hr />
                   <div className="text-end">
                      <h4>{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0}</h4>
                      <table className="table p-5">
                        <thead>
                        </thead>
                        {dimensionState.companyDetail.map((sub)=>(
                        <tbody>
                          <tr>
                            <td>Total quote</td>
                            <td>{sub.set_currency}{(quetState && quetState.cost != undefined) ? quetState.cost : 0}</td>
                          </tr>
                          <tr>
                            <td>Service charge</td>
                            <td>{sub.set_currency}{(quetState && quetState.service_charge != undefined) ? quetState.service_charge : 0}</td>
                          </tr>
                          <tr>
                            <th>Gross Total</th>
                            <th>{sub.set_currency}{(quetState && quetState.cost != undefined) ? (quetState.cost + quetState.service_charge) : 0}</th>
                          </tr>
                          <tr>
                            <td>Paid for booking</td>
                            <td>{sub.set_currency}{(state.shipmentDetail.admin_advance !="" && state.shipmentDetail.admin_advance!=undefined && state.shipmentDetail.transporter_advance!="" && state.shipmentDetail.transporter_advance!=undefined && quetState && quetState.service_charge != undefined) ? state.shipmentDetail.admin_advance + state.shipmentDetail.transporter_advance + quetState.service_charge : 0}</td>
                          </tr>
                          <tr>
                            <th>To be paid</th>
                            <th className="fs-4">{sub.set_currency}{state.shipmentDetail.admin_advance !="" && state.shipmentDetail.admin_advance!=undefined && state.shipmentDetail.transporter_advance!="" && state.shipmentDetail.transporter_advance!=undefined && quetState && quetState.service_charge != undefined && quetState && quetState.cost != undefined? (quetState.cost +  quetState.service_charge) - (state.shipmentDetail.admin_advance + state.shipmentDetail.transporter_advance +quetState.service_charge ) : 0}</th>
                          </tr>
                        </tbody>
                        ))}
                      </table>
            </div>
          </div>
          <div className={"tab-pane container p-3 "+(state.shipmentDetail.current_shipment_status == 2?"active":"fade")} id="pickupinprogress">
                    <h3>Your shipment is Pick up in Progress <a href=""></a></h3>
                    <h6>Tracking #{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0}  {currentState.time2.map((sub)=>(<small>Date: {sub.current_date}     Time: {sub.time}</small>))}</h6>
                    {state.shipmentDetail.driver_detail.map((item,index)=><div>Driver Name: {item.driver_name} , Mob: {item.mobile_number}</div>)}
                  </div>
          <div className={"tab-pane container p-3 "+(state.shipmentDetail.current_shipment_status == 3?"active":"fade")} id="pickedup">
                    <h3>Your shipment has been Picked up by <a href=""></a></h3>
                    <h6>Tracking #{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0}  {currentState.time3.map((sub)=>(<small>Date: {sub.current_date}     Time: {sub.time}</small>))}</h6>
                    {state.shipmentDetail.driver_detail.map((item,index)=><div>Driver Name: {item.driver_name} , Mob: {item.mobile_number}</div>)}

                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.current_shipment_status == 4?"active":"fade")} id="intransit">
                    <h3>Your shipment is In Transit <a href=""></a></h3>
                    <h6>Tracking #{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0}   {currentState.time4.map((sub)=>(<small>Date: {sub.current_date}     Time: {sub.time}</small>))}</h6>
                    {state.shipmentDetail.driver_detail.map((item,index)=><div>Driver Name: {item.driver_name} , Mob: {item.mobile_number}</div>)}

                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.current_shipment_status == 5?"active":"fade")} id="delivered">
                    <h3>Your shipment has been Delivered by <a href=""><Link to={`/carrier/${state.shipmentDetail.transporter_id}`}>{(quetState && quetState.branch_name != undefined) ? quetState.branch_name : ""}</Link></a> 
                    </h3>
                    <h6>Tracking #{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0}  {currentState.time5.map((sub)=>(<small>Date: {sub.current_date}     Time: {sub.time}</small>))}</h6>
                    {state.shipmentDetail.driver_detail.map((item,index)=>    <p>Driver Name: {item.driver_name} , Mob: {item.mobile_number} </p>)}
                
                    {/* <p><a href="">ePOD sign</a> |  <a href="">ePOD picture</a></p> */}
                    <p className='text-primary'>  {state.shipmentDetail.review_detail!==undefined && state.shipmentDetail.review_detail.length>0?"Your Reviews":"Rate your experience. Give a review."}</p>
                    
                  
                    <section className='p-2 row ' >


<div class="card" >
<div class="card-body">
<h3 className='mt-2 mb-4'>REVIEW </h3>

{state.shipmentDetail.review_detail!==undefined && state.shipmentDetail.review_detail.length>0?
<div>
{state.shipmentDetail.review_detail.map((sub)=>(

<section>


<div class="d-flex justify-content-between">

<div class=" ">
<h4>{state.shipmentDetail.full_name}</h4>
<p class="text-primary"><ReactStarsRating value={sub.rating} isEdit={false} /></p>
<p></p>
</div>


</div>

<h5>{state.shipmentDetail.title}</h5>

<p>{sub.review_description}</p>
<p class="text-end text-muted mx-2"><small>Was this helpful? {state.shipmentDetail.isLiked || state.shipmentDetail.notLiked?<i className="fa fa-thumbs-o-up text-primary"></i>:<i class="fa fa-thumbs-o-up text-primary" aria-hidden="true" onClick={()=>handleLiked(state.shipmentDetail.transporter_id,state.shipmentDetail.invoice_id)}></i>} {state.shipmentDetail.isLiked ?(state.shipmentDetail.isLiked):"0"} &nbsp;&nbsp;&nbsp; {state.shipmentDetail.notLiked||state.shipmentDetail.isLiked?<i class="fa fa-thumbs-o-down text-primary"></i>:<i class="fa fa-thumbs-o-down text-primary" aria-hidden="true" onClick={()=>handlenotLiked(state.shipmentDetail.transporter_id,state.shipmentDetail.invoice_id)}></i>}  {state.shipmentDetail.notLiked?(state.shipmentDetail.notLiked):"0"}</small></p>

{ state.shipmentDetail.transporter_review!==undefined && state.shipmentDetail.transporter_review.length>0?
<div>
{state.shipmentDetail.transporter_review.map((sub)=>(


<div class="p-3 ml-30 mb-5 ml-5 bg-light  border-start border-primary border-5 ">



<h4 className='text-uppercase'>{quetState.branch_name}</h4>

<small>{sub.transporter_review_description}</small>

</div>


))}
</div>
:
""}

    </section>
   ))}</div>:<>
    
    <ReactStarsRating onChange={onChange} value={rating} isHalf={false}  />

   <textarea placeholder="Type your review here" className="form-control mt-2" rows="3" cols="3" name="review_description" onChange={handleChange5}></textarea>
   <button className="btn btn-primary mt-2 mb-3" onClick={()=>handleSubmitreview(state.shipmentDetail.invoice_id,state.shipmentDetail.transporter_id)}>Submit</button>
       </>
        }</div></div></section>
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.current_shipment_status == 6?"active":"fade")} id="cancelled">
                    <h3>Your shipment has been Cancelled</h3>
                    <h6><small>Date: 28-03-2022 18:30</small></h6>
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.current_shipment_status == 7?"active":"fade")} id="returned">
                    <h3>Your shipment has been Returned</h3>
                    <h6><small>Date: 28-03-2022 18:30</small></h6>
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.current_shipment_status == 8?"active":"fade")} id="ontheway">
                    <h3>Your shipment is Out For Delivery</h3>
                    <h6>Tracking #{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0}   {currentState.time4.map((sub)=>(<small>Date: {sub.current_date}     Time: {sub.time}</small>))}</h6>
                    {state.shipmentDetail.driver_detail.map((item,index)=><div>Driver Name: {item.driver_name} , Mob: {item.mobile_number}</div>)}
                  </div>
        </div>
        :""}
        <br/>
  
          <div className="row" style={width<=768?{marginLeft:"-20px",marginRight:"-20px"}:{marginLeft:"0px"}}>
            <div className="col itme_detail_box ">
              <div className="card">
                <div className="card-body p-4 shadow-sm">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h6 className="text-muted pb-2">Pickup</h6>
                            <div className="d-flex pb-4">
                              <div className="flex-shrink-0">
                                <i className="fa fa-arrow-right rotate_40 text-muted" aria-hidden="true"></i>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>{state.shipmentDetail.pickup_address}</h3>
                                <p className="text-muted ">{state.shipmentDetail.pickup_address_type}</p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <i className="fa fa-clock-o rotate_40 text-muted" aria-hidden="true"></i>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>Between <Moment format="MMMM DD" unix>{state.shipmentDetail.pickup_datetime_from}</Moment> - <Moment format="MMMM DD" unix>{state.shipmentDetail.pickup_datetime_to}</Moment></h3>
                              </div>
                            </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <h6 className="text-muted pb-2">Delivery</h6>
                        <div className="d-flex pb-4">
                          <div className="flex-shrink-0">
                            <i className="fa fa-arrow-down rotate_40 text-muted" aria-hidden="true"></i>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h3>{state.shipmentDetail.delivery_address}</h3>
                            <p className="text-muted ">{state.shipmentDetail.delivery_address_type}</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <i className="fa fa-clock-o rotate_40 text-muted" aria-hidden="true"></i>
                          </div>
                          <div className="flex-grow-1 ms-3">
                        <h3>Between <Moment format="MMMM DD" unix>{state.shipmentDetail.delivery_datetime_from}</Moment> - <Moment format="MMMM DD" unix>{state.shipmentDetail.delivery_datetime_to}</Moment></h3>
                            
                          </div>
                        </div>
                    </div>
                  </div>
  
                  <div className="row pt-3 ">
                    <div className="col">
                    {(state.shipmentDetail.toLocCord != undefined && state.shipmentDetail.toLocCord.length > 0) ?   <Mapsecond data={[state.shipmentDetail.toLocCord[0],state.shipmentDetail.fromLocCord[0]]}/>:""}
                    </div>
                  </div>
  
                  <div className="row p-4 text-center">
                    <div className="col-12 mb-2 col-md-4 border-end">
                      <span className=""><i className="fa fa-2x fa-map-marker text-muted" aria-hidden="true"></i></span>
                      <span className="fs-2 p-1">{state.shipmentDetail.distance}</span>
                      {dimensionState.companyDetail.map((subscriber)=>(
                      <span className="text-uppercase crack"> {subscriber.set_distance}   total </span>
                      ))}
                    </div>
                    <div className="col-12 mb-2 col-md-4 border-end">
                      <span className=""><i className="fa fa-2x fa-th-large text-muted" aria-hidden="true"></i></span> 
                      <span className="fs-2 p-1">{state.shipmentDetail.qty}</span>
                      <span className="text-uppercase crack">total Items</span>
                    </div>
                    <div className="col-12 mb-2 col-md-4 ">
                      <span className=""><i className="fa fa-2x fa-cube text-muted" aria-hidden="true"></i></span>
                      <span className="fs-2 p-1">{state.shipmentDetail.item_weight}</span>
                     {dimensionState.companyDetail.map((subscriber)=>(
                      <span className="text-uppercase crack">total weight in ({subscriber.set_weight})</span>
                     ))}
                    </div>
                  </div>
  
  
                </div>
              </div>
            </div>
          </div>
          
        <section className="mt-4 shadow-sm shipment_info_box" style={width<=768?{marginRight:"-10px",marginLeft:"-10px"}:{marginLeft:"10px"}}>
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body p-4">
                  <h2 className="fs-5 text-uppercase">Shipment details</h2>
                  <hr/>
                  <div className="row">
                    <div className="col-12 col-md-3 text-muted">
                      Details
                    </div>
                    <div className="col">
                      <div className="row">
                        <h5 className=" f-w-500 pb-4">{state.shipmentDetail.title}</h5>
                        <div className="col-12 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>category</small></h6>
                          <small className=" ">{state.shipmentDetail.category_name}</small>
                          <p className=" f-w-500">{state.shipmentDetail.subcategory_name}</p>

                        </div>
                        <div className="col-12 col-md-4">
                          
                        {dimensionState.companyDetail.map((subscriber)=>( 
                 <h6 className="fw-normal text-uppercase text-muted fs-m"><small>TOTAL WEIGHT in ({subscriber.set_weight})</small></h6>
                         ))}  {dimensionState.companyDetail.map((subscriber)=>( <p className=" f-w-500">{state.shipmentDetail.item_weight} {subscriber.set_weight}</p>
                        ))}
                        </div>
                        <div className="col-12 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>TOTAL QUANTITY</small></h6>
                          <p className=" f-w-500">{state.shipmentDetail.qty}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>

                  <div className="row">
                    <div className="col-md-3 col-12 text-muted">
                      Item
                    </div>
                    <div className="col">
                    {state.shipmentDetail.products.map((item,index)=>
                    <>
                      <div className="row">
                        <h6 className=" f-w-500 ">Item {index+1}: 
                        {/* {state.shipmentDetail.title} */}
                        </h6>
                        <div className="col-12 col-md-4">
                        {dimensionState.companyDetail.map((subscriber)=>( 
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>EST. DIMENSION in ({subscriber.set_dimension})</small></h6>
                         ))}  {dimensionState.companyDetail.map((subscriber)=>( 
                          <>
                          <p className=" f-w-500">{item.dimensions[0].item_height} {subscriber.set_dimension} x {item.dimensions[0].item_length} {subscriber.set_dimension} x {item.dimensions[0].item_width} {subscriber.set_dimension}</p>
                       
                  <hr/>
                     </>
                         ))}
                        </div>
                        {/* <div className="col-6 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>CONDITION</small></h6>
                          <p className=" f-w-500">Operable</p>
                        </div> */}
                        <div className="col-12 col-md-4">
                          
                        {dimensionState.companyDetail.map((subscriber)=>( 
                 <h6 className="fw-normal text-uppercase text-muted fs-m"><small>EST. WEIGHT in ({subscriber.set_weight})</small></h6>
                         ))}   {dimensionState.companyDetail.map((subscriber)=>( <p className=" f-w-500">{item.dimensions[0].item_weight}  {subscriber.set_weight}</p>
                         ))}
                        </div>
                       
                        <div className="col-12 col-md-4">
                        {dimensionState.companyDetail.map((subscriber)=>( 
                         <h6 className="fw-normal text-uppercase text-muted fs-m"><small>EST. value in ({subscriber.set_currency})</small></h6>
                        ))}   {dimensionState.companyDetail.map((subscriber)=>(   <p className=" f-w-500">{subscriber.set_currency} {item.dimensions[0].item_value}</p>
                        ))}
                            
                          
                        </div>
                      
                      </div>
                         <div className='row'>
                         {item.attributes.map((attr)=>( 
                          
                 <div className="col-lg-4 col-4">
                 <h6 className="fw-normal text-uppercase text-muted fs-m">  {attr.name}: </h6><h6 >  <small> {attr.value} </small></h6>    
                 </div>
                 
                         ))}
                 </div>
                 </>
                      
                   
 )}
                  </div>
                 
                  </div>

                  <hr/>
                  <div className="row">
                    <div className="col-md-3 col-12 text-muted">
                      Photos
                    </div>
                    <div className="col">
                      <div className="row">
                      {state.shipmentDetail.item_imgs.length>0?

                        <div className="col-12 col-md-12">
                            
                        {state.shipmentDetail.item_imgs.map((items, indexItem) => (
                            <div key={indexItem}><img src={`https://shippxcdn.fniix.com/web/${items}`} className="mb-2 img-thumbnail" onClick={() => setStateModal({show: true})}/></div>
                          ))}
                        </div>
                        :<h6 className=" f-w-500 pb-4 text-muted">No Photos Uploaded</h6>}
                      </div>
                      
                    </div>
                  </div>
  
                  <hr/>
                  <div className="row">
                    <div className="col-md-3 col-12 text-muted">
                      Description
                    </div>
                    <div className="col">
                      <div>
                        <h6 className="fw-normal text-uppercase text-muted fs-m"><small>REQUESTED SERVICE TYPE(S)</small></h6>
                        <p className=" f-w-500">{state.shipmentDetail.service_type}</p>
                      </div>
                      <div>
                        <h6 className="fw-normal text-uppercase text-muted fs-m"><small>SPECIAL INSTRUCTIONS
                        </small></h6>
                        <p className="">{state.shipmentDetail.description}.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
  {(state.shipmentDetail.viewer_type == 2 && state.shipmentDetail.dstatus==1 && state.shipmentDetail.status==0 ) ? 
        <section className="mt-4 shadow-sm "  style={width<=768?{marginLeft:"-20px",marginRight:"-10px"}:{marginLeft:'10px'}}>
          <div className="card  ">
            <div className="card-body">
              
              <section className="p-3  bg-light  border-start border-primary border-5 add_quote">
              <h2 className="fs-5 text-uppercase text-primary">Submit Quote</h2>
              <div className="row">
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-8">
                  <section className="text-center">
                    <label for="" className="form-label">Enter Bid Value</label>
                    <div className="input-group input-group-lg mb-3 text-center col-md col-12">
                      <span className="input-group-text " id="basic-addon1"
                        ><i className="fa fa-usd" aria-hidden="true"></i
                      ></span>
                      {/* for desktop  */}
                      <input
                        type="number"
                        className="form-control "
                        placeholder={  width<=768?" amount":"enter your quote amount"}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="cost"
                        value={stateSave.cost}
                        onChange={(e)=>handleChange(e)}
                      />
                    <button className="btn btn-primary " disabled={stateSave.sendQuoteButton}type="submit" onClick={(e)=>addNewshipmentQuote(e)}>Send <span className="">Quote</span></button>
                     
                    </div>
                  </section>
                </div>
                <div className="col-12 col-md-2"></div>
              </div>
                <hr/>
  
                <div className="row">
                  <div className="col">
                    <h6 className="mt-2">TIME FRAME</h6>
                    <div className="d-flex justify-content-between"></div>
                      <div className="row">
                        <div className='col-12 col-md'>
                        <p>Pickup Within: </p></div>
                    
                        <div className='col-11 col-md '>
                        <div className="input-group">
                        <select className="form-select form-select-sm" name="pickup_date" onChange={(e)=>handleChange(e)}>
                          <option value="6">6 </option>
                          <option value="5">5 </option>
                          <option value="4">4 </option>
                          <option value="3">3 </option>
                          <option value="2">2 </option>
                          <option value="1">1 </option>
                        </select>
                          <span className="input-group-text">days of booking</span>
                        </div>
                        </div>
                      </div>
                    
                    <div className="d-flex justify-content-between"></div>
                      <div className="row">
                        <div className='col-12 col-md'>
                        <p>Delivery Within: </p></div>
                      <div className="col-11 col-md">
                        <div className="input-group">
                        <select className="form-select form-select-sm" name="delivery_date" onChange={(e)=>handleChange(e)}>
                          <option value="6">6 </option>
                          <option value="5">5 </option>
                          <option value="4">4 </option>
                          <option value="3">3 </option>
                          <option value="2">2 </option>
                          <option value="1">1 </option>
                        </select>
                          <span className="input-group-text">days of booking</span>
                        </div>
                        </div>
                      </div>
                    
                    
                    <hr/>
                    <div className="d-flex justify-content-between"></div>
                      <div className="row ">
                        <div className='col-12 col-md'>
                        <h6 className="mt-2">VEHICLE TYPE</h6></div>
                      <div className=" col-12 col-md">
                        <select className="form-select form-select-sm" name="vehicle_type" onChange={(e)=>handleChange(e)}>
                          <option>Multi-car open trailer</option>
                        </select>
                      </div>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-between"></div>
                      <div className=" row">
                        <div className='col-12 col-md'>
                        <h6 className="mt-2">PAYMENT METHOD</h6></div>
                      <div className="col-12 col-md ">
                        <select className="form-select form-select-sm" name="payment_method" onChange={(e)=>handleChange(e)}>
                          <option>Cash Payments</option>
                          <option>Paypal </option>
                          <option>Stripe </option>
                        </select>
                      </div>
                    </div>
                  </div>
               
                  <div className="col">
                    <hr/>
                    <div className="d-flex justify-content-between"></div>
                      <div className=" row">
                        <div className='col-12 col-md'>
                        <h6 className="mt-2">TRANSPORT MODE</h6></div>
                      <div className="col-12 col-md ">
                      <select className="form-select form-select-sm" name="transport_mode" onChange={(e)=>handleChange(e)}>
                        <option value="Trucking" >Trucking</option>
                        <option value="Sea">Sea </option>
                        <option value="Air">Air </option>
                      </select>
                      </div>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-between"></div>
                      <div className="row ">
                        <div className='col-12 col-md'>
                        <h6 className="mt-2">SERVICE TYPE</h6></div>
                      <div className="col-12 col-md ">
                      <select className="form-select form-select-sm" name="service_type" onChange={(e)=>handleChange(e)}>
                        <option value="Open Transport">Open Transport</option>
                        <option value="Close Transport">Close Transport </option>
                      </select>
                      </div>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-between"></div>
                      <div className=" row">
                        <div className='col-12 col-md'>
                        <h6 className="mt-2">PAYMENT ACCEPTED</h6></div>
                      <div className=" col-12 col-md">
                      <select className="form-select form-select-sm" name="payment_accepted" onChange={(e)=>handleChange(e)}>
                        <option value="At Pickup">At Pickup</option>
                        <option value="At Delivery">At Delivery </option>
                      </select>
                      </div>
                    </div>
                  </div>
              </div>
              </section>
            </div>
          </div>
        </section>
  :""}
        <section className="mt-4 shadow-sm" style={width<=768?{marginLeft:"-10px",marginRight:"-10px"}:{marginLeft:'10px'}}>
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h2 className="fs-5 text-uppercase">Quotes</h2>
                  <hr/>
                  {state.quotes.length>0?
                  <section>
                  {state.quotes.map((quote,index)=>
                  <div className="card transporter_item mb-3" key={index}>
                     <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-md-2">
                          <img src={"https://shippxcdn.fniix.com/web/"+quote.logo} className="img-fluid rounded-circle border" />
                          </div>
                          <div className="col-12 col-md-4 border-end">
                          <h2><a href="" className="text-dark"><Link to={`/carrier/${quote.transporter_id}`}>{quote.branch_name}</Link></a></h2>
                          <span>
                   <ReactStarsRating value={quote.total_ratings!==0 && quote.total_reviews!==0?(quote.total_ratings/ quote.total_reviews):"0"} isEdit={false} isHalf={true} className="tab-content"/> 

                          </span>
                          
                          <small><a href="" className=""> </a></small>
                          </div>
                          <div className="col-12 col-md-3">
                          <h6 className="text-muted pb-3 fw-light">{state.shipmentDetail.service_type}</h6>
                          <h3 className=" pb-1">Estimated Pickup</h3>
                          <p className="fw-light">Within {quote.pickup_date} days of booking</p>
                        </div>
                        <div className="col-12 col-md-3 text-end">
                        {dimensionState.companyDetail.map((subscriber) => (
               
                        (quote.status == 3) ?  <h1><s>{subscriber.set_currency}{quote.cost}</s></h1>:

                          <h2>{subscriber.set_currency}{quote.cost}</h2>
                         
                          ))} 
                          <small className="text-muted"></small>
                        </div>
                        </div>
                        <hr/>
                        {(state.shipmentDetail.viewer_type==1)?
                        <div className="row">
                       
                        <div className="col-md-6 col-12">
                          <div className="card bg-light">
                            <div className="card-body">
                              <table className="table">
                                <tbody>
                                  <tr>
                                  {dimensionState.companyDetail.map((subscriber) => (
                                    <th>Quote:  {subscriber.set_currency}{quote.cost}</th>
                                     
                                  ))}
                                  </tr>
                                  {/* <tr>
                                  {dimensionState.companyDetail.map((subscriber) => (
                                    <th>Service charge:  {subscriber.set_currency}100</th>
                      
                                    ))}
                          
                                  </tr> */}
                                  <tr>
                                  {dimensionState.companyDetail.map((subscriber) => (
                                    <th>Total:  {subscriber.set_currency}{quote.cost}</th>
                                    ))}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          
                          </div>
                          
                         
                          {(state.shipmentDetail.dstatus == 1 && state.shipmentDetail.status == 0 && quote.status == 1) ?
                        <div className="col-md-6 col-12 text-center pt-2">
                          
                          <p><small className="text-muted">Payment METHOD: {quote.payment_method}</small></p>
                          
                          <div className="d-flex p-3 justify-content-center"></div>
                          <div className='row'>
                            <div className=" col-12 p-2 col-md">
                            <a className="btn btn-outline-success btn-lg" onClick={() => acceptQuotation(quote, state.transporter_commission,state.admin_commission)}>Accept</a>

                            </div>
                            <div className="p-2 col-12 col-md ">
                              <div className="btn-group">
                                <button type="button" className="btn btn-outline-danger btn-lg dropdown-toggle" data-bs-toggle="dropdown">Decline</button>
                                <div className="dropdown-menu">
                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id, transporter_id: quote.transporter_id, status: 3, report: "other" })}>Other</a>

                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id, transporter_id: quote.transporter_id, status: 3, report: "Price too high" })}>Price too high</a>

                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id ,transporter_id: quote.transporter_id, status: 3, report: "Insufficient feedback" })}>Insufficient feedback</a>

                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id , transporter_id: quote.transporter_id, status: 3, report: "Dates not compatible" })}>Dates not compatible</a>

                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id , transporter_id: quote.transporter_id, status: 3, report: "Shipping no longer required" })}>Shipping no longer required</a>

                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id , transporter_id: quote.transporter_id, status: 3, report: "Insufficient communication" })}>Insufficient communication</a>

                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id , transporter_id: quote.transporter_id, status: 3, report: "Inadequate insurance" })}>Inadequate insurance</a>

                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id , transporter_id: quote.transporter_id, status: 3, report: "Inadequate equipment" })}>Inadequate equipment</a>

                                                <a className="dropdown-item" onClick={() => declinehandleQuotation({ invoice_id: quote.invoice_id,quotes_id:quote.quotes_id , transporter_id: quote.transporter_id, status: 3, report: "I need to make changes to this shipment" })}>I need to make changes to this shipment</a>
                                              </div>
                            </div>
                          </div>
                          </div>

                          <small>By booking this quote, you agree to the provider's <Link to={`/carrier_terms/${quote.transporter_id}`}><a href="">Terms & Conditions</a></Link> and the <Link to={`/carrier_terms/${quote.transporter_id}`}><a href="">User Agreement</a></Link></small>
                        
                          </div>
                        : <div className='col-12 col-md'>{state.shipmentDetail.status != 0 && quote.status == 2 ?  <span class="badge rounded-pill bg-success">Accepted</span> :state.shipmentDetail.status == 0 && quote.status == 3 ?  <span class="badge rounded-pill bg-danger">Rejected</span>:""}
                    </div>}
                    

                      </div>
                      :""}
                      <hr/>
                        <div className="row">
                          <div className="col-6 col-md-2">
                            {/* <a href="" className="text-muted" data-bs-toggle="tooltip" title="Feedback Score"><i className="fa fa-star" aria-hidden="true"></i> 2983</a>  */}
                          </div>
                          <div className="col-6 col-md-2">
                            {/* <a href="" className="text-muted" data-bs-toggle="tooltip" title="Positive Feedback "><i className="fa fa-check" aria-hidden="true"></i> 29%</a> */}
                          </div>
                           <div className="col-6 col-md-2">
                            {/* <a href="" className="text-muted" data-bs-toggle="tooltip" title="Cancellation Rate"><i className="fa fa-times" aria-hidden="true"></i> 11%</a> */} 
                          </div>
                          <div className="col-6 col-md-2">
                            {/* <a href="" className="text-muted" data-bs-toggle="tooltip" title="Question"><i className="fa fa-question-circle" aria-hidden="true"></i> 0</a> */}
                          </div>
                          <div className="col-12 col-md-4 text-end">
                          <a href="" className="text-uppercase" data-bs-toggle="collapse" data-bs-target={`#quote_detail_box_${index}`}>Show Quote Details</a>
                        </div>
  
                        <div id={`quote_detail_box_${index}`} className="collapse">
                       
                          <section className="mt-3 quote_detail_box"  id="demo" >
                            
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href={`#details_1_${index}`}>Details</a>
                              </li> 
                              <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href={`#questions_1_${index}`}>Questions</a>
                                
                              
                              </li>
                             

                            </ul>
  
                            
                            <div className="tab-content mt-3">
                              <div className="tab-pane container active" id={`details_1_${index}`}>
                                <p className="fs-12 text-end text-muted">Quote Placed {quote.date} {quote.time}<br/>
                                  {/* Quote Expires Apr 7, 2022, 7:21 PM<br/> */}
                                  Quote ID: {quote.quotes_id}</p>
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <h5>TIME FRAME</h5>
                                    <p>Pickup: Within {quote.pickup_date} days of booking</p>
                                    <p>Delivery: Within {quote.delivery_date} days of pickup</p>
                                    <hr/>
                                    <h5>TRANSPORT MODE</h5>
                                    <p>{quote.transport_mode}</p>
                                    <hr/>
                                    <h5>VEHICLE TYPE</h5>
                                    <p>{quote.vehicle_type}</p>
                                    <hr/>
                                    <h5>SERVICE TYPE</h5>
                                    <p>{quote.service_type}</p>
                                    <hr/>
                                    <h5>PAYMENT METHOD</h5>
                                    <p>{quote.payment_method}</p>
                                    <hr/>
                                    <h5>PAYMENT ACCEPTED</h5>
                                    <p>{quote.payment_accepted}</p>
                                  </div>
                                  <div className="col-12 col-md-6 mt-2">
                                    <h5>NOTE TO SHIPPING CUSTOMER</h5>
                                    <p className="mt-3">{quote.note} </p>
                                    <hr/>
                                    <p className="mt-3 text-end"><Link to={`/carrier/${quote.transporter_id}`}><a >View Our Profile</a></Link></p>
                                  </div>
                              </div>
                              
                              </div>
                             
                              <div className="tab-pane container fade"  id={`questions_1_${index}`} >
                              {quote.question.length>0?
                                  <div className=" p-3" key={index}>
                                    {quote.question.map((sub,index)=>(
                                  
                                  <div className="p-2">
                                  
                                    <p className="pt-3"> Ques:  {sub.question}</p>
                                    {sub.answer.length>0?
                                    <p className="mt-1"> Answer:     {sub.answer} </p> :""}
                                      {sub.answer.length>0?
                                    <small className='text-muted ' >By: {state.shipmentDetail.full_name}</small> :""} 
                                    <hr/>
                                  </div>
                                
                                  ))}
                                </div>
                                  :<h6 className="text-muted fw-bold">No Questions</h6>} 
                                 <hr/>
                                
                              </div>
                             
                            </div>
                          </section>
                          
                         
                          </div>
  
                        </div>
                      </div>
                    </div>
                  )}
                  </section>
                  : <section className="text-center p-5 h-100">
                  <h4 className="text-muted fw-bold">NO QUOTES YET</h4>
                  
                </section>}
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        {state.shipmentDetail.viewer_type==2 ?
        <section className="mt-4 shadow-sm" style={width<=768?{marginLeft:"-10px",marginRight:"-10px"}:{marginLeft:'10px'}} >
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h2 className="fs-5 text-uppercase"> Ask Questions</h2>
                  <hr/>
                  <section className="text-center">
                    <div className="input-group input-group-lg mb-3 text-center">
                      
                      <textarea
                        
                        className="form-control"
                        placeholder="Ask Your Question"
                        
                        name="question"
                        value={question || ""}
                        onChange={handleChange3}
                        rows="3"
                      ></textarea>
                      
                    </div>
                    
                  </section>
                 <button className="btn btn-primary mb-2" type="submit" onClick={(event)=>addquestion(event)}>Submit </button>
                
                </div>
              </div>
            </div>
          </div>
        </section>
        :""}
  
        
  
          
        <section className="mt-4 shadow-sm" style={width<=768?{marginLeft:"-10px",marginRight:"-10px"}:{marginLeft:'10px'}}>
          <div className="row">
            <div className="col">
              <div className="card">
              <div className="card-body">
              <h2 className="fs-5 text-uppercase">Questions</h2>
                  {state.questions.length>0?
                  <section>
                  {state.questions.map((item,index)=>
                  <>
                  
                  
                  
                  <div className="d-flex p-3" key={index}>
                    <div className="p-2 text-muted">
                      <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
                    </div>
                    <div className="p-2">
                      <h6 className="m-0">{item.full_name}</h6>
                      <small className="text-muted"><Moment format="MMMM DD, YYYY hh:mm:ss" unix>{item.created}</Moment></small>
                      <p className="pt-3"> Ques:  {item.question}</p>
                      {item.answer.length>0?
                      <p className="pt-3"> Answer:     {item.answer} </p> :""}
                        {item.answer.length>0?
                      <small className='text-muted ' >By: {state.shipmentDetail.full_name}</small> :""} {item.answer.length>0?<small className='text-muted ms-5'> On:<Moment format="MMMM DD, YYYY hh:mm:ss" unix>{item.answercreated}</Moment></small>
                      :""}
                    </div>
                    
                  </div>
                  <hr/>
                 
                     
                 
                 
                 
              
                  
                  {state.shipmentDetail.viewer_type==1 && item.answer.length==0?
                    
                   
                  <textarea 
                  className="form-control"
                  placeholder="Type Your Answer"
                  
                  name="answer"
                 
                  onChange={handleChange4}
                  rows="3"
                        ></textarea>:""}
                        {state.shipmentDetail.viewer_type==1 && item.answer.length==0?
                        <button className="btn btn-primary mt-3" type="submit" onClick={(event)=>handleSubmit(item.question_id)}>Submit </button>
                  :""}
                  <hr/>
                
                  </>
                  )}
  
                  </section>
                  : <section className="text-center p-5 h-100">
                  <h4 className="text-muted fw-bold">NO QUESTIONS YET</h4>
                  
                  
                </section>}
                </div>
              </div>
            </div>
          </div>
        </section>
  
        </div>
        
        <Modal show={modals.show} >
        <Modal.Header closeButton onClick={() => handleClick()}>
          <ModalTitle>
            <h4>Report</h4>
          </ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>
          <h6>Report Reason</h6>
            <select name="reason" className="form-select"  onChange={(e) => stateChangeHandler(e)}
                               value={report.reason} >
                              <option value="" selected="selected">Please Select</option>
                              <option value="Pornography or Repulsive">Pornography or Repulsive</option>
                              <option value="Violent or Hateful">Violent or Hateful</option>
                              <option value="Spam or Misleading ">Spam or Misleading </option>
                              <option value="Unlawful ">Unlawful </option>
                              <option value="Others">Others</option>
                              
                            </select>
          </div>
        
          <h6>Message</h6>
          <textarea className='form-control' rows="5" cols="3" name="description" placeholder='Please Enter Message' onChange={(e) => stateChangeHandler(e)}
                               value={report.description}></textarea>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={()=>handleReport( )} >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
  
        <div className="col-md-3 col-12" >
          {state.shipmentDetail.viewer_type==2 && userdata.transporter_id!="" && userdata.transporter_id!=undefined?
                <div style={{marginBottom:"10px", marginLeft:"100px" , cursor:"pointer"}}><small  className="text-primary " onClick={() => setModals({ show: true })}>Report Shipment</small>
                 </div>:""}
          <section className="pt-2" style={width<=768?{marginLeft:"-10px",marginRight:"-10px"}:{marginLeft:'10px'}}>
            <div className="card shadow-sm mb-4">
              <div className="card-body text-center">
                <small>Photos</small>
                <div className="row">
                    {state.shipmentDetail.item_imgs.length>0?
                  <div className="col-12 col-md-12 ">
                  {state.shipmentDetail.item_imgs.map((items, indexItem) => (
                            <div key={indexItem}><img src={`https://shippxcdn.fniix.com/web/${items}`} className="mb-2 img-thumbnail" onClick={() => setStateModal({show: true})}/></div>
                          ))}
                  </div>
                        :<h6 className=" f-w-500 pb-4 text-muted">No Photos Uploaded</h6>}
                  
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card shadow-sm" style={width<=768?{marginLeft:"-10px",marginRight:"-10px"}:{marginLeft:'10px'}}>
              <div className="card-body">
              {(userdata.user_id == undefined) ?
                <>
                <p className="text-center">Want to submit a quote or ship something similar?</p>
               
                
                <div className="d-grid pb-3">
                  <Link className="btn btn-success btn-block" to='/login'>Sign In</Link>
                </div>
                <div className="d-grid">
                  <Link className="btn  btn-outline-success btn-block" to='/registration'>Join Now</Link>
                </div>
                </>
:""}
                <div className="d-flex justify-content-between p-3">
                  
                  <div className="p-2 flex-fill"><small>LOWEST <br/>QUOTE</small></div>
                  {(state.shipmentDetail.lowest_quote)?
                      
                      dimensionState.companyDetail.map((subscriber) => (
                <div className="p-2 flex-fill  f-w-500">  {subscriber.set_currency}{state.shipmentDetail.lowest_quote}
                 </div>))
                : <section className="text-center m-3 h-100">
                <h6 className="text-muted fw-bold">NOT QUOTED YET</h6>
                
              </section>}  
                </div>
                
                <hr/>
                <div className="d-flex justify-content-between p-3 text-center">
                  <div className="p-2 flex-fill border-end">
                    <h6><small className="text-muted fw-light">Watching</small></h6>
                    <p className="text-center text-primary"><i className="fa fa-eye " aria-hidden="true"></i> {state.shipmentDetail.watching} </p>
                  </div>
                  <div className="p-2 flex-fill">
                    <h6><small className="text-muted fw-light">Quotes</small></h6>
                    <p className="text-center text-primary"><i className="fab fa-first-order " aria-hidden="true"></i> {state.shipmentDetail.total_quotes} </p>
                  </div>
                </div>
  
  
              </div>
            </div>
          </section>
         
        </div>

          
      </div>
      
    </section>
    
    </div>
              }
              </div>
  </section>
              }
              <Modal show={stateModal.show}    size="lg" >  
              <Modal.Header closeButton onClick={() => setStateModal({show: false})}></Modal.Header>         
              <Modal.Body>
              <Carousel>
              {state.shipmentDetail.item_imgs.map((items, indexItem) => (
                <div key={indexItem}>
                <img src={`https://shippxcdn.fniix.com/web/${items}`} />
                  
                </div>
              ))}
                {/* <div>
                <img src="../../img/feature-bg2.jpg" style={{width:"100%"}}/>
                    
                </div>
                <div>
                <img src="../../img/feature-bg2.jpg" style={{width:"100%"}}/>
                    
                </div> */}
            </Carousel>

                {/* <div>
               <img src="../../img/feature-bg2.jpg" style={{width:"100%"}}/>
                </div>
               */}
               
              </Modal.Body>
             
            </Modal>
  <Footer/>

  </>
  )
}

export default Ship_detail