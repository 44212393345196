import React, { useEffect, useState } from "react";
import { useNavigate,useLocation,useParams, Link } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';

import Footer from "./Footer";
import Header from "./Header";
import exportValue from "../apiconfig";
import Mapsecond from "./components/Mapsecond";

// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-places-autocomplete';




const Tracking = () => {
  const {invoice_id} = useParams();
  console.log("invoice_id    ", invoice_id);
  let dintercount = 0;
  const [state, setState] = useState({shipmentDetail:{driver_detail:[]},current_shipment_detail:{}, quotes: [], shipment_status:[] ,detailFound:false ,isLoading:true});
  const[dimensionState,setdimensionState] = useState({companyDetail:[]})
  const [currentState, setCurrentState] = useState({location:[]});
  const [driverState, setDriverState] = useState({location:{latitude:0,longitude:0}});
  const [clearIntervalState, setClearIntervalState] = useState({driverLocation:0});

  const [mystate, setmyState] = React.useState({
    myshipment_lists: [], myshipment_driver: [], transporter_vehicle_detail: [],isLoading:true })

    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
      console.log("userData  -  ",userdata);
       
    }
  useEffect(() => {
    shipmentDetail();
  }, [])

  const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/get_company_info_api`;
    let sendData = {};
  
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  React.useEffect(() => {
  //  dimension_detail();
//currentshipmenttimeDetail()
}, [])

  const shipmentDetail = async() => {
    let refreshIntervalId = null;
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/shipment_tracking_api`;
    let sendData = { invoice_id: invoice_id };
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res 222->->-> ",res);
     
      if (res.data.output.length == 1) {

        setState({ ...state, shipmentDetail: res.data.output[0],shipment_status:res.data.shipment_status, current_shipment_detail:res.data.current,quotes: res.data.quot,detailFound:true,isLoading:false })
        if(res.data.output[0].current_shipment_status == 8){
          console.log("---------driver_position--------");
        dintercount = setTimeout(()=>driver_position({driver_id:res.data.output[0].driver_detail[0].driver_id,invoice_id:invoice_id}), 1000);
          console.log("1 refreshIntervalId=>     ",dintercount);
         // setClearIntervalState({...clearIntervalState,driverLocation:refreshIntervalId})
         // driver_position({driver_id:res.data.current.driver_id,invoice_id:invoice_id});
        }else {
          console.log("2 . refreshIntervalId=>     ",dintercount);
          if(dintercount!= null && dintercount!= 0){
            console.log("4 . refreshIntervalId=>     ",dintercount);
              //clearInterval(dintercount);
          }
          //var refreshIntervalId
        }


       

        
    }
    else {
      setState({...state,isLoading:false})
    }
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };
  
  const currentshipmenttimeDetail = () => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/current_status_detail`;
    let sendData = { invoice_id: invoice_id };
    console.log("bb",sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
    
      
         setCurrentState({ ...currentState, location:  res.data.output[0]})
         
     
  
   
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };

  const driver_position = (sendData) => {
    console.log("3 . refreshIntervalId=>     ",dintercount);
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/driver/driver_current_position`;
    //let sendData = { invoice_id: invoice_id };
    console.log("bb",sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log(".res ==    ",res);
      if(res.data.isvalid && res.data.output.length > 0){
       
         setDriverState({ location:  res.data.output[0]})
        
      }else {
        console.log("else not valid");
        //isvalid
      //  shipmentDetail()
      }
     
  
   
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };

  

  return (<>
    <Header/>
  {state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
    
  
    <section className="bg-light p-5">
    
       {(!state.detailFound  ) ? 
       <section className="text-center p-5 h-100">
        <div class="alert alert-danger" role="alert">
        YOUR SHIPMENT IS NOT BOOKED YET
</div>
          
          
        </section>
  :     
  <div className="container ">

    <section className="bg-white p-5 mb-3">
      <div className="text-muted">
         <p>
         <Link to={"/shipment/"+state.shipmentDetail.invoice_id}><strong className="ms-2 me-2"># <span className=" fw-bold">{state.shipmentDetail.invoice_id}</span></strong></Link>
         {state.shipmentDetail.current_shipment_status==1? <span class="badge rounded-pill bg-success text-light">Booked</span>:state.shipmentDetail.current_shipment_status==2?<span class="badge rounded-pill bg-primary text-light">Pickup In Progress</span>:state.shipmentDetail.current_shipment_status==3?<span class="badge rounded-pill bg-info text-light">Picked Up</span>:state.shipmentDetail.current_shipment_status==4?<span class="badge rounded-pill bg-primary text-light">In Transit</span> :state.shipmentDetail.current_shipment_status==5?<span class="badge rounded-pill bg-warning text-light">Delieverd</span>:state.shipmentDetail.current_shipment_status==6?<span class="badge rounded-pill bg-primary text-light">Returned</span>:state.shipmentDetail.current_shipment_status==7?<span class="badge rounded-pill bg-primary text-light">Cancelled</span>:state.shipmentDetail.current_shipment_status==8?<span class="badge rounded-pill bg-danger text-light">Out For Delivery</span>:<span class="badge rounded-pill bg-dark text-light">Not Booked</span>}{state.shipmentDetail.pickup_address} 
         <i className="fa fa-long-arrow-right" aria-hidden="true"></i>  {state.shipmentDetail.delivery_address}  
        </p>
      </div>
      <div className="row pt-5">
        <div className="col-md-8">

          {/* <p className="text-end"><small className="text-muted">Location last updated on </small></p> */}

            <section>
              {/* <div id="dvMap" style="width: 100%; height: 280px">
              </div> */}
            </section>

            <section className="mt-3 mb-5">
              <h2>{state.shipmentDetail.current_shipment_status==1?"Start":state.shipmentDetail.current_shipment_status==2?"Pickup in Progress":state.shipmentDetail.current_shipment_status==3?"Picked Up":state.shipmentDetail.current_shipment_status==4?"In Transit":state.shipmentDetail.current_shipment_status==5?"Delivered":state.shipmentDetail.current_shipment_status==6?"Returned":state.shipmentDetail.current_shipment_status==7?"Cancelled":state.shipmentDetail.current_shipment_status==8?"Out For Delivery":""}</h2>
              <p>Delivery ETA - <span className="text-muted"><Moment format="DD-MMMM-YYYY" unix>{state.shipmentDetail.pickup_datetime_to }</Moment> </span> | Scheduled Delivery - <span className="text-muted"><Moment format="DD-MMMM-YYYY" unix>{state.shipmentDetail.delivery_datetime_to }</Moment> </span></p>
              {/* <a  className="btn btn-primary btn-sm">Copy share link</a>
              <a  className="btn btn-outline-primary btn-sm">Request Update</a> */}
            </section>

            
            <section>
          {(state.shipmentDetail.current_shipment_status== 8) ?
              <div style={{marginBottom:"20px"}}>
                {(driverState.location.longitude != 0)?
            < Mapsecond data={[state.shipmentDetail.toLocCord[0],{lat:driverState.location.latitude,lng:driverState.location.longitude}]} /> :""}
            </div>
          :""}
              {state.shipment_status.map((item,index)=>(

              
          (item.current_status==1)?  
                <div className="card shadow-sm mb-3">
                  <div className="card-body">
                    <h3 className="fs-5 ">Start</h3>
                    <small className="text-muted">{item.current_date} {item.time} {item.current_location}</small>
                    <p>Order has been confirmed by Carrier and assigned</p>
                  </div>
                  
                </div>
                :

                (item.current_status==2)?          
                <div className="card shadow-sm mb-3">
                  <div className="card-body">
                    <h3 className="fs-5 ">Pickup in Progress</h3> 
                    <small className="text-muted">{item.current_date} {item.time} {item.current_location}</small>
                    <p>Order Pickup In Progress </p>
                  </div>
                  
                </div>
                :
 
                (item.current_status==3)? 
                <div className="card shadow-sm mb-3">
                  <div className="card-body">
                    <h3 className="fs-5 ">Pickedup</h3>
                    <small className="text-muted">{item.current_date} {item.time} {item.current_location}</small>
                    <p>Order has been Pickedup by Driver</p>
                  </div>
                </div>
                :

                (item.current_status==4)?            
                <div className="card shadow-sm mb-3">
                  <div className="card-body">
                    <h3 className="fs-5 ">In Transit</h3>
                    <small className="text-muted">{item.current_date} {item.time} {item.current_location}</small>
                    <p>Order In Transit </p>
                  </div>
                  
                </div>
                  :

                  (item.current_status==8)?            
                  <div className="card shadow-sm mb-3">
                    <div className="card-body">
                      <h3 className="fs-5 ">On The Way</h3>
                      <small className="text-muted">{item.current_date} {item.time} {item.current_location}</small>
                      <p>Order Is On The Way </p>
                    </div>
                    
                  </div>
                :
                (item.current_status==5)? 
                <div className="card shadow-sm mb-3">
                  <div className="card-body">
                    <h3 className="fs-5 ">Delivered</h3>
                    <small className="text-muted">{item.current_date} {item.time} {item.current_location}</small>
                    <p>Order has been Delivered by Driver</p>
                    {state.shipmentDetail.driver_detail.map((subscriber,index)=>
                    <div className="row">
                      {subscriber.delivered_image?
                      <div className="col-6">
                      <img src={`https://shippxcdn.fniix.com/web/${subscriber.delivered_image}`}  height="300px" ></img>
                      </div>
                      :""}
                      {subscriber.delivered_sign?
                      <div className="col-6">
                      <img src={`https://shippxcdn.fniix.com/web/${subscriber.delivered_sign}`}  height="300px" ></img>
                      </div>
                     :""}
                    </div>
                 
                 )}
                  </div>
                </div>
                :

                (item.current_status==6)?             
                <div className="card shadow-sm mb-3">
                  <div className="card-body">
                    <h3 className="fs-5 ">Returned</h3>
                    <small className="text-muted">{item.current_date} {item.time} {item.current_location}</small>
                    <p>Order has been Returned </p>
                  </div>
                  
                </div>
                :

                (item.current_status==7)?            
                <div className="card shadow-sm mb-3">
                  <div className="card-body">
                    <h3 className="fs-5 ">Cancelled</h3>
                    <small className="text-muted">{item.current_date} {item.time} {item.current_location}</small>
                    <p>Order has been Cancelled </p>
                  </div>
                  
                </div>
                :""
                ))}
            </section>





            
        </div>
        <div className="col-md-4 bg-light">
          <section className="ps-4 pt-3">
            <h3 className="fs-5 pb-3">Basic information</h3>
            <div className="row">
              <div className="col-md-6">
                <small className="text-muted">Shipment Tracking #</small>
                <h4 className="fs-5">{state.shipmentDetail.invoice_id}</h4>
              </div>
              {/* <div className="col-md-6">
                <small className="text-muted">PO/Refernce #</small>
                <h4 className="fs-5">293848</h4>
              </div> */}
              <div className="col-md-6">
                <small className="text-muted">Pickup</small>
                <h4 className="fs-6">{state.shipmentDetail.pickup_address}</h4>
              </div>
              <div className="col-md-6">
                <small className="text-muted">Delivery</small>
                <h4 className="fs-6">{state.shipmentDetail.delivery_address}</h4>
              </div>
              <div className="col-md-6">
                <small className="text-muted">Shipper</small>
                <h4 className="fs-5"><a href=""> {state.shipmentDetail.full_name}</a></h4>
              </div>
              <div className="col-md-6">
                <small className="text-muted">Item(s)</small>
                <h4 className="fs-5">{state.shipmentDetail.qty}</h4>
              </div>
              <small className="text-muted">Noted: {state.shipmentDetail.description} </small>
            </div>
          </section>

          <hr className="m-4"/>


          <section className="ps-4">
         
            <h3 className="fs-5 pb-3">Order details</h3>
            <div className="row">
              {state.quotes.map((item,index)=>
              <div className="col-md-6">
                <small className="text-muted">Carrier</small>
                 <h4 className="fs-5"><a href="">{item.branch_name}</a></h4> 
              </div>
             )}  
              <div className="col-md-6">
                <small className="text-muted">Order on</small>
                <h4 className="fs-5"> <Moment format="MMMM DD , YYYY" unix>{state.shipmentDetail.created}</Moment>

                <small className="text-muted">{state.shipmentDetail.time}</small></h4>
              </div>
              <div class="col-md-6">
                        <small class="text-muted">Total Price #</small>
                        <div>
                        {state.shipmentDetail.products.map((item,index)=>
                        <>
                        Item {index+1}
                        <h4 class="fs-5"> {dimensionState.companyDetail.map((sub)=>(
                                    sub.set_currency
                                   ))}{item.dimensions[0].item_value}</h4>
                        </>)}
                        </div>
                        
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Total Weight</small>
                        <h4 class="fs-5">{state.shipmentDetail.item_weight} {dimensionState.companyDetail.map((sub)=>(
                                    sub.set_weight
                                   ))}</h4>
                      </div>
                    </div>
          
          </section>

          <hr className="m-4"/>

          <section class="ps-4">
                    <h3 class="fs-5 pb-3">Shipping Items</h3>
                    <div class="row">
                   
                      <div class="col-md-12">
                        <h4 class="fs-6">{state.shipmentDetail.title}</h4>
                        {state.shipmentDetail.products.map((item,index)=>
                        <>
                        Item {index+1}
                        <div><small class="text-muted">{item.dimensions[0].item_weight}   {dimensionState.companyDetail.map((sub)=>(
                                    sub.set_weight
                                   ))} - {item.dimensions[0].item_height} x{item.dimensions[0].item_length} x{item.dimensions[0].item_width}  {dimensionState.companyDetail.map((sub)=>(
                                    sub.set_dimension
                                   ))}</small></div>
                        
                      </>  )}
                      </div>
                  
                      
                    </div>
                  </section>
          
          <hr className="m-4"/>

          <section className="ps-4">
            <h3 className="fs-5 pb-3">Shipping Equipments</h3>
            <div className="row">
              <div className="col-md-6">
                <small className="text-muted">Truck Type</small>
                <h4 className="fs-6">{state.shipmentDetail.service_type}</h4>
              </div>
              {state.shipmentDetail.driver_detail.map((item,index)=>
              <div className="col-md-6">
                <small className="text-muted">Truck Number</small>
                <h4 className="fs-6">{item.vehicle_number}</h4>
              </div>
              )}
              {state.shipmentDetail.driver_detail.map((item,index)=>
              <div className="col-md-6 ">
                <small className="text-muted">Driver Name</small>
                <h4 className="fs-6">{item.driver_name}</h4>
              </div>
              )}
               {state.shipmentDetail.driver_detail.map((item,index)=>
              <div className="col-md-6 ">
                <small className="text-muted">Driver Mobile Number</small>
                <h4 className="fs-6">{item.mobile_number}</h4>
              </div>
             )}
            </div>
          </section>

        </div>
      </div>
    </section>

   

    
  </div>
}
</section>


}
     
</>
  
  )
}

export default Tracking