import React,{useState,useEffect} from "react";
import { useNavigate,useLocation,useParams, Link } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";

import Header from './Header';
import MenuCustomer  from './MenuCustomer';
import Footer from './Footer';
import exportValue from "../apiconfig";
import Moment from 'react-moment';
import ReactStarsRating from 'react-awesome-stars-rating';
import ReactLoading from 'react-loading';

import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalTitle } from "react-bootstrap"





//import { form } from 'react-router-dom';

const ProfileCustomer = () => {
  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log("userData  -  ",userdata);
    //navigate('/home') 
  }
    const navigate = useNavigate();
    const location = useLocation();
    const {user_id} = useParams();

    const [state, setState] = useState({isLoading:true});
    const [customerstate, setCustomerState] = useState({customer_review:[],isLoading:true});
  const[likecountstate,setLikeCountState]= React.useState({count_customer_likes:[]})
  const[notlikecountstate,setnotLikeCountState]= React.useState({count_customer_notlikes:[]})
  const[countstate,setCountState]= React.useState({count_customer_review:[]})
  const[infostate,setInfoState]= React.useState({customer_info:[],shipment_count:[],isLoading:true})
  const[modals,setModals] = React.useState({show: false})
  const [report, setReport] =  React.useState({  reportOn_userId:user_id , reportBy_transporterId:userdata.transporter_id , report_type:"3",reason:"", description:""  })
   





  useEffect(() => {
    logincheck()
    
  }, [])


    const logincheck = () => {
        console.log("->< ", localStorage.getItem('loggedIn'));
        if (localStorage.getItem('loggedIn')) {
          let userDetail = JSON.parse(localStorage.getItem('loggedIn'));
          setState(userDetail);
          console.log("-> ", userDetail);
        }
      }
      


      const axios_get_api = () => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/customer_review_find`;
        let sendData = {
         user_id: user_id,
       
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setCustomerState({ ...customerstate,  customer_review: res.data.output ,isLoading:false});
          console.log("response customer", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }

      useEffect(() => {
        axios_get_api()
        like_count()
        notlike_count()
        review_count()
        customer_info()
      }, [])

      const customer_info = () => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/customer_info_find`;
        let sendData = {
         user_id: user_id,
       
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setInfoState({ ...infostate,  customer_info: res.data.output ,shipment_count:res.data.shipment_count,isLoading:false});
          console.log("response user", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }

      const like_count = () => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/liked_customer_count_api`;
        let sendData = {
        
          isLiked: 1,
          user_id:user_id
    
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setLikeCountState({ ...likecountstate, count_customer_likes:res.data.count_customer_review});
          console.log("response_count_like", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }
    
      const notlike_count = () => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/notliked_customer_count_api`;
        let sendData = {
        
          notLiked: 1,
          user_id:user_id
    
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setnotLikeCountState({ ...notlikecountstate, count_customer_notlikes:res.data.count_customer_review});
          console.log("response_count_notlike", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }

      const review_count = () => {
    
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/customer_review_count_api`;
        let sendData = {
        
          dstatus: 1,
          user_id:user_id
    
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setCountState({ ...countstate, count_customer_review:res.data.count_customer_review });
          console.log("response_count", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

 function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

  const { height, width } = useWindowDimensions();

  const handleClick=()=>{
    setModals({show:false})
  }
  const handleReport = () => {
    
    console.log("submit1");
   
    let state_detailes = {reportOn_userId: user_id , reportBy_transporterId:"" , report_type:3 , reason: report.reason, description:report.description}
    if(userdata.transporter_id) {
      state_detailes.reportBy_transporterId = userdata.transporter_id;
    }
  
    console.log("report detailes is ",state_detailes);
    if(report.reason!="" && report.description!=""){
    Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/report/add_report`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
            console.log("result is i ",result);
           
            if(result && result.data != undefined){
               
                    toast.configure()
                    toast(result.data.message) 
                    setModals({show:false})
                    // shipmentDetail()
                }
 
         }).catch((e) => {
       
 
          toast.configure();
          toast.error("Some thing went wrong");
          console.log("----error:   ", e);
        });}
        else{
          toast.configure()
          toast.error("Please Select Reason And Enter Message") 
        }
}

const stateChangeHandler = (event) => {
  console.log(event);  
  setReport({
      ...report,
      [event.target.name]: event.target.value,
    });
  
}

      
    return (
        <>
         <Modal show={modals.show} >
        <Modal.Header closeButton onClick={() => handleClick()}>
          <ModalTitle>
            <h4>Report</h4>
          </ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>
          <h6>Report Reason</h6>
            <select name="reason" className="form-select"  onChange={(e) => stateChangeHandler(e)}
                               value={report.reason} >
                              <option value="" selected="selected">Please Select</option>
                              <option value="Pornography or Repulsive">Pornography or Repulsive</option>
                              <option value="Violent or Hateful">Violent or Hateful</option>
                              <option value="Spam or Misleading ">Spam or Misleading </option>
                              <option value="Unlawful ">Unlawful </option>
                              <option value="Others">Others</option>
                              
                            </select>
          </div>
        
          <h6>Message</h6>
          <textarea className='form-control' rows="5" cols="3" name="description" placeholder='Please Enter Message' onChange={(e) => stateChangeHandler(e)}
                               value={report.description}></textarea>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={()=>handleReport( )} >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
            <MenuCustomer />
            {infostate.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
            <section className="bg-light p-5" style={width<=768?{marginLeft:"-40px",marginRight:"-40px"}:{marginLeft:"0px"}}>

                <div className="container ">
                    <div className="row">
                   
                        <div className="col-12 col-md-2"></div>
                        {infostate.customer_info.map((sub)=>(
                        <div className="col-12 col-md-8">
                           {sub.viewer_type==2?<small  className="text-primary " style={{cursor:"pointer", float:"right"}} onClick={() => setModals({ show: true })}>Report Profile </small>:""}
                            <h3 className="text-uppercase mb-5">{sub.full_name}</h3>
                           

                            <section className="pb-4">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="card">
                                            <div className="card-body shadow-sm">
                                                <h5 className="mb-4">User Information</h5>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Joined Since</td>
                                                            <td><Moment format="MMMM DD YYYY" unix>{sub.created}</Moment></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shipments Posted</td>
                                                            <td>{infostate.shipment_count}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Percent Positive</td>
                                                            <td>{likecountstate.count_customer_likes!==0 &&infostate.shipment_count!==0?parseInt(likecountstate.count_customer_likes/infostate.shipment_count*100):"0"}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Positive</td>
                                                            <td>{likecountstate.count_customer_likes}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Negative</td>
                                                            <td>{notlikecountstate.count_customer_notlikes}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div className="col-12 col-md-8" style={width<=768?{marginTop:"20px"}:{margin:"0px"}}>
                                        <div className="card">
                                            <div className="card-body shadow-sm">
                                                <h5 className="mb-4">Customer History</h5>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Total</th>
                                                            {/* <th>1 mo</th>
                                                            <th>6 mo</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-success">Positive</td>
                                                            <td>{likecountstate.count_customer_likes}</td>
                                                            {/* <td>0</td>
                                                            <td>0</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-muted">Neutral</td>
                                                            <td> {infostate.shipment_count   -  (likecountstate.count_customer_likes+notlikecountstate.count_customer_notlikes ) }</td>
                                                            {/* <td>0</td>
                                                            <td>0</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-danger">Negative</td>
                                                            <td>{notlikecountstate.count_customer_notlikes}</td>
                                                            {/* <td>0</td>
                                                            <td>0</td> */}
                                                        </tr>
                                                        {/* <tr>
                                                            <td>Cancellations</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="">
                                <div className="card">
                                <div className ="card-body shadow-sm">
                                <div className ="row">
                                <div className ="col">
                                <ul className ="nav nav-pills ">
                               
                                </ul>

                                <div className ="tab-content">
                                <div className ="tab-pane container active" id="fb_rec">
                                <section className ="customer_review_box pt-5">
                                <h1>REVIEWS | {countstate.count_customer_review}</h1>
                                <hr />
                                {customerstate.customer_review.map((sub)=>(
                                <section>
                                <div className ="d-flex justify-content-between">
                                <div className =" ">
                                <h3>{state.full_name}</h3>
                                <ReactStarsRating value={sub.rating} isEdit={false}/>
                                </div>
                                <div className =" ">
                                <span className ="text-muted"><Moment format="MMMM DD YYYY" unix>{sub.created}</Moment></span>
                                </div>
                                </div>
                                <h2>{sub.title}</h2>
                                <p>{sub.review_description}</p>
                                <p class="text-end text-muted"><small> Was this helpful? <i class="fa fa-thumbs-o-up text-primary" aria-hidden="true"></i> {sub.isLiked==undefined && sub.isLiked==""?"0":(sub.isLiked)} &nbsp;&nbsp;&nbsp; <i class="fa fa-thumbs-o-down text-primary" aria-hidden="true"></i> {sub.notLiked==undefined && sub.notLiked==""?"0":(sub.notLiked) }</small></p>
                                {sub.transporter_review.map((subscriber)=>(
                                <div className ="p-3 ml-30 ml-5 bg-light mb-5 border-start border-primary border-5">
                                <h3>{sub.branch_name}</h3>
                                <small>{subscriber.transporter_review_description}</small>
                                </div>
                                ))}
                                </section>
                                ))}
                                <hr />
                               
                                </section>
                                
                                </div>
                                {/* <div className ="tab-pane container fade" id="fb_left">...s</div> */}
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                            </section>

                            
                        </div>
                        ))}
                        <div className="col-12 col-md-2"></div>
                    </div>
                </div>
            </section>
}
            <Footer />
        </>
    )
}

export default ProfileCustomer;
