import React, { useEffect, useState } from "react";
import Header from './Header';
import Footer from './Footer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import exportValue from '../apiconfig';
import Moment from 'react-moment';
import moment from 'moment';
import ReactLoading from 'react-loading';
import usePlacesAutocomplete from "use-places-autocomplete";
import MapMarkers from './components/MapMarkers';
import { width } from "@mui/system";
//import { handleInputChange } from "react-select/dist/declarations/src/utils";

const Shipment = () => {

    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
        userdata = JSON.parse(localStorage.getItem('loggedIn'));
        //  console.log("userData  -  ", userdata);

    }
    let navigate = useNavigate();
    let newDate = Date.now();
    // console.log("new date is ", newDate);

    //console.log("new date is ",newDate);
    const [state, setState] = useState({
        shipment_list: {item_imgs:[]}, category_list: [],
        dstatus: 1, isLoading: true,
    })
    var options = {
        types: [('regions')]
    };
    const search = window.location.search;
    // console.log("search -   ",search);
    const params = new URLSearchParams(search);
    let sb = params.get('sb');
    let pd = params.get('pd');
    let dl = params.get('dl');
    let pl = params.get('pl');
    let mnW = params.get('mnW');
    let mxW = params.get('mxW');
    let loct = params.get('loct');
    console.log("loct=====",loct)
    let subcId = params.get('subcId');


    const placeDelivery = usePlacesAutocomplete({ library: "country" });

    const placePickup = usePlacesAutocomplete();

    const [shipmentLocation, setShipmentLocation] = useState({ pickUpLocation: "", deliveryLocation: "" });

    const [dimensionState, setdimensionState] = useState({ companyDetail: [] })

    const [filters, setFilters] = useState({ range: "", pickupDate: (pd) ? pd : "" })

    const [sortfilters, setsortFilters] = useState({ sort: (sb) ? sb : "recentList" })

    const [catfilters, setCatfilters] = useState({ subcategory_id: [] })

    const [weightfilters, setWeightFilters] = useState({ minWeight: "", maxWeight: "" })

    const [pricefilters, setPriceFilters] = useState({ auction: "", offer: "" })

    const [locationfilters, setLocationfilters] = useState({ locationType: [] })

    const [listingMap,setListingMap] = useState({type:1});
    const [forRender,setRender] = useState(true);


    // console.log("filter change ", filters);
    // console.log("catfilters change ", catfilters);
    // console.log("weightfilters change ", weightfilters);
    // console.log("pricefilters change ", pricefilters);
    //console.log("locationfilters change ", locationfilters);
    //console.log("sortfilters change ", sortfilters);

    const dimension_detail = () =>{
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
      }
      React.useEffect(() => {
        dimension_detail();
      }, [])

    function ShipmentList() {
        let data = { category_lists: {}, shipment_lists: {}, dstatus: 1 };
        if (catfilters.category_id) {
            data.category_id = catfilters.category_id
        }
        if(subcId != null) {
            data.subcategory_id = JSON.parse(subcId);
        }
        else if (catfilters.subcategory_id) {
            data.subcategory_id = catfilters.subcategory_id
        }

        if (filters.range) {
            data.range = filters.range
        }


        if (pd != null) {
            data.pickupDate = pd;
        }
        else if (filters.pickupDate) {
            data.pickupDate = filters.pickupDate
        }


        if (sb != null) {
            data.sort = sb;
        }
        else if (sortfilters.sort) {
            data.sort = sortfilters.sort
        }

        if (dl != null) {
            data.delivery_address = dl;
        } else if (shipmentLocation.deliveryLocation) {
            data.delivery_address = shipmentLocation.deliveryLocation;
        }

        if (pl != null) {
            data.pickup_address = pl;
        } else if (shipmentLocation.pickUpLocation) {
            data.pickup_address = shipmentLocation.pickUpLocation;
        }

        if (mnW != null) {
            data.minWeight = mnW;
        } else {
            data.minWeight = weightfilters.minWeight;
        }

        if (mxW != null) {
            data.maxWeight = mxW;
        } else {
            data.maxWeight = weightfilters.maxWeight;
        }
        if(loct!= null) {
            data.location_type = JSON.parse(loct);
        }
        else if (locationfilters.locationType.length > 0) {
            data.location_type = locationfilters.locationType;
        }

        console.log("stay is ", data);

        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/shipment_list`, data, { headers: exportValue.headers }).then((res) => {
             console.log(" res data is shipment", res);
            setState({ ...state, shipment_list: res.data.output, category_list: res.data.category_output, isLoading: false })
    
        })
    }


    useEffect(() => {
        if (pl) {
            placePickup.setValue(pl)
        }
        if (dl) {
            placeDelivery.setValue(dl);
        }
        if (mnW && mxW) {
            setWeightFilters({ ...weightfilters, minWeight: mnW, maxWeight: mxW })
        }
        if (loct != null) {
            console.log("loct - -   ", JSON.parse(loct));
            setLocationfilters({ locationType: JSON.parse(loct) })
        }
        if(subcId!= null) {
            setCatfilters({subcategory_id:JSON.parse(subcId)})
        }

        ShipmentList()
    }, [filters, sortfilters,forRender])

    // const ForAll = () => {
    //     ShipmentList()
    // }

    const urlFilter = () => {

        // console.log("pppparms  -   ",search);





    }
    // urlFilter()

    const handleInput = (e) => {
        // console.log("------     ", e.target.name);
        if (e.target.name == "pickUpLocation") {
            if (e.target.value == "") {
                params.delete('pl')
                pl = null;
                setShipmentLocation({ ...shipmentLocation, pickUpLocation: "" })
                navigate({ search: params.toString() })

            }
            placePickup.setValue(e.target.value);
        } else if (e.target.name == "deliveryLocation") {
            if (e.target.value == "") {
                params.delete('dl');
                dl = null;
                setShipmentLocation({ ...shipmentLocation, deliveryLocation: "" })
                navigate({ search: params.toString() })
            }
            placeDelivery.setValue(e.target.value);
        }

    };
    const renderSuggestionsPickup = () =>
        placePickup.suggestions.data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={() => handleSelectPickup(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    const handleSelectPickup = async (value) => {
        // console.log("value -- p ",value);
        placePickup.setValue(value.description, false);
        setShipmentLocation({ ...shipmentLocation, pickUpLocation: value.description })
        placePickup.clearSuggestions();
        // ShipmentList()


    }

    const renderSuggestionsDelivery = () =>
        placeDelivery.suggestions.data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={() => handleSelectDelivery(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });
    const handleSelectDelivery = (value) => {
        //console.log("value -- d ",value);
        placeDelivery.setValue(value.description, false);
        setShipmentLocation({ ...shipmentLocation, deliveryLocation: value.description })
       
        placeDelivery.clearSuggestions();

        
        // ShipmentList()
    }

    const rangeOnChangeHandle = (e) => {
        // console.log("ee ", e)
        let detail = { ...filters }
        detail[e.target.name] = e.target.value
        // const params = new URLSearchParams()
        params.delete('pd')
        params.append('pd', e.target.value)
        setFilters(detail)
        navigate({ search: params.toString() })
        // ShipmentList()
    }

    const handleSubcategoryChange = (data, e) => {
        const checked = e.target.checked;
        //const selectedSubCategory = e.target.name;
        // console.log("checked io is ", checked);


        if (checked) {
            setCatfilters(prevState => ({
                subcategory_id: [...prevState.subcategory_id, data.subcategory_id]
            }));
        } else {
            setCatfilters(prevState => ({
                subcategory_id: prevState.subcategory_id.filter(subcategory_id => subcategory_id !== data.subcategory_id)
            }));
        }

        // ShipmentList()
    }

    const handleLocationChange = (e) => {
        const checked = e.target.checked;
        const selectedLocation = e.target.value;

        if (checked) {
            setLocationfilters(prevState => ({
                locationType: [...prevState.locationType, selectedLocation]
            }));
        } else {
            setLocationfilters(prevState => ({
                locationType: prevState.locationType.filter(locationType => locationType !== selectedLocation)
            }));
        }

        //ShipmentList()
    }

    const handleSortChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        //console.log("-----val---->  ", value);

        //const params = new URLSearchParams()
        params.delete('sb')
        params.append('sb', value)
        setsortFilters({ ...sortfilters, sort: value })
        navigate({ search: params.toString() })

        // ShipmentList()
    }

    const handlePriceChange = (e) => {
        const checked = e.target.checked;
        const selectedPrice = e.target.name;

        if (checked) {
            setPriceFilters({ ...pricefilters, [selectedPrice]: true })
        } else {
            setPriceFilters({ ...pricefilters, [selectedPrice]: false })
        }
    }

    const handleWeightChange = (e) => {
        let detail = { ...weightfilters }
        detail[e.target.name] = e.target.value
        setWeightFilters(detail)
    }

    const shipment_confirm = (invoice_id, status) => {
        //console.log("confirm");
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/transporter_shipment_confirm_cancel_api`;
        let sendData = { confirm_status: status, invoice_id: invoice_id, transporter_id: userdata.transporter_id };

        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {

            // console.log("res =>>>>>  ", res);
            if (res.data.confirm_status == 1) {
                navigate('/myaccount_carrier')
            }
        }).catch((e) => {
            // toast.configure()
            //toast.error("Some thing went wrong")
            console.log("----error:   ", e);
        })
    }
    const filterShipment = () => {
        sb = null;
        pd = null;
        dl = null;
        pl = null;
        mnW = null;
        mxW = null;
        loct = null;
        subcId = null;

        params.delete('mnW')
        if (weightfilters.minWeight !== "") {          
            params.append('mnW', weightfilters.minWeight)
        }

        params.delete('mxW')
        if (weightfilters.maxWeight !== "") {           
            params.append('mxW', weightfilters.maxWeight)
        }

        params.delete('loct')
        if (locationfilters.locationType.length > 0) {           
            params.append('loct', JSON.stringify(locationfilters.locationType))
        }

        params.delete('pl')
        if (shipmentLocation.pickUpLocation != '') {          
            params.append('pl', shipmentLocation.pickUpLocation)
        }

        params.delete('dl')
        if(shipmentLocation.deliveryLocation != '') {          
            params.append('dl', shipmentLocation.deliveryLocation)
        }

        params.delete('subcId');       
        if(catfilters.subcategory_id.length > 0) {
            params.append('subcId', JSON.stringify(catfilters.subcategory_id))
        }

       
        //console.log("location  -     ",locationfilters.locationType);
        setState({ ...state, isLoading: true })
        ShipmentList();
        navigate({ search: params.toString() })
        if(listingMap.type == 2) {
            setRender((forRender)?false:true)
        }
    }

    const clearFilter = () => {
        sb = null;
        pd = null;
        dl = null;
        pl = null;
        mnW = null;
        mxW = null;
        loct = null;
        subcId = null;
        setsortFilters({ ...sortfilters, sort: "recentList" });
        setFilters({ range: "", pickupDate: "" });
        placePickup.clearSuggestions();
        placeDelivery.clearSuggestions();
        placePickup.setValue("")
        placeDelivery.setValue("")
        setShipmentLocation({ pickUpLocation: "", deliveryLocation: "" })
        setWeightFilters({ minWeight: "", maxWeight: "" })
        setLocationfilters({ locationType: [] })
        setCatfilters({subcategory_id:[]})
        window.history.pushState({}, document.title, window.location.pathname);
        ShipmentList();


        //params.delete(['pd','dl'])
        //navigate({ search: params.toString() })
    }

    const locationIschecked = (value) => {       
        let index = locationfilters.locationType.indexOf(value);
        return (index != -1) ? true : false;
    }

    const categoryIsChecked = (value) => {
        let index = catfilters.subcategory_id.indexOf(value);
        console.log("value  -   ",value);
        return (index != -1) ? true : false;
    }
    var currentDate = Math.abs((new Date().getTime() / 1000).toFixed(0));
    let endDate = ""; //1650969569;
    let days = ""; // currentDate - endDate;
    let day = ""; // parseInt(days /86400);

    return (
        <>
            <Header />
          
            <section>
    <div className="desktop_off">
    <button className="btn btn-primary btn-sm filter-btn shadow-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="fa fa-filter" aria-hidden="true"></i> Filter</button>
  </div>

  <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div className="offcanvas-header">
      <h5 id="offcanvasRightLabel"><i className="fa fa-filter" aria-hidden="true"></i> Shipment Filter</h5>
      <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
    <div className="bg-white sticky-top pb-2 mh-100">

<section className="">
    <div className="card border-0">
        <div className="card-body">
            <button className="btn btn-primary btn-block" data-bs-dismiss="offcanvas" onClick={() => filterShipment()}>Apply Filter</button>

            <text className="btn-block" onClick={() => clearFilter()} style={{ float: "right" }}>Clear Filter</text>
            {/* <a href="" className="text-uppercase">Sign in to save the search</a> */}
        </div>
    </div>
</section>

<section className="">
    <div className="card bg-dark">
        <div className="card-body text-light">
            <h6 className="text-center ">Shipment Filter</h6>
            <div>Pickup Location</div>
            <div className="input-group mb-3">
                <span className="input-group-text"><i className="fa fa-arrow-right rotate_40" aria-hidden="true"></i></span>
                <input type="text" className="form-control" placeholder="Search" name="pickUpLocation" onChange={(e) => handleInput(e)} value={placePickup.value} />
                <div>&nbsp; </div>
                <button className="btn btn-primary" type="submit">
                    <i className="fa fa-map-marker fs-4" aria-hidden="true"></i>
                    <i className="fa fa-plus fs-6" aria-hidden="true"></i>
                </button>
                <ul>{(!pl) ? renderSuggestionsPickup() : ""}</ul>
            </div>


            {/* <label htmlFor="customRange2" className="form-label">Range: <small>100KM</small></label>
            <input type="range" className="form-range" min="0" max="100" id="" name="range" value={filters.range} onChange={(e)=>rangeOnChangeHandle(e)} placeholder="100KM" /> */}

            <hr />
            <div>Delivery Location</div>
            <div className="input-group mb-3">
                <span className="input-group-text"><i className="fa fa-arrow-right rotate_40" aria-hidden="true"></i></span>
                <input type="text" className="form-control" placeholder="Search" name="deliveryLocation" onChange={(e) => handleInput(e)} value={placeDelivery.value} />
                <div>&nbsp; </div>
                <button className="btn btn-primary" type="submit">
                    <i className="fa fa-map-marker fs-4" aria-hidden="true"></i>
                    <i className="fa fa-plus fs-6" aria-hidden="true"></i>
                </button>
                <ul>{(!dl) ? renderSuggestionsDelivery() : ""}</ul>
            </div>
        </div>
    </div>
</section>

<section className="">
    <div className="accordion" id="accordion_1">

        <div className="accordion-item">
            <h2 className="accordion-header" id="heading_1">

                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    All Categories
                </button>
            </h2>

            <div id="collapse1" className="accordion-collapse collapse " aria-labelledby="heading_1" data-bs-parent="#accordion_1">
                <div className="accordion-body accordion-body_custom accordion-flush">
                    <div className="row">
                        <div className="col"></div>
                    </div>
                    {state.category_list.map((subscriber, index) => (

                        <>
                            <div className="accordion " id="accordion_2" key={index}>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading_2_1">
                                        <button className="accordion-button accordion-button-custom" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_2_${index}`} aria-expanded="true" aria-controls="collapse_2_2">
                                            {subscriber.category_name}
                                        </button>
                                    </h2>
                                    {(subscriber.subcategorydetail != undefined && subscriber.subcategorydetail.length > 0) ? (subscriber.subcategorydetail.map((sub, ind) => {
                                        return (
                                            <>
                                                <div id={`collapse_2_${index}`} className="accordion-collapse collapse" aria-labelledby="heading_2_1" data-bs-parent="#accordion_2">

                                                    <div className="accordion-body">


                                                        <div className="form-check" key={ind}>
                                                            <input className="form-check-input" type="checkbox" id="check1" name="{sub.subcategory_name}" value="something" onChange={(e) => handleSubcategoryChange({ subcategory_id: sub.subcategory_id }, e)} 
                                                            checked={                    categoryIsChecked(sub.subcategory_id)}
                                                            />
                                                            <label className="form-check-label">{sub.subcategory_name}</label>
                                                        </div>


                                                    </div>




                                                </div>
                                            </>
                                        )
                                    }
                                    )) : ""}
                                </div>

                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    </div>

</section>

<section className="">
    <div className="accordion" id="accor_weight_box">
        <div className="accordion-item">
            <h2 className="accordion-header" id="accor_weight_box_heading_1">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_weight_box_collapse_1" aria-expanded="true" aria-controls="accor_weight_box_collapse_1">
                    Weight
                </button>
            </h2>
            <div id="accor_weight_box_collapse_1" className="accordion-collapse collapse " aria-labelledby="accor_weight_box_heading_1" data-bs-parent="#accor_weight_box">
                <div className="accordion-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-floating">
                                <input type="text" className="form-control" name="minWeight" value={weightfilters.minWeight} id="email" onChange={(e) => handleWeightChange(e)} placeholder="min." />
                                <label htmlFor="email">Min Weight</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-floating">
                                <input type="text" className="form-control" name="maxWeight" value={weightfilters.maxWeight} onChange={(e) => handleWeightChange(e)} id="max" placeholder="min." />
                                <label htmlFor="max">Max Weight</label>
                            </div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="">
    <div className="accordion" id="accor_pricing_box">
        <div className="accordion-item">
            <h2 className="accordion-header" id="accor_pricing_box_heading_1">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_pricing_box_collapse_1" aria-expanded="true" aria-controls="accor_pricing_box_collapse_1">
                    Pricing Type
                </button>
            </h2>
            <div id="accor_pricing_box_collapse_1" className="accordion-collapse collapse " aria-labelledby="accor_pricing_box_heading_1" data-bs-parent="#accor_pricing_box">
                <div className="accordion-body">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="auction" onChange={(e) => handlePriceChange(e)} value="something" />
                        <label className="form-check-label">Auction</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="offer" onChange={(e) => handlePriceChange(e)} value="something" />
                        <label className="form-check-label">Offer</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="">
    <div className="accordion" id="accor_location_box">
        <div className="accordion-item">
            <h2 className="accordion-header" id="accor_location_box_heading_1">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_location_box_collapse_1" aria-expanded="true" aria-controls="accor_location_box_collapse_1">
                    Location Type
                </button>
            </h2>
            <div id="accor_location_box_collapse_1" className="accordion-collapse collapse " aria-labelledby="accor_location_box_heading_1" data-bs-parent="#accor_location_box">
                <div className="accordion-body">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="anywhare" onChange={(e) => handleLocationChange(e)} value="Anywhere" checked={locationIschecked("Anywhere")} />
                        <label className="form-check-label">Anywhere</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="residence" onChange={(e) => handleLocationChange(e)} value="Residence / Home Business" checked={locationIschecked('Residence / Home Business')} />
                        <label className="form-check-label">Residence / Home Business</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="business" onChange={(e) => handleLocationChange(e)} value="Business (with loading dock or forklift)" checked={locationIschecked("Business (with loading dock or forklift)")} />
                        <label className="form-check-label">Business (with loading dock or forklift)</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="business without" onChange={(e) => handleLocationChange(e)} value="Business (without loading dock or forklift)" checked={locationIschecked("Business (without loading dock or forklift)")} />
                        <label className="form-check-label">Business (without loading dock or forklift)</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="port" onChange={(e) => handleLocationChange(e)} value="Port" checked={locationIschecked("Port")} />
                        <label className="form-check-label">Port</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="construction" onChange={(e) => handleLocationChange(e)} value="Construction Site" checked={locationIschecked("Construction Site")} />
                        <label className="form-check-label">Construction Site</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="trade show" onChange={(e) => handleLocationChange(e)} value="Trade Show / Convention Center" checked={locationIschecked("Trade Show / Convention Center")} />
                        <label className="form-check-label">Trade Show / Convention Center</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="storage facility" onChange={(e) => handleLocationChange(e)} value="Storage Facility" checked={locationIschecked("Storage Facility")} />
                        <label className="form-check-label">Storage Facility</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="military base" onChange={(e) => handleLocationChange(e)} value="Military Base" checked={locationIschecked("Military Base")} />
                        <label className="form-check-label">Military Base</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="airport" onChange={(e) => handleLocationChange(e)} value="Airport" checked={locationIschecked("Airport")} />
                        <label className="form-check-label">Airport</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="other secured" onChange={(e) => handleLocationChange(e)} value="Other Secured or Limited Access Location" checked={locationIschecked("Other Secured or Limited Access Location")} />
                        <label className="form-check-label">Other Secured or Limited Access Location</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="">
    <div className="accordion" id="accor_llisted_box">
        <div className="accordion-item">
            <h2 className="accordion-header" id="accor_llisted_box_heading_1">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_llisted_box_collapse_1" aria-expanded="true" aria-controls="accor_llisted_box_collapse_1">
                    Listed By
                </button>
            </h2>
            <div id="accor_llisted_box_collapse_1" className="accordion-collapse collapse " aria-labelledby="accor_llisted_box_heading_1" data-bs-parent="#accor_llisted_box">
                <div className="accordion-body">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                        <label className="form-check-label">All</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                        <label className="form-check-label">Customer</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                        <label className="form-check-label">Broker</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

</div>
    </div>
  </div>

  <div className="bg-light">
  <div className="row g-0">
      <div className="col-12 col-md-3 mobile_off">

                    <div className="bg-white sticky-top pb-2 mh-100">

                        <section className="">
                            <div className="card border-0">
                                <div className="card-body">
                                    <button className="btn btn-primary btn-block" onClick={() => filterShipment()}>Filter</button>

                                    <button className="btn btn-primary btn-block" onClick={() => clearFilter()} style={{ float: "right" }}>Clear Filter</button>
                                    {/* <a href="" className="text-uppercase">Sign in to save the search</a> */}
                                </div>
                            </div>
                        </section>

                        <section className="">
                            <div className="card bg-dark">
                                <div className="card-body text-light">
                                    <h6 className="text-center ">Shipment Filter</h6>
                                    <div>Pickup Location</div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text"><i className="fa fa-arrow-right rotate_40" aria-hidden="true"></i></span>
                                        <input type="text" className="form-control" placeholder="Search" name="pickUpLocation" onChange={(e) => handleInput(e)} value={placePickup.value} />
                                        <div>&nbsp; </div>
                                        <button className="btn btn-primary" type="submit">
                                            <i className="fa fa-map-marker fs-4" aria-hidden="true"></i>
                                            <i className="fa fa-plus fs-6" aria-hidden="true"></i>
                                        </button>
                                        <ul>{(!pl) ? renderSuggestionsPickup() : ""}</ul>
                                    </div>


                                    {/* <label htmlFor="customRange2" className="form-label">Range: <small>100KM</small></label>
                                    <input type="range" className="form-range" min="0" max="100" id="" name="range" value={filters.range} onChange={(e)=>rangeOnChangeHandle(e)} placeholder="100KM" /> */}

                                    <hr />
                                    <div>Delivery Location</div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text"><i className="fa fa-arrow-right rotate_40" aria-hidden="true"></i></span>
                                        <input type="text" className="form-control" placeholder="Search" name="deliveryLocation" onChange={(e) => handleInput(e)} value={placeDelivery.value} />
                                        <div>&nbsp; </div>
                                        <button className="btn btn-primary" type="submit">
                                            <i className="fa fa-map-marker fs-4" aria-hidden="true"></i>
                                            <i className="fa fa-plus fs-6" aria-hidden="true"></i>
                                        </button>
                                        <ul>{(!dl) ? renderSuggestionsDelivery() : ""}</ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="">
                            <div className="accordion" id="accordion_1">

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading_1">

                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                            All Categories
                                        </button>
                                    </h2>

                                    <div id="collapse1" className="accordion-collapse collapse " aria-labelledby="heading_1" data-bs-parent="#accordion_1">
                                        <div className="accordion-body accordion-body_custom accordion-flush">
                                            <div className="row">
                                                <div className="col"></div>
                                            </div>
                                            {state.category_list.map((subscriber, index) => (

                                                <>
                                                    <div className="accordion " id="accordion_2" key={index}>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="heading_2_1">
                                                                <button className="accordion-button accordion-button-custom" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_2_${index}`} aria-expanded="true" aria-controls="collapse_2_2">
                                                                    {subscriber.category_name}
                                                                </button>
                                                            </h2>
                                                            {(subscriber.subcategorydetail != undefined && subscriber.subcategorydetail.length > 0) ? (subscriber.subcategorydetail.map((sub, ind) => {
                                                                return (
                                                                    <>
                                                                        <div id={`collapse_2_${index}`} className="accordion-collapse collapse" aria-labelledby="heading_2_1" data-bs-parent="#accordion_2">

                                                                            <div className="accordion-body">


                                                                                <div className="form-check" key={ind}>
                                                                                    <input className="form-check-input" type="checkbox" id="check1" name="{sub.subcategory_name}" value="something" onChange={(e) => handleSubcategoryChange({ subcategory_id: sub.subcategory_id }, e)} 
                                                                                    checked={                    categoryIsChecked(sub.subcategory_id)}
                                                                                    />
                                                                                    <label className="form-check-label">{sub.subcategory_name}</label>
                                                                                </div>


                                                                            </div>




                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            )) : ""}
                                                        </div>

                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>

                        <section className="">
                            <div className="accordion" id="accor_weight_box">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accor_weight_box_heading_1">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_weight_box_collapse_1" aria-expanded="true" aria-controls="accor_weight_box_collapse_1">
                                            Weight
                                        </button>
                                    </h2>
                                    <div id="accor_weight_box_collapse_1" className="accordion-collapse collapse " aria-labelledby="accor_weight_box_heading_1" data-bs-parent="#accor_weight_box">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control" name="minWeight" value={weightfilters.minWeight} id="email" onChange={(e) => handleWeightChange(e)} placeholder="min." />
                                                        <label htmlFor="email">Min Weight</label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control" name="maxWeight" value={weightfilters.maxWeight} onChange={(e) => handleWeightChange(e)} id="max" placeholder="min." />
                                                        <label htmlFor="max">Max Weight</label>
                                                    </div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="">
                            <div className="accordion" id="accor_pricing_box">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accor_pricing_box_heading_1">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_pricing_box_collapse_1" aria-expanded="true" aria-controls="accor_pricing_box_collapse_1">
                                            Pricing Type
                                        </button>
                                    </h2>
                                    <div id="accor_pricing_box_collapse_1" className="accordion-collapse collapse " aria-labelledby="accor_pricing_box_heading_1" data-bs-parent="#accor_pricing_box">
                                        <div className="accordion-body">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="auction" onChange={(e) => handlePriceChange(e)} value="something" />
                                                <label className="form-check-label">Auction</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="offer" onChange={(e) => handlePriceChange(e)} value="something" />
                                                <label className="form-check-label">Offer</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="">
                            <div className="accordion" id="accor_location_box">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accor_location_box_heading_1">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_location_box_collapse_1" aria-expanded="true" aria-controls="accor_location_box_collapse_1">
                                            Location Type
                                        </button>
                                    </h2>
                                    <div id="accor_location_box_collapse_1" className="accordion-collapse collapse " aria-labelledby="accor_location_box_heading_1" data-bs-parent="#accor_location_box">
                                        <div className="accordion-body">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="anywhare" onChange={(e) => handleLocationChange(e)} value="Anywhere" checked={locationIschecked("Anywhere")} />
                                                <label className="form-check-label">Anywhere</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="residence" onChange={(e) => handleLocationChange(e)} value="Residence / Home Business" checked={locationIschecked('Residence / Home Business')} />
                                                <label className="form-check-label">Residence / Home Business</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="business" onChange={(e) => handleLocationChange(e)} value="Business (with loading dock or forklift)" checked={locationIschecked("Business (with loading dock or forklift)")} />
                                                <label className="form-check-label">Business (with loading dock or forklift)</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="business without" onChange={(e) => handleLocationChange(e)} value="Business (without loading dock or forklift)" checked={locationIschecked("Business (without loading dock or forklift)")} />
                                                <label className="form-check-label">Business (without loading dock or forklift)</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="port" onChange={(e) => handleLocationChange(e)} value="Port" checked={locationIschecked("Port")} />
                                                <label className="form-check-label">Port</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="construction" onChange={(e) => handleLocationChange(e)} value="Construction Site" checked={locationIschecked("Construction Site")} />
                                                <label className="form-check-label">Construction Site</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="trade show" onChange={(e) => handleLocationChange(e)} value="Trade Show / Convention Center" checked={locationIschecked("Trade Show / Convention Center")} />
                                                <label className="form-check-label">Trade Show / Convention Center</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="storage facility" onChange={(e) => handleLocationChange(e)} value="Storage Facility" checked={locationIschecked("Storage Facility")} />
                                                <label className="form-check-label">Storage Facility</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="military base" onChange={(e) => handleLocationChange(e)} value="Military Base" checked={locationIschecked("Military Base")} />
                                                <label className="form-check-label">Military Base</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="airport" onChange={(e) => handleLocationChange(e)} value="Airport" checked={locationIschecked("Airport")} />
                                                <label className="form-check-label">Airport</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="other secured" onChange={(e) => handleLocationChange(e)} value="Other Secured or Limited Access Location" checked={locationIschecked("Other Secured or Limited Access Location")} />
                                                <label className="form-check-label">Other Secured or Limited Access Location</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="">
                            <div className="accordion" id="accor_llisted_box">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accor_llisted_box_heading_1">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_llisted_box_collapse_1" aria-expanded="true" aria-controls="accor_llisted_box_collapse_1">
                                            Listed By
                                        </button>
                                    </h2>
                                    <div id="accor_llisted_box_collapse_1" className="accordion-collapse collapse " aria-labelledby="accor_llisted_box_heading_1" data-bs-parent="#accor_llisted_box">
                                        <div className="accordion-body">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                <label className="form-check-label">All</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                <label className="form-check-label">Customer</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                <label className="form-check-label">Broker</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
            </div>
                
            <div className="col-12 col-md-9">
                <div className="p-2 flex-grow-1">

                    <section>
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <div className="text-uppercase text-muted"><small>Pickup Date</small></div>
                                <div>
                                    <div className="input-group">
                                        <span className="input-group-text"><i className="fa fa-clock-o" aria-hidden="true"></i></span>
                                        <input type="date" name="pickupDate" value={filters.pickupDate} onChange={(e) => rangeOnChangeHandle(e)} className="form-control" placeholder="Username" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="text-uppercase text-muted"><small>Sort By</small></div>
                                <div>
                                    <div className="input-group">
                                        <span className="input-group-text"><i className="fa fa-sort" aria-hidden="true"></i></span>
                                        <select className="form-select" name="sort" onChange={(e) => handleSortChange(e)} value={sortfilters.sort}>
                                            <option value="recentList">Recently Listed</option>
                                            <option value="expireList">Listing Expiration</option>
                                            <option value="numberQuotes">Number of Quotes</option>
                                            <option value="numberQuotesPrice">Quote/Offer Price</option>
                                            <option value="totalDistance">Total Distance</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1 col-md-3">&nbsp;</div>
                            <div className="col-8 col-md-2 mt-3">
                                <div className="text-uppercase text-muted">
                                    {/* <small>View</small> */}
                                    </div>
                                <div>
                                    <div className="btn-group">
                                        <button type="button" className={(listingMap.type == 1) ? "btn btn-primary" : "btn btn-secondary"} onClick={()=>setListingMap({...listingMap,type:1})}><i className="fa fa-list" aria-hidden="true"></i></button>
                                        <button type="button" className={(listingMap.type == 2) ? "btn btn-primary" : "btn btn-secondary"} onClick={()=>setListingMap({...listingMap,type:2})}><i className="fa fa-map" aria-hidden="true" ></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 col-md-1">
                                <div className="text-uppercase text-muted"><small>&nbsp;</small></div>
                                <div>
                                    <div className="btn-group">
                                        {/* <button type="button" className="btn btn-warning rounded-circle"><i className="fa fa-heart" aria-hidden="true"></i> <span className="badge bg-danger bedge_custom rounded-circle">90</span></button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <hr />
                    <div className="d-flex justify-content-between">
                        {/* <div className="p-2 bd-highlight">673 Results</div>
                        <div className="p-2 bd-highlight"><small className="text-muted">last updated { moment(newDate).format("YYYY-MM-DD HH:mm:ss")  } </small></div> */}
                    </div>
                    {state.isLoading == true ? <ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} /> :
                        (state.shipment_list.length > 0) ?
                         (listingMap.type == 1)   ?
                         <section className="shipment_list_table">
                                <div className="row shipment_list_table_heading mb-2 mobile_off ">
                                    <div className="col-1 "></div>
                                    <div className="col-3">PICKUP</div>
                                    <div className="col-3">DELIVERY</div>
                                    <div className="col-1">TRIP</div>
                                    <div className="col-1">TOTAL QUOTES</div>
                                    <div className="col-2">LISTING INFO</div>
                                    <div className="col-1">MORE</div>
                                </div>
                                {state.shipment_list.map((subscriber, ind) => (
                                    <section>
                                        <div key={ind}>
                                        <div className="row">
                  <div className="col-12 col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row g-2">
                                                  
                                                        <div className="col-4 col-md-1">
     <Link to={"/shipment/" + subscriber.invoice_id}>
        {subscriber.item_imgs.length>0? 
     <img src={`https://shippxcdn.fniix.com/web/${subscriber.item_imgs[0]}`} className="img-fluid " style={{height:"80px", width:"100%"}} /> :(subscriber.item_imgs.length==0)?<img src={`https://shippxcdn.fniix.com/web/${subscriber.category_img}`} style={{height:"80px", width:"100%"}}/>:<img src="https://www.nicepng.com/png/detail/933-9332131_profile-picture-default-png.png" style={{height:"80px", width:"100%"}}/>}
     
     
                                                        </Link>
                                                        </div>
                                                   
                                                        <div className="col-8 col-md-6">
                                                            <div className="row g-2">
                                                        <div className="col-12 col-md-6">
                                                        <div className="d-flex">
                                                            <div className="p-2">

                                                            <i class="fa  fs-4 fa-arrow-right rotate_40 text-muted  opacity-50" aria-hidden="true"></i>
                                                            </div>
                                                            <div className="mb-3">
                                                            <h5><i className="fa fa-map-marker text-muted" aria-hidden="true"></i><Link to={"/shipment/" + subscriber.invoice_id}>  <a >{subscriber.pickup_address}</a></Link>
                                                            </h5>
                                                            <small><Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_to}</Moment> </small>
                                                            </div>
                                                            </div>
                                                        </div>
                                                            {/*  */}
                                                        
                                                       
                                                        <div className="col-12 col-md-6">

                                                            <div className="d-flex">
                                                            <div className="p-2">
                                                            <i class="fa fs-4 fa-arrow-down rotate_40 text-muted  opacity-50" aria-hidden="true"></i>
                                                            </div>
                                                            <div className="mb-3">
                                                        <h5><i className="fa fa-map-marker text-muted" aria-hidden="true"></i><Link to={"/shipment/" + subscriber.invoice_id}> <a >{subscriber.delivery_address}</a></Link>
                                                            </h5>
                                                            <small><Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_to}</Moment></small>
                                                            </div>
                                                            </div>
                                                           </div>
                                                           </div>
                                                        </div>
                                                        <div className="col-3 col-md-1">
                                                        <p>{subscriber.distance} mi.</p>
                                                           
                                                            {/* <p>{subscriber.description}</p> */}
                                                        </div>
                                                        <div className="col-4 col-md-1">
                                                        <p>{subscriber.total_quotes} quotes</p>
                                                        </div>
                                                        <div className="col-4 col-md-2">
                                                        <p>{endDate = subscriber.created,
                                                            days = currentDate - endDate,
                                                            day = parseInt(days / 86400), day != 0 ? `${day}d :` : ""} {Math.floor(days / 3600) % 24}h : {Math.floor(days / 60) % 60}m : {days % 60}s</p>
                                                        </div>
                                                        <div className="col-1 col-md-21"><a href="" data-bs-toggle="collapse" data-bs-target={`#info_box_${ind}`}><i className="fa fa-chevron-circle-down" aria-hidden="true"></i></a>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row collapse" id={`info_box_${ind}`}>
                                                        <div className="card bg-light border-0">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-12 col-md-4">
                                                                        <h6 className="pt-3 pb-2">LOCATION DETAILS</h6>
                                                                        <p className="m-0">Pickup: <span className=" ">{subscriber.pickup_address_type}</span></p>
                                                                        <p className="m-0">Delivery: <span className="">{subscriber.delivery_address_type}</span></p>
                                                                    </div>
                                                                    <div className="col-12 col-md-4 ">
                                                                        <h6 className="pt-3 pb-2">DETAILS</h6>
                                                                        <p className="m-0">Items: <span className=" ">{subscriber.qty}</span></p>
                                                                        <p className="m-0">Trailer Type: <span className="">{subscriber.service_type}</span></p>
                                                                        <p className="m-0">Listed By: <span className="">Shipping Customer</span></p>
                                                                    </div>
                                                                    <div className="col-12  col-md-4 border-start text-center view_order_box">
                                                                        {(subscriber.lowest_quote != undefined) ?

                                                                            <>
                                                                                <h6 className="pt-3 pb-2">LOW QUOTE</h6>
                                                                               {dimensionState.companyDetail.map((sub)=>(
                                                                                        <p className="shipment_list_table_price m-0">{sub.set_currency}{subscriber.lowest_quote}</p>
                                                                               ))}
                                                                                <p className="m-0 d-grid"><a href={"/shipment/" + subscriber.invoice_id} className="btn btn-primary btn-block"><Link style={{ color: 'white' }} to={"/shipment/" + subscriber.invoice_id}>View</Link></a></p>
                                                                            </>

                                                                            : <> <p className="m-0 d-grid"><a href={"/shipment/" + subscriber.invoice_id} className="btn btn-primary btn-block"><Link style={{ color: 'white' }} to={"/shipment/" + subscriber.invoice_id}>View</Link></a></p> </>}


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

</div>
</div>





                                        </div>
                                    </section>
                                ))}
                            </section>
:<MapMarkers data={state.shipment_list} />
                            :
                            <section>
                                <div className="row">
                                    <div className="col text-center">
                                        <img src="img/404.png" className="img-fluid w-25" />
                                        <p>Results not found.Please try again.</p>
                                    </div>
                                </div>
                            </section>

                    }
                </div>
</div>
            </div>
            </div>


</section>

            {/* </div>
                    </section>
                    </div>
                    </div>
                    </div> */}
            <Footer />
        </>
    )
}

export default Shipment;
