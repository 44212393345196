import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import exportValue from "../apiconfig";
import axios from "axios";
const MenuGuest = () => {

  const [categories, setCategories] = useState({ category_list: [], dstatus: 1, subcategoryList: [] })
  let navigate = useNavigate();
  const loginCheck = () => {
    if (localStorage.getItem('loggedIn')) {
      //navigate('/home') 
    }
  }

  const logged = () => {
   // console.log("->< ", localStorage.getItem('loggedIn'));
   // if (localStorage.getItem('loggedIn')) {
    //  let userDetail = JSON.parse(localStorage.getItem('loggedIn'));
      navigate('/ship')
      //setState(userDetail);
     // console.log("-> ", userDetail);
  //  } else {
     // navigate('/login')
    //}
  }
  useEffect(() => {
    loginCheck()
    //dispatch(tagsListAction());
  }, []);

  // if (localStorage.getItem('loggedIn')) {
  //   navigate('/home') 
  // }

  const cat_subcat_detail = () => {
    let data = { category_list: {}, dstatus: 1 };
   // console.log("stay is ", data);

    axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/shipment_list`, data, { headers: exportValue.headers }).then((res) => {
      // console.log(" res data is ", res);
      setCategories({ ...categories, category_list: res.data.category_output, isLoading: false })
    })

  }
  useEffect(() => {
    cat_subcat_detail()
    //dispatch(tagsListAction());
  }, []);
  return (
    <>

      {/* header for desktop  */}
      <header className="nav-item_header shadow-sm d-none d-sm-block">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-sm nav_custom">
            <Link to="/">  <img src={__dirname + "img/logo.png"} className="img-fluid logo" /></Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
              <span className="navbar-toggler-icon"><i className="fa fa-bars" aria-hidden="true"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul className="navbar-nav me-auto">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Getting Start
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/how_does_it_work"><a className="dropdown-item" >How does it work?</a></Link></li>
                    <li><Link to="/why_us"><a className="dropdown-item" >Why us?</a></Link></li>
                    <li><Link to="/send_anything"><a className="dropdown-item" >Send anything anywhere</a></Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    {categories.category_list.map((subscriber) => (
                      <li><Link to={`/shipment-list?cId=%5B%5D`}><a className="dropdown-item" >{subscriber.category_name}</a></Link></li>
                    ))}

                    {/* <li><hr className="dropdown-divider" /></li> */}
                    {/* <li><Link to="/myaccount_customer"><a className="dropdown-item">My account Customer</a></Link></li>
            <li><a className="dropdown-item" >My account Transporter</a></li> */}
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={(e) => logged(e)} style={{ cursor: "pointer" }}> Ship Now</a>
                </li>
                <li className="nav-item">
                  <Link to="/shipment-list"><a className="nav-link">Find Shipment</a></Link>
                </li>
                <li className="nav-item">
                  <Link to="/for_carriers"><a className="nav-link">FOR CARRIERS</a></Link>
                </li>
                <li className="nav-item">
                  <Link to="/for_business"><a className="nav-link" >
                    FOR BUSINESS</a></Link>
                </li>
              </ul>

              <ul className="navbar-nav justify-content-center">
                <li className="nav-item border-end">

                  <Link to="/login">  <a className="nav-link" >Sign In</a></Link>
                </li>
                <li className="nav-item">
                  <Link to="/registration">   <a className="nav-link">Sign Up</a></Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>



      {/* header for mobile  */}
      <header className="nav-item_header shadow-sm d-block d-sm-none">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-sm nav_custom">
            <Link to="/">  <img src={__dirname + "img/logo.png"} className="img-fluid logo" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
              <span className="navbar-toggler-icon"><i className="fa fa-bars" aria-hidden="true"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul className="navbar-nav me-auto">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle mx-3" href="" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Getting Start
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/how_does_it_work"><a className="dropdown-item" >How does it work?</a></Link></li>
                    <li><Link to="/why_us"><a className="dropdown-item" >Why us?</a></Link></li>
                    <li><Link to="/send_anything"><a className="dropdown-item" >Send anything anywhere</a></Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    {categories.category_list.map((subscriber) => (
                      <li><Link to={`/shipment-list?cId=%5B%5D`}><a className="dropdown-item" >{subscriber.category_name}</a></Link></li>
                    ))}

                    {/* <li><hr className="dropdown-divider" /></li> */}
                    {/* <li><Link to="/myaccount_customer"><a className="dropdown-item">My account Customer</a></Link></li>
            <li><a className="dropdown-item" >My account Transporter</a></li> */}
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link mx-3" onClick={(e) => logged(e)} style={{ cursor: "pointer" }}> Ship Now</a>
                </li>
                <li className="nav-item">
                  <Link to="/shipment-list"><a className="nav-link">Find Shipment</a></Link>
                </li>
                <li className="nav-item">
                  <Link to="/for_carriers"><a className="nav-link">FOR CARRIERS</a></Link>
                </li>
                <li className="nav-item">
                  <Link to="/for_business"><a className="nav-link" >
                    FOR BUSINESS</a></Link>
                </li>
              </ul>

              <ul className="navbar-nav justify-content-center">
                <li className="nav-item border-end">

                  <Link to="/login">  <a className="nav-link" >Sign In</a></Link>
                </li>
                <li className="nav-item">
                  <Link to="/registration">   <a className="nav-link">Sign Up</a></Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

    </>
  )
}

export default MenuGuest;
