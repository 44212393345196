import React, { useState, useEffect } from "react";
import Moment from 'react-moment';
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import exportValue from '../apiconfig';
//import { form } from 'react-router-dom';
import FormData from 'form-data';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactLoading from 'react-loading';
import { height } from "@mui/system";
import Modal from 'react-bootstrap/Modal';
import Login_view from './Login_view';
import { Container, Row, Col, Form, Button, ProgressBar, ModalTitle } from "react-bootstrap"






const AddShipmentStepTwo = () => {
    let userDetail = localStorage.getItem('loggedIn');
    let navigate = useNavigate();
    let newDate = Date.now();
    // let getTime = newDate.getTime();
    console.log("---------------   date--  ", newDate);
    console.log(moment(newDate).format("YYYY-MM-DD"));
    let pickupDateto = newDate + (2 * 86400 * 1000);
    let deliveryDateTo = pickupDateto + (2 * 86400 * 1000);
    console.log("---------------   pickupDateto--  ", pickupDateto);
    const [somevalue, setValue] = useState({ setStatelist: 1, isLoop: true, locationLoop: true });
    const [state, setState] = useState({ title: "", service_type: "open transport", instructions: "", pickup_datetime_from: moment(newDate).format("YYYY-MM-DD"), pickup_datetime_to: moment(pickupDateto).format("YYYY-MM-DD"), pickup_address_type: "", delivery_datetime_from: moment(pickupDateto).format("YYYY-MM-DD"), delivery_address_type: "", delivery_datetime_to: moment(deliveryDateTo).format("YYYY-MM-DD"), uploadFiles: [], pickup_address: "", delivery_address: "", products: [], category: "", sub_category: "", item_weight: 0, item_height: 0, item_length: 0, item_width: 0, item_value: 0, sender_name: "", sender_mob_no: "", reciever_name: "", reciever_mob_no: "" });
    const [products, setProducts] = useState([])
    const [isLoading, setIsloading] = useState(false)
    const [selectedImages, setSelectedImages] = useState({ uploadPhoto: [] })
    const [modalState, setModalState] = useState({ show: false, email: "", password: "", isLoading: false });

    useEffect(() => {

    }, [])


    let attributeData1 = useSelector((state) => state.addShipmentAttributeDetail.ATTRIBUTE_ADD_SHIPMENT_DETAIL);
    console.log("attributeData1 ", attributeData1);
    let locationShipment = useSelector((state) => state.addShipmentLocationDetail.LOCATION_ADD_SHIPMENT_DETAIL);
    //let date = Moment(newDate).format("YYYY-MM-DD");
    console.log("locationShipment ", locationShipment);



    // if (locationShipment.pickUpLocation) {
    //     setState({ ...state, pickup_address: locationShipment.pickUpLocation });
    // }
    // if (locationShipment.deliveryLocation) {
    //     setState({ ...state, delivery_address: locationShipment.deliveryLocation });
    // }


    if (locationShipment != undefined && somevalue.locationLoop) {
        setValue({ ...somevalue, locationLoop: false })
        setState({ ...state, pickup_address: locationShipment.pickUpLocation, pickup_address_type: locationShipment.pickup_address_type, delivery_address: locationShipment.deliveryLocation, delivery_address_type: locationShipment.delivery_address_type, category: locationShipment.category_id, sub_category: locationShipment.subcategory_id, sender_name: locationShipment.sender_name, sender_mob_no: locationShipment.sender_mob_no, reciever_name: locationShipment.reciever_name, reciever_mob_no: locationShipment.reciever_mob_no });
        //item_value: locationShipment.value, item_width: locationShipment.width, item_length: locationShipment.length, item_height: locationShipment.height, item_weight: locationShipment.weight,
    }
    console.log("setttttttt", locationShipment.pickup_address_type)
    console.log("userDetail is ", userDetail);
    if (attributeData1 != undefined && somevalue.isLoop) {
        setValue({ ...somevalue, isLoop: false })
        console.log("attributeData1 ", attributeData1);
        // let products = [];
        if (attributeData1) {
            let pite = [];
            let items = [];
            let attribes = [];
            let groups = {};
            let item_weight = 0;
            let sItem = "";
            attributeData1.forEach(element => {
                sItem = (element.item_name != "") ? sItem + " " + element.item_name : "";
                //setState({...state, category:element.category_id});
                // groups = {type:"",name:"",attribute_id:"",group_id:"",value:""}
                console.log("element ->>>", element);
                items = [];
                item_weight += parseInt(element.dimensions.item_weight);
                element.attributes.forEach(eleVal => {
                    console.log("eleVal ->>>", eleVal);
                    attribes = [];
                    if (eleVal.attribute_type == 1 || eleVal.attribute_type == 2 || eleVal.attribute_type == 3) {

                        eleVal.attribute_value.forEach(val => {
                            console.log("val ==>>  ", val);
                            if (val.isSelected) {
                                groups = {};
                                console.log("val true ", val);

                                groups.type = eleVal.attribute_type;
                                groups.name = eleVal.attribute_name;
                                groups.attribute_id = eleVal.attribute_id;
                                groups.group_id = val.group_id;
                                groups.value = val.group_value;
                                items.push(groups)
                                // setProducts(products => [...products, groups])
                                //const updatedCarsArray = [...products, groups];
                                // setProducts(updatedCarsArray)

                            }
                        })
                    }
                    else if (eleVal.attribute_type == 4) {
                        console.log("eleVal 4", eleVal);
                        // eleVal.forEach(val => {
                        //   console.log("val 4 => ", val);
                        groups = {};
                        groups.type = eleVal.attribute_type;
                        groups.name = eleVal.attribute_name;
                        groups.attribute_id = eleVal.attribute_id;
                        // groups.group_id = val.group_id;
                        // groups.group_value = val.group_value;
                        groups.value = eleVal.attribute_value[0];
                        items.push(groups)
                        // setProducts(products => [...products, groups])
                        // })
                    }
                    if (attribes.length > 0) {
                        //  items.push(attribes)

                    }
                    console.log("attribes ==>>>> ", attribes);
                });
                pite.push({ item_name: element.item_name, attributes: items, dimensions: [element.dimensions] })

                // console.log("pite ==>>>> ", pite);
            });
            setProducts(pite)
            setState({ ...state, item_weight: item_weight, title: sItem });

            console.log("pite ==>>>> ", pite);
            // console.log("items ==>>>> ", attribes);
        }


    }

    const [axiosheader, setHeader] = useState(exportValue.headers);
    const datePicker = (e) => {
        console.log(e);

        if (e.target.name == "pickup_datetime_from") {
            console.log("before time ", e.target.value);
            //    setState({...state, [e.target.name]: moment(e.target.valueAsNumber).format("YYYY-MM-DD")});

            let nextDate = (e.target.value)
            console.log("nextDate  ", nextDate);
            setState({ ...state, [e.target.name]: nextDate, pickup_datetime_from: nextDate });
        }
        if (e.target.name == "pickup_datetime_to") {
            let nextDate = (e.target.value);
            console.log("bbb", nextDate)
            let nextDate1 = moment((nextDate + (7 * 86400 * 1000))).format("YYYY-MM-DD");
            setState({ ...state, [e.target.name]: moment(nextDate).format("YYYY-MM-DD"), delivery_datetime_from: nextDate1 });
        } else {
            setState({ ...state, [e.target.name]: moment(e.target.value).format("YYYY-MM-DD") });
        }
    }
    const handleChange = (event) => {
        console.log(event);
        if (event.target.type == "file") {
            console.log(event.target.files);
            setState({
                ...state,
                [event.target.name]: event.target.files,
            });
            if (event.target.name == "uploadFiles") {
                const selectedFilesArray = Array.from(event.target.files);
                //console.log("selectedFilesArray     ",selectedFilesArray);
                const imagesArray = selectedImages.uploadPhoto;

                selectedFilesArray.map((file) => {
                    imagesArray.push(URL.createObjectURL(file))
                })


                setSelectedImages({ ...selectedImages, uploadPhoto: imagesArray });
            }

        }
        else if (event.target.type == "date") {
            // console.log(event)
            // setState({
            //     ...state,
            //     [event.target.name]: moment(event.target.valueAsNumber).format("YYYY-MM-DD"),
            // });

        } else {
            setState({
                ...state,
                [event.target.name]: event.target.value,
            });
        }
    }
    const removeImage3 = (value) => {
        console.log("on click 3- - -   ", value);
        // let filteredArray = selectedImages.filter(item => item !== value)
        // console.log("filteredArray 2   ",filteredArray);
        // setSelectedImages({...selectedImages, filteredArray});
        // setSelectedImages({...selectedImages.uploadPhoto.filter(item=>item!==value)});
        setSelectedImages({ ...selectedImages, uploadPhoto: selectedImages.uploadPhoto.filter(item => item !== value) })
        setState({ ...state, uploadFiles: selectedImages.uploadPhoto.filter(item => item !== value) })



    }

    const createShipment = () => {
        console.log("value submit");
        console.log("stay is ", state);
        console.log("products is ", products);
        console.log("userDetail is ", userDetail);


        if (state.title != '' && state.pickup_address != '' && state.delivery_address !== '' && state.pickup_datetime_from != '' && state.pickup_datetime_to != '' && state.delivery_datetime_from != '' && state.delivery_datetime_to != '' && state.service_type != '') {
            if (userDetail != null) {
                submitTosave();

                // setModalState({...modalState, show: true })
            } else {
                submitTosave();
                setModalState({ ...modalState, show: true })
                toast.configure();
                toast.success("Please login ")
            }
        }
        else {
            toast.configure();
            toast.error("Please Fill The Inputs")
        }

    }

    async function submitTosave() {
        // console.log("function call ", moment(state.pickup_datetime_to , 'YYYY-MM-DD').valueOf());
        setIsloading(true)
        let newDate = new Date();
        let getTime = newDate.getTime();
        let bodyFormData = new FormData();
        let temp_id = "temp_" + getTime;

        bodyFormData.append('title', state.title);

        if (userDetail == null) {
            bodyFormData.append('dstatus', 9);
            bodyFormData.append('invoice_id', temp_id);
        }
        bodyFormData.append('description', state.instructions);
        bodyFormData.append('service_type', state.service_type);
        bodyFormData.append('pickup_datetime_from', moment(state.pickup_datetime_from, 'YYYY-MM-DD').valueOf());
        bodyFormData.append('pickup_datetime_to', moment(state.pickup_datetime_to, 'YYYY-MM-DD').valueOf());
        bodyFormData.append('delivery_datetime_from', moment(state.delivery_datetime_from, 'YYYY-MM-DD').valueOf());
        bodyFormData.append('delivery_datetime_to', moment(state.delivery_datetime_to, 'YYYY-MM-DD').valueOf());
        bodyFormData.append('category', state.category);
        bodyFormData.append('sub_category', state.sub_category);
        bodyFormData.append('pickup_address', state.pickup_address);
        bodyFormData.append('pickup_address_type', state.pickup_address_type);
        bodyFormData.append('delivery_address_type', state.delivery_address_type);
        bodyFormData.append('sender_name', state.sender_name);
        bodyFormData.append('sender_mob_no', state.sender_mob_no);
        bodyFormData.append('reciever_name', state.reciever_name);
        bodyFormData.append('reciever_mob_no', state.reciever_mob_no);
        bodyFormData.append('pickup_city', "");
        bodyFormData.append('delivery_address', state.delivery_address);
        bodyFormData.append('delivery_city', "");
        bodyFormData.append('qty', products.length);

        bodyFormData.append('item_weight', state.item_weight);
        // bodyFormData.append('item_height', state.item_height);
        // bodyFormData.append('item_length', state.item_length);
        // bodyFormData.append('item_width', state.item_width);
        // bodyFormData.append('item_value', state.item_value);
        bodyFormData.append('products', JSON.stringify(products));
        let pImages = [];
        for (var i = 0; i < state.uploadFiles.length; i++) {
            //  [...state.uploadFiles].forEach(image => {
            let productimages = state.uploadFiles[i];
            // Array.from(statße.uploadFiles).forEach(file => {
            // pImages.push(productimages);
            bodyFormData.append("fileImage", productimages)
            //  fd.append("fileToUpload[]", document.getElementById('fileToUpload').files[x]);
            //console.log("pfiles is ", state.uploadFiles[i]);


        }
        // bodyFormData.append("fileImage", pImages);

        //  FileList



        console.log("stay is ", bodyFormData);
        const formHeaders = { 'content-type': 'multipart/form-data' };
        //console.log("formHeaders is ", formHeaders);
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/shipment_create`;
        console.log("full_api is ", full_api);
        console.log("axiosheader -  ", axiosheader);
        // setHeader({...axiosheader,formHeaders})
        if (localStorage.getItem('loggedIn')) {
            let userDetail = JSON.parse(localStorage.getItem('loggedIn'));
            exportValue.headers.usertuid = userDetail.user_id;
        }
        axios.post(full_api, bodyFormData, { headers: exportValue.headers }, true).then((res) => {
            console.log(" res data is ", res);
            if (res.data.status == "succcess") {
                toast.configure();
                toast.success("Shipment Added Successfully ")

                if (userDetail == null) {
                    localStorage.setItem('temp_shipment', temp_id);
                    navigate('/login/' + temp_id)
                }
                else {
                    navigate('/myaccount_customer')
                }
            } else {
                toast.configure();
                toast.success(res.data.message)
            }

            //setState({ ...state, shipment_list: res.data.output, category_list: res.data.category_output })

        })
    }




    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowDimensions;
    }

    const { height, width } = useWindowDimensions();

    const loginModal = () => {
        console.log(modalState);
        if (modalState.email == "" && modalState.password == "") {

            toast.configure();
            toast.error("Please fill login details");
        } else {
            setModalState({ ...modalState, isLoading: true })
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/login`;
            let sendData = {
                email: modalState.email,
                password: modalState.password,
            };
            console.log(sendData);
            if (localStorage.getItem('firebaseToken')) {
                sendData.token = localStorage.getItem('firebaseToken');
            }
            axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
                console.log(res);

                if (res.data.output.length == 1) {
                    //  setState({...state,user_id:res.data.output[0].user_id})
                    // setIsloading(true)

                    if (state.remember_me) {
                        // setCookie('_user', JSON.stringify(res.data.output[0]));

                    }
                    //sessionStorage.setItem("loggedIns", JSON.stringify(res.data.output[0]));
                    localStorage.setItem('loggedIn', JSON.stringify(res.data.output[0]));
                    console.log("---------------------------");
                    if (res.data.output[0].user_type == 1) {
                        console.log("------------user---------------");
                        setTimeout(
                            submitTosave, 3000
                        )
                    } else {

                        console.log("-----------transporter----------------");
                        toast.configure();
                        toast.error("You logged in  as transporter, shipment not submitted");
                        setTimeout(
                            redirectf, 3000
                        )
                    }

                } else {

                    toast.configure();
                    toast.error(res.data.message);
                    setModalState({ ...modalState, isLoading: false })
                    //setState({ ...state, isLoading: false })

                }
                //state.isLoading = false;
                // setUserDataCount(res.data.count);
                //setUserData(res.data.categoryList);
            })
                .catch((e) => {
                    setState({ ...state, isLoading: false });

                    toast.configure();
                    toast.error("Some thing went wrong");
                    console.log("----error:   ", e);
                });
        }
    }

    const redirectf = () => {

        navigate('/')
    }
    const loginStateChangeHandler = (e) => {
        setModalState({ ...modalState, [e.target.name]: e.target.value })
    }




    return (
        <>
            <Modal show={modalState.show} >
                {/* <Modal.Header closeButton onClick={() => setModalState({ show: false })}>
                    <ModalTitle>
                        <h4>Login </h4>
                    </ModalTitle>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-4'>
                        <h6>Email-id</h6>
                        <input type="text" className='form-control' rows="5" cols="3" name="email" placeholder='Please Enter Message' onChange={(e) => loginStateChangeHandler(e)} />
                    </div>
                    <div className='mb-4'>
                        <h6>Password</h6>
                        <input type="password" className='form-control' rows="5" cols="3" name="password" placeholder='Please Enter Message' onChange={(e) => loginStateChangeHandler(e)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                {(modalState.isLoading == true) ? <ReactLoading type="bubbles" color="black" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} /> :""}
                    <Button variant="primary" disabled={modalState.isLoading} onClick={() => loginModal()} >
                        Submit
                    </Button>
                
                </div>
                </Modal.Footer>
                 */}
                <Login_view />
            </Modal>
            <Header />
            <section className="sub_heading p-3 bg-white">
                <div className="container ">
                    <div className="row">
                        <div className="col ">
                            <h3 className="fw-light">CONFIRM DETAILS</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-light p-5 ">
                <div className="container ">
                    <div className="row">
                        <div className="col-12 col-md-9 add_shipment">
                            <section style={width <= 768 ? { marginLeft: "-45px", marginRight: "-45px" } : { margin: "0px" }}>
                                <div className="d-flex p-3">
                                    <div className="p-2"><i className="fa fa-lightbulb-o fa-2x text-primary" aria-hidden="true"></i></div>
                                    <div className="p-2">
                                        <h3 className="fs-5 fw-normal">How It Works</h3>
                                        <p className="text-muted"><small>Create a free listing to receive competitive shipping quotes. Compare providers and book through our platform and save time and money. All this our gurantee.</small></p>
                                    </div>
                                </div>
                            </section>
                            <div className="card shadow-sm p-3" style={width <= 768 ? { marginLeft: "-45px", marginRight: "-45px" } : { margin: "0px" }}>
                                <div className="card-body">
                                    <h2 className="fs-5 text-uppercase">SHIPMENT DETAILS</h2>
                                    <hr />
                                    <div className="mb-3 mt-3">
                                        <small className="text-danger">*</small> <label htmlFor="email" className="form-label">Shipment title</label>
                                        <input type="text" className="form-control" id="" placeholder="Shipment title" name="title" required value={state.title} onChange={(e) => handleChange(e)} />
                                    </div>
                                    <hr />
                                    <h5 className="pt-4">Pickup & Delivery Dates</h5>
                                    <small className="text-muted">Edit your date range below. Choose a specific day by selecting the same date twice. Please choose dates before September 27th, 2022.</small>
                                    <section className="pt-4 pb-4">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="" className="form-label">PICKUP DATE RANGE</label>
                                                <div className="d-*-flex ">
                                                    <div className=" ">
                                                        <div className="input-group input-group-lg mb-3">
                                                            <input type="date" className="form-control" placeholder="dd-mm-yyyy" aria-label="Username" aria-describedby="basic-addon1" value={state.pickup_datetime_from} name="pickup_datetime_from" onChange={(e) => datePicker(e)} min={state.pickup_datetime_from} />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="input-group input-group-lg mb-3">
                                                            <input type="date" className="form-control" placeholder="dd-mm-yyyy" aria-label="Username" aria-describedby="basic-addon1" value={state.pickup_datetime_to} name="pickup_datetime_to" onChange={(e) => datePicker(e)} min={state.pickup_datetime_from} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="" className="form-label">DELIVERY DATE RANGE</label>
                                                <div className="d-*-flex ">
                                                    <div className="">
                                                        <div className="input-group input-group-lg mb-3">
                                                            <input type="date" className="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1" value={state.delivery_datetime_from} name="delivery_datetime_from" onChange={(e) => datePicker(e)} min={state.pickup_datetime_to} />
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="input-group input-group-lg mb-3">
                                                            <input type="date" className="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1" value={state.delivery_datetime_to} name="delivery_datetime_to" onChange={(e) => datePicker(e)} min={state.delivery_datetime_from} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <hr />
                                    <section>
                                        <h5 className="pt-4">  Service Type Options</h5>
                                        <div className="col-lg-12 col-12">
                                            <label htmlFor="" className="form-label">SERVICE TYPE</label>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="service_type" id="flexRadioDefault1" checked onChange={(e) => handleChange(e)} value="open" />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1" >
                                                    Open Transport ($) <img src="img/trans_2.png" className="service" />
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="service_type" id="flexRadioDefault2" onChange={(e) => handleChange(e)} value="enclosed" />
                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    Enclosed Transport ($$$) <img src="img/trans_1.png" className="service" />
                                                </label>
                                            </div>
                                        </div>
                                    </section>
                                    <hr />
                                    <section >
                                        <h5 className="pt-4 mb-3">Additional Details (Optional)</h5>

                                        <div>

                                            {selectedImages.uploadPhoto.map((image, index) => {
                                                return (
                                                    <>
                                                        <div style={{ width: "170px", marginRight: "7px" }} className="imageLogo" key={index}>
                                                            <div className="overlay">
                                                                <i class="fa fa-trash-o icon" onClick={() => removeImage3(image)}></i>
                                                            </div>

                                                            <img src={image} style={{ width: "100%", height: "180px" }} alt="upload" className="mt-3" />

                                                        </div>

                                                    </>);
                                            })}





                                            <label style={{ margin: 0, display: "inline-flex", flexdirection: "column", justifyContent: "center", border: "1px dotted black", borderRadius: "10px", width: "8rem", height: "8rem", cursor: "pointer", fontSize: "large" }}>
                                                <i className="fa fa-plus mx-3 text-muted" style={{ fontSize: "50px", marginTop: "35px" }} aria-hidden="true"></i>
                                                <input type="file" id="" placeholder="photos" name="uploadFiles" onChange={(e) => handleChange(e)} multiple="multiple" style={{ display: "none" }} />
                                            </label>
                                        </div>


                                    </section>

                                    <hr />

                                    <section>
                                        <h5 className="pt-4">SPECIAL INSTRUCTIONS</h5>
                                        <div className="col-lg-12 col-12">
                                            <textarea className="form-control" id="" rows="3" cols="10" placeholder="Use the alley, call before you arrive, fragile shipment..." name="instructions" onChange={(e) => handleChange(e)}></textarea>
                                            <label htmlFor="" className="form-label">Note: Do not include personal contact information in this section.</label>
                                        </div>
                                    </section>

                                    <hr />
                                    <section>
                                        <h5 className="pt-4">Reference Number (Optional)</h5>
                                        <label htmlFor="" className="form-label">You can add VIN, Invoice, Sales Order, Auction Lot, Release, Tracking or Other Numbers if needed</label>
                                        <div className="col-lg-12 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md">
                                                    <label htmlFor="email" className="form-label">Type</label>
                                                    <select className="form-select form-select-lg">
                                                        <option>Select</option>
                                                        <option>VIN</option>
                                                        <option>Invoice</option>
                                                        <option>Sales Order</option>
                                                        <option>Other</option>
                                                        <option>Lot</option>
                                                        <option>Release</option>
                                                        <option>Third Party Tracking</option>
                                                        <option>Order #</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="" className="form-label">number</label>
                                                    <input type="text" className="form-control" placeholder="123456" aria-label="Username" aria-describedby="basic-addon1" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <hr />
                                    {isLoading == true ? <ReactLoading type="bubbles" color="black" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} /> :
                                        <section className="text-center pt-3">
                                            <button type="button" className="btn btn-primary btn-lg" onClick={() => createShipment()}>Create Shipment</button>
                                        </section>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-3 d-none d-sm-block">
                            <section className="pb-5">
                                <h5>Communication is Key</h5>
                                <p className="text-muted">Alert your provider of pickup and delivery constraints like business hours or weekend delivery options as it can impact price and provider availability.</p>
                            </section>

                            <section className="pt-5">
                                <h5>Understand your Shipping Options</h5>
                                <p className="text-muted">Fewer service providers offer enclosed transport. Selecting Open Transport may increase the amount of available providers.</p>
                            </section>
                            <section className="pt-5">
                                <h5>Attract More Quotes</h5>
                                <p className="text-muted">Shipment listings with photos and details book 50% faster and ensure better pricing estimates from providers.</p>
                            </section>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AddShipmentStepTwo;
