import React,{useEffect,useState} from 'react'
import axios from "axios";
import {isMobile} from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import exportValue from "../apiconfig";
import { inputClasses } from '@mui/material';
import { useNavigate,useLocation,useParams, Link } from "react-router-dom";
import ReactLoading from 'react-loading';

import LazyLoad from 'react-lazyload';
import Image_uploader from './Image_uploader'
import { toast } from 'react-toastify';

const Index = () => {
  const[isLoadingstate,setisLoadingstate]= useState({isLoader:true});
  const[isLoadingstate2,setisLoadingstate2]= useState({isLoader:true});
  const[isLoadingstate3,setisLoadingstate3]= useState({isLoader:true});
  const[isLoadingstate4,setisLoadingstate4]= useState({isLoader:true});
  const[isLoadingstate5,setisLoadingstate5]= useState({isLoader:true});
  const[isLoadingstate6,setisLoadingstate6]= useState({isLoader:true});
  const[isLoadingstate7,setisLoadingstate7]= useState({isLoader:true});
  const[isLoadingstate8,setisLoadingstate8]= useState({isLoader:true});
  const[isLoadingstate9,setisLoadingstate9]= useState({isLoader:true});


  const [header, setHeader] = useState({title:"",description:"",url:"",isLoading:true})
  
  const [subheader, setSubHeader] = useState({title:"",description:"",isLoading:true})
  
  const [icon, setIcon] = useState({title:"",description:"" , isLoading:true})
  
  const [video, setVideo] = useState({title:"",description:"",url:"",isLoading:true})
  
  const [categories,setCategories] = useState({category_list: [],dstatus: 1,subcategoryList: [],isLoading:true})
  
  const [loginstate, setLoginState] = useState({isLoading:true});

  const [state, setState] = React.useState({
    web_body_list: [],
  isLoading:true  
  })
  const [teststate, setTestState] = React.useState({
    web_testimonial_list: [],
    isLoading:true
  })
  useEffect(()=>{
      getWebData();
      cat_subcat_detail()
      getSubHeadData()
      getIconData()
      getVideoData()
  },[])
  
// useEffect(()=>{
//   setTimeout(() => setisLoadingstate8({isLoader:false}), 5500)
// },[])

  const getWebData = () =>{
      let data = {};
      axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/web_detail_api`, data, { headers: exportValue.headers }).then((res) => {
         console.log("response =>    ",res);
         if(res.data.status == "success") {
          let getData = res.data.web_data[0];
          
         
          setHeader({...header,title:getData.values[0].title,description:getData.values[0].description,url:getData.values[0].url,isLoading:false})
          if(!res){
           setisLoadingstate({...isLoadingstate,isLoader:true})
           setisLoadingstate8({...isLoadingstate8,isLoader:true})           
          }
          else{
           
            setTimeout(() => setisLoadingstate({isLoader:false}), 1500)
            setTimeout(() => setisLoadingstate8({isLoader:false}), 5500)
  
          }
        }else  if(res.data.status == 401) {
          toast.configure();
          toast.error(res.data.message);
          localStorage.removeItem('loggedIn');
          window.location.href = '/';

        }
         
        }).catch((e) => {
          // setisLoadingstate({...isLoadingstate,isLoader:false})
          console.log("e =>    ",e);
          toast.configure();
          toast.error("Some thing went wrong");
         
         });
  }

  const getSubHeadData = () =>{
    let data = {};
    axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/web_detail_api`, data, { headers: exportValue.headers }).then((res) => {
       
       
        let getData = res.data.web_data[1];
        
      
        setSubHeader({...subheader,title:getData.values[0].title,description:getData.values[0].description,title_one:getData.values[1].title,description_one:getData.values[1].description ,title_two:getData.values[2].title,description_two:getData.values[2].description,isLoading:false})
       if(!res){
        setisLoadingstate3({...isLoadingstate2,isLoader:true})
       }
       else{
        setTimeout(() => setisLoadingstate3({isLoader:false}), 3000)
      }
    })
}

const getIconData = () =>{
  let data = {};
  axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/web_detail_api`, data, { headers: exportValue.headers }).then((res) => {
   
     
      let getData = res.data.web_data[2];
      
    
      setIcon({...icon,isLoading:false,title:getData.values[0].title,description:getData.values[0].description,title_one:getData.values[1].title,description_one:getData.values[1].description ,title_two:getData.values[2].title,description_two:getData.values[2].description ,title_three:getData.values[3].title,description_three:getData.values[3].description})
      if(!res){
        setisLoadingstate5({...isLoadingstate3,isLoader:true})
       }
       else{
       

        setTimeout(() => setisLoadingstate5({isLoader:false}), 4000)
      }
       
  })
}
const getVideoData = () =>{
  let data = {};
  axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/web_detail_api`, data, { headers: exportValue.headers }).then((res) => {
     
      let getData = res.data.web_data[3];
      
     
      setVideo({...video,isLoading:false,title:getData.values[0].title,description:getData.values[0].description,url:getData.values[0].url})
      if(!res){
        setisLoadingstate7({...isLoadingstate4,isLoader:true})
       }
       else{
       

        setTimeout(() => setisLoadingstate7({isLoader:false}), 5000)
      }
 
    })
}

  const cat_subcat_detail = () =>{
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/category/category_list`;
      let sendData = {
          category_lists: {},
          dstatus: 1,
          subcategoryList: {},
          limit: (isMobile) ? 2 : 4
        };

        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setCategories({ ...categories,isLoading:false, category_list: res.data.output, subcategoryList: res.data.output });
       
          if(!res){
            setisLoadingstate2({...isLoadingstate5,isLoader:true})
            setisLoadingstate8({...isLoadingstate8,isLoader:true})

           }
           else{
          

            setTimeout(() => setisLoadingstate2({isLoader:false}), 2000)
            setTimeout(() => setisLoadingstate8({isLoader:false}), 5500)

            }
        })
      
  }

  const axios_get_api = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/web_body_list`;
    let sendData = {
    web_body_list: {},
    dstatus:1
    };
    

    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setState({ ...state,web_body_list: res.data.output ,isLoading:false });
    
      if(!res){
        setisLoadingstate4({...isLoadingstate6,isLoader:true})
        setisLoadingstate8({...isLoadingstate8,isLoader:true})

       }
       else{
       

        setTimeout(() => setisLoadingstate4({isLoader:false}), 3500)
        setTimeout(() => setisLoadingstate8({isLoader:false}), 5500)

      }
    })
  }
  React.useEffect(() => {
    axios_get_api()
  }, [])

  const axios_api = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/web_testimonial_list`;
    let sendData = {
    web_testimonial_list: {},
    dstatus:1
    };
    

    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setTestState({ ...teststate,web_testimonial_list: res.data.output ,isLoading:false });
   
      if(!res){
        setisLoadingstate6({...isLoadingstate7,isLoader:true})
        setisLoadingstate8({...isLoadingstate8,isLoader:true})

       }
       else{
      

        setTimeout(() => setisLoadingstate6({isLoader:false}), 4500)
        setTimeout(() => setisLoadingstate8({isLoader:false}), 5500)

      }
    })
  }
  React.useEffect(() => {
    axios_api()
  }, [])


  let navigate = useNavigate();
  const logincheck = () => {
 
    if (localStorage.getItem('loggedIn')) {
      let userDetail = JSON.parse(localStorage.getItem('loggedIn'));
      setLoginState(userDetail);
    
    }}
  const logged = () => {
 

    if (localStorage.getItem('loggedIn')) {
      let userDetail = JSON.parse(localStorage.getItem('loggedIn'));
  
      navigate('/ship')
     
      
    } else {
      navigate('/login')
    }
  }
  useEffect(() => {
    logincheck()
    //dispatch(tagsListAction());
  }, []);
  
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
   function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }
  
    const { height, width } = useWindowDimensions();

  

  return (
    <div>
        <Helmet>
            <title>shippx-shipment solution</title>
            <meta name="description" content="good transport" />
            <meta name="twitter:card" content="summary_large_image" />        
            <meta name="twitter:site" content="@user" />        
            <meta name="twitter:creator" content="@user" />        
            <meta name="twitter:title" content="Pets - Products" />        
            <meta name="twitter:description" content="Best Products for your pet" />        <meta name="twitter:image" content="url_to_image"/>        
            <meta property="og:title" content="Pets - Products" />        
            <meta property="og:description" content="Best Products for your pet" />        <meta property="og:image" content="url_to_image"/>
            {/* <meta property="og:title" content="Title" /> */}
            {/* <meta property="og:description" content="details contnet"/> */}
            <meta property="og:url" content="https://shippx.fniix.com" />
            <meta property="og:site_name" content="shippx - Shipment" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
            <meta property="og:image" content="https://www.beebush.com/assets/images/beeBush-logo.png"/>
            <link rel="canonical" href=''/>
        </Helmet>
     
      <section>
      
     <Header />
        
       
     
{/* header content for desktop  */}
{isLoadingstate.isLoader == true ? <div><ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} />
</div>:

<section class="sub_header d-none d-sm-block">
  
<img src={`https://shippxcdn.fniix.com/web/web_home/${header.url}`} className="sub_header"></img>    
<div class="container">
  <div class="row">
    <div class="col-md-6 col-xs-12 head_box1"  style={{position:"absolute",top:"130px"}}>
      <h1 className='head'>{header.title}
        </h1>
        
        <h4 class="">{header.description}</h4>
        <div class="row">
          <div class="col-md-5 col-6"> {loginstate.user_type==1 ?<a  className="btn btn-lg btn-success" onClick={(e) => logged(e)} style={{cursor:"pointer"}} >Get a Free Estimate</a>:loginstate.user_type==2?"":<a  className="btn btn-lg btn-success" onClick={(e) => logged(e)} style={{cursor:"pointer"}} >Get a Free Estimate</a>}</div>
{(!loginstate.user_type)?
          <div class="col-md-4 col-6"><Link to="/registration">{loginstate.user_type==2 ?"":<a class="btn btn-lg btn-outline-success">Become a Carrier</a>}</Link></div>:""}

        </div>
    </div>
    <div class="col-6"></div>
  </div> 
</div>

</section> 
}
{/* header content for mobile  */}
{isLoadingstate.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :

<section class="sub_header d-block d-sm-none">
{/* <img src={`https://shippxcdn.fniix.com/web/web_home/${header.url}`} className="sub_header"></img>     */}
<div class="container">
  <div class="row">
    <div class="col-md-6 col-xs-12 head_box1 "  >
      <h1 className='head' style={{fontSize:"42px"}}>{header.title}
        </h1>
        
        <h4 class="" >{header.description}</h4>
        <div class="row">
          <div class="col-md-5 col-6"> {loginstate.user_type==1 ?<a  className="btn btn-lg btn-success" onClick={(e) => logged(e)} style={{cursor:"pointer"}} >Get a Free Estimate</a>:loginstate.user_type==2?"":<a  className="btn btn-lg btn-success" onClick={(e) => logged(e)} style={{cursor:"pointer"}} >Get a Free Estimate</a>}</div>
          <div class="col-md-4 col-6"><Link to="/registration">{loginstate.user_type==2 ?"":<a class="btn btn-lg btn-outline-success">Become a Carrier</a>}</Link></div>
        </div>
    </div>
    <div class="col-6"></div>
  </div> 
</div>
</section> 
}

{isLoadingstate2.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :

<section class="categories_box mt-4">
  <div class="container ">
    <div class="row">
      <div class="col">
        <div class={ width<=768?"":"d-flex flex-row justify-content-between"}>
        <h3 className={width<=768?"text-center mb-2":"d-block d-none"} style={{color: "#262626",
    
    letterSpacing: ".09em",fontSize:"30px"}}>Popular Categories</h3>
        
        { categories.category_list.map((subscriber) => (
         
          <div class="p-2 bd-highlight  col-md-3 col-12">
           
            <a href="">
              <div class="text-center">
              <img src={`https://shippxcdn.fniix.com/web/${subscriber.category_img}`} className="img-fluid" />
              <Link to={`/shipment-list?cId=%5B%5D`}> <h5 className="">{subscriber.category_name}</h5></Link>

                                        {(subscriber.subcategorydetail != undefined && subscriber.subcategorydetail.length > 0) ? (subscriber.subcategorydetail.map((sub)=>{
                                        return(
                                            <p className="d-inline-flex p-1">{sub.subcategory_name}</p>
                                           )
                                        }
                                        )) : "" } 
                                    </div>
                                    </a>
                                    </div>
                                ))} 
         
            
        
        </div>
      </div>
    </div>
  </div>
</section>
}


           

{isLoadingstate3.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :

<section class="stats_box p-5">

  <div class="container ">
    <div class="row">
      <div class={width<=768?"col-12":"col-md"}>
        <div class="d-flex justify-content-around">
 
          <div class="p-2"><span class="st1">{subheader.title}</span>
            <h5 class="st2">{subheader.description}</h5></div>

          <div class="p-2"><span class="st1">{subheader.title_one}</span>
            <h5 class="st2">{subheader.description_one}</h5></div>

          <div class="p-2"><span class="st1">{subheader.title_two}</span>
            <h5 class="st2">{subheader.description_two}</h5></div>
            

        </div>
      </div>
    </div>
  </div>
</section>
}    





{isLoadingstate4.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :



 state.web_body_list.map((subscriber) => (
  <section class="home_box_1 p-4">
  <div class="container ">
    {subscriber.pic!="right"?
    <div class="row">
      
      <div class="col-12 col-md-6 order-5 order-md-1 p-5" >
      <div class="p-5 home_shipping_us home_shipping_usq">
          <div class="home_shipping_right" >
            <h5 class="">{subscriber.body_heading}</h5>
          <h1 class="">{subscriber.body_sub_heading}</h1>
          <p class=""> {subscriber.body_content}</p>
          <a class="" href=""><span><span class="">{subscriber.body_link_title}</span> <i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
          </div>
          
        </div>
       
      </div>
      <div class="col-12 col-md-6 order-1 order-md-5 p-5">

      <div class=""><img src={`https://shippxcdn.fniix.com/web/${subscriber.body_img}`}  class="img-fluid" style={{width:"100%"}} />
        </div>
        
      </div>


    </div>
    : <div class="row">
      
    <div class="col-12 col-md-6 p-5">
      <div class=""><img src={`https://shippxcdn.fniix.com/web/${subscriber.body_img}`}  class="img-fluid" style={{width:"100%"}} />
      </div>
    </div>
    <div class="col-12 col-md-6 p-5">


      <div class="p-5 home_shipping_us home_shipping_usq">
        <div class="home_shipping_right" >
          <h5 class="">{subscriber.body_heading}</h5>
        <h1 class="">{subscriber.body_sub_heading}</h1>
        <p class=""> {subscriber.body_content}</p>
        <a class="" href=""><span><span class="">{subscriber.body_link_title}</span> <i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
        </div>
        
      </div>
    </div>


  </div>}
  </div>
  </section>
))}



{/* icons for mobile */}
{isLoadingstate5.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :

<section class="stats_box p-5 d-block d-sm-none">
  <div class="container ">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-around">
          <div class="p-2"><a href=""><span class="st4"><i class={icon.title} aria-hidden="true"></i></span><h5 class="st5">{icon.description}</h5></a></div>
          <div class="p-2"><a href=""><span class="st4"><i class={icon.title_one} aria-hidden="true"></i></span><h5 class="st5">{icon.description_one}</h5></a></div>
          <div class="p-2"><a href=""><span class="st4"><i class={icon.title_two} aria-hidden="true"></i></span><h5 class="st5">{icon.description_two}</h5></a></div>
          <div class="p-2"><a href=""><span class="st4"><i class={icon.title_three} aria-hidden="true"></i></span><h5 class="st5">{icon.description_three}</h5></a></div>
        </div>
      </div>
    </div>
  </div>
</section>
}

{/* icons for desktop */}
{isLoadingstate5.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :

<section class="stats_box p-5 d-none d-sm-block">
  <div class="container ">
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-around">
          <div class="p-2"><a href=""><span class="st1"><i class={icon.title} aria-hidden="true"></i></span><h5 class="st3">{icon.description}</h5></a></div>
          <div class="p-2"><a href=""><span class="st1"><i class={icon.title_one} aria-hidden="true"></i></span><h5 class="st3">{icon.description_one}</h5></a></div>
          <div class="p-2"><a href=""><span class="st1"><i class={icon.title_two} aria-hidden="true"></i></span><h5 class="st3">{icon.description_two}</h5></a></div>
          <div class="p-2"><a href=""><span class="st1"><i class={icon.title_three} aria-hidden="true"></i></span><h5 class="st3">{icon.description_three}</h5></a></div>
        </div>
      </div>
    </div>
  </div>
</section>
}

{isLoadingstate6.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :

<section class="pt-4 mb-4 ">
  <div class="container testimonials">
    <div class="row">
      <h3 class="pt-5 pb-3">Testimonials</h3>
     
       {teststate.web_testimonial_list.map((subscriber) => (
      <div class="col-12 col-md-4 text-center">
       
        <div class="card shadow-lg border-0">
          <div class="card-body p-5">
            <p>{subscriber.testimonial_description} </p>
            <img src={`https://shippxcdn.fniix.com/web/${subscriber.testimonial_img}`} class="rounded-circle img-fluid"  />
            <h6 class="p-2 text-muted">{subscriber.testimonial_title} </h6>
          </div>
        </div>
      </div>
      
      ))}
    </div>
</div> 
      </section>
}





{isLoadingstate7.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :

<section class="stats_box  p-5 text-center ">
  <div class="container ">
    <div class="row">
      <div class="col">
        <h3>{video.title}</h3>
        <p>{video.description}</p>
        <section class="text-center">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-12 col-md-6 p-5">
              <div class="ratio ratio-16x9  ">
              <iframe src={`https://www.youtube.com/embed/${video.url}?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0`}  width="560" height="315"  frameborder="0" allowfullscreen></iframe>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
}


{isLoadingstate8.isLoader == true ? <ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%',marginTop:"50px"}} /> :

 <Footer/>
}
 </section>
 

    </div>
  )
}

export default Index;