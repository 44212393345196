import axios from 'axios';
import { toast } from 'react-toastify';
//import exportValue from '../../apiconfig';
import exportValue from '../../../apiconfig';
import { SHIPMENT_DETAIL,CATEGORY_ATTRIBUTE_DETAIL,ATTRIBUTE_ADD_SHIPMENT_DETAIL,LOCATION_ADD_SHIPMENT_DETAIL } from "../constants/types";

export const ShipmentLists = (data) =>(dispatch) => {

    const shipmentAllData = {name:"vikram singh shekhawat"}
        dispatch({
            type: SHIPMENT_DETAIL,
            payload: shipmentAllData
        })

    
}

   
export const AttributeLists = (data) =>(dispatch) => {
   
          let attribute_data  = [];
          if(data){
            attribute_data = {category_id:data.category_id,subcategory_id:data.subcategory_id}
          } 

        //  console.log("data is v ",attribute_data);
      // const attribute_data = {category_id:data.category_id,subcategory_id:data.subcategory_id}
       //console.log(" attribute_data is rr ",attribute_data);
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/category/category_attribute_list`, attribute_data, { headers: exportValue.headers }).then((res) => {
         //   console.log(" res is rr ",res);
            const catAttributData = { cat_attribute_detail:res.data.output }
           // console.log(" catAttributData is rr ",catAttributData);
            dispatch({
                type: CATEGORY_ATTRIBUTE_DETAIL,
                payload: catAttributData
            })
            
        })
}

export const AttributeEditDetail = (data) => (dispatch) => {
    console.log("data is ",data );
    if(!data){
        data  = [];
    }
    if (data.length<0){
         toast.configure()
           toast.error("Insert all the values")
    }
    console.log("remove is",data);
    dispatch({
        type: ATTRIBUTE_ADD_SHIPMENT_DETAIL,
        payload: data
    })
}

export const LocationEditDetail = (data) => (dispatch) => {
  
    if(!data){
        data = ""
    }
        console.log("remove is one",data);
    dispatch({
        type: LOCATION_ADD_SHIPMENT_DETAIL,
        payload: data
    })
}