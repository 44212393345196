import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';

import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Footer from "./Footer";
import Header from "./Header";
import Map from "./components/Map";
import Mapsecond from "./components/Mapsecond";
import Image_uploader from './Image_uploader';


//import { form } from 'react-router-dom';

const Payments = () => {
    let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log("userData  -  ",userdata);
    //navigate('/home') 
  }

   const[state,setState]=React.useState({customer_Payments:[],transporter_payments:[],transporter_detail:[],isLoading:true}) 
   const[dimensionState,setdimensionState] = useState({companyDetail:[]})

   const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/get_company_info_api`;
    let sendData = {};
  
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  React.useEffect(() => {
    dimension_detail();
    paymentDetail()
   
   
  }, [])
    const paymentDetail = () => {

        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/payments`;
        
     let senddata={
        user_id:userdata.user_id
     }
        Axios.post(full_api, senddata, { headers: exportValue.headers }).then((res) => {
          console.log("res -> ", res);
    
        
            setState({ ...state, customer_Payments:res.data.customer_Payments,transporter_payments:res.data.transporter_Payments, transporter_detail:res.data.transporter_detail,isLoading: false })
          
           
              
             
        }).catch((e) => {
          
          toast.configure();
          toast.error("Some thing went wrong");
          console.log("----error:   ", e);
        });
      };
      useEffect(() => {
       
       
        paymentDetail();
      }, [])

      function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
      
       function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
      }
      
        const { height, width } = useWindowDimensions();

    return (
        <>

            <Header />

            <section className="sub_heading p-5 text-black" style={width<=768?{marginLeft:"-52px",marginRight:"-52px"}:{marginLeft:"0px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h5>Payments</h5>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-light p-5">
                <div className="container">
                    <div className="row" style={width<=768?{marginLeft:"-40px",marginRight:"-40px"}:{marginLeft:'10px'}}>
                        <div className="col-12 col-md-3"></div>
                        <div className="col-12 col-md-6" >
                            <div className="card">
                                <div className="card-body table-wrapper table-responsive">
                                    {userdata.user_type==1?"":state.transporter_detail.map((sub)=>(<h6 className="text-uppercase">Account Balanace: {dimensionState.companyDetail.map((sub)=>(sub.set_currency))}{sub.transporter_wallet!=undefined && sub.transporter_wallet!=""?sub.transporter_wallet:0}   <span><small className='mx-3 text-uppercase'>Hold Amount:{dimensionState.companyDetail.map((sub)=>(sub.set_currency))} {sub.hold_amount!=undefined && sub.hold_amount!=""?sub.hold_amount:0}</small></span> </h6>))}
                                    <hr />

                                    <table className="table  table-hover">
                                        <thead>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <th>Shipment ID</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                      
                                        
                                        <tbody>
                                            {userdata.user_type==1?
                                            state.customer_Payments.length>0?
                                            state.customer_Payments.map((sub)=>(
                                            <tr>
                                                <td>{sub.invoice_id}</td>
                                                <td><a href={`/shipment/`+sub.shipment_id}>{sub.shipment_id}</a></td>
                                                <td>${sub.paid_amount}</td>
                                            </tr>)):"No Payments":
                                            state.transporter_payments.length>0?
                                            state.transporter_payments.map((item)=>(
                                                <tr>
                                                <td>{item.invoice_id}</td>
                                                <td><a href={`/shipment/`+item.shipment_id}>{item.shipment_id}</a></td>
                                                <td>{dimensionState.companyDetail.map((sub)=>(sub.set_currency))}{item.paid_amount}</td>
                                            </tr> 
                                            )):"No Payments"
                                            }
                                           
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3"></div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Payments;
