import React from "react";
import { Link } from 'react-router-dom';

import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportValue from '../apiconfig';
//import { BulletList } from "react-content-loader";
import ReactLoading from 'react-loading';



import Header from './Header';
import Footer from './Footer';

const New_shipment = (data, category_id) => {

  const [state, setState] = React.useState({
    category_list: [],
    dstatus: 1,
    subcategoryList: [],
    isLoading:true
  })


  console.log("pp", state)
  //console.log("state is user list is ", state);


  const axios_get_api = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/category/category_list`;
    let sendData = {
      category_lists: {},
      dstatus: 1,
      subcategoryList: {},
      
    };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setState({ ...state, category_list: res.data.output, subcategoryList: res.data.output ,isLoading:false});
      console.log("response", res);
    }).catch((e) => {

      toast.configure()
      toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }



  React.useEffect(() => {
    axios_get_api()
  }, [])





  return (
    <>
     <Header />
     <section className="sub_heading p-5 text-black">
  <div className="container ">
    <div className="row">
      <div className="col ">
        <h5>NEW SHIPMENT</h5>
      </div>
    </div>
  </div>
</section>


<section className=" bg-light p-5 ">
  <div className="container ">
        <h6 className="text-uppercase">Categories</h6>
        
    <div className="row">
   {state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'5px',width:'10%'}} />:
      
      state.category_list
       
      .map((subscriber, index) => (
                             
      <div className="col-12 col-md-3">
      <Link to={`/subcategory/${subscriber.category_id}`} className="text-decoration-none"> 
       <a  className="ship_item_hov text-decoration-none">  
      <div className="text-center p-5 ship_item_box">
        <img src={`https://shippxcdn.fniix.com/web/${subscriber.category_img}`}  className="img-fluid "  />
                <h5 className="">{subscriber.category_name}  </h5>

                 {(subscriber.subcategorydetail != undefined && subscriber.subcategorydetail.length > 0) ? (subscriber.subcategorydetail.map((sub)=>{
                   return(
                     <p className=" mb-2   ">{sub.subcategory_name}</p>
                   )
                 }
        )) : "" } 
               
    </div>
   </a>
        </Link>
      </div> 
      ))}
                
                
   </div> 
                                    
                
    
  </div>
                
</section>
    <Footer />  
    </>
  )
}


export default New_shipment;
