import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import { Provider } from 'react-redux'
import './App.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { requestForToken, onMessageListener } from './firebase';
//import { Store } from './redux/Store'
import { Store } from '../src/views/Redux/Store'
// import 'bootstrap/dist/css/bootstrap.min.css';
 //import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
 import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import '../node_modules/font-awesome/css/font-awesome.min.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Helmet,HelmetProvider } from 'react-helmet-async';
import Home from './views/Home';
import Shipment from './views/Shipment';
import Categories from './views/Categories';

import Addshipment from './views/Addshipment';

import ShipmentDetail from './views/Shipment_detail';

import Login from './views/Login';
import Registration from './views/Regitsration';
import New_shipment from './views/New_shipment';
import Subcategory from './views/Subcategory';

import Footer from './views/Footer';
import Header from './views/Header';
import Settings from './views/Settings';
import Payments from './views/Payments';
import Inbox from './views/Inbox';
import ProfileCustomer from './views/ProfileCustomer';
import ProfileCarrier from './views/ProfileCarrier';
import Myaccount_customer from './views/Myaccount_customer';
import AddShipmentStepTwo from './views/AddShipmentStepTwo';
import Shipment_detail_user from './views/Shipment_detail_user';
import Driver_list from './views/Driver_list';
import Add_driver from './views/Add_driver';
import Add_vehicle from './views/Add_vehicle';

import Vehicle_list from './views/Vehicle_list';
import Edit_driver from './views/Edit_driver';
import Edit_vehicle from './views/Edit_vehicle';
import Myaccount_carrier from './views/Myaccount_carrier';
import Quote_list_carrier from './views/Quote_list_carrier';
import Tracking from './views/Tracking';
import SamplePage from './views/SamplePage';
import Verify from './views/Verify';
import Payment from './views/Payment';
import Forgot_otp_verify from './views/Forgot_otp_verify';
import Ship_detail from './views/Ship_detail';
import Index from './views/Index';
import Profile_update_carrier from './views/Profile_update_carrier';
import Profile_carrier from './views/Profile_carrier';
import Carrier_terms from './views/Carrier_terms';
// import Image_uploader from './views/Image_uploader';
import Blog from './views/Blog';
import Careers from './views/Careers';
import Company from './views/Company';
import Cookie_policy from './views/Cookie_policy';
import Help from './views/Help';
import Press from './views/Press';
import Privacy_policy from './views/Privacy_policy';
import Site_map from './views/Site_map';
import User_agreement from './views/User_agreement';
import Why_us from './views/Why_us';
import How_does_work from './views/How_does_work';
import For_carriers from './views/For_carriers';
import For_business from './views/For_business';
import Send_anything from './views/Send_anything';

import ChatHistory from './views/Chat_history';
import Chat_box from './views/Chat_box';
import NotificationComponent from './views/NotificationComponent';
import Tickets from './views/Tickets';
import Tickets_details from './views/Tickets_details';
import Create_ticket from './views/Create_ticket';
import MyAccount from './views/MyAccount';
import Shipment_tracking from './views/Shipment_tracking';




function App() {
  const [notification, setNotification] = useState({ title: "", body: "" })
  useEffect(() => {
   // console.log("1=> => => ");

    if (notification.title != "") {
    //  console.log("2=> => => ");
      // toast.success("Submitted succesfully");
    }
   // console.log("app.js");
  }, [notification]);

  if (!localStorage.getItem('shippx_firebaseToken')) {
    // requestForToken().then((data) => {
    //  // console.log("fire token =>> ", data);
    //   if (data.status && data.token != "") {
    //     localStorage.setItem('shippx_firebaseToken', data.token);
    //   }
    // });
  }else {
   // console.log("already token store =>> ");
  }

  // onMessageListener().then((payload) => {
  //  // console.log("app.js payload ", payload.notification);

  //   setNotification({ title: payload.notification.title, body: payload.notification.body })

  // }).catch((err) => {
  //   console.log("app.js Error: message listner ");
  //   //toast.error(JSON.stringify(err));
  // });

  return (
    <>
      {(notification.body != "") ?
        <NotificationComponent noti={notification} /> : ""}
      <CookiesProvider>
        <Provider store={Store}>
          <Router>
            <Routes>
              <Route exact path='/' element={<Index />} />
              <Route exact path='/chat-box/:other_id' element={<Chat_box />} />
              <Route exact path='/chat-box' element={<Chat_box />} />

              <Route exact path='/shipment-list' element={<Shipment />} />
              <Route exact path='/categories-list' element={<Categories />} />

              <Route exact path='/addshipment/:category_id/:subcategory_id' element={<Addshipment />} />
              <Route exact path='/ship' element={<New_shipment />} />
              <Route exact path='/subcategory/:category_id' element={<Subcategory />} />
              <Route exact path='/driver_list' element={<Driver_list />} />
              <Route exact path='/add_driver' element={<Add_driver />} />
              <Route exact path='/edit_driver/:driver_id' element={<Edit_driver />} />
              <Route exact path='/edit_vehicle/:vehicle_id' element={<Edit_vehicle />} />
              <Route exact path='/verify' element={<Verify />} />
              <Route exact path='/forgot_otp_verify' element={<Forgot_otp_verify />} />

              <Route exact path='/vehicle_list' element={<Vehicle_list />} />
              <Route exact path='/add_vehicle' element={<Add_vehicle />} />

              <Route exact path='/payment/:invoice_id' element={<Payment />} />
              <Route exact path='/index' element={<Index />} />
              <Route exact path='/update_carrier/:transporter_id' element={<Profile_update_carrier />} />
              <Route exact path='/profile_update_carrier' element={<Profile_update_carrier />} />
              <Route exact path='/carrier/:transporter_id' element={<Profile_carrier />} />
              <Route exact path='/carrier_terms/:transporter_id' element={<Carrier_terms />} />

              <Route exact path='/user_agreement' element={<User_agreement />} />
              <Route exact path='/site_map' element={<Site_map />} />
              <Route exact path='/privacy_policy' element={<Privacy_policy />} />
              <Route exact path='/press' element={<Press />} />
              <Route exact path='/help' element={<Help />} />
              <Route exact path='/cookie_policy' element={<Cookie_policy />} />
              <Route exact path='/company' element={<Company />} />
              <Route exact path='/careers' element={<Careers />} />
              <Route exact path='/blog' element={<Blog />} />
              <Route exact path='/why_us' element={<Why_us />} />
              <Route exact path='/how_does_it_work' element={<How_does_work />} />
              <Route exact path='/for_carriers' element={<For_carriers />} />

              <Route exact path='/for_business' element={<For_business />} />
              <Route exact path='/send_anything' element={<Send_anything />} />
              <Route exact path='/create_ticket' element={<Create_ticket />} />
              <Route exact path='/tickets' element={<Tickets />} />
              <Route exact path='/ticket_details/:ticket_id' element={<Tickets_details />} />













              <Route exact path='/tracking/:invoice_id' element={<Tracking />} />

              <Route exact path='/login' element={<Login />} />
              <Route exact path='/login/:temp_id' element={<Login />} />
              <Route exact path='/registration' element={<Registration />} />
              <Route exact path='/registration/:temp_id' element={<Registration />} />
              <Route exact path='/shipment-detail/:invoice_id' element={<ShipmentDetail />} />
              <Route exact path='/shipment/:invoice_id' element={<Ship_detail />} />


              <Route exact path='/header' element={<Header />} />
              <Route exact path='/footer' element={<Footer />} />

              <Route exact path='/settings' element={<Settings />} />
              <Route exact path='/payments' element={<Payments />} />
              <Route exact path='/inbox' element={<Inbox />} />
              <Route exact path='/myaccount_customer' element={<Myaccount_customer />} />
              <Route exact path='/myaccount_carrier' element={<Myaccount_carrier />} />
              <Route exact path='/quote_list_carrier' element={<Quote_list_carrier />} />
              <Route exact path='/sample_page' element={<SamplePage />} />



              <Route exact path='/profile/:user_id' element={<ProfileCustomer />} />
              <Route exact path='/profile-carrier/:transporter_id' element={<ProfileCarrier />} />

              <Route exact path='/add_shipment_step_two' element={<AddShipmentStepTwo />} />
              <Route exact path='/shipment-detail-user/:invoice_id' element={<Shipment_detail_user />} />
              {/* <Route exact path='/image_uploader' element={<Image_uploader/>} /> */}
              <Route exact path='/chat-history/:other_id' element={<ChatHistory />} />
              <Route exact path='/my_account' element={<MyAccount />} />
              <Route exact path='/shipment-tracking' element={<Shipment_tracking />} />
              



            </Routes>
          </Router>
        </Provider>
      </CookiesProvider>
    </>
  );
}

export default App;
