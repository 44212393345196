import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import Footer from "./Footer";
import MenuCustomer from './MenuCustomer';
import Header from "./Header";
import Axios from "axios";
import ReactLoading from 'react-loading';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Moment from 'react-moment';
import { Typography } from "@material-ui/core";
// import 'bootstrap/dist/css/bootstrap.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactStarsRating from 'react-awesome-stars-rating';

const Myaccount_customer = () => {

  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log("userData  -  ",userdata);
    exportValue.headers.usertuid = userdata.user_id;   
    //navigate('/home') 
  }
  //console.log("Myaccount_customer");

  const [state, setState] = useState({ shipmentList: [], searchValue: undefined, dialogOpen: false, isLoading: true });
  const [shipmentstate, setshipStatus] = useState({ shipment_status: null });
  console.log("shipmentstate",shipmentstate)
  // const [reviewstate, setReviewState] = useState({invoice_id:"",transporter_id:"",review_description:"",rating:0,user_id:""});
  const [likestate, setLikeState] = useState({isLiked:0,invoice_id:"",transporter_id:''});
  const[notlikestate,setnotLikeState]=useState({transporter_id:"",notLiked:0,invoice_id:""})
  const[dimensionState,setdimensionState] = useState({companyDetail:[]})

  useEffect(() => {
    userShipmentList(null)
  }, [])

  const userShipmentList = (status) => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/customer_shipments`;
    let sendData = { status: status };
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("response",res);
      if(res.data.output) {      
      setState({ ...state, dialogOpen: false, shipmentList: res.data.output, isLoading: false })
      }else {
        setState({ ...state, dialogOpen: false, isLoading: false })
        toast.configure();
        toast.error(res.data.message);
      }
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };

  function deleteship(invoice_id) {
    let userdata = { invoice_id: invoice_id }
    Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/shipment_delete`, userdata, { headers: exportValue.headers }).then((res) => {
      console.log("re", res)
      setState({ ...state, invoice_id: invoice_id });
      userShipmentList(null);
      toast.configure()
      toast.success("Deleted Successfully")
    }).catch((e) => {

      toast.configure()
      toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }


  const shipmentFilter = (status) => {
    console.log("action ", status);
    setshipStatus({ ...shipmentstate, shipment_status: status })
    userShipmentList(status)
  }

  function searchtitle() {
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/shipment_find_detail`;

    let shipment_data = { offset: 0, limit: 50,status:shipmentstate.shipment_status };
    console.log("pv", state.title)
    if (state.searchValue) {
      shipment_data.title = state.title;
    }

    Axios.post(full_api, shipment_data, { headers: exportValue.headers }).then(res => {
      console.log("response", res);
      if(res.data.output) { 
      setState({ ...state, dialogOpen: false, shipmentList: res.data.output })
      }else {
        setState({ ...state, dialogOpen: false})
      }

    }).catch((e) => {

      console.log("----error:   ", e);
    })
  }

  const stateHandler = (key, value) => {
    state[key] = value
    setState({ ...state, title: state.searchValue })
  }
  const [modals, setModals] = useState({ show: false });
  const shipment_stat = (invoice_id, dstatus) => {
    setModals({ ...modals, show: true })
   
      
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/shipment_status`;
    // setState({ ...state, org_id: org_id, verified_change:verified })
    let sendData = {
      invoice_id: invoice_id,
      dstatus: dstatus,

    };



    console.log("status", sendData)

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      console.log("mm", res);
      setState({ ...state, invoice_id: invoice_id, dstatus: dstatus })

      userShipmentList();
      //state.isLoading = false;
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure()
      toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  
  }

  
  const handleClose = (condition) => {
    console.log("handleClose  ",condition);
    
    if(condition) {
      shipment_stat(modals.invoice_id, modals.dstatus)
    }
    setModals({ show: false })
  }
  const handleOpen = (invoice_id, dstatus) => {
    
    setModals({show: true,invoice_id:invoice_id,dstatus:dstatus })
    
  }

//   const handleSubmit = (invoice_id,transporter_id) => {
    
//     console.log("submit1");
//     //state.isLoading = true
//     let state_detailes = {review_description:reviewstate.review_description,transporter_id:transporter_id,invoice_id:invoice_id, user_id:"",rating:reviewstate.rating}
//     if(userdata.user_id) {
//       state_detailes.user_id = userdata.user_id;
//     }
    
//     console.log("state detailes is ",state_detailes);
    
//     Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/customer_review_create`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
//             console.log("result is i ",result);
//            // state.isLoading = false
//             if(result && result.data != undefined){
               
//                     toast.configure()
//                     toast(result.data.message) 
//                     userShipmentList()
//                 }
 
//         })
// }

// const handleLiked = (transporter_id,invoice_id) => {
    
//   console.log("submitlike");
//   //state.isLoading = true
//   let state_detailes = {transporter_id:transporter_id,invoice_id:invoice_id}
  
  
//   console.log("state liked",state_detailes);
  
//   Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/liked_create`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
          
//          // state.isLoading = false
//           if(result && result.data != undefined){
//             console.log("result is i ",result.data);
//                   toast.configure()
//                   toast(result.data.message) 
//                   userShipmentList()
//                   //setLikeState({...likestate, isLiked:likestate.isLiked+1})
                
//               }

//       })
// }


// const handlenotLiked = (transporter_id,invoice_id) => {
    
//   console.log("submitlike");
//   //state.isLoading = true
//   let state_detailes = {transporter_id:transporter_id,invoice_id:invoice_id}
  
  
//   console.log("state liked",state_detailes);
  
//   Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/notliked_create`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
//           console.log("result is i ",result);
//          // state.isLoading = false
//           if(result && result.data != undefined){
             
//                   toast.configure()
//                   toast(result.data.message) 
//                   userShipmentList()
                  
                
//               }

//       })
// }

// const handleChange4 = (event) => {
//   console.log(event);  
//   setReviewState({
//       ...reviewstate,
//       [event.target.name]: event.target.value,
//     });
  
// }

// const onChange = (value) => {
//   setReviewState({
//     ...reviewstate,rating:value
//   })
//   console.log(`React Stars Rating value is ${value}`);
// };

const dimension_detail = () =>{
  let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/get_company_info_api`;
  let sendData = {};

  Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
    setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
    console.log("rnmmmmm", res);
  }).catch((e) => {
    // toast.configure()
     //toast.error("Some thing went wrong")
    console.log("----error:   ", e);
  })
}
React.useEffect(() => {
  dimension_detail();
}, [])



// const {rating}=reviewstate
  return (<>
    <Header />
         
      
    <Modal show={modals.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Box</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you really want to do it ?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>handleClose(false)}>
          No
        </Button>
        <Button variant="primary" onClick={()=>handleClose(true)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>

    <section className="pt-1 pb-3 bg-light ">
      <div className="container">
        <section>
          <div className="row">
            <div className="col-12 col-md-8">
              <ul className="nav nav-pills pt-2 pb-3">
                <li className="nav-item">
                  <a className={(shipmentstate.shipment_status == null) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(null)}>All</a>
                </li>
                <li className="nav-item">
                  <a className={(shipmentstate.shipment_status == 0) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(0)}>Pending</a>
                </li>
                
                <li className="nav-item">
                  <a className={(shipmentstate.shipment_status == 1) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(1)}>Assigned</a>
                </li>
                <li className="nav-item">
                  <a className={(shipmentstate.shipment_status == 2) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(2)}>Completed</a>
                </li>
                <li className="nav-item">
                  <a className={(shipmentstate.shipment_status == 3) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(3)}>Cancelled</a>
                </li>
                {/* <li className="nav-item">
                  <a className={(shipmentstate.shipment_status == 4) ? 'nav-link active' :'nav-link' }  onClick={()=>shipmentFilter(4)}>Saved</a>
                </li> */}
              </ul>
            </div>

         
            <div className="col-10  col-md-3 mt-1 ">
              <div className="form-floating">
                <input type="text" className="form-control " id="Search" placeholder="Enter Search" name="Search" value={state.searchValue} onChange={({ target }) => {
                  stateHandler('searchValue', target.value)

                }} />
               <label htmlFor="email">Search shipment</label>
              </div>
            </div>
            <div className="col-2 col-md-1 mt-3 " >
              <i onClick={(e) => searchtitle(state.searchValue)} className="fa-solid fa-magnifying-glass fa-2x">  </i>

            </div>

              
          </div>
        </section>
        <hr />
        {state.isLoading == true ? <ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} /> :
          state.shipmentList.length > 0 ?
            <section className="shipment_list_table mb-3">
       
              <div className="row shipment_list_table_heading mb-2">
                <div className="col-4 col-md-2 ">PICKUP</div>
                <div className="col-4 col-md-2 ">DELIVERY</div>
                <div className="col-3 col-md-3  d-none d-sm-block ">GOODS</div>

                <div className="col-4 col-md-1 ">Shipment Id. #</div>

               
                <div className="col-4 col-md-2   d-none d-sm-block">INFO</div>
                <div className="col-1 col-md-1  d-none d-sm-block">Status</div>
                <div className="col-1 col-md-1  d-none d-sm-block">MORE</div>
              </div>
                 
                
              


              {state.shipmentList.map((subscriber, index) => (

                // for desktop 
               
                <div className="card" key={index}>
                  <div className="card-body">
                  <Link  to={"/shipment/" + subscriber.invoice_id}>
                    <div className="row">
                      <div className="col-12 col-md-2 ">
                        <h5 ><i className="fa fa-map-marker text-muted " aria-hidden="true"></i>
                         <a >{subscriber.pickup_address}</a>
                        </h5>
                        <small className="text-dark"><Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_to}</Moment></small>
                      </div>
                      <div className="col-12 col-md-2 ">
                        <h5><i className="fa fa-map-marker text-muted " aria-hidden="true"></i>
                          <Link to={{ pathname: "/shipment/" + subscriber.invoice_id, state: { nationality: "Icelandic" } }} > <a >{subscriber.delivery_address}</a></Link>
                        </h5>
                        <small className="text-dark" ><Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_to}</Moment> </small>
                      </div>
                      <div className="col-12 col-md-3 text-dark"><p>{subscriber.title}</p></div>
                      <div className="col-12 col-md-1 text-dark ">
                        <p>{subscriber.invoice_id}</p>
                      </div>
                      <div className="col-12 col-md-2 text-dark"><p><a href="">{subscriber.total_quotes + "(Quote)"}</a> {dimensionState.companyDetail.map((sub)=>(<small>{sub.set_currency}{subscriber.lowest_quote} low</small>))}</p>
                      </div>
                      <div className="col-12 col-md-1 ">
                        {(subscriber.status < 1 )?
                        <span className={subscriber.dstatus == 1 ?"badge rounded-pill bg-success text-light ":"badge rounded-pill bg-danger text-light"}>{(subscriber.dstatus == 1) ? "Active" : "Inactive"}</span> :""}
                        {/* {(subscriber.status > 0 )?
                        <span className="badge rounded-pill bg-warning text-light ">{(subscriber.current_shipment_status != 5 && subscriber.status == 0) ? "Pending" : (subscriber.current_shipment_status != 5 && subscriber.status == 1 )?"Assigned":(subscriber.current_shipment_status != 5 && subscriber.status == 2)? "Completed":(subscriber.current_shipment_status != 5 && subscriber.status == 3)?"Cancelled":""}</span>                        :""} */}
                        <span className="badge rounded-pill bg-primary text-light ">{(subscriber.current_shipment_status == 1) ? "Booked" : (subscriber.current_shipment_status == 2) ? "Pickup In Progress":(subscriber.current_shipment_status == 3) ? "Picked Up":(subscriber.current_shipment_status == 4) ? "In Transit":(subscriber.current_shipment_status == 5) ? "Delivered":(subscriber.current_shipment_status == 6) ? "Returned":(subscriber.current_shipment_status == 7) ? "Cancelled": (subscriber.current_shipment_status == 8) ? "Out For Delivery":""}</span>

                      </div>
                      <div className="col-12  col-md-1 text-end "><a href="" data-bs-toggle="collapse" data-bs-target={`#info_box_${index}`}><i className="fa fa-chevron-circle-down" aria-hidden="true"></i></a>
                      </div>
                    </div>
</Link>

                   
                    

                    <div className="row collapse" id={`info_box_${index}`}>
                      <div className="card bg-light border-0">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <h6 className="pt-3 pb-2">LOCATION DETAILS</h6>
                              <p className="m-0">Pickup: <span className=" ">{subscriber.pickup_address_type}</span></p>
                              <p className="m-0">Delivery: <span className="">{subscriber.delivery_address_type}</span></p>
                            </div>
                            <div className="col-12 col-md-4">
                              <h6 className="pt-3 pb-2">DETAILS</h6>
                              <p className="m-0">Items: <span className=" ">{subscriber.qty}</span></p>
                              <p className="m-0">Trailer Type: <span className="">{subscriber.service_type}</span></p>
                              <p className="m-0">Listed By: <span className="">Shipping Customer</span></p>
                            </div>
                            <div className="col-12 col-md-4 border-start text-center">
                              {(subscriber.lowest_quote != undefined) ?

                                <>
                                  <h6 className="pt-3 pb-2">LOW QUOTE</h6>
                                  {dimensionState.companyDetail.map((sub)=>(<p className="shipment_list_table_price m-0">{sub.set_currency}{subscriber.lowest_quote}</p>))}
                                  <Link style={{ color: 'white' }} to={"/shipment/" + subscriber.invoice_id}><p className="m-0 d-grid"><a target="_blank" className="btn btn-primary btn-block">View</a></p></Link>
                                  {(subscriber.status > 0 && subscriber.current_shipment_status>0 ) ? 
                                  <Link style={{ color: 'white' }} to={"/tracking/" + subscriber.invoice_id}><p className="mt-2 d-grid"><a target="_blank" className="btn btn-primary btn-block">Tracking</a></p></Link>
                                  :""}
                                </>

                                : <> <Link style={{ color: 'white' }} to={"/shipment/" + subscriber.invoice_id}><p className="m-0 d-grid"><a target="_blank" className="btn btn-primary btn-block">View</a></p></Link> </>}

                            </div>
                          </div>
                        </div>




                        
                       </div>


                      {(subscriber.status == 0) ?
                        <div className="d-flex flex-row-reverse p-3">
                          <div className="p-2 ">
                            <a type="button" onClick={() => deleteship(subscriber.invoice_id)} className="btn btn-outline-danger btn-sm">Delete</a>
                          </div>
                          <div className="p-2 ">
                            {subscriber.dstatus === 2 ? <a type="button" className="btn btn-outline-warning btn-sm" onClick={() => handleOpen(subscriber.invoice_id, 1)}><Typography color='primary'>Active</Typography></a> : subscriber.dstatus === 1 ? <a type="button" className="btn btn-outline-warning btn-sm" onClick={() => handleOpen(subscriber.invoice_id, 2)}><Typography color='error'>Inactive</Typography></a> : ""}

                          </div>
                          {/* <div className="p-2 ">
                            <a type="button" className="btn btn-outline-secondary btn-sm">Edit</a>
                          </div> */}
                          <div className="p-2 ">
                            {/* <a type="button" className="btn btn-outline-primary btn-sm">View</a> */}
                          </div>
                        </div>

                        : ""}
                    </div>
                  </div>
                </div>
                

              ))}
             
 
            </section>
            
            :
            <section className="text-center p-5 h-100">
              <h4 className="text-muted fw-bold">NO SHIPMENTS</h4>
              <p className="pb-5">You do not currently have any shipments in this status. Find what you're looking for in another tab or...</p>
              <Link to="/ship"><a className="btn btn-outline-primary btn-lg">Start a new shipment</a></Link>
            </section>
        }
        
      </div>
     
    </section>


        

     

    <Footer />
  </>)
}

export default Myaccount_customer;
