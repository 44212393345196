import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import { useState } from 'react';
import ReactLoading from 'react-loading'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Moment from 'react-moment';
import { Typography } from "@material-ui/core";


const Driver_list = () => {
let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
   
     
  }

    const [state, setState] = React.useState({
        driver_list: [],
        searchValue:undefined,
        status:null,
        isLoading:true,
       
    
      })
      const [driverstate, setdriverStatus] = React.useState({ driver_status:null});

    
    
    
      //console.log("state is user list is ", state);
    
    
      const axios_get_api = (status,driver_stat) => {
    
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/driver/driver_list`;
        let sendData = {
        driver_lists: {},
        transporter_id:"",
        status:status,
        driver_status:driver_stat
        };
        if(userdata.transporter_id != undefined) {
          sendData['transporter_id'] = userdata.transporter_id;
        }
        
          // sendData.driver_status = driverstate.driver_status
        
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setState({ ...state,driver_list: res.data.output ,isLoading:false });
         
        }).catch((e) => {
    
          toast.configure()
          toast.error("Some thing went wrong")
       
        })
      }
      function search_driver_name() {
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/driver/driver_find_detail`;
                
        let driver_data= { offset: 0, limit: 50 };
           
            if ( state.searchValue) {
                driver_data.driver_name = state.driver_name;
            }
                
             Axios.post(full_api,driver_data, { headers: exportValue.headers }).then(res => {        
                
                  setState({ ...state, driver_list: res.data.output  });
          
            }).catch((e) => {
          
               
            })          
    }
          
         const stateHandler = (key, value) => {
             state[key] = value
             setState({ ...state, driver_name:state.searchValue })
         }

          function deleteship(driver_id) {
             let userdata = {driver_id:driver_id}
             Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/driver/driver_delete` , userdata ,{ headers: exportValue.headers }).then((res)=>{
            
              setState({...state,driver_id:driver_id});
              axios_get_api();
              }).catch((e) => {
        
                 
                
              })
          }
         
             
    
          const driverFilter = (driver_stat) => {
            console.log("driver_stat",driver_stat)
            setdriverStatus({ ...driverstate,driver_status:driver_stat})
            // console.log("driverstate====",driverstate)
             axios_get_api(0,driver_stat)
          }

          const driver_stat = (driver_id,status) => {
            let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/driver/driver_status`;
           // setState({ ...state, org_id: org_id, verified_change:verified })
            let sendData = {
                driver_id: driver_id,
                status: status , 
                
                };
               
    
            
        
    
            Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
              
            setState({ ...state, driver_id: driver_id, status:status })
            
             axios_get_api();
                //state.isLoading = false;
            }).catch((e) => {
                //setState({...state,isLoading: false});
                toast.configure()
                toast.error("Some thing went wrong")
               
            })
        }

    
      React.useEffect(() => {
        axios_get_api()
      }, [])
    



  return (

    <>
        <Header/>

        
    <section className="pt-1 pb-3 bg-light">
  <div className="container">
    <section>
      <div className="row">
        <div className="col-8 col-md-6">
          <ul className="nav nav-pills pt-2 pb-3">
            <li className="nav-item">
              <a className={(driverstate.driver_status == null) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={()=>driverFilter(null)} >All</a>
            </li>
            <li className="nav-item">
              <a className={(driverstate.driver_status == 0) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={()=>driverFilter(0)} >Active</a>
            </li>
            <li className="nav-item">
              <a className={(driverstate.driver_status == 1) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={()=>driverFilter(1)} >Inactive</a>
            </li>
          </ul>
        </div>

        <div className="col-4 col-md-2 mt-2">
         <Link to="/add_driver"> <a href="add_driver.php" className="btn btn-outline-primary">Add Driver</a></Link>
        </div>
 
         
        <div className="col-10 col-md-3">
          <div className="form-floating">
            <input type="text" className="form-control mt-1 " id="Search" placeholder="Enter Search" name="Search" value={state.searchValue} onChange={({ target }) => {
                                stateHandler('searchValue', target.value)

                            }}/>
            <label htmlFor="email">Search Driver</label>
          </div>
        </div>
        <div className="col-2 col-md-1 mt-3" >
            <i onClick={(e)=>search_driver_name(state.searchValue)}  className="fa-solid fa-magnifying-glass fa-2x">  </i>
                        
            </div>

          
      </div>
    </section>
    <hr/>

    {state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
  
  (state.driver_list.length> 0)?
    <section className="shipment_list_table">
            <div className="row shipment_list_table_heading mb-2 ">
                <div className="col-2  d-none d-sm-block">Driver ID</div>
                <div className="col-2 d-none d-sm-block">License #</div>
                <div className="col-2 d-none d-sm-block">Name / Mobile</div>
                <div className="col-2 d-none d-sm-block">Address</div>
                <div className="col-2 d-none d-sm-block">Gov. ID #</div>
                <div className="col-1  d-none d-sm-block">Status</div>
                <div className="col-1  d-none d-sm-block">MORE</div>
            </div>
            { state.driver_list.map((subscriber,index) => (
             
                <div className="card">
                <div className="card-body">
                    
                      <div className="row">
                      
                      <div className="col-12 col-md-2 "><a href="">{subscriber.driver_id}</a> 
                      </div>
                    

                     
                       <div className="col-12 col-md-2 ">
                        <h4 className="text-dark" >{subscriber.license_number}<small className="text-muted" data-bs-toggle="tooltip" title="Driving Licnese Number"><i className="fa fa-info-circle" aria-hidden="true"></i></small></h4>
                      </div>
                    
                        <div className="col-12 col-md-2 ">
                          <p>{subscriber.driver_name} <br/>{subscriber.mobile_number}</p>
                        </div>
                       
                        <div className="col-12 col-md-2 ">
                          <p>{subscriber.address}</p>
                        </div>
                       
                        <div className="col-12 col-md-2 ">
                          <p>{subscriber.govid_number}</p>
                        </div>
                       
                        <div className="col-6 col-md-1 "><span className="badge rounded-pill bg-success text-light">{(subscriber.status==0)? "Active": "Inactive" }</span>
                        </div>
                       
                        <div className="col-6  col-md-1 text-end "><a href="" data-bs-toggle="collapse" data-bs-target={`#info_box_${index}`}><i className="fa fa-chevron-circle-down" aria-hidden="true"></i></a>
                      </div>
                      </div>
    
                      <div className="row collapse" id={`info_box_${index}`}>
                        <div className="card bg-light border-0">
                          <div className="card-body">
                            <div className="d-flex flex-row-reverse p-3">
                              <div className="p-2 ">
                              <a type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteship(subscriber.driver_id)}>Delete</a>
                              </div>
                              <div className="p-2 ">
                              {subscriber.status === 1 ? <a type="button" className="btn btn-outline-warning btn-sm" onClick={()=>driver_stat(subscriber.driver_id,0)}><Typography color='primary'>Active</Typography></a> : subscriber.status === 0 ? <a type="button" className="btn btn-outline-warning btn-sm" onClick={()=>driver_stat(subscriber.driver_id,1)}><Typography color='error'>Inactive</Typography></a> : ""}
                              </div>
                              <div className="p-2 ">
                             <Link to={`/edit_driver/${subscriber.driver_id}`}> <a type="button" className="btn btn-outline-secondary btn-sm">Edit</a></Link>
                              </div>
                            </div>  
                          </div>
                        </div>
                        
                      </div>
    
                  </div>
              </div>
              
            ))}
         
        

  </section>
  :
            
    
            
    <section className="text-center p-5 h-100">
      <h4 className="text-muted fw-bold">NO Driver</h4>
      <p className="pb-5">You do not currently have any Driver in this status.</p>
      <Link to="/add_driver"> <a href="add_driver.php" className="btn btn-outline-primary btn-lg">Add a new Driver</a></Link>
    
    </section>
}

    </div>
                              
</section>
                
<Footer/>
</>

  )
}

export default Driver_list;


