import React from "react";
import { Link } from 'react-router-dom';

import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportValue from '../apiconfig';
import {useParams,useNavigate } from 'react-router-dom'; 
import ReactLoading from 'react-loading';


import Header from './Header';
import Footer from './Footer';
import { AttributeLists,AttributeEditDetail,LocationEditDetail } from './Redux/actions/ShipmentAction'

import { useDispatch } from "react-redux";
const Subcategory = () => {

  const { category_id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();


    const [state, setState] = React.useState({
        dstatus: 1,
        sub_categoryList:[],
        isLoading:true
      })
    
    
      console.log("pp", state)
      //console.log("state is user list is ", state);
    
    
      const axios_get_api = () => {
    
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/category/sub_category_list`;
        let sendData = {
          dstatus: 1,
          category_id:category_id,
          sub_categoryList: {
        },
        
        };
    
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setState({sub_categoryList: res.data.output,isLoading:false });
          //console.log("response", res);
        }).catch((e) => {
    
          toast.configure()
          toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
      }
    
      React.useEffect(() => {
        axios_get_api()
      }, [])
    

      const removeLocationAttribute = (category_id,subcategory_id) =>{
        dispatch(AttributeLists())
        dispatch(AttributeEditDetail())
        dispatch(LocationEditDetail())

        function myGreeting() {
          navigate(`/addshipment/${category_id}/${subcategory_id}`)
        }
        setTimeout(myGreeting, 120)
      }



  return (
    <>
     <Header />
        <section className="sub_heading p-5 text-black">
          <div className="container ">
            <div className="row">
              <div className="col ">
                <h5>NEW SHIPMENT</h5>
              </div>
            </div>
          </div>
        </section>

       
        <section className=" bg-light p-5 ">
       
          <div className="container ">

          
                <h6 className="text-uppercase">Sub Categories</h6>
                 {/* {(state.sub_categoryList.length>0)?  */}
                 <div className="row">

                 {state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}}  />:
      state.sub_categoryList.length>0?
      state.sub_categoryList
       
      .map((subscriber, index) => (
               
            
                                   
              <div className="col-12 col-md-3" key={index}>
           
              <a  className="ship_item_hov text-decoration-none" >  
              <div className="text-center p-5 ship_item_box" onClick={()=>removeLocationAttribute(subscriber.category_id,subscriber.subcategory_id)}>
              <img src={`https://shippxcdn.fniix.com/web/${subscriber.subcategory_img}`}  className="img-fluid " />
                
                  <h5 className=" p-1"> {subscriber.subcategory_name}</h5>
              </div>
          </a>
          
          {/* </Link> */}
              </div> 
                
         
      )):<section class="text-center p-5 h-100">
      <h4 class="text-muted fw-bold">NO SUB CATEGORY FOUND</h4>
      
    </section>}
                           
       {/* :      */}
      
          </div></div>
        
        </section>
      
    <Footer />  
    </>
  )
}


export default Subcategory;
