import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

import exportValue from "../apiconfig";
import Header from './Header';
import Footer from './Footer';

const Registration = () => {
  let navigate = useNavigate();
  const { temp_id } = useParams();
  const [state, setState] = useState({})
  const [stateCustomer, setStateCustomer] = useState({ user_type: 1, account_type: "Personal", full_name: "", email: "", mob_number: "", password: "", confirmPassword: "", aggrement: true })
  const [stateCarrier, setStateCarrier] = useState({ user_type: 2, account_type: "Carrier", full_name: "", email: "", mob_number: "", password: "", confirmPassword: "", aggrement: true })
  const [stateBroker, setStateBroker] = useState({ user_type: 3, account_type: "Broker", full_name: "", email: "", mob_number: "", password: "", confirmPassword: "", aggrement: true })


  const handleChange = (event) => {
    console.log(event);
    if (event.target.type == "checkbox") {
      setStateCustomer({
        ...stateCustomer,
        [event.target.name]: event.target.checked,
      });
    } else {
      setStateCustomer({
        ...stateCustomer,
        [event.target.name]: event.target.value,
      });
    }
  }

  const handleChangeCarrier = (event) => {
    console.log(event);
    if (event.target.type == "checkbox") {
      setStateCarrier({
        ...stateCarrier,
        [event.target.name]: event.target.checked,
      });
    } else {
      setStateCarrier({
        ...stateCarrier,
        [event.target.name]: event.target.value,
      });
    }
  }

  const handleChangeBroker = (event) => {
    console.log(event);
    if (event.target.type == "checkbox") {
      setStateBroker({
        ...stateBroker,
        [event.target.name]: event.target.checked,
      });
    } else {
      setStateBroker({
        ...stateBroker,
        [event.target.name]: event.target.value,
      });
    }
  }


  const submitCustomer = (event) => {
    event.preventDefault();
    let flag = true;
    if (!stateCustomer.aggrement) {
      flag = false;
      console.log("not aggree");
    }
    if (stateCustomer.password != stateCustomer.confirmPassword) {
      flag = false;
      toast.configure();
      toast.error("Confirm password not match");
      console.log("not match");
    }
    if (flag) {
      axios_registration_api(stateCustomer);
      console.log("submited");
    }
  }

  const submitCarrier = (event) => {
    event.preventDefault();
    let flag = true;
    if (!stateCarrier.aggrement) {
      flag = false;
      console.log("not aggree");
    }
    if (stateCarrier.password != stateCarrier.confirmPassword) {
      flag = false;
      console.log("not match");
    }
    if (flag) {
      axios_registration_api(stateCarrier);
      console.log("submited");
    }
  }

  const submitBroker = (event) => {
    event.preventDefault();
    let flag = true;
    if (!stateBroker.aggrement) {
      flag = false;
      console.log("not aggree");
    }
    if (stateBroker.password != stateBroker.confirmPassword) {
      flag = false;
      console.log("not match");
    }
    if (flag) {
      axios_registration_api(stateBroker);
      console.log("submited");
    }
  }

  const axios_registration_api = (savevalue) => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/registration`;
    let sendData = savevalue;
    if (temp_id != undefined) {
      sendData.temp_id = temp_id;   
     }
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log(res);
      if(res.data.status == "success") {
      
        //localStorage.setItem('loggedIn', JSON.stringify(res.data.output));
        console.log("---------------------------");
        toast.configure();
        toast.success("Verification code send on your email-id.");
        let sendState = {email:sendData.email};
        if(temp_id) {
          sendState.temp_id = temp_id;
        }
        navigate('/verify',{state:sendState})
      
    }else{
      toast.configure();
      toast.error(res.data.message);
    }
    }).catch((e) => {
      //setState({...state,isLoading: false});

      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });

  };

  return (
    <>
      <Header />
      <section className="bg-light pad_custom">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-2">&nbsp;</div>
            <div className="col-12  col-md-8 bg-white p-5">
              <h3 className="text-uppercase">Register</h3>
              <div className="row">
                <div className="col">
                  <h6>I am a..</h6>
                  <div>&nbsp;</div>
                  <ul
                    className="nav nav-tabs justify-content-evenly"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link register_box active _box2"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"

                      >
                        <div className="">
                          <i className="fa fa-2x fa-user" aria-hidden="true"></i>
                        </div>
                        Shipping Customer
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link register_box _box2"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"

                      >
                        <div className="">
                          <i className="fa fa-2x fa-truck" aria-hidden="true"></i>
                        </div>
                        Carrier
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link register_box _box2"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"

                      >
                        <div className="">
                          <i className="fa fa-2x fa-users" aria-hidden="true"></i>
                        </div>
                        Broker
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row">
                        <form autoComplete="off" onSubmit={(e) => (submitCustomer(e))}>
                          <div className="col">
                            <div className="pb-2 pt-3">Account Type:</div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="account_type"
                                id="inlineRadio1"
                                value="Personal"
                                checked={stateCustomer.account_type === "Personal"}
                                onChange={(e) => handleChange(e)}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">Personal</label>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="account_type"
                                id="inlineRadio2"
                                value="Business"
                                checked={stateCustomer.account_type === "Business"}
                                onChange={(e) => handleChange(e)}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio2" >Business</label>
                            </div>
                          </div>
                           
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>Full Name</label>

                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Enter name"
                              name="full_name"
                              onChange={(e) => handleChange(e)}
                              required
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Full Name</label>
                          </div>
                          
                        
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>Email</label>

                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Enter email"
                              name="email"
                              required
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Email</label>
                          </div>
                        
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email"  className="d-block d-sm-none" style={{fontSize:"10px"}}>Phone</label>

                            <input
                              type="number"
                              className="form-control"
                              id="email"
                              placeholder="Enter phone"
                              name="mob_number"
                              required
                              onChange={(e) => handleChange(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Phone</label>
                          </div>
                         
                        
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email"className="d-block d-sm-none" style={{fontSize:"10px"}}>Password</label>

                            <input
                              type="password"
                              className="form-control"
                              id="email"
                              placeholder="Enter password"
                              name="password"
                              required
                              onChange={(e) => handleChange(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Password</label>
                          </div>
                        
                         
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>Confirm Password</label>

                            <input
                              type="password"
                              className="form-control"
                              id="email"
                              placeholder="Enter password again"
                              name="confirmPassword"
                              required
                              onChange={(e) => handleChange(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Confirm Password</label>
                          </div>
                         
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check1"
                              name="aggrement"
                              defaultChecked={stateCustomer.aggrement}
                              onChange={(e) => handleChange(e)}
                            />
                            <label className="form-check-label"
                            ><small className="text-muted"
>I AGREE TO THE TERMS OF USER AGREEMENT AND PRIVACYPOLICY.</small
                              ></label
                            >
                          </div>
                          <div className="col mt-4 mb-3">
                            {(stateCustomer.aggrement) ? 
                            <input
                              className="btn btn-primary"
                              type="submit"
                              value="Submit"

                            />
                            :""}
                          </div>
                        </form>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="row">
                        <form autoComplete="off" onSubmit={(e) => (submitCarrier(e))}>
                          <div className="pb-2 pt-3">Account Type:</div>
                          <div className="col">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="account_type"
                                id="inlineRadio11"
                                value="Carrier"
                                checked={stateCarrier.account_type === "Carrier"}
                                onChange={(e) => handleChangeCarrier(e)}

                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">Carrier</label >
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="account_type"
                                id="inlineRadio21"
                                value="Carrier Broker"
                                checked={stateCarrier.account_type === "Carrier Broker"}
                                onChange={(e) => handleChangeCarrier(e)}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio2">Carrier  Broker</label>
                            </div>
                          </div>
                             
                           
                          <div className="form-floating mb-3 mt-3  ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>Full Person's Name</label>

                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Enter name"
                              name="full_name"
                              onChange={(e) => handleChangeCarrier(e)}
                              required
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Full Person's Name</label>
                          </div>
                          
                          
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>Email</label>

                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Enter email"
                              name="email"
                              required
                              onChange={(e) => handleChangeCarrier(e)}
                              autoComplete="off"
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Email</label>
                          </div>
                         
                        
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>Phone</label>

                            <input
                              type="number"
                              className="form-control"
                              id="email"
                              placeholder="Enter phone"
                              name="mob_number"
                              required
                              onChange={(e) => handleChangeCarrier(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Phone</label>
                          </div>
                         
                       
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email"  className="d-block d-sm-none" style={{fontSize:"10px"}}>Password</label>

                            <input
                              type="password"
                              className="form-control"
                              id="email"
                              placeholder="Enter password"
                              name="password"
                              required
                              onChange={(e) => handleChangeCarrier(e)}
                              autoComplete="new-password"
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Password</label>
                          </div>
                          
                         
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email"  className="d-block d-sm-none" style={{fontSize:"10px"}}>Confirm Password</label>

                            <input
                              type="password"
                              className="form-control"
                              id="email"
                              placeholder="Enter password again"
                              name="confirmPassword"
                              required
                              onChange={(e) => handleChangeCarrier(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Confirm Password</label>
                          </div>
                          
                          <hr />
                          
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>DOT number (optional)</label>

                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Enter password again"
                              name="dot_number"
                              onChange={(e) => handleChangeCarrier(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">DOT number (optional)</label>
                          </div>
                         
                             
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>MC number (optional)</label>

                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Enter password again"
                              name="mc_number"
                              onChange={(e) => handleChangeCarrier(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">MC number (optional)</label>
                          </div>
                        
                            
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className="d-block d-sm-none" style={{fontSize:"10px"}}>Broker MC number (for broker only)</label>

                            <input
                              type="text"
                              className="form-control"
                              id="brmcnumber"
                              placeholder="Enter password again"
                              name="brmcnumber"
                              
                              onChange={(e) => handleChangeCarrier(e)}
                            />
                            <label htmlFor="email" className="d-none d-sm-block">Broker MC number (for broker only)</label>
                          </div>
                          
                             
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check1"
                              name="aggrement"
                              defaultChecked={stateCarrier.aggrement}

                              onChange={(e) => handleChangeCarrier(e)}
                            />
                            <label className="form-check-label"
                            ><small className="text-muted"
                            >I AGREE TO THE TERMS OF USER AGREEMENT AND PRIVACY
                                POLICY.</small
                              ></label
                            >
                          </div>
                         
                         
                          <div className="col mt-5 ">
                            <input
                              className="btn btn-primary"
                              type="submit"
                              value="Submit"
                            />
                          </div>
                         
                        </form>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="contact"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div className="row">
                        <form autoComplete="off" onSubmit={(e) => (submitBroker(e))}>
                          <div className="pb-2 pt-3">Account Type:</div>
                          <div className="col">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="account_type"
                                id="inlineRadio11"
                                value="Broker"
                                checked={stateBroker.account_type === "Broker"}
                                onChange={(e) => handleChangeBroker(e)}

                              />
                              <label className="form-check-label" htmlFor="inlineRadio1"
                              >Broker</label
                              >
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="account_type"
                                id="inlineRadio21"
                                value="Freight Forwarder"
                                checked={stateBroker.account_type === "Freight Forwarder"}
                                onChange={(e) => handleChangeBroker(e)}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio2"
                              >Freight Forwarder</label
                              >
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="account_type"
                                id="inlineRadio21"
                                value="Broker Agent"
                                checked={stateBroker.account_type === "Broker Agent"}
                                onChange={(e) => handleChangeBroker(e)}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio2">Broker Agent</label >
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="account_type"
                                id="inlineRadio21"
                                value="3PL"
                                checked={stateBroker.account_type === "3PL"}
                                onChange={(e) => handleChangeBroker(e)}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio2"
                              >3PL</label
                              >
                            </div>
                          </div>
                            
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}>Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Enter name"
                              name="email"
                              required
                              onChange={(e) => handleChangeBroker(e)}
                            />
                            <label htmlFor="email" className='d-none d-sm-block'>Full Name</label>
                          </div>
                          
                            
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}>Email</label>

                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Enter email"
                              required
                              name="full_name"
                              onChange={(e) => handleChangeBroker(e)}
                              autoComplete="off"

                            />
                            <label htmlFor="email" className='d-none d-sm-block'>Email</label>
                          </div>
                          
                       
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}>Phone</label>

                            <input
                              type="number"
                              className="form-control"
                              id="email"
                              placeholder="Enter phone"
                              name="mob_number"
                              required
                              onChange={(e) => handleChangeBroker(e)}
                            />
                            <label htmlFor="email" className='d-none d-sm-block'>Phone</label>
                          </div>
                         
                         
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}>Password</label>

                            <input
                              type="password"
                              className="form-control"
                              id="email"
                              placeholder="Enter password"
                              name="email"
                              required
                              autoComplete="new-password"
                              onChange={(e) => handleChangeBroker(e)}
                            />
                            <label htmlFor="email" className='d-none d-sm-block'>Password</label>
                          </div>
                          
                         
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}>Confirm Password</label>

                            <input
                              type="password"
                              className="form-control"
                              id="email"
                              placeholder="Enter password again"
                              name="email"
                              required
                              onChange={(e) => handleChangeBroker(e)}
                            />
                            <label htmlFor="email" className='d-none d-sm-block'>Confirm Password</label>
                          </div>
                          
                          <hr />
                         
                          <div className="form-floating mb-3 mt-3 ">
                          <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}} >Broker MC number</label>

                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Enter password again"
                              name="email"
                              required
                              onChange={(e) => handleChangeBroker(e)}
                            />
                            <label htmlFor="email"  className='d-none d-sm-block'>Broker MC number</label>
                          </div>
                         

                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check1"
                              name="aggrement"
                              defaultChecked={stateBroker.aggrement}
                              onChange={(e) => handleChangeBroker(e)}
                            />
                            <label className="form-check-label"
                            ><small className="text-muted"
                            >I AGREE TO THE TERMS OF USER AGREEMENT AND PRIVACY
                                POLICY.</small
                              ></label
                            >
                          </div>
                          <div className="col mt-4 mb-3">
                            <input
                              className="btn btn-primary"
                              type="submit"
                              value="Submit"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2">&nbsp;</div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Registration;
