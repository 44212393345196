import React, { useEffect, useState } from "react";
import Header from './Header';
import { useLocation, useParams, Link } from "react-router-dom";

import MenuCustomer from './MenuCustomer';
import Footer from './Footer';
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Moment from 'react-moment';


const Shipment_detail_user = (props) => {
  const { invoice_id } = useParams();
  console.log("invoice_id is ", invoice_id);
  console.log("props   ", props);
  const location = useLocation();
  console.log("props   ", location);
  const [state, setState] = useState({ shipmentDetail: { item_imgs: [] }, questions: [], quotes: [], isLoading: true })
  const [quetState, setQuetState] = useState();

  console.log("quotations quotes is ", quetState)
  useEffect(() => {
    shipmentDetail()
  }, [])

  const shipmentDetail = () => {


    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/shipment_detail`;
    let sendData = { invoice_id: invoice_id };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log(res);
      if (res.data.output.length == 1) {
        setState({ ...state, shipmentDetail: res.data.output[0], questions: res.data.questions, quotes: res.data.quotes })
        if (res.data.acceptedquotes) {

          setQuetState(res.data.acceptedquotes)
        }
      }
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };


  const handleQuotation = (data_array) => {

    console.log("this is second invoice is ", data_array);
    //setState({...state,invoice_id:invoice_id})

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/accept_decline_shipment_quote`;
    let sendData = data_array;
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res issssd ", res);
      if (res && res.data.status == '200')
        if (data_array.status == 2) {
          let stateShipdetail = state.shipmentDetail;
          stateShipdetail.status = 1;
          setState({ ...state, shipmentDetail: stateShipdetail });
        }
        if (data_array.status == 3) {
          let stateShipdetail = state.shipmentDetail;
          stateShipdetail.status = 3;
          setState({ ...state, shipmentDetail: stateShipdetail });
        }
      setQuetState(res.data.output)
      toast.configure();
      toast.success(res.data.message);
      // shipmentDetail()
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });

  }

  return (
    <>
      <Header />
      <section className=" p-5 bg-light">
        <div className="container ">



          <section>
            <div className="row">

              <div className="col">
                <div className="row">
                  <div className="col itme_detail_head ">
                    <div className="d-flex justify-content-between p-3 ">
                      <div className="p-2">
                        <h2>{state.shipmentDetail.title}</h2>
                        <p className="text-muted"><a href="">Mister C.</a> ID #{state.shipmentDetail.invoice_id}</p>
                      </div>
                      <div className="p-2 text-end">
                        <div><span className="badge bg-success fw-normal ">{state.shipmentDetail.status==0?"Active":state.shipmentDetail.status==1?"Booked":state.shipmentDetail.status==2?"Picked Up":state.shipmentDetail.status==3?"Cancelled":state.shipmentDetail.status==4?"Delivered":state.shipmentDetail.status==5?"Returned":""}</span></div>
                        <p><small>2 days, 16 hours left | Listed Mar 31, 2022</small></p>
                        <small>{state.shipmentDetail.category_name}</small>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <ul className="nav nav-pills nav-fill c_breadcrumb">
                  {(state.shipmentDetail.status >= 0) ?
                    <li className="nav-item">
                      <a className={"nav-link "+(state.shipmentDetail.status == 0 ? "active":"")} data-bs-toggle="pill" href="#active">Active</a>
                    </li>
                    : ""}
                  {(state.shipmentDetail.status >= 1) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.status == 1  ? "active":"")} data-bs-toggle="pill" href="#booked">Booked</a>
                    </li>
                    : ""}
                  {(state.shipmentDetail.status >= 2) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.status == 2  ? "active":"")} data-bs-toggle="pill" href="#pickedup">Picked Up</a>
                    </li>
                    : ""}
                    {(state.shipmentDetail.status >= 3) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.status == 3  ? "active":"")} data-bs-toggle="pill" href="#cancelled">Cancelled</a>
                    </li>
                    : ""}
                    {(state.shipmentDetail.status >= 4) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.status == 4  ? "active":"")} data-bs-toggle="pill" href="#delivered">Delivered</a>
                    </li>
                    : ""}

                  {(state.shipmentDetail.status >= 5) ?
                    <li className="nav-item">
                      <a className={"nav-link " + (state.shipmentDetail.status == 5  ? "active":"")} data-bs-toggle="pill" href="#returned">Returned</a>
                    </li>
                    : ""}
                </ul>


                <div className="tab-content card mt-3 shadow-sm">
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.status == 0?"active":"fade")} id="active">
                    <div className="alert alert-info">
                      <strong><i className="fa fa-question-circle" aria-hidden="true"></i></strong> You have <a href="#" className="alert-link"> questions</a> to answer.
                    </div>
                    <div className="alert alert-warning">
                      <strong><i className="fa fa-info-circle" aria-hidden="true"></i></strong> You have received <a href="#" className="alert-link">{state.shipmentDetail.total_quotes}quotes</a> as low as ${state.shipmentDetail.lowest_quote}
                    </div>
                    <div className="alert alert-primary">
                      <strong><i className="fa fa-map-marker" aria-hidden="true"></i></strong> <a href="#" className="alert-link">Confirm</a> your exact street address
                    </div>
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.status == 1?"active":"fade")} id="booked">

                    <h3>Your shipment has been booked with <a href="">{(quetState && quetState.branch_name != undefined) ? quetState.branch_name : ""}</a></h3>

                    <h6>Tracking #{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0} <small>Date: 28-03-2022 18:30</small></h6>
                    <hr />
                    <div className="text-end">
                      <h4>{(quetState && quetState.invoice_id != undefined) ? quetState.invoice_id : 0}</h4>
                      <table className="table p-5">
                        <thead>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total quote</td>
                            <td>${(quetState && quetState.cost != undefined) ? quetState.cost : 0}</td>
                          </tr>
                          <tr>
                            <td>Service charge</td>
                            <td>${(quetState && quetState.cost != undefined) ? 100 : 0}</td>
                          </tr>
                          <tr>
                            <th>Gross Total</th>
                            <th>${(quetState && quetState.cost != undefined) ? (quetState.cost + 100) : 0}</th>
                          </tr>
                          <tr>
                            <td>Paid for booking</td>
                            <td>${(quetState && quetState.cost != undefined) ? 300 : 0}</td>
                          </tr>
                          <tr>
                            <th>To be paid</th>
                            <th className="fs-4">${(quetState && quetState.cost) != undefined ? (quetState.cost + 100 - 300) : 0}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.status == 2?"active":"fade")} id="pickedup">
                    <h3>Your shipment has been Picked up by <a href=""></a></h3>
                    <h6>Tracking #4903409545 <small>Date: 28-03-2022 18:30</small></h6>
                    <div>Driver Name: Paul Narraot, Mob: 9283923494</div>
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.status == 4?"active":"fade")} id="delivered">
                    <h3>Your shipment has been Delivered by <a href="">Aero logistics</a></h3>
                    <h6>Tracking #4903409545 <small>Date: 28-03-2022 18:30</small></h6>
                    <p>Driver Name: Paul Narraot, Mob: 9283923494</p>
                    <p><a href="">ePOD sign</a> |  <a href="">ePOD picture</a></p>
                    <p><a href="">Rate your experience. Give a review.</a></p>
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.status == 3?"active":"fade")} id="cancelled">
                    <h3>Your shipment has been Cancelled</h3>
                    <h6><small>Date: 28-03-2022 18:30</small></h6>
                  </div>
                  <div className={"tab-pane container p-3 "+(state.shipmentDetail.status == 5?"active":"fade")} id="returned">
                    <h3>Your shipment has been Returned</h3>
                    <h6><small>Date: 28-03-2022 18:30</small></h6>
                  </div>
                </div>
                <section className="mt-4 shadow-sm">
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <h2 className="fs-5 text-uppercase">Quotes</h2>
                          <hr />

                          <section>
                            {state.quotes.map((quote, ind) => (


                              <div className="card transporter_item mb-3" key={ind}>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-2">
                                      <img src={__dirname + "img/partner/" + quote.logo} className="img-fluid rounded-circle border" />
                                    </div>
                                    <div className="col-4 border-end">
                                      <h2><a href="#" className="text-dark"><Link to={`/profile-carrier/${quote.transporter_id}`}>{quote.branch_name}</Link></a></h2>
                                      <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                      <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                      <small><a href="" className="">(234) </a></small>
                                    </div>
                                    <div className="col-3">
                                      <h6 className="text-muted pb-3 fw-light">Open Transport</h6>
                                      <h3 className=" pb-1">Estimated Pickup</h3>
                                      <p className="fw-light">Within 5 days of booking</p>
                                    </div>
                                    <div className="col-3 text-end">
                                      <h1>${quote.cost}</h1>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="card bg-light">
                                        <div className="card-body">
                                          <table className="table">
                                            <tbody>
                                              <tr>

                                                <td>Quote:</td>
                                                <td>${quote.cost}</td>
                                              </tr>
                                              <tr>
                                                <td>Service charge:</td>
                                                <td>$100</td>
                                              </tr>
                                              <tr>
                                                <td>Total:</td>
                                                <td className="fs-4">${quote.cost + 100}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    {(state.shipmentDetail.status == 0 && quote.status == 1) ?
                                    
                            
                            
                                      <div className="col-6 text-center pt-2">
                                        <p><small className="text-muted">Payment METHOD: Cash</small></p>
                                        <div className="d-flex p-3 justify-content-center">
                                          <div className="p-2 ">
                                            <a className="btn btn-outline-success btn-lg" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 2 })}>Accept</a>
                                          </div>
                                          <div className="p-2 ">
                                            <div className="btn-group">
                                              <button type="button" className="btn btn-outline-danger btn-lg dropdown-toggle" data-bs-toggle="dropdown">Decline</button>
                                              <div className="dropdown-menu">
                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "other" })}>Other</a>

                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "Price too high" })}>Price too high</a>

                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "Insufficient feedback" })}>Insufficient feedback</a>

                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "Dates not compatible" })}>Dates not compatible</a>

                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "Shipping no longer required" })}>Shipping no longer required</a>

                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "Insufficient communication" })}>Insufficient communication</a>

                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "Inadequate insurance" })}>Inadequate insurance</a>

                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "Inadequate equipment" })}>Inadequate equipment</a>

                                                <a className="dropdown-item" onClick={() => handleQuotation({ invoice_id: quote.invoice_id, transporter_id: quote.transporter_id, status: 3, report: "I need to make changes to this shipment" })}>I need to make changes to this shipment</a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <small>By booking this quote, you agree to the provider's <a href="">Terms & Conditions</a> and the <a href="">User Agreement</a></small>
                                      </div>
                                      : (state.shipmentDetail.status != 0 && quote.status == 2) ? "accepted" :(state.shipmentDetail.status != 0 && quote.status == 3) ? "reject":""
                                      }

                                  </div>
                                  <hr />
                                  <div className="row">
                                    <div className="col-2">
                                      <a href="" className="text-muted" data-bs-toggle="tooltip" title="Feedback Score"><i className="fa fa-star" aria-hidden="true"></i> 2983</a>
                                    </div>
                                    <div className="col-2">
                                      <a href="" className="text-muted" data-bs-toggle="tooltip" title="Positive Feedback "><i className="fa fa-check" aria-hidden="true"></i> 29%</a>
                                    </div>
                                    <div className="col-2">
                                      <a href="" className="text-muted" data-bs-toggle="tooltip" title="Cancellation Rate"><i className="fa fa-times" aria-hidden="true"></i> 11%</a>
                                    </div>
                                    <div className="col-2">
                                      <a href="" className="text-muted" data-bs-toggle="tooltip" title="Question"><i className="fa fa-question-circle" aria-hidden="true"></i> 0</a>
                                    </div>
                                    <div className="col-12 col-md-4 text-end">
                                      <a href="" className="text-uppercase" data-bs-toggle="collapse" data-bs-target={`#quote_detail_box${ind}`}>Show Quote Details</a>
                                    </div>


                                    <div id={`quote_detail_box${ind}`} className="collapse">

                                      <section className="mt-3 quote_detail_box" id="demo" >

                                        <ul className="nav nav-tabs">
                                          <li className="nav-item">
                                            <a className="nav-link active" data-bs-toggle="tab" href="#details_1">Details</a>
                                          </li>
                                          <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#questions_1">Questions</a>
                                          </li>
                                        </ul>
                                        <div className="tab-content mt-3">
                                          <div className="tab-pane container active" id="details_1">
                                            <p className="fs-12 text-end text-muted">Quote Placed Apr 1, 2022, 7:23 PM<br />
                                              Quote Expires Apr 7, 2022, 7:21 PM<br />
                                              Quote ID:{quote.quotes_id} </p>
                                            <div className="row">
                                              <div className="col-12 col-md-6">
                                                <h6>TIME FRAME</h6>
                                                <p>Pickup: Within 5 days of booking</p>
                                                <p>Delivery: Within 7 days of pickup</p>
                                                <hr />
                                                <h6>TRANSPORT MODE</h6>
                                                <p>{quote.transport_mode}</p>
                                                <hr />
                                                <h6>VEHICLE TYPE</h6>
                                                <p>Multi-car open trailer</p>
                                                <hr />
                                                <h6>SERVICE TYPE</h6>
                                                <p>{quote.service_type}</p>
                                                <hr />
                                                <h6>PAYMENT METHOD</h6>
                                                <p>Cash Payments</p>
                                                <hr />
                                                <h6>PAYMENT ACCEPTED</h6>
                                                <p>{quote.payment_accepted_on}</p>
                                              </div>
                                              <div className="col-12 col-md-6">
                                                <h6>NOTE TO SHIPPING CUSTOMER</h6>
                                                <p className="mt-3">{quote.note} </p>
                                                <hr />
                                                <p className="mt-3 text-end"><a href="">View Our Profile</a></p>
                                              </div>
                                            </div>

                                          </div>
                                          <div className="tab-pane container fade" id="questions_1">
                                            <div className="d-flex p-3">
                                              <div className="p-2 text-muted">
                                                <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
                                              </div>
                                              {state.questions.map((item, index) =>
                                                <div className="p-2">
                                                  <h6 className="m-0">{item.full_name}</h6>
                                                  <small className="text-muted"><Moment format="MMMM DD, YYYY hh:mm:ss" unix>{item.created}</Moment></small>
                                                  <p className="pt-3">{item.question}</p>
                                                </div>
                                              )}
                                            </div>
                                            <hr />




                                          </div>
                                        </div>
                                      </section>
                                    </div>




                                  </div>
                                </div>
                              </div>
                            ))}
                            {/* <div className="card transporter_item mb-3">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-2">
                                    <img src="img/partner/m-partner-2.jpg" className="img-fluid rounded-circle border" />
                                  </div>
                                  <div className="col-4 border-end">
                                    <h2><a href="" className="text-dark">team logistics </a></h2>
                                    <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                    <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                    <small><a href="" className="">(234) </a></small>
                                  </div>
                                  <div className="col-3">
                                    <h6 className="text-muted pb-3 fw-light">Open Transport</h6>
                                    <h3 className=" pb-1">Estimated Pickup</h3>
                                    <p className="fw-light">Within 5 days of booking</p>
                                  </div>
                                  <div className="col-3 text-end">
                                    <h1>$1,104</h1>
                                  </div>
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Feedback Score"><i className="fa fa-star" aria-hidden="true"></i> 2983</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Positive Feedback "><i className="fa fa-check" aria-hidden="true"></i> 29%</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Cancellation Rate"><i className="fa fa-times" aria-hidden="true"></i> 11%</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Question"><i className="fa fa-question-circle" aria-hidden="true"></i> 0</a>
                                  </div>
                                  <div className="col text-end">
                                    <a href="" className="text-uppercase">Show Quote Details</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card transporter_item mb-3">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-2">
                                    <img src="img/partner/m-partner-4.jpg" className="img-fluid rounded-circle border" />
                                  </div>
                                  <div className="col-4 border-end">
                                    <h2><a href="" className="text-dark">packline logistics </a></h2>
                                    <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                    <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                    <small><a href="" className="">(234) </a></small>
                                  </div>
                                  <div className="col-3">
                                    <h6 className="text-muted pb-3 fw-light">Open Transport</h6>
                                    <h3 className=" pb-1">Estimated Pickup</h3>
                                    <p className="fw-light">Within 5 days of booking</p>
                                  </div>
                                  <div className="col-3 text-end">
                                    <h1>$923</h1>
                                  </div>
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Feedback Score"><i className="fa fa-star" aria-hidden="true"></i> 2983</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Positive Feedback "><i className="fa fa-check" aria-hidden="true"></i> 29%</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Cancellation Rate"><i className="fa fa-times" aria-hidden="true"></i> 11%</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Question"><i className="fa fa-question-circle" aria-hidden="true"></i> 0</a>
                                  </div>
                                  <div className="col text-end">
                                    <a href="" className="text-uppercase">Show Quote Details</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card transporter_item mb-3">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-2">
                                    <img src="img/partner/m-partner-1.jpg" className="img-fluid rounded-circle border" />
                                  </div>
                                  <div className="col-4 border-end">
                                    <h2><a href="" className="text-dark">Hopper logistics </a></h2>
                                    <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                    <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                    <small><a href="" className="">(234) </a></small>
                                  </div>
                                  <div className="col-3">
                                    <h6 className="text-muted pb-3 fw-light">Open Transport</h6>
                                    <h3 className=" pb-1">Estimated Pickup</h3>
                                    <p className="fw-light">Within 5 days of booking</p>
                                  </div>
                                  <div className="col-3 text-end">
                                    <h1>$922</h1>
                                  </div>
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Feedback Score"><i className="fa fa-star" aria-hidden="true"></i> 2983</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Positive Feedback "><i className="fa fa-check" aria-hidden="true"></i> 29%</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Cancellation Rate"><i className="fa fa-times" aria-hidden="true"></i> 11%</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Question"><i className="fa fa-question-circle" aria-hidden="true"></i> 0</a>
                                  </div>
                                  <div className="col text-end">
                                    <a href="" className="text-uppercase">Show Quote Details</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card transporter_item mb-3">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-2">
                                    <img src="img/partner/p1.jpg" className="img-fluid rounded-circle border" />
                                  </div>
                                  <div className="col-4 border-end">
                                    <h2><a href="" className="text-dark">TT3 logistics </a></h2>
                                    <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                    <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                                    <small><a href="" className="">(234) </a></small>
                                  </div>
                                  <div className="col-3">
                                    <h6 className="text-muted pb-3 fw-light">Open Transport</h6>
                                    <h3 className=" pb-1">Estimated Pickup</h3>
                                    <p className="fw-light">Within 5 days of booking</p>
                                  </div>
                                  <div className="col-3 text-end">
                                    <h1><del className="text-muted">$881</del></h1>
                                    <small className="text-muted">Declined</small>
                                  </div>
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Feedback Score"><i className="fa fa-star" aria-hidden="true"></i> 2983</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Positive Feedback "><i className="fa fa-check" aria-hidden="true"></i> 29%</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Cancellation Rate"><i className="fa fa-times" aria-hidden="true"></i> 11%</a>
                                  </div>
                                  <div className="col-2">
                                    <a href="" className="text-muted" data-bs-toggle="tooltip" title="Question"><i className="fa fa-question-circle" aria-hidden="true"></i> 0</a>
                                  </div>
                                  <div className="col text-end">
                                    <a href="" className="text-uppercase">Show Quote Details</a>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                          </section>

                        </div>
                      </div>
                    </div>
                  </div>
                </section>


                <section className="mt-4 shadow-sm">
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <h2 className="fs-5 text-uppercase">Questions</h2>
                          <hr />
                          <section>
                            {state.questions.map((value, index) => (
                              <>
                                <div className="d-flex p-3" key={index}>
                                  <div className="p-2 text-muted">
                                    <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
                                  </div>
                                  <div className="p-2">
                                    <h6 className="m-0">{value.full_name}</h6>
                                    <small className="text-muted">March 31, 2022 8:41 AM</small>
                                    <p className="pt-3">{value.question}</p>
                                  </div>
                                </div>
                                <hr />
                              </>
                            ))}
                            {/* <div className="d-flex p-3">
                              <div className="p-2 text-muted">
                                <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
                              </div>
                              <div className="p-2">
                                <h6 className="m-0">Service Provider</h6>
                                <small className="text-muted">March 31, 2022 8:41 AM</small>
                                <p className="pt-3">Hello, may I ask if you will load any additional personal items in the vehicle or just shipping the vehicle as is? I can move it for 899. Have a great day!</p>
                              </div>
                            </div> */}
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="row">
                  <div className="col mt-3 itme_detail_box ">
                    <div className="card">
                      <div className="card-body p-4 shadow-sm">
                        <div className="row">
                          <div className="col">
                            <h6 className="text-muted pb-2">Pickup</h6>
                            <div className="d-flex pb-4">
                              <div className="flex-shrink-0">
                                <i className="fa fa-arrow-right rotate_40 text-muted" aria-hidden="true"></i>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>{state.shipmentDetail.pickup_address}</h3>
                                <p className="text-muted ">Residence / Home Business</p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <i className="fa fa-clock-o rotate_40 text-muted" aria-hidden="true"></i>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>Between<Moment format="MMMM DD" unix>{state.shipmentDetail.pickup_datetime_from}</Moment> - <Moment format="MMMM DD" unix>{state.shipmentDetail.pickup_datetime_to}</Moment></h3>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="text-muted pb-2">Delivery</h6>
                            <div className="d-flex pb-4">
                              <div className="flex-shrink-0">
                                <i className="fa fa-arrow-down rotate_40 text-muted" aria-hidden="true"></i>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>{state.shipmentDetail.delivery_address}</h3>
                                <p className="text-muted ">Residence / Home Business</p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <i className="fa fa-clock-o rotate_40 text-muted" aria-hidden="true"></i>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>Between <Moment format="MMMM DD" unix>{state.shipmentDetail.delivery_datetime_from}</Moment> - <Moment format="MMMM DD" unix>{state.shipmentDetail.delivery_datetime_to}</Moment></h3>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row pt-3 ">
                          <div className="col"><img src="img/staticmap.png" className="img-fluid" /></div>
                        </div>

                        <div className="row p-4 text-center">
                          <div className="col border-end">
                            <span className=""><i className="fa fa-2x fa-map-marker text-muted" aria-hidden="true"></i></span>
                            <span className="fs-2 p-1">333</span>
                            <span className="text-uppercase crack">total km</span>
                          </div>
                          <div className="col border-end">
                            <span className=""><i className="fa fa-2x fa-th-large text-muted" aria-hidden="true"></i></span>
                            <span className="fs-2 p-1">{state.shipmentDetail.qty}</span>
                            <span className="text-uppercase crack">total Items</span>
                          </div>
                          <div className="col ">
                            <span className=""><i className="fa fa-2x fa-cube text-muted" aria-hidden="true"></i></span>
                            <span className="fs-2 p-1">{state.shipmentDetail.item_weight}</span>
                            <span className="text-uppercase crack">total weight</span>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>

                <section className="mt-4 shadow-sm shipment_info_box">
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div className="card-body p-4">
                          <h2 className="fs-5 text-uppercase">Shipment details</h2>
                          <hr />
                          <div className="row">
                            <div className="col-3 text-muted">
                              Description
                            </div>
                            <div className="col">
                              <div className="row">
                                <h5 className=" f-w-500 pb-4">{state.shipmentDetail.title}</h5>
                                <div className="col-6 col-md-4">
                                  <h6 className="fw-normal text-uppercase text-muted fs-m"><small>category</small></h6>
                                  <p className=" f-w-500">{state.shipmentDetail.category_name}</p>
                                </div>
                                <div className="col-6 col-md-4">
                                  <h6 className="fw-normal text-uppercase text-muted fs-m"><small>TOTAL WEIGHT</small></h6>
                                  <p className=" f-w-500">{state.shipmentDetail.item_weight} kg</p>
                                </div>
                                <div className="col-6 col-md-4">
                                  <h6 className="fw-normal text-uppercase text-muted fs-m"><small>TOTAL QUANTITY</small></h6>
                                  <p className=" f-w-500">{state.shipmentDetail.qty}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-3 text-muted">
                              Item
                            </div>
                            <div className="col">
                              <div className="row">
                                <h6 className=" f-w-500 pb-4">{state.shipmentDetail.title}</h6>
                                <div className="col-6 col-md-4">
                                  <h6 className="fw-normal text-uppercase text-muted fs-m"><small>BODY TYPE</small></h6>
                                  <p className=" f-w-500">Coupe</p>
                                </div>
                                <div className="col-6 col-md-4">
                                  <h6 className="fw-normal text-uppercase text-muted fs-m"><small>CONDITION</small></h6>
                                  <p className=" f-w-500">Operable</p>
                                </div>
                                <div className="col-6 col-md-4">
                                  <h6 className="fw-normal text-uppercase text-muted fs-m"><small>EST. SIZE</small></h6>
                                  <p className=" f-w-500">{state.shipmentDetail.item_height} x {state.shipmentDetail.item_width} x {state.shipmentDetail.item_length} in</p>
                                </div>
                                {state.quotes.map((quote, ind) => (
                                <div className="col-6 col-md-4">
                            
                                  
                                  <h6 className="fw-normal text-uppercase text-muted fs-m"><small>EST. value</small></h6>
                                  <p className=" f-w-500">{quote.cost+100-300}$</p>
                            
                                </div>
                            ))}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-3 text-muted">
                              Photos
                            </div>
                            <div className="col">
                              {(state.shipmentDetail.item_imgs.length > 0) ?
                              <>
                              <div className="row">
                                <div className="col-12 col-md-12">
                                  <a href=""><img src={`https://shippxcdn.fniix.com/web/${state.shipmentDetail.item_imgs[0]}`} className="mb-2 img-thumbnail" /></a>
                                  {/* <a href=""><img src="img/shipment/1.jpg" className="mb-2 img-thumbnail" /></a>
                                  <a href=""><img src="img/shipment/2.jpg" className="mb-2 img-thumbnail" /></a>
                                  <a href=""><img src="img/shipment/3.jpg" className="mb-2 img-thumbnail" /></a>
                                  <a href=""><img src="img/shipment/4.jpg" className="mb-2 img-thumbnail" /></a> */}
                                </div>
                              </div>
                              </>
                              :
                              <h6 className=" f-w-500 pb-4 text-muted">No Photos Uploaded</h6>
                                }
                            </div>
                          </div>

                          <hr />
                          <div className="row">
                            <div className="col-3 text-muted">
                              Item
                            </div>
                            <div className="col">
                              <div>
                                <h6 className="fw-normal text-uppercase text-muted fs-m"><small>REQUESTED SERVICE TYPE(S)</small></h6>
                                <p className=" f-w-500">{state.shipmentDetail.service_type}</p>
                              </div>
                              <div>
                                <h6 className="fw-normal text-uppercase text-muted fs-m"><small>SPECIAL INSTRUCTIONS
                                </small></h6>
                                <p className="">{state.shipmentDetail.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>




              </div>



              <div className="col-3">
                <section className="pt-2">
                  <div className="card shadow-sm mb-4">
                    <div className="card-body text-center">
                      <small>Photos</small>
                      <div className="row">
                        <div className="col-12 col-md-12">
                          {state.shipmentDetail.item_imgs.map((items, indexItem) => (
                            <div key={indexItem}><a href=""><img src={`https://shippxcdn.fniix.com/web/${items}`} className="mb-2 img-thumbnail" /></a></div>
                          ))}
                          {/* <a href=""><img src="img/shipment/0.jpg" className="mb-2 img-thumbnail" /></a>
                          <a href=""><img src="img/shipment/1.jpg" className="mb-2 img-thumbnail" /></a>
                          <a href=""><img src="img/shipment/2.jpg" className="mb-2 img-thumbnail" /></a>
                          <a href=""><img src="img/shipment/3.jpg" className="mb-2 img-thumbnail" /></a>
                          <a href=""><img src="img/shipment/4.jpg" className="mb-2 img-thumbnail" /></a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="card shadow-sm">
                    <div className="card-body">

                      <div className="d-flex justify-content-between p-3">
                        <div className="p-2 flex-fill"><small>LOWEST <br />QUOTE</small></div>
                        <div className="p-2 flex-fill fs-2 f-w-500">${state.shipmentDetail.lowest_quote}</div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between p-3 text-center">
                        <div className="p-2 flex-fill border-end">
                          <h6><small className="text-muted fw-light">Watching</small></h6>
                          <p className="text-center text-primary"><i className="fa fa-eye " aria-hidden="true"></i> 6 </p>
                        </div>
                        <div className="p-2 flex-fill">
                          <h6><small className="text-muted fw-light">Quotes</small></h6>
                          <p className="text-center text-primary"><i className="fa fa-first-order " aria-hidden="true"></i> {state.shipmentDetail.total_quotes}</p>
                        </div>
                      </div>


                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>

        </div>
      </section>
      <Footer />
    </>

  )
}

export default Shipment_detail_user;