import React, { useState ,useEffect} from 'react'
import Footer from './Footer'
import Header from './Header'
import exportValue from "../apiconfig";
import axios from 'axios';
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Add_vehicle = () => {
  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
  
    //navigate('/home') 
  }
  let navigate = useNavigate();
  const[state,setState] = useState({category_attributes:[]})

  const[vehicleCategory,setVehicleCategory] = useState({vehicleNumber:""})

  const[detailstate,setDetailState]=useState({transporter_detail:[]})

  const shipmentDetail = () => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/transporter_detail`;
    let sendData = { transporter_id:userdata.transporter_id};
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
    
      if (res.data.output.length == 1) {
        setDetailState({ ...detailstate, transporter_detail:res.data.output, detailFound:true,isLoading:false })
      
     
    }
    else {
      setDetailState({...detailstate,isLoading:false})
    }
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
     
    });
  };
  useEffect(() => {
    shipmentDetail();
  }, [])

  const vehicle_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/vehicle/vehicle_category_list`;
    let sendData = {};

    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setState({...state,category_attributes:res.data.output});
      console.log("res",res)
     
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
   
    })
  }
  
  React.useEffect(() => {
       vehicle_detail();
  }, [])

  const changeSelectedData = (e) => {
      
       let names = e.target.name;
       console.log("names",e.target.name)
       let value = e.target.value
       console.log("value",e.target.value)

    setVehicleCategory({...vehicleCategory,[names]:value})
    console.log("vehicle",vehicleCategory)
  }

  const handleChange = (e) => {
     setVehicleCategory({...vehicleCategory,vehicleNumber:e.target.value})
  }

  const axios_get_api = (e) => {
        e.preventDefault()
       let transporter= userdata.transporter_id;
        if(transporter!==undefined){
      
      if(userdata.transporter_id!=""&& vehicleCategory.vehicleNumber!="" && vehicleCategory.model!="" && vehicleCategory.type!="" && vehicleCategory.wheel!=""){
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/vehicle/add_transporter_vehicle`;
        let sendData = {vehicleCategory,transporter_id:userdata.transporter_id};

        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
         // setState(res.data.output);
         if(res && res.data != undefined){
         toast.configure()
         toast(res.data.message)
         navigate('/vehicle_list')
         }
       
        
        }).catch((e) => {
           toast.configure()
           toast.error("Some thing went wrong")
         
        })
      }
      
      else{
        
        toast.configure()
           toast.error("Fill All The Inputs")
          }
        }
        else{
          toast.configure()
          toast.error("You are not allowed yet! Please First verify your Transporter account")
          navigate("/profile_update_carrier")

        }
      
  }
  
  
 return (
    <div>
        <Header/><section class="bg-light p-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-2">&nbsp;</div>
        <div class="col-12 col-md-8 bg-white p-5">{detailstate.transporter_detail.map((sub)=>(
            sub.dstatus==3?
            <div class="alert alert-danger text-center" role="alert">
        Your Account is not Verified
          </div>
        :""  ))}
          <h3 class="text-uppercase">Add Vehicle</h3>
  
          <hr/>
          <section>
            <form onSubmit={(e) => axios_get_api(e)}>

             
            <div class="form-floating mb-3 mt-3  ">
           
            <label for="email" className="d-block d-sm-none mt-1" style={{fontSize:"10px"}}>Enter Vehicle Number</label>
              <input
                type="text"
                class="form-control"
                name="vehicleNumber"
                value={vehicleCategory.vehicleNumber}
                placeholder="Enter Vehicle Number"
                onChange={(e) => handleChange(e)}
                required
              />
              <label for="email" className="d-none d-sm-block">Enter Vehicle Number</label>
            </div>
            

          
           


           
  {state.category_attributes.map((sub)=>( 
            <div class="mt-3">
               <label for="email" class="form-label">{sub.category_name}</label> 
              <select class="form-select form-select-lg" name={sub.category_name} onChange={(e)=>{changeSelectedData(e)}}>
              <option value="">Select</option>
                {(sub.subcategory != undefined && sub.subcategory.length > 0) ? sub.subcategory.map((subscriber) => {
                  return( <>
                   <option value={subscriber.subcategory_name}>{subscriber.subcategory_name}</option>
                   </>
                  )
                }) 
                :""}
                
              </select>
            </div>
           ))} 
  
          
  
          
            
            <div class="mt-3">
             <Button variant="primary" type="submit">Add Vehicle</Button>
          </div>
            </form>
          </section>
          
        </div>
        <div class="col-12 col-md-2">&nbsp;</div>
      </div>
    </div>
  </section>
  <Footer/>
  </div>
  )
}

export default Add_vehicle