import React, { useEffect, useState } from "react";
import { useLocation,useParams, Link } from "react-router-dom";
import { GoogleMap, useJsApiLoader,LoadScript,Marker,DirectionsRenderer } from '@react-google-maps/api';
const google = window.google;
const containerStyle = {
  width: '100%',
  height: '400px'
};



const Mapsecond = (props)=> {
  console.log("props ==   ",props);
  const {cordi} = useParams();
  let [directions, setDirections] = useState({});
  const DirectionsService = new google.maps.DirectionsService();
  //props.data[0].icon = "/img/map-marker.png";
  let origin = props.data[0]
  delete  origin._id 
  console.log("origin ==   ",origin);
  DirectionsService.route(
    {
      origin:  origin,
      destination:  props.data[1],
      travelMode: google.maps.TravelMode.DRIVING,
    },
    (result, status) => {
      console.log("status =>  ",status);
      console.log(result);
      if (status === google.maps.DirectionsStatus.OK && result != null) {
        setDirections(result);
      } else {
        console.error(`error fetching directions ${result}`);
      }
    }
  );


 // useEffect(() => {
    
    // const directionsService = new google.maps.DirectionsService();

    // const origin = { lat: 6.5244, lng:  3.3792 };
    // const destination = { lat: 6.4667, lng:  3.4500};

    // directionsService.route(
    //     {
    //         origin: origin,
    //         destination: destination,
    //         travelMode: google.maps.TravelMode.DRIVING,
    //         waypoints: [
    //             {
    //                 location: new google.maps.LatLng(6.4698,  3.5852)
    //             },
    //             {
    //                 location: new google.maps.LatLng(6.6018,3.3515)
    //             }
    //         ]
    //     },
    //     (result, status) => {
    //         if (status === google.maps.DirectionsStatus.OK) {
    //             console.log(result)
    //             this.setState({
    //                 directions: result
    //             });
    //         } else {
    //             console.error(`error fetching directions ${result}`);
    //         }
    //     }
    // );
    
  //}, [])
  //console.log("second map  ",props.data);
    // const { isLoaded } = useJsApiLoader({
    //   id: 'shipxx-11052022',
    //   googleMapsApiKey: 'AIzaSyD6BJ4wBm-MrZOt8EfM7kGZq1NjNKPvzeo'
    // })

  // const [map, setMap] = React.useState(null);
  
  // const center = {
  //   lat: props.data[0].lat,
  //   lng: props.data[0].lng
  // };
  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  return  (
    
    <GoogleMap
    
      mapContainerStyle={containerStyle}
      //center={center}
      zoom={10}
      
    >

     {/* {props.data.map((mark,index) => ( <Marker key={Math.random()+''+index} position={{lat:mark.lat,lng:mark.lng}}/>))} */}
      { /* Child components, such as markers, info windows, etc. */ }
      {directions && <DirectionsRenderer directions={directions} />}
    </GoogleMap>
  
  ) 
}

export default Mapsecond