import React, { useState, useEffect,createRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import Button from "react-bootstrap/Button";
import exportValue from "../apiconfig";
import Header from "./Header";
import Footer from "./Footer";
const Forgot_otp_verify = () => {

    let navigate = useNavigate();
    const location = useLocation();
    console.log("location", location);
    const [state, setState] = useState({ email: location.state.email, otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "", password_con: "", password: "" });
    const [focinp,setfocinp] = useState({otp2:createRef(),otp3:createRef(),otp4:createRef(),otp5:createRef(),otp6:createRef()})
    
    const handleChange = (event,focus) => {
        //console.log(event);
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        if(focus) {
            focinp['otp'+focus].current.focus();
        }

    }

    const axios_verify_api = (event) => {
        event.preventDefault();
        if (state.password_con == state.password) {
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/forgot_otp_verification`;
            let fullOtp = state.otp1 + "" + state.otp2 + "" + state.otp3 + "" + state.otp4 + "" + state.otp5 + "" + state.otp6
            let sendData = { email: state.email, otp: fullOtp, new_password: state.password_con };
           // console.log(sendData);
            Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
                console.log(res);
                if (res.data.status == "success") {
                    toast.configure();
                    toast.success(res.data.message);
                    navigate('/login')
                } else {
                    toast.configure();
                    toast.error(res.data.message);
                }

            }).catch((e) => {
                //setState({...state,isLoading: false});

                toast.configure();
                toast.error("Some thing went wrong");
                console.log("----error:   ", e);
            });
        }else {
            toast.configure();
            toast.error("Confirm password not match");
        }

    }

    const Otp_resend = () => {

    }
    return (
        <div>
            <Header/>
            <section className=" bg-light p-5 ">
                <div className="container ">
                    <div className="row">
                        <div className="col-12 col-md-3">
                            &nbsp;
                        </div>
                        <div className="col-12 col-md-6 bg-white p-5">
                            <h3 className="text-uppercase">Forgot password Reset</h3>
                            <p>Enter the code we just sent on your email address <strong className="text-primary">{state.email}</strong></p>
                            <form onSubmit={(e) => axios_verify_api(e)}>
                                <div className="row">
                                    <div className="col">
                                        <div className="mb-3 mt-3">
                                            <input type="text" maxLength="1" className="form-control form-control-lg" id="" placeholder="X" required step="1" name="otp1" onChange={(e) => handleChange(e,2)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mb-3 mt-3">
                                            <input type="text" maxLength="1" required className="form-control form-control-lg" id="" placeholder="X" name="otp2" ref={focinp.otp2} onChange={(e) => handleChange(e,3)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mb-3 mt-3">
                                            <input type="text" maxLength="1" required className="form-control form-control-lg" id="" placeholder="X" name="otp3" ref={focinp.otp3} onChange={(e) => handleChange(e,4)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mb-3 mt-3">
                                            <input type="text" maxLength="1" required className="form-control form-control-lg" id="" placeholder="X" name="otp4" ref={focinp.otp4} onChange={(e) => handleChange(e,5)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mb-3 mt-3">
                                            <input type="text" maxLength="1" required className="form-control form-control-lg" id="" placeholder="X" name="otp5" ref={focinp.otp5} onChange={(e) => handleChange(e,6)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mb-3 mt-3">
                                            <input type="text" maxLength="1" required className="form-control form-control-lg" id="" placeholder="X" name="otp6" ref={focinp.otp6} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-floating mb-3 mt-3">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter email"
                                                name="password"
                                                required
                                                autoComplete="off"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <label htmlFor="email">Password</label>
                                        </div>
                                        <div className="form-floating mb-3 mt-3">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter email"
                                                name="password_con"
                                                required
                                                autoComplete="off"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <label htmlFor="email">Confirm password</label>
                                        </div>
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                                <div className=""><Button className="btn btn-primary" type="submit">Verify</Button></div>
                            </form>
                            <p className="mt-4">
                                Didn't receive the code? <a className="btn" onClick={() => Otp_resend()}>Resend</a>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-2">
                        &nbsp;
                    </div>
                </div>

            </section >
            <Footer/>
        </div >
    )
}

export default Forgot_otp_verify