import React, { useState, useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import MenuCustomer from './MenuCustomer';
import Footer from './Footer';
import exportValue from "../apiconfig";
import Header from "./Header";




const Settings = () => {
  let navigate = useNavigate();
  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log("userData  -  ",userdata);
    //navigate('/home') 
  }
  const [state, setState] = useState({user_id:userdata.user_id, full_name: "", email: "", mob_number: "",account_type:"", old_password: "", new_password: "", confirm_password: "",profile_image:"" });
  const [addimg,setAddimg] = useState({uploadImage:""});
  
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    if (localStorage.getItem('loggedIn')) {
      let userDetail = JSON.parse(localStorage.getItem('loggedIn'));
      setState({...state,user_id:state.user_id, full_name: userDetail.full_name, email: userDetail.email, mob_number: userDetail.mob_number,account_type: userDetail.account_type,profile_image:userDetail.profile_image})
    }
  }

  const handleChange = (event) => {
    console.log(event);
    if (event.target.type == "checkbox") {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
    }
     
    
    
     else {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    }
  }

  const handleChangeImage =(event)=>{
    console.log("event",event)
    if(event.target.type == "file"){
      setState({
        ...state,
        [event.target.name]: event.target.files,
      })
      
//        if(event.target.name=="profile_image"){
//         const selectedFilesArray=Array.from(event.target.files);
// console.log("selectedFilesArray     ",selectedFilesArray);
//      const imagesArray = addimg.uploadImage;
//      console.log("seleceted",imagesArray)

//    selectedFilesArray.map((file)=>{
//      imagesArray.push(URL.createObjectURL(file))
//       })
  

//     setAddimg({...addimg,uploadImage:imagesArray});
// }
if(event.target.name == "profile_image") {
  setAddimg({...addimg,uploadImage:URL.createObjectURL(event.target.files[0])})
  console.log("set",addimg)
}
    }else{
 

      setState({
        ...state,
        [event.target.name]: event.target.value,
      })
     
  
    }

  }
    
  

  const axios_get_api = (event) => {
    event.preventDefault();
    let bodyFormData = new FormData();
   
    let sendData = state;
    bodyFormData.append('setValue', JSON.stringify(state));
    console.log("profile_img   -->   ",state.profile_image);
    if(state.profile_image.length > 0){
      bodyFormData.append("profile_image", state.profile_image[0])
    }
    let flag = true;
    if (state.old_password != "" && state.new_password != "" && state.confirm_password != "") {
      if (state.new_password != state.confirm_password) {
        flag = false;
      }else {
        console.log("Password not match");
      }

    }
    console.log(bodyFormData);
   
    if (flag) {
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/profile_update`;
      Axios.post(full_api, bodyFormData, { headers: exportValue.headers }).then((res) => {
        console.log("res",res);
        setState({...state,old_password:"",new_password:"",confirm_password:""});
        if (res.data.output.length == 1) {
          // console.log("response",res.data.output)
          // toast.configure();
          // toast.message("Updated Successfully")
           localStorage.setItem('loggedIn', JSON.stringify(res.data.output[0]));
          //  setState(res.data.output[0])
          console.log("---------------------------");
          
          
          window.location.reload();
        }else{
          toast.configure();
          toast.error("Some  wrong");
        
        }
       
      })
        .catch((e) => {
          // setState({...state,isLoading: false});

          toast.configure();
          toast.error("Some thing went wrong");
          console.log("----error:   ", e);
        });
    }else {

    }


  };
  const removeImage = () => {
    console.log("on cick");
    // setState({...state,logo:""})
    setAddimg({...addimg,uploadImage:""})
    setState({
          ...state,
         profile_image:"" ,
      });
  }

  return (
    <>

      <Header />

      <section className="sub_heading p-5 text-black">
        <div className="container">
          <div className="row">
            <div className="col">
              <h5>Settings</h5>
            </div>
          </div>
        </div>
      </section>


      <section className="bg-light p-5">
        <div className="container">

          <div className="row">
            <h4>Update Account</h4>
            <form autoComplete="off" onSubmit={(e)=> axios_get_api(e)}>
              <div className="col">
                <div className="pb-2 pt-3">Account Type:</div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="account_type"
                    id="inlineRadio1"
                    value="Personal"
                    checked={state.account_type == "Personal"}
                    onChange={(e) => handleChange(e)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">Personal</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="account_type"
                    id="inlineRadio2"
                    value="Business"
                    checked={state.account_type == "Business"}
                    onChange={(e) => handleChange(e)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">Business</label>      
                  </div>
              </div>
              <hr />
              <h4>Update Profile</h4>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Enter name"
                  name="full_name"
                  value={state.full_name}
                  required
                  onChange={(e) => handleChange(e)}
                />
                <label htmlFor="email">Full Name</label>
              </div>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter email"
                  name="email"
                  value={state.email}
                  required
                  disabled
                  autoComplete="off"
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="number"
                  className="form-control"
                  id="email"
                  placeholder="Enter phone"
                  name="mob_number"
                  value={state.mob_number}
                  required
                  onChange={(e) => handleChange(e)}
                />
                <label htmlFor="email">Phone</label>
              </div>
              <hr />
              <h4>Upload Profile Image</h4>
              {(!state.profile_image  && !addimg.uploadImage ) ? 
                        <label   style={{margin:0,display:"flex",flexdirection:"column",justifyContent:"center",border:"1px dotted black",borderRadius:"10px",width:"8rem",height:"8rem",cursor:"pointer",fontSize:"large"}}> 
                       <i className="fa fa-plus mx-3 text-muted" style={{fontSize:"50px",marginTop:"35px"}} aria-hidden="true"></i>
                       <input type="file"  id="" placeholder="photos" name="profile_image" onChange={(e) => handleChangeImage(e)} style={{display:"none"}}/>
                     </label> 

                       
                        
                         :
                         <div style={{width:"170px",marginRight:"7px"}} className="imageLogo">
                           <div className="overlay"> 
                           <i className="fa fa-trash-o icon" onClick={()=>removeImage()}></i>
                           </div>
                           {(state.profile_image ) ?
                        <img src={"https://shippxcdn.fniix.com/web/"+state.profile_image} style={{width:"100%",height:"200px"}} className="mt-3"/> 
                        :(addimg.uploadImage )?
                        <img src={addimg.uploadImage} style={{width:"100%",height:"200px"}} className="mt-3"/> 
                        :""
                           }
                        </div>

                      }
              <hr/>
              <h4>Update Password</h4>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="password"
                  className="form-control"
                  id="email"
                  placeholder="Enter password"
                  name="old_password" 
                  value={state.old_password}                
                  onChange={(e) => handleChange(e)}
                />
                <label htmlFor="email">Current Password</label>
              </div>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="password"
                  className="form-control"
                  id="email"
                  placeholder="Enter password"
                  name="new_password"  
                  value={state.new_password}               
                  onChange={(e) => handleChange(e)}
                />
                <label htmlFor="email">New Password</label>
              </div>
              <div className="form-floating mb-3 mt-3">
                <input
                  type="password"
                  className="form-control"
                  id="email"
                  placeholder="Enter password again"
                  name="confirm_password" 
                  value={state.confirm_password}                 
                  onChange={(e) => handleChange(e)}
                  
                />
                <label htmlFor="email" >Confirm New Password</label>
              </div>

              <div className="col mt-5">
                <input
                  className="btn btn-primary"
                  type="submit"
                  value="Update"
                />
              </div>
            </form>
          </div>

        </div>
      </section>
      <Footer />
    </>
  )
}

export default Settings;
