import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const NotificationComponent = ({noti}) => {
  toast.info(<Display />);
  function Display() {
    return (
      <div>
        <h4>{noti.title}</h4>
        <p>{noti.body}</p>
      </div>
    );
  }
  return (
    <ToastContainer />
  );
};
export default NotificationComponent;