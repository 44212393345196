import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import axios from "axios";
import exportValue from "../apiconfig";

import ReactLoading from 'react-loading';
import {isMobile} from 'react-device-detect';
//console.log("isMobile=> ",isMobile);

const Footer = () => {
    let navigate = useNavigate();
    const location = useLocation();
 // console.log("location==>",location)
    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
     // console.log("userData  -  ",userdata);
      //navigate('/home') 
    }
    const [icon, setIcon] = useState({media:[]})
const[categories,setCategories]=useState({category_list:[],dstatus: 1,subcategoryList: [],isLoading:true})
const[company,setCompany]=useState({companyDetail:[],dstatus:1})
//const[state,setState]=useState({cms_list:[]})
    const cat_subcat_detail = () =>{
       
            let data = { category_list: {},  dstatus: 1 };
           
    
           
            
    
          
    
            axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/shipment_list`, data, { headers: exportValue.headers }).then((res) => {
                
                setCategories({ ...categories,  category_list: res.data.category_output, isLoading: false })
            })
        }
        
    
useEffect(()=>{
    cat_subcat_detail()
    social_medial_data()
    company_info()
},[])

const social_medial_data = () =>{
    let data = {dstatus:1};
    axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/social_media_list_api`, data, { headers: exportValue.headers }).then((res) => {
    
       
        let getData = res.data.media_list;
    //    console.log("getdata",getData)
        
      
        setIcon({...icon,media:getData})
       
    })
  }

  const company_info = () =>{
       
    let data = { companyDetail: {},  dstatus: 1 };
   

    axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/get_company_info_api`, data, { headers: exportValue.headers }).then((res) => {
       
        setCompany({ ...company,  companyDetail: res.data.companyDetail, isLoading: false })
    })
}



    return (
    <>

    <footer className="">
        {(!isMobile)?
        <>
     <section className="footer_f_1 mt-5 pb-3  footer_f">
            <div className="container ">
            <div className="row">
                <div className="col">
                <h2 className="">Cost to Ship</h2>
                <ul className="nav footer_f_11">
                   
        { categories.category_list.map((subscriber) => (

                    
                    <li className="nav-item">
                     <Link to={`/shipment-list?cId=%5B%5D`}> <a className="nav-link" >{subscriber.category_name}</a></Link>
                    </li>))}
                   
                </ul>
                </div>
            </div>
            </div>
        </section>

        <section className="footer_f_1 mt-3 pb-3 footer_f">
            <div className="container ">
            <div className="row">
                <div className="col">
                <ul className="nav footer_f_11">
                    <li className="nav-item">
                    <Link to="/company"  className="text-decoration-none"><a className="nav-link" >COMPANY</a></Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/careers"  className="text-decoration-none"><a className="nav-link" >
                        CAREERS</a></Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/press"  className="text-decoration-none"><a className="nav-link" >
                        PRESS</a></Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/blog"  className="text-decoration-none"><a className="nav-link" >
                        BLOG</a></Link>
                    </li>
                </ul>
                </div>
                
                <div className="col">
                <ul className="nav justify-content-end">
                    {icon.media.map((sub)=>(
                    <li className="nav-item">
                   <a className="nav-link" href={sub.media_url}><i className={sub.media_icon} aria-hidden="true"></i></a>
                    </li>))}
                    
                </ul>
                </div>
            </div>
            </div>
        </section>

        <section className=" mt-5 pb-3  ">
            <div className="container ">
            <div className="row">
                <div className="col">
             {company.companyDetail.map((sub)=>(
                <div><small className="text-muted">Copyright © {new Date().getFullYear()}, {sub.company_name}. All rights reserved.</small></div>
             ))}   
                <ul className="nav footer_f_11">
                    <li className="nav-item">
                    <Link to="/user_agreement"  className="text-decoration-none"><a className="nav-link "  >User Agreement
                        </a></Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/privacy_policy" className="text-decoration-none"><a className="nav-link" >Privacy Policy</a>
                      </Link></li>
                    <li className="nav-item">
                    <Link to="/site_map" className="text-decoration-none"><a className="nav-link" >
                        Site Map</a></Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/cookie_policy" className="text-decoration-none"><a className="nav-link" >
                        Cookie Policy</a></Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/help" className="text-decoration-none"><a className="nav-link">
                        Help</a></Link>
                    </li>
                </ul>
                </div>
            </div>
            </div>
        </section>
        </>
        :""
}
        </footer>

    </>
   )
}

export default Footer;
