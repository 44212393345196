import './components/chat.css';
import React, { useState, useEffect } from "react";
import Axios from "axios";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import ReactLoading from 'react-loading';

import exportValue from "../apiconfig";
import Header from "./Header";
import MenuGuest from './MenuGuest';
import Footer from "./Footer";
import io from 'socket.io-client';

const ENDPOINT = exportValue.host;
let userdata = {};
if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
  //  console.log("userData  - chat ", userdata);

}



//let socket = io(ENDPOINT, { query: { id: userdata.user_id, token: "456" } });
const ChatHistory = () => {


    const { other_id } = useParams();
    const [state, setState] = useState({ message: "", otheruserId: "", loggedUser: {},currentStatus:"online",chats:[] });
    const [chats, setChats] = useState([]);

 


   // useEffect(() => {

        
       // user_chat_history();
        // socket.on('connect', () => {
        //     console.log("connected");
          
        //     //setIsConnected(true);
        // });

        // socket.on('disconnect', () => {
        //     console.log("disconnected");
        //     // setIsConnected(false);
        // });

        // socket.on('pong', () => {
        //     // setLastPong(new Date().toISOString());
        // });

        // socket.on('SINGLE_CHAT_SEND_MESSAGE', message => {
        //     console.log("messsage receive =>     ", message);
        //    // let old_chats = chats;
        //    // old_chats.push(message);
        //    //setChats(old_chats);
    
        // })

        
        
       // return () => {
            ///socket.off('connect');
           // socket.off('disconnect');
            //socket.off('pong');
       // };

    //}, []);

 


    const user_detail = () => {
        console.log("user_detail");
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/profile_open`;
        let sendData = { user_id: userdata.user_id };

        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            //setState({ ...state, loggedUser: res.data.output[0] });
            console.log("rnmmmmm", res);
           
        }).catch((e) => {
            // toast.configure()
            //toast.error("Some thing went wrong")
            console.log("----error:   ", e);
        })
    }

    const user_chat_history = () => {
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/chat/chat_history`;
        let sendData = { sender: other_id, receiver: userdata.user_id };

        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setState({ ...state, chats: res.data.output });
            console.log("rnmmmmm", res);
        }).catch((e) => {
            // toast.configure()
            //toast.error("Some thing went wrong")
            console.log("----error:   ", e);
        })
    }

    const handleChange = (event) => {
       // console.log(event);
        
            setState({
                ...state,
                [event.target.name]: event.target.value,
            });
        
    };


    const sendMessage = () => {
        console.log(state.message);
        let chatMessage = { sender: userdata.user_id, receiver: other_id, message_type: 1, message: state.message };
     //   socket.emit('SINGLE_CHAT_SEND_MESSAGE', chatMessage);
       // let old_chats = chats;
       // old_chats.push(chatMessage);
       // setChats(old_chats);
       // setState({ ...state, message: "" });

      

    };

    useEffect(() => {
        user_detail()
      }, []);
    
    return (
        <>
        <Header/>
            <div className="app-container chatScreen">

                <div className="app-left">
                    <div className="app-left-header">
                        <div className="app-logo">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <defs />
                                <path className="path-1" fill="#3eb798" d="M448 193.108h-32v80c0 44.176-35.824 80-80 80H192v32c0 35.344 28.656 64 64 64h96l69.76 58.08c6.784 5.648 16.88 4.736 22.528-2.048A16.035 16.035 0 00448 494.868v-45.76c35.344 0 64-28.656 64-64v-128c0-35.344-28.656-64-64-64z" opacity=".4" />
                                <path className="path-2" fill="#3eb798" d="M320 1.108H64c-35.344 0-64 28.656-64 64v192c0 35.344 28.656 64 64 64v61.28c0 8.832 7.168 16 16 16a16 16 0 0010.4-3.84l85.6-73.44h144c35.344 0 64-28.656 64-64v-192c0-35.344-28.656-64-64-64zm-201.44 182.56a22.555 22.555 0 01-4.8 4 35.515 35.515 0 01-5.44 3.04 42.555 42.555 0 01-6.08 1.76 28.204 28.204 0 01-6.24.64c-17.68 0-32-14.32-32-32-.336-17.664 13.712-32.272 31.376-32.608 2.304-.048 4.608.16 6.864.608a42.555 42.555 0 016.08 1.76c1.936.8 3.76 1.808 5.44 3.04a27.78 27.78 0 014.8 3.84 32.028 32.028 0 019.44 23.36 31.935 31.935 0 01-9.44 22.56zm96 0a31.935 31.935 0 01-22.56 9.44c-2.08.24-4.16.24-6.24 0a42.555 42.555 0 01-6.08-1.76 35.515 35.515 0 01-5.44-3.04 29.053 29.053 0 01-4.96-4 32.006 32.006 0 01-9.28-23.2 27.13 27.13 0 010-6.24 42.555 42.555 0 011.76-6.08c.8-1.936 1.808-3.76 3.04-5.44a37.305 37.305 0 013.84-4.96 37.305 37.305 0 014.96-3.84 25.881 25.881 0 015.44-3.04 42.017 42.017 0 016.72-2.4c17.328-3.456 34.176 7.808 37.632 25.136.448 2.256.656 4.56.608 6.864 0 8.448-3.328 16.56-9.28 22.56h-.16zm96 0a22.555 22.555 0 01-4.8 4 35.515 35.515 0 01-5.44 3.04 42.555 42.555 0 01-6.08 1.76 28.204 28.204 0 01-6.24.64c-17.68 0-32-14.32-32-32-.336-17.664 13.712-32.272 31.376-32.608 2.304-.048 4.608.16 6.864.608a42.555 42.555 0 016.08 1.76c1.936.8 3.76 1.808 5.44 3.04a27.78 27.78 0 014.8 3.84 32.028 32.028 0 019.44 23.36 31.935 31.935 0 01-9.44 22.56z" />
                            </svg>
                        </div>
                        <h1>QuickChat</h1>
                    </div>
                    <div className="app-profile-box">
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80" alt="profile" />
                        <div className="app-profile-box-name">
                            {state.loggedUser.full_name}

                        </div>
                        <p className="app-profile-box-title">UI Designer</p>
                        <div className="switch-status">
                            {/* <input type="checkbox" name="switchStatus" id="switchStatus" checked/> */}
                            {/* <label className="label-toggle" htmlFor="switchStatus"></label> */}
                                                  
                            {/* <span className="toggle-text toggle-online">Online</span> */}
                        
                             <span className="toggle-text toggle-offline">{state.currentStatus}</span> 
                        </div>
                    </div>
                    <div className="chat-list-wrapper">
                        <div className="chat-list-header">Active Conversations <span className="c-number">4</span>
                            <svg className="list-header-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" viewBox="0 0 24 24">
                                <defs />
                                <path d="M18 15l-6-6-6 6" />
                            </svg>
                        </div>
                        <ul className="chat-list active">
                            <li className="chat-list-item active">
                                <img src="https://images.unsplash.com/photo-1587080266227-677cc2a4e76e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80" alt="chat" />
                                <span className="chat-list-name">Dwight Schrute</span>
                            </li>
                            <li className="chat-list-item">
                                <img src="https://images.unsplash.com/photo-1566465559199-50c6d9c81631?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80" alt="chat" />
                                <span className="chat-list-name">Andy Bernard</span>
                            </li>
                            <li className="chat-list-item">
                                <img src="https://images.unsplash.com/photo-1562788869-4ed32648eb72?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=80" alt="chat" />
                                <span className="chat-list-name">Michael Scott</span>
                            </li>
                            <li className="chat-list-item">
                                <img src="https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80" alt="chat" />
                                <span className="chat-list-name">Holy Flax</span>
                            </li>
                            <li className="chat-list-item">
                                <img src="https://images.unsplash.com/photo-1583864697784-a0efc8379f70?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE1fHx8ZW58MHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60" alt="chat" />
                                <span className="chat-list-name">Jim Halpert</span>
                            </li>
                        </ul>
                    </div>
                    <div className="chat-list-wrapper">
                        <div className="chat-list-header active">Achived Conversations <span className="c-number">3</span>
                            <svg className="list-header-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" viewBox="0 0 24 24">
                                <defs />
                                <path d="M18 15l-6-6-6 6" />
                            </svg>
                        </div>
                        <ul className="chat-list">
                            <li className="chat-list-item">
                                <img src="https://images.unsplash.com/photo-1542042457485-a4c7afd74cd5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1270&q=80" alt="chat" />
                                <span className="chat-list-name">Toby Flenderson</span>
                            </li>
                            <li className="chat-list-item">
                                <img src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjV8fHdvbWFufGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60" alt="chat" />
                                <span className="chat-list-name">Kelly Kapoor</span>
                            </li>
                            <li className="chat-list-item">
                                <img src="https://images.unsplash.com/photo-1528763380143-65b3ac89a3ff?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDExfHx8ZW58MHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60" alt="chat" />
                                <span className="chat-list-name">Roy Andersson</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="app-main">
                    <div className="chat-wrapper">
                        {state.chats.map((value, index) =>
                            (value.sender == userdata.user_id) ?
                                <div className="message-wrapper reverse" key={index}>
                                    <img className="message-pp" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80" alt="profile-pic" />
                                    <div className="message-box-wrapper">
                                        <div className="message-box">
                                            {value.message}
                                        </div>
                                        <span>9h ago</span>
                                    </div>
                                </div>
                                :
                                <div className="message-wrapper" key={index}>
                                    <img className="message-pp" src="https://images.unsplash.com/photo-1587080266227-677cc2a4e76e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=934&amp;q=80" alt="profile-pic" />
                                    <div className="message-box-wrapper">
                                        <div className="message-box">
                                            {value.message}
                                        </div>
                                        <span>9h ago</span>
                                    </div>
                                </div>
                        )}








                    </div>
                    <div className="chat-input-wrapper">
                        <button className="chat-attachment-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="feather feather-paperclip" viewBox="0 0 24 24">
                                <defs />
                                <path d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48" />
                            </svg>
                        </button>
                        <div className="input-wrapper">
                            <input type="text" className="chat-input" placeholder="Enter your message here" name="message" onChange={(e)=> handleChange(e)} value={state.message} />
                            <button className="emoji-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="feather feather-smile" viewBox="0 0 24 24">
                                    <defs />
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M8 14s1.5 2 4 2 4-2 4-2M9 9h.01M15 9h.01" />
                                </svg>
                            </button>
                        </div>
                        <button className="chat-send-btn" onClick={()=> sendMessage()}>Send</button>
                    </div>
                </div>




                {/* <input type="text" name="message" onChange={(e) => handleChange(e)} value={state.message} placeholder="message"/>
<Button onClick={()=>sendMessage()}>send</Button> */}
            </div>
            
        </>
    )
}
export default ChatHistory;