  import React, { useState, useEffect,createRef } from "react";
  import { toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import { useNavigate,useLocation } from "react-router-dom";
  import Axios from "axios";
  import Button from "react-bootstrap/Button";
  import exportValue from "../apiconfig";
  import Header from "./Header";
  import Footer from "./Footer";

  const Verify = () => {
    let navigate = useNavigate();
    const location = useLocation();
    console.log("location",location);
    //location.state.email
    const [state, setState] = useState({ email: location.state.email,otp1:"",otp2:"",otp3:"",otp4:"",otp5:"",otp6:"",otp:"" })
    const [focinp,setfocinp] = useState({otp2:createRef(),otp3:createRef(),otp4:createRef(),otp5:createRef(),otp6:createRef()})
    // const handleChangeBroker = (event,focus) => {
    //   console.log(event);
    //   setState({
    //       ...state,
    //       [event.target.name]: event.target.value,
    //     });
    //     if(focus) {
    //       focinp['otp'+focus].current.focus();
    //   }
      
    // }

    const handleChangeBroker = (event) => {
      setState({
               ...state,
               [event.target.name]: event.target.value,
             });

    }

    const axios_registration_api = (event) => {
      event.preventDefault();
      if (state.email != '') {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/otp_verification`;
       // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
        let sendData = { email: state.email, otp: state.otp };
        if(location.state.temp_id) {
          sendData.temp_id = location.state.temp_id;
        }
        console.log(sendData);
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          console.log("response11",res);
          if (res.data.output.length == 1) {
          
            //sessionStorage.setItem("loggedIns", JSON.stringify(res.data.output[0]));
            localStorage.setItem('loggedIn', JSON.stringify(res.data.output[0]));
            console.log("---------------------------",res.data.output[0].user_type);
            if(res.data.output[0].user_type == 2 && res.data.output[0].transporter_detail == undefined) {

              toast.configure();
              toast.success(res.data.message);
              setTimeout(
                redirectCarrier,3000
              )
            }else {

              toast.configure();
              toast.success(res.data.message);
            setTimeout(
              redirectf,3000
              
            )
            }
    
          }else {
            
            toast.configure();
            toast.error(res.data.message);
            setState({...state,isLoading:false})
          }
          
        }).catch((e) => {
          //setState({...state,isLoading: false});

          toast.configure();
          toast.error("Some thing went wrong");
          console.log("----error:   ", e);
        });
      }
    };

    const redirectf = () => {
      navigate('/')
    }
    const redirectCarrier = () => {
      navigate('/profile_update_carrier')
    }

    const Otp_resend = () => {
      console.log("----otp resend:   ");
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/otp_resend`;
       // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
        let sendData = { email: state.email, otp: state.otp };
        console.log(sendData);
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            toast.configure();
            toast.success(res.data.message);
          }else {
            toast.configure();
            toast.error(res.data.message);
          }
          
        }).catch((e) => {
          //setState({...state,isLoading: false});

          toast.configure();
          toast.error("Some thing went wrong");
          console.log("----error:   ", e);
        });
    }
    return (          
      <div>
        <Header/>
        <section className=" bg-light pad_custom  ">
          <div className="container ">
        
            <div className="row">
              <div className="col-12 col-md-3">
                &nbsp;
              </div>
              <div className="col-12 col-md-6 bg-white p-5">
                <h3 className="text-uppercase">Email Verification</h3>
                <p>Enter the code we just sent on your email address  <strong className="text-primary">{state.email}</strong><br/> If you did not get the code then check the spam folder</p>
                <form onSubmit={(e) => axios_registration_api(e)}>
                  <div className="d-flex ">
                  <input type="text" maxLength="6" className="form-control  " id="" placeholder="XXXXXX" required  style={{width:90}} name="otp" onChange={(e)=>handleChangeBroker(e)}/>
                    
                        {/* <input type="text" maxLength="1" className="form-control  " id="" placeholder="X" required  step="1" name="otp1" onChange={(e)=>handleChangeBroker(e,2)}/>
                    
                    
                        <input type="text" maxLength="1" required className="form-control  " id="" placeholder="X" name="otp2" ref={focinp.otp2} onChange={(e)=>handleChangeBroker(e,3)}/>
                      
                  
                        <input type="text" maxLength="1" required className="form-control " id="" placeholder="X" name="otp3" ref={focinp.otp3} onChange={(e)=>handleChangeBroker(e,4)}/>
                    
                        <input type="text" maxLength="1" required className="form-control  " id="" placeholder="X" name="otp4" ref={focinp.otp4} onChange={(e)=>handleChangeBroker(e,5)}/>
                      
                    
                        <input type="text" maxLength="1" required className="form-control  " id="" placeholder="X" name="otp5" ref={focinp.otp5} onChange={(e)=>handleChangeBroker(e,6)}/>
                      
                  
                        <input type="text" maxLength="1" required className="form-control " id="" placeholder="X" name="otp6" ref={focinp.otp6} onChange={(e)=>handleChangeBroker(e)}/>*/}
                    
                  </div> 

                  <div className="mt-3"><Button className="btn btn-primary" type="submit">Verify</Button></div>
                </form>
                <p className="mt-4">
                  Didn't receive the code? <Button  variant="primary" onClick={()=> Otp_resend()}>Resend</Button>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-2">
              &nbsp;
            </div>
          </div>

        </section >
        <Footer/>
      </div >
    )
  }

  export default Verify