import { SHIPMENT_DETAIL,CATEGORY_ATTRIBUTE_DETAIL,ATTRIBUTE_ADD_SHIPMENT_DETAIL,LOCATION_ADD_SHIPMENT_DETAIL } from '../constants/types'

const initialState = { SHIPMENT_DETAIL : "hello" }

export const ShipmentReducer = (state = initialState, action) => {
    switch(action.type){
        case SHIPMENT_DETAIL :
            return{
               ...state,
               SHIPMENT_DETAIL: action.payload
            }
            default : return state
    }
}

const initialStates = { CATEGORY_ATTRIBUTE_DETAIL:"" }
export const CatAttributeReducer = (state = initialStates,action) => {
     switch(action.type){
        case CATEGORY_ATTRIBUTE_DETAIL :
        //    console.log("action payload is ",action.payload)
            return{
               ...state,
               CATEGORY_ATTRIBUTE_DETAIL: action.payload
            }
            default : return state
     }
}

const initialOne = { ATTRIBUTE_ADD_SHIPMENT_DETAIL:"" }
export const AddShipmentAttributeDetail = (state = initialOne,action) => {
    switch(action.type){
        case ATTRIBUTE_ADD_SHIPMENT_DETAIL :
            
            return{
                ...state,
                ATTRIBUTE_ADD_SHIPMENT_DETAIL:action.payload
            }
            default : return state
    }
}

const initialTwo = {LOCATION_ADD_SHIPMENT_DETAIL:""}
export const AddShipmentLocationDetail = (state = initialTwo,action) => {
    switch(action.type){
        case LOCATION_ADD_SHIPMENT_DETAIL :
            return{
                ...state,
                LOCATION_ADD_SHIPMENT_DETAIL:action.payload
            }
            default : return state
    }
}