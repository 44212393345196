import React from "react";

//<?php include 'header.php';?>

const Categories = () => {
    return (<>

<section className="sub_heading p-5 text-black">
  <div className="container ">
    <div className="row">
      <div className="col ">
        <h5>NEW SHIPMENT</h5>
      </div>
    </div>
  </div>
</section>


<section className=" bg-light p-5 ">
  <div className="container ">
        <h6 className="text-uppercase">Categories</h6>
    <div className="row">
      <div className="col-12 col-md-3">
      <a href="/" className="ship_item_hov">  
      <div className="text-center p-5 ship_item_box">
        <img src="img/cat_icon/vehicles_desktop2x.png" className="img-fluid" />
                <h5 className="">Vehicles &amp; Boats</h5>
                <p className="">Cars, Boats, Motorcycles, RVs, Trailers, Parts</p>
        </div>
        </a>
      </div>
      <div className="col-12 col-md-3">
      <a href="" className="ship_item_hov">  
      <div className="text-center p-5 ship_item_box">
        <img src="img/cat_icon/household-goods_desktop2x.png" className="img-fluid" />
                <h5 className="">Household Items</h5>
                <p className="">Furniture, Appliances</p>
        </div>
        </a>
      </div>
      <div className="col-12 col-md-3">
      <a href="add_shipment.php" className="ship_item_hov">  
      <div className="text-center p-5 ship_item_box">
        <img src="img/cat_icon/moves_desktop2x.png" className="img-fluid" />
                <h5 className="">MOVES</h5>
                <p className="">Apartment, Home, Office</p>
        </div>
        </a>
      </div>
      <div className="col-12 col-md-3">
      <a href="" className="ship_item_hov">  
      <div className="text-center p-5 ship_item_box">
        <img src="img/cat_icon/heavy-equipment_desktop2x.png" className="img-fluid" />
                <h5 className="">HEAVY EQUIPMENT</h5>
                <p className="">Farm, Construction</p>
        </div>
        </a>
      </div>
      <div className="col-12 col-md-3">
      <a href="add_shipment.php" className="ship_item_hov">  
      <div className="text-center p-5 ship_item_box">
        <img src="img/cat_icon/freight_desktop2x.png" className="img-fluid" />
                <h5 className="">FREIGHT</h5>
                <p className="">Less-Than-Truckload, Truckload</p>
        </div>
        </a>
      </div>
      <div className="col-12 col-md-3">
      <a href="add_shipment.php" className="ship_item_hov">  
      <div className="text-center p-5 ship_item_box">
        <img src="img/cat_icon/animals_desktop2x.png" className="img-fluid" />
                <h5 className="">ANIMALS</h5>
                <p className="">Dogs, Cats, Livestock</p>
        </div>
        </a>
      </div>
      <div className="col-12 col-md-3">
      <a href="" className="ship_item_hov">  
      <div className="text-center p-5 ship_item_box">
        <img src="img/cat_icon/other-items_desktop2x.png" className="img-fluid" />
                <h5 className="">OTHERS</h5>
                <p className="">Hay Bales, Waterslides, Jacuzzis, Etc.</p>
        </div>
        </a>
      </div>
      
</div>    
  </div>
</section>
</>)
}

export default Categories;


//<?php include 'footer.php';?>
