import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import exportValue from '../apiconfig';
import  Axios  from 'axios';
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import ReactLoading from 'react-loading';


const Tickets = () => {
    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
      console.log("userData  -  ",userdata);
       
    }

    const[state,setState]=React.useState({ticket_detail:[] , isLoading:true})
    const [shipmentstate, setshipStatus] = useState({ status: null });



    const axios_get_api = (status) => {
 
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/support/ticket_list`;
        
        let sendData = {
          dstatus: 1,
          ticket_createdById:"",
          status:status
        }
        if(userdata.user_type==1){
            if(userdata.user_id) {
                sendData.ticket_createdById = userdata.user_id;
            }}
            else{
                if(userdata.transporter_id) {
                    sendData.ticket_createdById = userdata.transporter_id;
                  }
            }
       
          
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          console.log("-----res-----  ",res);
         setState({...state,ticket_detail:res.data.output , isLoading:false})
          console.log("response", res);
        
    
        }).catch((e) => {
    
          // toast.configure()
          // toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])
      const shipmentFilter = (status) => {
        console.log("action ", status);
        setshipStatus({ ...shipmentstate, status: status })
        axios_get_api(status)
      }
    
  return (
    <div>
<Header/>
{state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:

<section class="bg-light p-5">
  <div class="container ">
       
    <section class="bg-white p-5 mb-2">
      <div class="">
         <h2>Tickets</h2>
      </div>
      <section>
        <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class={(shipmentstate.status == null) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(null)} style={{cursor:"pointer"}} >All</a>
            </li>
            <li class="nav-item">
              <a class={(shipmentstate.status == 0) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(0)} style={{cursor:"pointer"}}>Pending</a>
            </li>
            <li class="nav-item">
              <a class={(shipmentstate.status == 1) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(1)} style={{cursor:"pointer"}}>Answered</a>
            </li>
            <li class="nav-item">
              <a class={(shipmentstate.status == 2) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(2)} style={{cursor:"pointer"}}>Closed</a>
            </li>
          </ul>
      </section>
      
      <div class="row pt-1">
        <div class="col-md-12">
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover">

                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Ticket ID</th>
                    <th>Subject</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {state.ticket_detail.length>0?
                state.ticket_detail.map((sub,index)=>(
                  <tr>
                    <td>{sub.time} / {sub.date} </td>
                    <td><Link to={"/ticket_details/" + sub.ticket_id}>{sub.ticket_id}</Link></td>
                    <td>{sub.details}</td>
                    <td><span class={sub.status==0?"badge bg-warning":sub.status==1?"badge bg-success":"badge bg-danger"}>{sub.status==0?"Pending": sub.status==1?"Answered":"Closed" }</span></td>
                  </tr>
                )): <section className="text-center p-5 h-100" style={{marginLeft:"80px"}}>
                <h4 className="text-muted fw-bold">NO TICKETS</h4>
                <p className="pb-5">You do not currently have any tickets in this status. Find what you're looking for in another tab or...</p>
                <Link to="/create_ticket"><a className="btn btn-outline-primary btn-lg">Start creating a new ticket</a></Link>
              </section>}
                </tbody>
              </table>
              </div>
     
        </div>
        
      </div>
    
    </section>

    
  </div>
</section>
}
<Footer/>
    </div>
  )
}

export default Tickets