import Reac,{useEffect,useState} from 'react';
import axios from 'axios';
import exportValue from '../apiconfig';
import {Link} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
const Careers = () => {
  const[state,setState]=useState({cms_name:"",description:""})
  const cms_blog_detail = () =>{
    let data = {};
    axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/home/cms_web_manage_api`, data, { headers: exportValue.headers }).then((res) => {
       
      let getData = res.data.output[0];
      
     
      setState({...state,cms_name:getData.cms_name,description:getData.description})
      })
    
}
useEffect(()=>{
  cms_blog_detail()
},[])


  return (
    <div>
      <Header/>
     <div className='container'>
     <h2 className=' mt-3'>{state.cms_name}</h2>
     <div class=" mt-2 mb-2">
 
  <div class="">
    <h5 class=""></h5>
    <p class="">{state.description}</p>
   {/* <Link to="/"> <a href="" class="btn btn-primary">Go Back</a></Link> */}
  </div>
  
</div>
       </div>
       <Footer/>
  </div>
  )
}

export default Careers