import React, { useEffect, useState } from "react";
import { useNavigate,useLocation,useParams, Link } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';

import Footer from "./Footer";
import Header from "./Header";
import exportValue from "../apiconfig";
import Mapsecond from "./components/Mapsecond";

// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-places-autocomplete';




const MyAccount = () => {
    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
      console.log("userData  -  ",userdata);
      exportValue.headers.usertuid = userdata.user_id;   
      //navigate('/home') 
    }
return(
    <>
    <Header/>
    <section class="sub_heading p-5 text-black bg-light">
  <div class="container ">
    <div class="row">
      <div class="col ">
        <h5>My Account</h5>
      </div>
    </div>
  </div>
</section>


<section class=" ">
  <div class="container">            
    <div class="row mt-3">
        <div class="col-12 px-0">
            <ul class="list-group list-group-flush mb-4">
              <li class="list-group-item">
                                        <a href={"/profile/"+userdata.user_id} class="row">                     
                      <div class="col-1 pl-0 align-self-center">
                        <i class="material-icons-outlined">person</i>
                      </div>
                      <div class="col">
                          <h6 class="text-dark mb-1">Profile</h6>
                          <p class="text-secondary mb-0 small">View your Profile</p>
                      </div>                                
                      <div class="col-2 pl-0 align-self-center text-right">
                           <i class="material-icons text-secondary">chevron_right</i>
                      </div>
                  </a>
              </li>
              {(userdata.user_type ==1)?
              <>
              <li class="list-group-item">
                                        <a href="/myaccount_customer" class="row">  
                    <div class="col-1 pl-0 align-self-center">
                      <i class="material-icons-outlined">view_in_ar</i>
                    </div>
                      <div class="col">
                          <h6 class="text-dark mb-1">Shipments</h6>
                          <p class="text-secondary mb-0 small">View your Shipments</p>
                      </div>                                
                      <div class="col-2 pl-0 align-self-center text-right">
                           <i class="material-icons text-secondary">chevron_right</i>
                      </div>
                  </a>
              </li>
              </>
              :""}
              {(userdata.user_type ==2)?
              <>
              <li class="list-group-item">
                  <a href="/quote_list_carrier" class="row"> 
                    <div class="col-1 pl-0 align-self-center">
                      <i class="material-icons-outlined">gavel</i>
                    </div>
                      <div class="col">
                          <h6 class="text-dark mb-1">Quotes</h6>
                          <p class="text-secondary mb-0 small">View your Quotes</p>
                      </div>                                
                      <div class="col-2 pl-0 align-self-center text-right">
                           <i class="material-icons text-secondary">chevron_right</i>
                      </div>
                  </a>
              </li>
              <li class="list-group-item">
                                        <a href="/vehicle_list" class="row">         
                      <div class="col-1 pl-0 align-self-center">
                        <i class="material-icons-outlined">local_shipping</i>
                      </div>
                      <div class="col">
                          <h6 class="text-dark mb-1">Vehicals</h6>
                          <p class="text-secondary mb-0 small">View your Vehicals</p>
                      </div>                                
                      <div class="col-2 pl-0 align-self-center text-right">
                           <i class="material-icons text-secondary">chevron_right</i>
                      </div>
                  </a>
              </li>
              <li class="list-group-item">
                                        <a href="/driver_list" class="row">         
                      <div class="col-1 pl-0 align-self-center">
                        <i class="material-icons-outlined">face</i>
                      </div>
                      <div class="col">
                          <h6 class="text-dark mb-1">Drivers</h6>
                          <p class="text-secondary mb-0 small">View your Drivers</p>
                      </div>                                
                      <div class="col-2 pl-0 align-self-center text-right">
                           <i class="material-icons text-secondary">chevron_right</i>
                      </div>
                  </a>
              </li>
              </>
              :""
}
              <li class="list-group-item">
                                        <a href="/inbox" class="row">         
                      <div class="col-1 pl-0 align-self-center">
                        <i class="material-icons-outlined">forum</i>
                      </div>
                      <div class="col">
                          <h6 class="text-dark mb-1">Inbox</h6>
                          <p class="text-secondary mb-0 small">View your Inbox</p>
                      </div>                                
                      <div class="col-2 pl-0 align-self-center text-right">
                           <i class="material-icons text-secondary">chevron_right</i>
                      </div>
                  </a>
              </li>
              <li class="list-group-item">
                                        <a href="/payments" class="row">         
                      <div class="col-1 pl-0 align-self-center">
                        <i class="material-icons-outlined">account_balance</i>
                      </div>
                      <div class="col">
                          <h6 class="text-dark mb-1">Payments</h6>
                          <p class="text-secondary mb-0 small">View your Payments</p>
                      </div>                                
                      <div class="col-2 pl-0 align-self-center text-right">
                           <i class="material-icons text-secondary">chevron_right</i>
                      </div>
                  </a>
              </li>
              <li class="list-group-item">
                                        <a href="/settings" class="row">         
                      <div class="col-1 pl-0 align-self-center">
                        <i class="material-icons-outlined">settings</i>
                      </div>
                      <div class="col">
                          <h6 class="text-dark mb-1">Settings</h6>
                          <p class="text-secondary mb-0 small">View your Settings</p>
                      </div>                                
                      <div class="col-2 pl-0 align-self-center text-right">
                           <i class="material-icons text-secondary">chevron_right</i>
                      </div>
                  </a>
              </li>
            </ul>
        </div>
    </div>

</div>
</section>
<Footer/>

  </>
    )
}

export default MyAccount