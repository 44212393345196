
import React, { useState, useEffect } from 'react'

import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import MenuGuest from './MenuGuest';
import MenuCustomer from './MenuCustomer';
import MenuCarrier from './MenuCarrier';
import exportValue from "../apiconfig";


const Header = () => {
     const location = useLocation();
     //let sessionGet =  sessionStorage.getItem('loggedIns')
 // console.log("sessionGet   ",sessionGet);
     let userdata = {};
     if (localStorage.getItem('loggedIn')) {
          userdata = JSON.parse(localStorage.getItem('loggedIn'));
        //setState(userDetail);
        console.log("-> ",userdata);
      }
      let loggedIn = localStorage.getItem('loggedIn');
    if (loggedIn) {
         loggedIn = JSON.parse(loggedIn);
        //setState(userDetail);
       // console.log("-> ",userDetail);
      }
   // const [state,setState] = useState({loggedIn:false});
   // console.log("loggedIn -- ",loggedIn);

    return (
    <>
        {(userdata.user_type) ?
      <section class="footer_mobile desktop_off">
  <div class="col-auto mx-auto">
    <div class="row g-0 justify-content-center">
{(userdata.user_type == 2)?
<>
        <div class="col-auto">
            <a href="/shipment-list" class={(location.pathname == "/shipment-list")?"btn btn-link":"btn"}>
              <i class="material-icons-outlined">travel_explore</i>
              <p>Find Shipments</p>
            </a>
        </div>

        <div class="col-auto">
            <a href={(userdata.user_type == 2)?"/myaccount_carrier":"/myaccount_customer"} class={(location.pathname == "/myaccount_carrier" || location.pathname =="/myaccount_customer")?"btn btn-link":"btn"}>
              <i class="material-icons-outlined">rv_hookup</i>
              <p>My Shipments</p>
            </a>
        </div>

        <div class="col-auto">
            <a href="/quote_list_carrier" class={(location.pathname == "/quote_list_carrier")?"btn btn-link":"btn"}>
              <i class="material-icons-outlined">gavel</i>
              <p>My Quotes</p>
            </a>
        </div>

        <div class="col-auto">
            <a href={"/my_account"} class={(location.pathname == "/my_account")?"btn btn-link":"btn"}>
              <i class="material-icons-outlined">person</i>
              <p>My Account</p>
            </a>
        </div>
        </>
                :
                <>


        <div class="col-auto">
            <a href={(userdata.user_type == 2)?"/myaccount_carrier":"/myaccount_customer"} class={(location.pathname == "/myaccount_carrier" || location.pathname =="/myaccount_customer")?"btn btn-link":"btn"}>
              <i class="material-icons-outlined">rv_hookup</i>
              <p>My Shipments</p>
            </a>
        </div>

        <div class="col-auto">
            <a href="/ship" class={(location.pathname == "/ship")?"btn btn-link":"btn"}>
              <i class="material-icons-outlined">travel_explore</i>
              <p>Add New Shipments</p>
            </a>
        </div>
        <div class="col-auto">
            <a href="/shipment-tracking" class={(location.pathname == "/shipment-tracking")?"btn btn-link":"btn"}>
              <i class="material-icons-outlined">gavel</i>
              <p>Tracking</p>
            </a>
        </div>

        <div class="col-auto">
            <a href={"/my_account"} class={(location.pathname == "/my_account")?"btn btn-link":"btn"}>
              <i class="material-icons-outlined">person</i>
              <p>My Account</p>
            </a>
        </div>
        </>
            }
    </div>
  </div>
</section> 
:<section class="footer_mobile desktop_off">
<div class="col-auto mx-auto">
  <div class="row g-0 justify-content-center">

      <div class="col-auto">
          <a href="/" class={(location.pathname == "/")?"btn btn-link":"btn"}>
            <i class="material-icons-outlined">travel_explore</i>
            <p>Home</p>
          </a>
      </div>

      <div class="col-auto">
          <a href="/shipment-list" class={(location.pathname == "/shipment-list")?"btn btn-link":"btn"}>
            <i class="material-icons-outlined">travel_explore</i>
            <p>Find Shipments</p>
          </a>
      </div>    

      <div class="col-auto">
          <a href="/ship" class={(location.pathname == "/ship")?"btn btn-link":"btn"}>
            <i class="material-icons-outlined">gavel</i>
            <p>Add New Shipment</p>
          </a>
      </div>

      <div class="col-auto">
          <a href={"/login"} class={(location.pathname == "/login")?"btn btn-link":"btn"}>
            <i class="material-icons-outlined">person</i>
            <p>My Account</p>
          </a>
      </div>
  </div>
</div>
</section> 
}
         {(loggedIn) ? (loggedIn.user_type == 1) ?   <MenuCustomer /> :<MenuCarrier /> :       <MenuGuest />   }
    </>
   )
}

export default Header;
