import React, { useState,useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Select from "react-select";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import exportValue from "../apiconfig";



import NewShipmentAttribute from "./NewShipmentAttribute";
import { Link } from 'react-router-dom';
import { AttributeLists,AttributeEditDetail,LocationEditDetail } from './Redux/actions/ShipmentAction'
import {useParams} from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import { usePlacesWidget } from "react-google-autocomplete";
//import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import usePlacesAutocomplete from "use-places-autocomplete";
import { toast } from "react-toastify";




const Addshipment = () => {
  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log(userdata);
   
     
  }
  // const {
  //   placesService,
  //   placePredictions,
  //   getPlacePredictions,
  //   isPlacePredictionsLoading,
  // } = usePlacesService({
  //   apiKey: "AIzaSyD6BJ4wBm-MrZOt8EfM7kGZq1NjNKPvzeo",
  // });
  // useEffect(() => {
  //   // fetch place details for the first element in placePredictions array
  //   if (placePredictions.length){
  //     console.log(placePredictions);
  //   }
  // }, [placePredictions]);
  const navigate = useNavigate();
  let attributeData = useSelector((state) => state.catAttributeReducer.CATEGORY_ATTRIBUTE_DETAIL.cat_attribute_detail);
  let attributeData1 = useSelector((state) => state.addShipmentAttributeDetail.ATTRIBUTE_ADD_SHIPMENT_DETAIL);
  console.log("attributeData1 ",attributeData1);
 
 // setStatelist([{attributes:attributeData,dimensions:{}}]);
  //itemState.attributes = attributeData;
  console.log("groupState ",attributeData);
  const[dimensionState,setdimensionState] = useState({dimensions:{}})
  const[allItemsState,setAllItemsState] = useState([])
  const {category_id,subcategory_id} = useParams();
 

  const [state, setState] = useState({ setStatelist: 1 ,isLoop:true, locationLoop:true})
  const [stateList, setStatelist] = useState([{item_name:"",attributes:[],dimensions:{item_height:"",item_width:"",item_length:"",item_weight:"",item_value:""}}])
 // console.log("state is ",state);
 let itemState = {attributes:attributeData,dimensions:{item_height:"",item_width:"",item_length:"",item_weight:"",item_value:""}};
  //const [itemState, setItems] = useState({groupState:[],dimensions:{height:"",width:"",length:"",weight:"",value:""}})

  const [groupState, setGroupState] = useState([])
  //console.log("group state is ",groupState);

  const [shipmentLocation, setShipmentLocation] = useState({category_id:category_id,subcategory_id:subcategory_id,pickUpLocation:"",deliveryLocation:"",height:"",width:"",length:"",weight:"",value:"",pickup_address_type:"Residence / Home Business",delivery_address_type:"Residence / Home Business",sender_name:(userdata.full_name)? userdata.full_name:"",sender_mob_no:(userdata.mob_number)?userdata.mob_number:"",reciever_name:"",reciever_mob_no:""})

  //if(attributeData) {
    //let listCopy =  [...stateList];
   // listCopy[0].attributes = attributeData;
    
   // setStatelist(listCopy);
 // }


 
  const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/get_company_info_api`;
    let sendData = {};

    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      //console.log("dimension res ",res);
      let companyData = res.data.companyDetail[0];
     // console.log("groupState ",attributeData);
     
      //console.log("itemState ",itemState);
    //  setStatelist([{attributes:attributeData,dimensions:{height:"",width:"",length:"",weight:"",value:""}}])
      //  setGroupState(attributeData)
     // setItems({groupState:attributeData,dimensions:{height:"",width:"",length:"",weight:"",value:""}});
     
      setdimensionState({...dimensionState,dimensions:{set_currency:companyData.set_currency,set_dimension:companyData.set_dimension,set_weight:companyData.set_weight}});

     
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
     
    })
  }
  React.useEffect(() => {
    dimension_detail();
}, [])

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyD6BJ4wBm-MrZOt8EfM7kGZq1NjNKPvzeo",
    onPlaceSelected: (place) => {
    
    },
    options: {
    },
  });

  // const {
  //   ready,
  //   value,
  //   suggestions: { status, data },
  //   setValue,
  //   clearSuggestions
  // } = usePlacesAutocomplete();
  const placeDelivery = usePlacesAutocomplete();
  const hj = usePlacesAutocomplete();
 
 

  const handleInput = (e) => {
   
    if(e.target.name == "pickUpLocation") {
      hj.setValue(e.target.value);
    }else if(e.target.name == "deliveryLocation") {
      placeDelivery.setValue(e.target.value);
    }
    
  };

const handleSelectPickup = (value) =>{
 
  hj.setValue(value.description, false);
  setShipmentLocation({...shipmentLocation,pickUpLocation:value.description})
  hj.clearSuggestions();
}

  const renderSuggestionsPickup = () =>
  hj.suggestions.data.map((suggestion) => {
    const {
      place_id,
      structured_formatting: { main_text, secondary_text },
    } = suggestion;
   
    return (
      <li key={place_id} onClick={()=>handleSelectPickup(suggestion)}>
        <strong>{main_text}</strong> <small>{secondary_text}</small>
      </li>
    );
  });

 
  

  //console.log("placeDelivery  ",placeDelivery);
  // const handleInputDelivery = (e) => {
  //   placeDelivery.setValue(e.target.value);
  // };

  const handleSelectDelivery = (value) =>{
  
    placeDelivery.setValue(value.description, false);
    setShipmentLocation({...shipmentLocation,deliveryLocation:value.description})
    placeDelivery.clearSuggestions();
  }


  const renderSuggestionsDelivery = () =>
  placeDelivery.suggestions.data.map((suggestion) => {
    const {
      place_id,
      structured_formatting: { main_text, secondary_text },
    } = suggestion;
   
    return (
      <li key={place_id} onClick={()=>handleSelectDelivery(suggestion)}>
        <strong>{main_text}</strong> <small>{secondary_text}</small>
      </li>
    );
  });

  // const renderSuggestionsDelivery = () =>
  // console.log("delivery render call ------------------      ",placeDelivery.suggestions);
  //   placeDelivery.suggestions.data.map((suggestion1) => {
  //   const {
  //     place_id,
  //     structured_formatting: { main_text, secondary_text },
  //   } = suggestion1;
   
  //   return (
  //     <li key={place_id} onClick={()=>handleSelectDelivery(suggestion1)}>
  //       <strong>{main_text}</strong> <small>{secondary_text}</small>
  //     </li>
  //   );
  // });
  
 

  //console.log("state is ", state);

 

  const dispatch = useDispatch();

 

  
  //  let locationShipment = useSelector((state) => state.addShipmentLocationDetail.LOCATION_ADD_SHIPMENT_DETAIL);

   
  
   // if(locationShipment != undefined && locationShipment !="" && state.locationLoop == true){
     // setState({...state,locationLoop:false})
      //setShipmentLocation(locationShipment)
     
   // }

  React.useEffect(() => {
   
    //if(attributeData == undefined || attributeData.length == 0) {};
       dispatch(AttributeLists({category_id:category_id,subcategory_id:subcategory_id})) 
  //  setStatelist([<NewShipmentAttribute attra={1}/>])
  }, [])

  if(attributeData != undefined && attributeData.length > 0 && state.isLoop == true){
      setState({...state,isLoop:false})
      let listCopy =  [...stateList];
      listCopy[0].attributes = attributeData;
      
      setStatelist(listCopy);
      if(attributeData1 != '' && attributeData1.length > 0)
      {
        setStatelist(attributeData1);
        //attributeData = attributeData1;
      }

        //  console.log("attributeData --   ", attributeData)
        //  // let dimensionB = dimensionState.dimensions[0];
        //   attributeData.forEach((items)=>{
         
        //   console.log("items --   ", items)
        //  // items.dimesions = dimensionB
        //  })
     // setGroupState(attributeData)
  }

  const OptionCreate = () => {
    var rows = [];
    for (var i = 1; i <= 10; i++) {
      rows.push(<option value={i} selected={state.setStatelist == i}>{i}</option>);
    }
    return rows
  }

  const handleChange = (e) => {
    // console.log("vv ", e.target.name)
     setState({ setStatelist: e.target.value })
    //var rows2 = [];
   // setStatelist([])
   let newItem = itemState;
   console.log("newItem  =========>   ",newItem)
   let copyStateList = stateList;
  // console.log("copyStateList  ",copyStateList);
    let items = [];
  //  let newDimension = [];
    for (var i = 0; i < e.target.value; i++) {
      //console.log("for   =========>   ",copyStateList[i])
      if(copyStateList[i] != undefined) {
      //  console.log("loop  =========>   ",copyStateList[i])
        items.push(copyStateList[i]);
      }else {
        
        items.push(newItem);
     }
     // items.push(<NewShipmentAttribute attr={{'indexV':i}}/>);
      //newDimension.push(dimensionB)
     // rows2.push(<option value={i}>{i}</option>);
    }
    console.log("items  =========>   ",items)
    setStatelist(items)
   // setdimensionState({...dimensionState,dimensions:newDimension});
   // setStatelist(stateList => [...stateList, <NewShipmentAttribute attr={{'indexV':i}}/>])
    //console.log(rows2);
  }
  const handleChange1 = (e) => {
  
    let names = e.target.name;
    let value = e.target.value
    setShipmentLocation({...shipmentLocation,[names]:value})
   
    
}

  const handleChangeGroup = (e,index1) => {
  console.log("index1  ", index1);
  let check_attr_detail = JSON.parse(e.target.value);
  console.log("id is group_detail ",check_attr_detail);
  let copyItem = stateList[index1];
  console.log("copyItem  ", copyItem.attributes[check_attr_detail.index]);
  let y = copyItem.attributes[check_attr_detail.index];
  //groupState[check_attr_detail.index];
     
        y.attribute_value.map((item,index) => {
              if(item.group_id == check_attr_detail.group_id && item.isSelected == false){
               // console.log("id is 1 ",true);
                item.isSelected= true
              } else {
                //console.log("id is 2 ",false);
                item.isSelected= false
              }
             // setGroupState([{...groupState }])
              return item
            })
            console.log("y is ",y);
           // setGroupState([ ...groupState ] );
        //console.log("gg",groupState)
  }

  const handleChangeAttr = (event) => {
    //console.log(event);
    if (event.target.type == "checkbox") {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
    } else {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    }
  }


  const handleChangeCheckBox = (check_attr_detail,index1) => (e) =>{
         
      console.log("id is ",check_attr_detail);
      console.log("index1 is ",index1);
     // let copyItem = stateList[index1];
      //console.log("copyItem is ",copyItem);
      let y = stateList[index1].attributes[check_attr_detail.index].attribute_value;
      console.log("y is ",y);
        
      stateList[index1].attributes[check_attr_detail.index].attribute_value.map((item,index) => {
          if(item.group_id == check_attr_detail.group_id && item.isSelected == false){
           // console.log("id is 1 ",true);
            item.isSelected= true
          } else if(item.group_id == check_attr_detail.group_id && item.isSelected == true){
           // console.log("id is 2 ",false);
            item.isSelected= false
          }
             // setGroupState([{...groupState }])
          return item
      })
      setStatelist([...stateList])
      //setGroupState([ ...groupState ] );
     // console.log("gg",groupState)
  }
  

  const handleChangeButton = (check_attr_detail,index) => (e) => {
    console.log("id is ",check_attr_detail);
    console.log("index is ",index);
      
    let copyItem = [...stateList];
    console.log("copyItem is ",copyItem);

    let y = copyItem[index].attributes[check_attr_detail.index1].attribute_value;
    console.log("y is ",y);
      
    copyItem[index].attributes[check_attr_detail.index1].attribute_value.map((item,index) => {
      if(item.group_id == check_attr_detail.group_id && item.isSelected == false){
       // console.log("id is 1 ",true);
        item.isSelected= true
      } else {
       // console.log("id is 2 ",false);
        item.isSelected= false
      }
     // setGroupState([{...groupState }])
    //  return item
    })
    console.log("copyItem ==>>>  ",copyItem);
    setStatelist(copyItem)
  //  setGroupState([ ...groupState ] );
    //console.log("gg",groupState)
  }

  const handleInputChange = (e,check_attr_detail) =>  {
        console.log("id is ",check_attr_detail);
       // console.log(" e is ",e.target.value);
         let input_value = e.target.value
         let input_name = e.target.name;
         console.log("input_value is ",input_value)
         console.log("input_name is ",input_name)
         let copyItem = [...stateList];
         console.log("copyItem is ", copyItem[check_attr_detail.index].attributes[check_attr_detail.index1])
         copyItem[check_attr_detail.index].attributes[check_attr_detail.index1].attribute_value = [input_value];
         setStatelist(copyItem)
      //   let y = groupState[check_attr_detail.index];
      //  // console.log("y is ",y);

      //   y.attribute_value.map((item) => {
      //     if(item.group_id == check_attr_detail.group_id){
      //      // console.log("id is 1 ",true);
      //       item.group_input_value = input_value
      //     } 
      //     return item
      // })
      //console.log("gg",groupState)

     // setGroupState([ ...groupState ] );
  }

  const handleItem_nameChange = (e,index) => {
    let input_value = e.target.value;
    let input_name = e.target.name;
    console.log("input_value is ",input_value)
    console.log("input_name is ",input_name)
    console.log("index is ",index)
    let dataArray = [...stateList];
    console.log("dataArray - ",dataArray);
    dataArray[index][input_name] = input_value
    setStatelist(dataArray)

  }

  const handleDimensionChange = (e,index) => {
    
      
      let input_value = e.target.value;
      let input_name = e.target.name;
      console.log("input_value is ",input_value)
      console.log("input_name is ",input_name)
      console.log("index is ",index)
      let dataArray = [...stateList];
      console.log("dataArray - ",dataArray);
      dataArray[index].dimensions[input_name] = input_value
      setStatelist(dataArray)
      // if(locationType != null){
      //   console.log("e is ",locationType)
      
      //   getPlacePredictions({input: e.target.value},{opt:{}});
      //   console.log("pre - ",placePredictions);
      // }
  }

  const shipmentContinueButton = (e) => {
       //console.log("hi man");
       
       if(  hj.value.length!==0 && placeDelivery.value.length!==0 && shipmentLocation.pickup_address_type!==0 && shipmentLocation.delivery_address_type!==0 && shipmentLocation.sender_name!='' && shipmentLocation.sender_mob_no!='' && shipmentLocation.reciever_name!='' && shipmentLocation.reciever_mob_no!=''){
        dispatch(AttributeEditDetail(stateList))
        dispatch(LocationEditDetail(shipmentLocation))
        navigate('/add_shipment_step_two')
         
       }
       else{
        
        toast.configure();
        toast.error("Please Fill All The Inputs ")
        // e.preventDefault();
        // window.history.back();
       }
  } 
  
  const handleChange4= (event) => {
    console.log(event);
    setShipmentLocation({
            ...shipmentLocation,
            [event.target.name]: event.target.value,
        });
    }

  
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

 function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

  const { height, width } = useWindowDimensions();


  return (<>
    <Header />
    <div>
    <section className="sub_heading p-3 bg-white">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="fw-light">New Shipment</h3>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-light p-5">
      <div className="container">
        <div className="row" style={width<=768?{marginLeft:"-50px",marginRight:"-50px"}:{margin:"0px"}}>
          <div className="col-12 col-md-9 add_shipment">
            <div className="card shadow-sm p-3">
              <div className="card-body">
                <h2 className="fs-5 text-uppercase">List a Shipment</h2>
                <hr />
                <div className="row"  >
                  <div className="col">
                
                    <div className="col-lg-4 col-12">
                      <label for="email" className="form-label">No. of Shipments</label>
                      <select className="form-select form-select-lg" value={state.selectData} onChange={e => handleChange(e)}>
                        {<OptionCreate />}

                      </select>
                    </div>
                  
                  </div>
                </div>
                <hr />
               
                {stateList.map((subscriber, index) => (
                  <>
                  <section className="p-3  bg-light  border-start border-primary border-5">
                        <h5 className="pt-4 text-primary">Item #{index+1} Details</h5>
                        <div className="row pt-3">
                        <div className="col-lg-4 col-12">
                        <div className="col-lg-12 col-12">
                        <label for="email" label="Select Attribute" className="form-label">Name</label>
                          <input type="text" className="form-control"  onChange={(e) => handleItem_nameChange(e,index)} name="item_name" value={subscriber.item_name} placeholder="Item"/>
                          </div>
                          </div>
                          
        {(subscriber.attributes != undefined && subscriber.attributes.length > 0) ? subscriber.attributes.map((data,index1)=>{
            return(
              <>
             
                      {(data.attribute_type != undefined && data.attribute_type == 2 && data.attribute_value.length > 0 ) ? 
                               <>
                                 <div className="col-lg-4 col-12">
                  <div className="col-lg-12 col-12">
                                  <label for="email" label="Select Attribute" className="form-label">{data.attribute_name}</label>
                                  <select className="form-select form-select-lg" autoComplete="off" required value={data.attribute_value.group_id} onChange={(e) =>handleChangeGroup(e,index)}>
                                  <option value="">Select</option>
                                    {data.attribute_value.map((attri_data)=>{
                                      return(<option value={JSON.stringify({group_id:attri_data.group_id,index:index1})} selected={attri_data.isSelected == true}>{attri_data.group_value} </option>)} )}
                                  </select>
                                  </div>
              </div>
                                </>
                            : ""  
                          }
                   
                          
                  
                             
                        {(data.attribute_type != undefined && data.attribute_type == 1 && data.attribute_value.length > 0) ?
                        <> 
                          <div className="col-lg-4 col-12" style={{margin: "10px 0px 0px 8px"}}>
                      <div className="col-lg-4 ">
                          <label for="" className="form-label">{data.attribute_name}</label>  
                          { data.attribute_value.map((attri_data)=>{
                             return(
                               <>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={attri_data.isSelected}
                                  value={attri_data.group_value}
                                  required
                                  autoComplete="off"
                                  id="flexCheckDefault"
                                  onChange={handleChangeCheckBox({group_id:attri_data.group_id,index:index1},index)}
                                />
                                <label className="form-check-label" for="flexCheckDefault">
                                    {attri_data.group_value}
                                </label>
                            </div>
                           
                            </>
                             )
                           })}
                            </div>
                            </div>
                           </>
                             : ""  
                          }

                     
                          

                     

                        {(data.attribute_type != undefined && data.attribute_type == 3 && data.attribute_value.length > 0 ) ? 
                        
                        <>
                         <div className="col-lg-4">
                          <label for="" className="form-label">{data.attribute_name}</label>
                         { data.attribute_value.map((attri_data)=>{
                           return(
                              <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"flexRadioDefault"+index+""+index1}
                                id={"flexRadioDefault"+index+""+index1}
                                checked={attri_data.isSelected}
                                value={attri_data.isSelected}
                                required
                                autoComplete="off"
                                onChange={handleChangeButton({group_id:attri_data.group_id,index1:index1},index)}
                              />
                              <label className="form-check-label" for={"flexRadioDefault"+index+""+index1}>
                                {attri_data.group_value}
                              </label>
                            </div>
                           )
                           })  }
                            </div>
                           </>
                             : ""  
                          }
                    
                 
                         
                        
                            {/* <h6 className="pt-5">Dimension</h6> */}
                          {(data.attribute_type != undefined && data.attribute_type == 4 ) ? 
                          <>
                           <div className="col-lg-4">
                          <label for="email" className="form-label">{data.attribute_name}</label>
                         
                             
                             <input
                               type="text"
                               className="form-control"
                               id={`group_input_value_${index+""+index1}`}
                              
                               name={`group_input_value_${index+""+index1}`}
                              
                               autoComplete="off"
                               onChange={(e) => handleInputChange(e,{attribute_id:data.attribute_id,index:index,index1:index1})}
                               required
                             />
                           </div>
                          
                         </>: ""  
                          }
                          
                        
                    </>
                  ) 
                })
                
               :
                ""
                   }
                     
                     {//subscriber.dimensions.map((sub)=>(
                   <div className="row">
                        <h6 className="pt-5">Dimension</h6>
                    
                        <div className="col-12 col-md ">
                          <div className="mb-3">
                            <label for="nu" className="form-label d-none d-sm-block">Height</label>
                            <label for="nu" className="form-label d-block d-sm-none">Height in {dimensionState.dimensions.set_dimension}</label>
                          
                
                            <input
                              type="number"
                              className="form-control"
                              placeholder={`in ${dimensionState.dimensions.set_dimension}`}
                              name="item_height"
                              value={subscriber.dimensions.item_height}
                              onChange={(e)=>handleDimensionChange(e, index)}
                              required
                              autoComplete="off"
                            />
                           
                          
                          </div>
                        </div>
                           

                      
                        <div className="col-12 col-md  ">
                          <div className="mb-3 ">
                            <label for="email" className="form-label d-none d-sm-block">Width</label>
                            <label for="email" className="form-label d-block d-sm-none">Width in {dimensionState.dimensions.set_dimension}</label>

                          
                            <input
                              type="number"
                              className="form-control"
                              placeholder={`in ${dimensionState.dimensions.set_dimension}`}
                              name="item_width"
                              value={subscriber.dimensions.item_width}
                              onChange={(e)=>handleDimensionChange(e, index)}
                              required
                              autoComplete="off"
                            />
                          </div>
                        </div>

                       
                       
                        <div className="col-12 col-md ">
                          <div className="mb-3 ">
                            <label for="email" className="form-label d-none d-sm-block">Length</label>
                            <label for="email" className="form-label d-block d-sm-none">Length in {dimensionState.dimensions.set_dimension}</label>


                           
                            <input
                              type="number"
                              className="form-control"
                              id=""
                              placeholder={`in ${dimensionState.dimensions.set_dimension}`}
                              name="item_length"
                              value={subscriber.dimensions.item_length}
                              onChange={(e)=>handleDimensionChange(e, index)}
                              required
                              autoComplete="off"
                            /> 
                          </div>
                        </div>

                        

                           
                        <div className="col-12 col-md ">
                          <div className="mb-3 ">
                            <label for="email" className="form-label  d-none d-sm-block">Weight</label>
                            <label for="email" className="form-label  d-block d-sm-none">Weight in {dimensionState.dimensions.set_weight}</label>

                            
                            <input
                              type="number"
                              className="form-control"
                              placeholder={`in ${dimensionState.dimensions.set_weight}`}
                              name="item_weight"
                              value={subscriber.dimensions.item_weight}
                              onChange={(e)=>handleDimensionChange(e, index)}
                              required
                              autoComplete="off"
                            />
                          </div>
                        </div>

                       

                            
                        <div className="col-12 col-md ">
                          <div className="mb-3 ">
                            <label for="email" className="form-label d-none d-sm-block">Value</label>
                            <label for="email" className="form-label d-block d-sm-none">Value in {dimensionState.dimensions.set_currency}</label>

                          
                            <input
                              type="number"
                              className="form-control"
                              placeholder={`in ${dimensionState.dimensions.set_currency}`}
                              name="item_value"
                              value={subscriber.dimensions.item_value}
                              onChange={(e)=>handleDimensionChange(e ,index)}
                              required
                              autoComplete="off"
                            />
                          </div>
                        </div>

                      
                      </div>
//))
}
                   </div>
                      </section>
                  <hr />
                </>
                
                ))}

      <section>
      <h5 className="pt-4">Sender Information</h5>
      <div className="row">
      <div className="col-md col-12">
      <label for="email" className="form-label ">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Sender's Name"
                              name="sender_name"
                              value={shipmentLocation.sender_name}
                              onChange={(e) => handleChange4(e)}
                              required
                              autoComplete="off"
                            /> 
      </div>  
      <div className="col-md col-12">
      <label for="email" className="form-label ">Mobile Number</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Sender's Mobile Number"
                              name="sender_mob_no"
                              value={shipmentLocation.sender_mob_no}
                              onChange={(e) => handleChange4(e)}
                              required
                              autoComplete="off"
                            /> 
      </div>  
      </div> 

      <h5 className="pt-4">Reciever Information</h5>
      <div className="row">
      <div className="col-md col-12">
      <label for="email" className="form-label ">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Reciever's Name"
                              name="reciever_name"
                              value={shipmentLocation.reciever_name}
                              onChange={(e) => handleChange4(e)}
                              required
                              autoComplete="off"
                            /> 
      </div>  
      <div className="col-md col-12">
      <label for="email" className="form-label ">Mobile Number</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Reciever's Mobile Number"
                              name="reciever_mob_no"
                              value={shipmentLocation.reciever_mob_no}
                              onChange={(e) => handleChange4(e)}
                              required
                              autoComplete="off"
                            /> 
      </div>  
      </div> 
     
            <h5 className="pt-4">Pickup Location</h5>
            <div class="row">
             
                <div class="col-12 col-md-9 ">
            <label for="" className="form-label">City</label>
              <div className="input-group input-group-lg mb-3">
                    <span className="input-group-text" id="basic-addon1"
                    ><i className="fa fa-arrow-right rotate_40" aria-hidden="true"></i
                    ></span>
                    <input
                      id="pickup"
                      type="text"
                      className="form-control"
                      placeholder="e.g. London"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="pickUpLocation"
                      value={hj.value}
                      required
                      autoComplete="off"
                      onChange={(e)=>handleInput(e)}
                    /></div>
                  <ul>{renderSuggestionsPickup()}</ul>  
                  </div>

                

                  
                  <div class="col ">
                  <label for="" class="form-label">Address type</label>
                  <select class="form-select form-select-lg" required autoComplete="off" onChange={(e) => handleChange1(e)} name="pickup_address_type" value={shipmentLocation.pickup_address_type}>
                  <option value="Residence / Home Business">Residence / Home Business</option>
                    <option value="Business (with loading dock or forklift)">Business (with loading dock or forklift)</option>
                    <option value="Business (without loading dock or forklift)">Business (without loading dock or forklift)</option>
                    <option value="Port">Port</option>
                    <option value="Construction Site">Construction Site</option>
                    <option value="Trade Show / Convention Center">Trade Show / Convention Center</option>
                    <option value="Storage Facility">Storage Facility</option>
                    <option value="Military Base">Military Base</option>
                    <option value="Airport">Airport</option>
                    <option value="Other Secured or Limited Access Location">Other Secured or Limited Access Location</option>
                    
                  </select>
                </div>
                
              
                
                </div>
                </section>

                <section>
                  <h5 className="pt-4">Delivery Location</h5>
                  <div class="row">
                   
                <div class="col-md-9 col-12 ">
                  <label for="" className="form-label">City</label>
                  <div className="input-group input-group-lg mb-3">
                    <span className="input-group-text" id="basic-addon1"
                    ><i className="fa fa-arrow-down rotate_40" aria-hidden="true"></i
                    ></span>
                    <input
                    id="delivery"
                   //ref={ref}
                      type="text"
                      className="form-control"
                      placeholder="e.g. New York"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="deliveryLocation"
                      value={placeDelivery.value}
                      required
                      onChange={(e)=>handleInput(e)}
                      autoComplete="off"
                    />
                  </div>

                
                  <ul>{renderSuggestionsDelivery()}</ul>
                  </div>

              
                   
                   {/* for desktop  */}
                  <div class="col ">
                  <label for="" class="form-label">Address type</label>
                  <select class="form-select form-select-lg"  required autoComplete="off" onChange={(e) => handleChange1(e)} name="delivery_address_type" placeholder="select" value={shipmentLocation.delivery_address_type}>
                    <option value="Residence / Home Business">Residence / Home Business</option>
                    <option value="Business (with loading dock or forklift)">Business (with loading dock or forklift)</option>
                    <option value="Business (without loading dock or forklift)">Business (without loading dock or forklift)</option>
                    <option value="Port">Port</option>
                    <option value="Construction Site">Construction Site</option>
                    <option value="Trade Show / Convention Center">Trade Show / Convention Center</option>
                    <option value="Storage Facility">Storage Facility</option>
                    <option value="Military Base">Military Base</option>
                    <option value="Airport">Airport</option>
                    <option value="Other Secured or Limited Access Location">Other Secured or Limited Access Location</option>
                   
                  </select>
                </div>

               

                </div>
                </section>
                <hr />
                <section className="text-center">
                {/* to="/add_shipment_step_two" */}
                
                  <a className="btn btn-primary btn-lg"
                  onClick={()=>shipmentContinueButton()}>Continue</a>
                </section>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3 ">
            <section className="pb-5 add_ship_cont_1">
              <h5>Details Matter</h5>
              <p className="text-muted">
                The quotes you get are only as accurate as your listing. Make it as
                detailed as possible to avoid delays, price increases, and
                cancellations.
              </p>
            </section>

            <section className="pt-5 add_ship_cont_2">
              <h5>Double-Check Locations</h5>
              <p className="text-muted">
                Include correct locations for accurate pricing.
              </p>
            </section>
          </div>
        </div>
      </div>
    </section>
    </div>
    <Footer />
  </>)
}

export default Addshipment;
