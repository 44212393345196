import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import exportValue from '../apiconfig';
import  Axios  from 'axios';
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';



const Create_ticket = () => {
    let navigate = useNavigate()
   
    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
      console.log("userData  -  ",userdata);
       
    }

    const[shipment,shipmentState]=React.useState({shipment_detail:[]})
const [state, setState] = useState({  subject:"" , ticket_createdById:"" , details:"",shipment_id:""   })


    const axios_get_api = (current_shipment_status) => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/support/support_shipments`;
        let sendData = {
          dstatus: 1,
          transporter_id:userdata.transporter_id,
          current_shipment_status:current_shipment_status
           };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          console.log("-----res-----  ",res);
          if(userdata.user_type==1){
          shipmentState({ ...shipment, shipment_detail:res.data.output })}
          else{
            shipmentState({ ...shipment, shipment_detail:res.data.output2 })
            
          }
          console.log("response", res);
        
    
        }).catch((e) => {
    
          // toast.configure()
          // toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])


     
      const handleSupport = () => {
    
        console.log("submit1");
       
        let state_detailes = { subject:state.subject , ticket_createdById:"" , details:state.details,shipment_id:state.shipment_id }
        if(userdata.user_type==1){
        if(userdata.user_id) {
          state_detailes.ticket_createdById = userdata.user_id;
        }}
        else{
            if(userdata.transporter_id) {
                state_detailes.ticket_createdById = userdata.transporter_id;
              }
        }
      
        console.log("report detailes is ",state_detailes);
        if(state.subject!="" && state.details!=""){
        Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/support/add_support`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
                console.log("result is i ",result);
               
                if(result && result.data != undefined){
                   
                        toast.configure()
                        toast(result.data.message) 
                        navigate("/tickets")
                        
                    }
     
             }).catch((e) => {
           
     
              toast.configure();
              toast.error("Some thing went wrong");
              console.log("----error:   ", e);
            });}
            else{
              toast.configure()
              toast.error("Please Fill the Mandatory Fields ") 
            }
    }
    
    const handleChange = (event) => {
      console.log(event);  
      setState({
          ...state,
          [event.target.name]: event.target.value,
        });
      
    }
    
      

  return (
    <div>
<Header/>
<section class="bg-light p-5">
  <div class="container ">

    <section class="bg-white p-5 mb-2">
      <div className='row'>
        <div className='col-6'>
        <div class="text-muted">
         <p><Link to="/"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</Link></p>
        
      </div>
        </div>
        <div className='col-6'>
        <p className='text-end'><Link to="/tickets"> View All Tickets</Link></p>
        </div>

      </div>
     
      <div class="row pt-1">
        <div class="col-md-12">
       
            <section class="">
              <div class="mb-3 mt-3">
                <label for="email" class="form-label">Subject<small className='text-danger'>*</small></label>
                <input type="text" class="form-control" id="email" placeholder="Please enter the subject" name="subject" value={state.subject} onChange={(e) => handleChange(e)}/>
              </div>
              <div class="mb-3 mt-3">
              <label for="email" class="form-label">Shipment</label>
              <select class="form-select" name="shipment_id"  onChange={(e) => handleChange(e)}>
                                      
                                    <option value="">Select</option>
                                   
                                      { shipment.shipment_detail.map((subscrib,index) => {
                                         return(
                                        <option value={subscrib.invoice_id}>{subscrib.title}</option>
                                         )
                                      })}
                                    </select>
               </div>                     
              <div class="mb-3 mt-3">
                <label for="comment">Details<small className='text-danger'>*</small></label>
                <textarea class="form-control" rows="5" id="comment" name="details" placeholder="Please enter details" value={state.details} onChange={(e) => handleChange(e)}></textarea>
              </div>

              
              <a  class="btn btn-primary mt-3" onClick={()=>handleSupport()}>Submit</a>
            </section>





            
        </div>
        
      </div>
    </section>

    
  </div>
</section>
<Footer/>
    </div>
  )
}

export default Create_ticket