import React,{useState} from "react";
import Axios from "axios";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Header from './Header';
import MenuCustomer  from './MenuCustomer';
import Footer from './Footer';
//import { form } from 'react-router-dom';

const Inbox = () => {

    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
      console.log("userData  -  ",userdata);
      //navigate('/home') 
    }
  
     const[state,setState]=useState({notifications_list:[],isLoading:true}) 
     
  
     const notifications = () =>{
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/notifications`;
      let sendData = {user_id:(userdata.user_type == 1) ? userdata.user_id : userdata.transporter_id};
    
      Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        console.log("rnmmmmm", res);
        setState({...state,notifications_list:res.data.notification_list,isLoading:false});
       
      }).catch((e) => {
        setState({...state,isLoading:false});
         toast.configure()
        toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }
    React.useEffect(() => {
        notifications();
     
     
     
    }, [])


    return (
        <>
            <MenuCustomer />
            <section className="sub_heading p-5 text-black">
                <div className="container ">
                    <div className="row">
                        <div className="col ">
                            <h5>Inbox</h5>
                        </div>
                    </div>
                </div>
            </section>


            <section className=" bg-light p-5 ">
                <div className="container ">
                    <div className="row">
                        <div className="col"></div>
                        <div className="col-8">
                            <h4>My Messages</h4>
                            {state.isLoading == true ? <ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} /> :
                            <div className="card">
                                <div className="card-body">
                               
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="form-check form-select-sm">
                                                        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                    </div>
                                                </th>
                                                <th>Status</th>
                                                <th>Sender</th>
                                                <th>Subject</th>
                                                <th>Received</th>
                                            </tr>
                                        </thead>
                                        <tbody>
{state.notifications_list.map((sub)=>(
                                            <tr>
                                                <td>
                                                    <div className="form-check form-select-sm">
                                                        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                    </div>
                                                </td>
                                               
                                                <td><i className={(sub.seen == 0)?"fa fa-envelope-o":"fa fa-envelope-open-o"} aria-hidden="true"></i></td>
                                                <td>
                                                    <div className="form-check form-select-sm">
                                                        {sub.full_name}
                                                        </div>
                                                        </td>
                                                <td>
                                                    <a href={"shipment/"+sub.invoice_id}>{sub.subject}</a>
                                                </td>
                                                <td><Moment format="DD/MM/YYYY" unix>{sub.created}</Moment><br />
                                                    <small><Moment format="HH:mm" unix>{sub.created}</Moment></small></td>
                                            </tr>
))}

                                            {/* <tr>
                                                <td>
                                                    <div className="form-check form-select-sm">
                                                        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                    </div>
                                                </td>
                                                <td><i className="fa fa-envelope-open-o" aria-hidden="true"></i></td>
                                                <td>
                                                    <a href="inbox_msg.php">New question from PeprAutoTransport about 2011 Hyundai Elantra</a>
                                                </td>
                                                <td>4/2/2022 <br />
                                                    <small>1: 20 PM</small></td>
                                            </tr>
                                           
                                            <tr>
                                                <td>
                                                    <div className="form-check form-select-sm">
                                                        <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                    </div>
                                                </td>
                                                <td><i className="fa fa-envelope-o" aria-hidden="true"></i></td>
                                                <td>
                                                    <a href="inbox_msg.php">New question from PeprAutoTransport about 2011 Hyundai Elantra</a>
                                                </td>
                                                <td>4/2/2022 <br />
                                                    <small>1: 20 PM</small></td>
                                            </tr> */}
                                            {/* <tr>
                                                <td colspan="2">
                                                    <select className="form-select form-select-sm">
                                                        <option>Select</option>
                                                        <option>Delete</option>
                                                        <option>Read</option>
                                                        <option>Unread</option>
                                                    </select>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr> */}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
}
                        </div>
                        
                        <div className="col"></div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Inbox;
