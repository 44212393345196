import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import io from 'socket.io-client';
import Moment from 'react-moment';
import moment from 'moment';

import Picker from 'emoji-picker-react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { requestForToken, onMessageListener } from '../firebase';
import Header from "./Header";
import exportValue from "../apiconfig";
import axiosInstance from "../axios"
import NotificationComponent from './NotificationComponent';
import Url_view from './Url_view';
import './chat.css';
import { Lightbox } from "react-modal-image";
import Modal from 'react-bootstrap/Modal';

 
var fs = require('fs');
var FormData = require('form-data');
const toEmoji = require("to-emoji")
var emoji = require('node-emoji')

let userdata = {};

const ENDPOINT = exportValue.host;


//io(ENDPOINT, { query: { id: userdata.user_id, token: "456" }, transports: ["websocket", "polling"] });

const styles = {
    body: {

        background: "linear-gradient(131deg, rgb(17 109 246 / 23%) 0%, rgb(195 195 197 / 24%) 100%)"
        /* display: flex; */
    }
}
const Chat_box = () => {
    const socket = useRef();
    const messagesEnd = useRef();
    let navigate = useNavigate();
    if (localStorage.getItem('loggedIn')) {
        userdata = JSON.parse(localStorage.getItem('loggedIn'));
        //  console.log("userData  - chat ", userdata);

    } else {
        navigate('/login')
    }

    const { other_id } = useParams();
    const [state, setState] = useState({ message: "", otheruserId: other_id, loggedUser: {}, currentStatus: "online", typing: "", timeOut: 10000, leftList: "chatList", connected: true, emojis: false, inputFile: true, selectedFiles: "" });
    const [logUser, setloggedUser] = useState({ loggedUser: {} });
    const [chats, setChats] = useState([]);
    const [otherUser, setOtherUser] = useState({});
    const [userList, setUserList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [comingMsg, setComingMsg] = useState(null);
    const [msgRead, setMsgRead] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([])
    const [progress, setProgress] = useState("");
    const [addimg, setAddimg] = useState({ uploadImage: [] });
    const [notification, setNotification] = useState({ title: "", body: "" })
    const [otherUserchanges, setOtherUserchanges] = useState({})
    
    const[lightbox,setLightbox]=useState(false)
    const[lightboxFile,setLightboxFile] = useState({file:""})
  const [modals, setModals] = useState({ show: false });
   

    
    // console.log("state",lightbox)
   





   


    // onMessageListener().then((payload) => {
    //    // console.log("app.js payload ", payload.notification);

    //     setNotification({ title: payload.notification.title, body: payload.notification.body })

    // }).catch((err) => {
    //     // console.log("app.js Error: message listner ");
    //     //toast.error(JSON.stringify(err));
    // });
    useEffect(() => {
        socket.current = io(ENDPOINT, { query: { id: userdata.user_id, token: "456" }, transports: ["websocket", "polling"] });
        user_detail()
        user_chat_list()
        user_chat_history()

    }, [])

    //  useEffect(() => {


    // return () => {
    //     socket.off('connect');
    //     socket.off('disconnect');
    //     socket.off('pong');
    // };

    // }, []);


    // const socketConfig = () => {
    useEffect(() => {
        if (socket.current) {
           // console.log("----socket---   ");

            socket.current.on('connect', () => {

                // let message = {loggedUser:userdata.user_id,otherUser:other_id};
               // console.log("connected  ");
                
                //socket.emit('ONLINE_OFFLINE', message);

                //setIsConnected(true);

            });
            socket.current.on('disconnect', () => {
               // console.log("disconnected");
                // setIsConnected(false);
            });

            socket.current.on('pong', () => {
                // setLastPong(new Date().toISOString());
            });




            socket.current.on('SINGLE_CHAT_TYPING', function (message) {

              //  console.log("typing receive =>     ", message);
                // console.log("state  =>  1   " );
                let yu = 10000;
                if (state.typing == "" && other_id == message.sender) {
                    setState({ ...state, typing: "typing..." })

                    // timehide = timehide + timehide;
                    setTimeout(function () {
                        setState({ ...state, typing: "" })
                    }, yu);
                }
                // setChats({...chats,chatHistory:chats.chatHistory.concat(message)});


            })

            socket.current.on('SINGLE_CHAT_DELIVERED', function (message) {
              //  console.log("chat delivered   ", message);
            })


            socket.current.on('SINGLE_CHAT_SEND_MESSAGE', function (message) {
               // console.log("messsage receive =>     ", message);
                // setChats({...chats,chatHistory:chats.chatHistory.concat(message)});
                if (message) {

                    // user_chat_list()
                    setState({ ...state, typing: "" })
                    setComingMsg(message);
                    // if (message.sender == other_id) {
                    //     console.log("1------->>>  ");
                    //     incomingMessage(message)
                    // } else {
                    //     console.log("2------->>>  ",userList);
                    //     userList.map((item)=> {
                    //         console.log("items =>     ",item);
                    //     })
                    //     //                     userList.map((user)=>{
                    //     // console.log("user  ",user);
                    //     //                     })
                    // }
                }


            })

            socket.current.on('SINGLE_CHAT_READ', function (message) {
               // console.log("chat read");

                setMsgRead(true)
            })




            socket.current.on('ONLINE_OFFLINE', function (message) {
                console.log("user online offline   ", message);
                if (message.receiver.user_id == other_id) {
                    // console.log("user online offline   match");
                    setOtherUserchanges({key:"last_seen",value:message.receiver.last_seen})
                   // setOtherUser(message.receiver)
                }
            })

            socket.current.on('BLOCK_CONTACT', function (message) {
                // console.log("user BLOCK_CONTACT   ", message);
                if(message.receiver == other_id) {
                    // console.log( "BLOCK_CONTACT match  ", message);
                    setOtherUserchanges({key:"chat_status",value:message.status})
                    //setOtherUser({ ...otherUser, chat_status: message.status })
                }
                // if (message.receiver.user_id == other_id) {
                //     setOtherUser(message.receiver)
                // }
            })
            socket.current.on('MESSAGE_DELETED_ON_CHAT', function (message) {
                
                // console.log("MESSAGE_DELETED_ON_CHAT   ", message);
                if (message.receiver == userdata.user_id && message.sender == other_id) {
                    // console.log("hey match");
                   // setOtherUser(message.receiver)
                }
            })

            
        }
    }, [])

    useEffect(() => {
        // console.log("1otherUserchanges    ",otherUserchanges);
        let otherUserDetail = otherUser;
        otherUserDetail[otherUserchanges.key] = otherUserchanges.value;
        // console.log("2otherUserchanges    ",otherUserchanges);
        setOtherUser({...otherUser,otherUserDetail})

    },[otherUserchanges])

    useEffect(() => {
        if (msgRead) {
           // console.log("useEffect yes");
           // console.log("--->  ", chats);
            for (let i = 0; i < chats.length; i++) {
                if (chats[i].message_status == undefined || chats[i].message_status == 1) {
                    chats[i].message_status = 2;
                  //  console.log(chats[i]);
                }

            }
            setMsgRead(null)

        }
        //console.log("useEffect msg read");
    }, [msgRead])

    useEffect(() => {
        if (comingMsg) {
            //console.log("comingMsg =>     ", comingMsg);

            if (comingMsg.sender == other_id) {
                let indexV = userList.findIndex(item => item.user_id == comingMsg.sender);
                incomingMessage(comingMsg);

                if (indexV != -1) {
                    userList[indexV].message = comingMsg.message;
                    userList[indexV].created = comingMsg.created;
                } else {
                    let leftUsers = userList;
                    
                    leftUsers.unshift(comingMsg);
                    setUserList(leftUsers);
                }
            } else {
               // console.log("userList =>     ", userList);
                user_chat_list()
                let indexV = userList.findIndex(item => item.user_id == comingMsg.sender);
               // console.log("indexV =>     ", indexV);
                if (indexV != -1) {
                    // let lists = userList[indexV];
                    //delete userList[indexV];
                    //let listAll = userList;

                    //    userList[indexV].message = comingMsg.message;
                    //   userList[indexV].created = comingMsg.created;                  
                    // userList[indexV].unread_message = userList[indexV].unread_message + 1;

                    // console.log("userList =>     ", userList);                
                    //listAll.unshift(lists);
                    //console.log("listAll =>     ", listAll);
                    //setUserList(listAll);

                } else {
                    // let lists = userList;
                    //lists.unshift(comingMsg);
                    //setUserList(lists);
                }

            }
            // userList.map((item) => 
            //     console.log("items =>     ", item.user_id);
            // })






        }
    }, [comingMsg])

    useEffect(() => {

        //console.log("chats ");
        if(chats.length > 0) {
        scrollToBottom()
        }
    }, [chats])

    useEffect(() => {
        // setTimeout(function () {
        //     setState({ ...state, typing: "" })
        //  }, yu);    

        if (state.message == "") {
           // console.log("no message");
            setState({ ...state, inputFile: true })
        } else {
            setState({ ...state, inputFile: false })
        }

    }, [state.message])




    const scrollToBottom = () => {
        messagesEnd.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
        // messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    const user_detail = () => {
        //console.log("user_detail");
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/profile_open`;
        let sendData = { user_id: userdata.user_id };

        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setloggedUser({ ...state, loggedUser: res.data.output[0] });
            // console.log("logged in user detail", res);

        }).catch((e) => {
            // toast.configure()
            //toast.error("Some thing went wrong")
            //console.log("----error:   ", e);
        })
    }

    const user_chat_history = (otherUserId) => {
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/chat/chat_history`;
        let sendData = { sender: other_id, receiver: userdata.user_id };

        if (otherUserId) {
            sendData.sender = otherUserId;
        }
       // console.log("sendData =>    ", sendData);
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            // console.log("chat history1", res);
            if (res.data.output) {
                setChats(res.data.output);
            }
            setOtherUser(res.data.userDetail)

        }).catch((e) => {
            // toast.configure()
            //toast.error("Some thing went wrong")
            // console.log("----error:   ", e);
        })
    }

    const user_chat_list = () => {
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/chat/user_chat_list`;
        let sendData = { user_id: userdata.user_id };

        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setUserList(res.data.output);

            // console.log("chat list", res);
        }).catch((e) => {
            // toast.configure()
            //toast.error("Some thing went wrong")
            // console.log("----error:   ", e);
        })
    }

    const user_message_seen = (other_id) => {
        // if(message.sender == other_id) {
        socket.current.emit('SINGLE_CHAT_READ', { sender: other_id, receiver: userdata.user_id });
        // }
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/chat/user_chat_list_seen`;
        let sendData = { loggedUser: userdata.user_id, otherUser: other_id };
       // console.log("sendData->        ", sendData);
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          //  console.log("chat list", res);
        }).catch((e) => {
            // toast.configure()
            //toast.error("Some thing went wrong")
            // console.log("----error:   ", e);
        })
    }

    const handleChange = (event) => {
        //console.log("typing");
        let chatMessage = { sender: userdata.user_id, receiver: other_id };
        socket.current.emit('SINGLE_CHAT_TYPING', chatMessage);
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });

    };

    const handleKeyDown = (event) => {
        if (event.keyCode == 13) {
            sendMessage()
        }


    };

    const sendMessage = () => {
        if (state.message != "" || state.selectedFiles != '') {
            let chatMessage = { sender: userdata.user_id,full_name: userdata.full_name, receiver: other_id, message_status: 1, files: [] };
            let newDate = new Date();
            let strtime = newDate.getTime();
            chatMessage.created = parseInt(newDate.getTime() / 1000)
            if (state.message != "") {
                chatMessage.message = state.message;
            }
            if (state.message != "" && state.selectedFiles == "") {
               // console.log("plain message-  ", state.message);
                chatMessage.message_type = 1;
                var res = state.message.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (res != null) {
                    chatMessage.message_type = 3;
                }
                socket.current.emit('SINGLE_CHAT_SEND_MESSAGE', chatMessage);
                incomingMessage(chatMessage);
            } else if (state.selectedFiles != "") {
              //  console.log("file message-  ", state.selectedFiles);
                //chatMessage.files = state.selectedFiles


               // console.log(chatMessage);
              //  console.log("---->     ", state.selectedFiles[0]);
              //  var file_name = 'logo_' + strtime + '.jpg';
               
                //console.log("upload file name -     ", file_name);
              //  let image_path = './web/' + file_name;
                let bodyFormData = new FormData()
               // let file_type = 'image/jpeg';
              //  let image = state.selectedFiles[0];
                // formData.append("file", chatMessage.files[0])
                //bodyFormData.append('keys', 'APA91bHLuizLdddWact8kQkgffRTsvPSODhuasd3Iv5IfUkd-k2LUW9MUDaVZat7DjsB7k6RI3RLQsiWzcMINLAkJ649m03smf8X3dKL6L');
               // bodyFormData.append('image_path', image_path);
               // bodyFormData.append('type', 'upload');

               if(state.selectedFiles.length > 0){
                    for (var i = 0; i < state.selectedFiles.length; i++) {
                        let productimages = state.selectedFiles[i];
                        bodyFormData.append("fileImage",productimages)
                    }
                }
              //  bodyFormData.append('fileImage', image);
                //bodyFormData.append('image_type', file_type);
                // const formHeaders = bodyFormData.getHeaders();
                let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/chat/chat_image_upload`;
                Axios.post(full_api, bodyFormData, {
                    mode: 'no-cors',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                       // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        "Content-Type": "multipart/form-data",
                      // "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                       
                    },                   
                   
                    proxy: {
                        host: 'http://localhost',
                        port: 3000
                    },
                    onUploadProgress: data => {
                       // console.log("upload data  ", data);
                        //Set the progress value to show the progress bar
                        //console.log("progress-     ", Math.round((100 * data.loaded) / data.total))
                        //setProgress(Math.round((100 * data.loaded) / data.total));
                    },
                }).then(async function (response) {
                    // console.log("upload response  ", response);
                  //  setProgress("")
                    setAddimg({ ...addimg, uploadImage: [] });
                    if (response.data && response.data.status == "success") {
                       // console.log("chatMessage ---->    ", chatMessage);
                       chatMessage.files = response.data.output;
                       chatMessage.message_type = 2;
                        socket.current.emit('SINGLE_CHAT_SEND_MESSAGE', chatMessage);
                        incomingMessage(chatMessage);
                    }
                })
                    .catch((err) => {
                        // console.log("error found  ", err);
                    })
            }

            //user_chat_list()
            // let old_chats = chats;
            // old_chats.push(chatMessage);
            // incomingMessage(chatMessage);
            // console.log(newDate.getTime()/1000)



            let indexV = userList.findIndex(item => item.user_id == chatMessage.receiver);
            if (indexV != -1) {
                let lists = userList[indexV];
                delete userList[indexV];
                let listAll = userList;

                lists.message = chatMessage.message;
                lists.created = chatMessage.created;
               // console.log("lists =>     ", lists);
                listAll.unshift(lists);
                //console.log("listAll =>     ", listAll);
                setUserList(listAll);
            } else {
                let listAll = userList;
                chatMessage.full_name = otherUser.full_name;
                chatMessage.user_img = otherUser.user_img;
               // console.log("chatMessage - - > ", chatMessage);
                listAll.unshift(chatMessage);
                setUserList(listAll);
            }
            setState({ ...state, message: "", emojis: false, selectedFiles: "" });
        }

    };

    const incomingMessage = (message) => {
        if (message) {
            // let old_chats = chats;
            //  old_chats.push(message);
            // console.log("message =>     ", message);
           message.user_id = message.receiver;
            setChats(chats.concat(message));
            scrollToBottom()
            if (message.sender == other_id) {
                socket.current.emit('SINGLE_CHAT_READ', message);
            }

        }
    }

    const changeUser = (item) => {
        //   console.log("changeUser item =>   ", item);
        item.unread_message = 0;
        let indexV = userList.indexOf(item);
        userList[indexV].unread_message = 0
        // console.log("changeU ", indexV);
        setUserList(userList);
        setState({ ...state, otheruserId: item.user_id });
        user_message_seen(item.user_id)
        user_chat_history(item.user_id);
        navigate(`/chat-box/${item.user_id}`)
    }

    const changeUserSearch = (item) => {
        //  console.log("item =>   ", item);
        // item.unread_message = 0;
        // let indexV = userList.indexOf(item);
        // userList[indexV].unread_message = 0
        // // console.log("changeU ", indexV);
        // setUserList(userList);
       // setSearchList([])
        setState({ ...state, otheruserId: item.user_id });
        user_message_seen(item.user_id)
        user_chat_history(item.user_id);
        navigate(`/chat-box/${item.user_id}`)
    }

    const LastSeen = (value) => {
        // console.log("val =>   ", value.time);
        let newDate = new Date();
        
            if( value.time != undefined && value.time != "online") {
                let valueTime = parseInt(value.time);
                    let todayDate = moment(newDate).format("DD-MM-YYYY");
                    //console.log("todaydate == ",todayDate);
                    let userDate = moment(value.time * 1000).format("DD-MM-YYYY");
                    //console.log("user date - -  ",userDate);
                // userDate + + ""
                    let addTime = (valueTime * 1000) + 86400000;
                   // console.log("addTime  - -  ",addTime);
                    let addedDate = moment(addTime).format("DD-MM-YYYY");
                   // console.log("addedDate date - -  ",addedDate);
                    let io = newDate.getTime() - (value.time * 1000);
                    let yu = "";

                    if(io < 300000){
                        yu = "Just now"
                    }else if(io > 300000 && todayDate == userDate) {
                        let time = moment(value.time * 1000).format("hh:mm");
                        yu = "Today "+time
                    }else if(io > 300000 && todayDate == addedDate) {
                        let time = moment(value.time * 1000).format("hh:mm");
                        yu = "Yesterday "+time
                    }else if(todayDate > addedDate) {
                        yu = moment(value.time * 1000).format("DD-MM-YYYY hh:mm");        
                    }
                    //console.log("io   ",io)
                    return "last seen " + yu 
                }else {
                    return value.time;
                }

                
        // humanizeDuration(io, { units: ["d", "h", 'm', 's'], maxDecimalPoints: 0 });
        // console.log("yu",yu);
        
    }

    const searchUser = (e) => {
       // console.log(e);
        if (e.target.value != "") {
            setState({ ...state, leftList: "searchList" });
            let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/chat/user_search`;
            let sendData = { keyword: e.target.value };

            Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {

               // console.log("chat list", res);
                setSearchList(res.data.output)
            }).catch((e) => {
                // toast.configure()
                //toast.error("Some thing went wrong")
                // console.log("----error:   ", e);
            })
        } else {
            setState({ ...state, leftList: "chatList" });
        }
    }

    const userListdateTime = (dateTimeStr) => {
        // console.log(dateTimeStr);
        let newDate = Date.now();
        let todaydate = moment(newDate).format("DD-MM-YYYY");
        let date = moment(dateTimeStr * 1000).format("DD-MM-YYYY");
        let time = moment(dateTimeStr * 1000).format("hh:mm");
        //console.log("time ------ ",time);
        return (todaydate != date) ? date : time;
    }

    const onEmojiClick = (event, emojiObject) => {
        //console.log(emojiObject.emoji);
        // setChosenEmoji(emojiObject);
        let messages = state.message + "" + emojiObject.emoji;
        setState({ ...state, message: messages })

    };
    const inputHandleChange = (e) => {
       
        let fileLimit = 5;
        let target = e.target;
      //  console.log("target ---->     ", target.files);
        if (target.files.length <= fileLimit) {
            setState({
                ...state,
                [e.target.name]: e.target.files,
            });
            let imagesArray = [];
            // console.log("target ------>     ",e.target.files);
            let files = Array.from(e.target.files);
            // console.log("files ------>     ",files);
            files.map((file)=>{
                imagesArray.push(URL.createObjectURL(file))
            })
            // console.log("imagesArray ------>     ",imagesArray);
            setAddimg({ ...addimg, uploadImage: imagesArray })
            // setState({ ...state, selectedFiles: target.files })

        } else {
           // console.log("----more file---");
            toast.warning("Submitted succesfully");
        }
    }
    const MessageView = (data) => {
        //console.log("Value ->> ", data);
        let msg = data.value;
        if (msg != undefined && msg != "") {
            // console.log("msg ->> ",msg);
            msg = toEmoji.emoticonToEmoji(msg);
            // console.log("msg ->> ",msg);
            emoji_collections.map((val) => {
                msg = (val.symbol == msg) ? val.emoji : msg;
            })
            msg = emoji.emojify(msg);
            return msg;

        }
    }


    var emoji_collections = [
        { symbol: 'o/', emoji: '👋' },
        { symbol: '</3', emoji: '💔' },
        { symbol: '<3', emoji: '💗' },
        { symbol: '{8-D', emoji: '😁' },
        { symbol: '8D', emoji: '😁' },
        { symbol: ':-D', emoji: '😁' },
        { symbol: '=-3', emoji: '😁' },
        { symbol: '=-D', emoji: '😁' },
        { symbol: '=3', emoji: '😁' },
        { symbol: '=D', emoji: '😁' },
        { symbol: 'B^D', emoji: '😁' },
        { symbol: 'X-D', emoji: '😁' },
        { symbol: 'XD', emoji: '😁' },
        { symbol: 'x-D', emoji: '😁' },
        { symbol: 'xD', emoji: '😁' },
        { symbol: ':\')', emoji: '😂' },
        { symbol: ':\'-)', emoji: '😂' },
        { symbol: ':-))', emoji: '😃' },
        { symbol: '8)', emoji: '😄' },
        { symbol: ':)', emoji: '😄' },
        { symbol: ':-)', emoji: '😄' },
        { symbol: ':3', emoji: '😄' },
        { symbol: ':D', emoji: '😄' },
        { symbol: ':]', emoji: '😄' },
        { symbol: ':^)', emoji: '😄' },
        { symbol: ':c)', emoji: '😄' },
        { symbol: ':o)', emoji: '😄' },
        { symbol: ':}', emoji: '😄' },
        { symbol: ':っ)', emoji: '😄' },
        { symbol: '=)', emoji: '😄' },
        { symbol: '=]', emoji: '😄' },
        { symbol: '0:)', emoji: '😇' },
        { symbol: '0:-)', emoji: '😇' },
        { symbol: '0:-3', emoji: '😇' },
        { symbol: '0:3', emoji: '😇' },
        { symbol: '0;^)', emoji: '😇' },
        { symbol: 'O:-)', emoji: '😇' },
        { symbol: '3:)', emoji: '😈' },
        { symbol: '3:-)', emoji: '😈' },
        { symbol: '}:)', emoji: '😈' },
        { symbol: '}:-)', emoji: '😈' },
        { symbol: '*)', emoji: '😉' },
        { symbol: '*-)', emoji: '😉' },
        { symbol: ':-,', emoji: '😉' },
        { symbol: ';)', emoji: '😉' },
        { symbol: ';-)', emoji: '😉' },
        { symbol: ';-]', emoji: '😉' },
        { symbol: ';D', emoji: '😉' },
        { symbol: ';]', emoji: '😉' },
        { symbol: ';^)', emoji: '😉' },
        { symbol: ':-|', emoji: '😐' },
        { symbol: ':|', emoji: '😐' },
        { symbol: ':(', emoji: '😒' },
        { symbol: ':-(', emoji: '😒' },
        { symbol: ':-<', emoji: '😒' },
        { symbol: ':-[', emoji: '😒' },
        { symbol: ':-c', emoji: '😒' },
        { symbol: ':<', emoji: '😒' },
        { symbol: ':[', emoji: '😒' },
        { symbol: ':c', emoji: '😒' },
        { symbol: ':{symbol:', emoji: '😒' },
        { symbol: ':っC', emoji: '😒' },
        { symbol: '%)', emoji: '😖' },
        { symbol: '%-)', emoji: '😖' },
        { symbol: ':-P', emoji: '😜' },
        { symbol: ':-b', emoji: '😜' },
        { symbol: ':-p', emoji: '😜' },
        { symbol: ':-Þ', emoji: '😜' },
        { symbol: ':-þ', emoji: '😜' },
        { symbol: ':P', emoji: '😜' },
        { symbol: ':b', emoji: '😜' },
        { symbol: ':p', emoji: '😜' },
        { symbol: ':Þ', emoji: '😜' },
        { symbol: ':þ', emoji: '😜' },
        { symbol: ';(', emoji: '😜' },
        { symbol: '=p', emoji: '😜' },
        { symbol: 'X-P', emoji: '😜' },
        { symbol: 'XP', emoji: '😜' },
        { symbol: 'd:', emoji: '😜' },
        { symbol: 'x-p', emoji: '😜' },
        { symbol: 'xp', emoji: '😜' },
        { symbol: ':-||', emoji: '😠' },
        { symbol: ':@', emoji: '😠' },
        { symbol: ':-.', emoji: '😡' },
        { symbol: ':-/', emoji: '😡' },
        { symbol: ':/', emoji: '😡' },
        { symbol: ':L', emoji: '😡' },
        { symbol: ':S', emoji: '😡' },
        { symbol: ':\\', emoji: '😡' },
        { symbol: '=/', emoji: '😡' },
        { symbol: '=L', emoji: '😡' },
        { symbol: '=\\', emoji: '😡' },
        { symbol: ':\'(', emoji: '😢' },
        { symbol: ':\'-(', emoji: '😢' },
        { symbol: '^5', emoji: '😤' },
        { symbol: '^<_<', emoji: '😤' },
        { symbol: 'o/\\o', emoji: '😤' },
        { symbol: '|-O', emoji: '😫' },
        { symbol: '|;-)', emoji: '😫' },
        { symbol: ':###..', emoji: '😰' },
        { symbol: ':-###..', emoji: '😰' },
        { symbol: 'D-\':', emoji: '😱' },
        { symbol: 'D8', emoji: '😱' },
        { symbol: 'D:', emoji: '😱' },
        { symbol: 'D:<', emoji: '😱' },
        { symbol: 'D;', emoji: '😱' },
        { symbol: 'D=', emoji: '😱' },
        { symbol: 'DX', emoji: '😱' },
        { symbol: 'v.v', emoji: '😱' },
        { symbol: '8-0', emoji: '😲' },
        { symbol: ':-O', emoji: '😲' },
        { symbol: ':-o', emoji: '😲' },
        { symbol: ':O', emoji: '😲' },
        { symbol: ':o', emoji: '😲' },
        { symbol: 'O-O', emoji: '😲' },
        { symbol: 'O_O', emoji: '😲' },
        { symbol: 'O_o', emoji: '😲' },
        { symbol: 'o-o', emoji: '😲' },
        { symbol: 'o_O', emoji: '😲' },
        { symbol: 'o_o', emoji: '😲' },
        { symbol: ':$', emoji: '😳' },
        { symbol: '#-)', emoji: '😵' },
        { symbol: ':#', emoji: '😶' },
        { symbol: ':&', emoji: '😶' },
        { symbol: ':-#', emoji: '😶' },
        { symbol: ':-&', emoji: '😶' },
        { symbol: ':-X', emoji: '😶' },
        { symbol: ':X', emoji: '😶' },
        { symbol: ':-J', emoji: '😼' },
        { symbol: ':*', emoji: '😽' },
        { symbol: ':^*', emoji: '😽' },
        { symbol: 'ಠ_ಠ', emoji: '🙅' },
        { symbol: '*\\0/*', emoji: '🙆' },
        { symbol: '\\o/', emoji: '🙆' },
        { symbol: ':>', emoji: '😄' },
        { symbol: '>.<', emoji: '😡' },
        { symbol: '>:(', emoji: '😠' },
        { symbol: '>:)', emoji: '😈' },
        { symbol: '>:-)', emoji: '😈' },
        { symbol: '>:/', emoji: '😡' },
        { symbol: '>:O', emoji: '😲' },
        { symbol: '>:P', emoji: '😜' },
        { symbol: '>:[', emoji: '😒' },
        { symbol: '>:\\', emoji: '😡' },
        { symbol: '>;)', emoji: '😈' },
        { symbol: '>_>^', emoji: '😤' }
    ];


    const nameFormat = (name)=> {
        
        if(name) {
            let nameArray =  name.split(" ");
           // console.log("nameArray  ",nameArray);
            if(nameArray.length > 1) {
                let n1 = nameArray[0].substring(0,1).toUpperCase();
                let n2 = nameArray[1].substring(0,1).toUpperCase();
                name = n1+n2;
            }else {
                name = nameArray[0].substring(0,2).toUpperCase();
            }
        }else{
            name = "NO";
        }
        return name;
    }
    


    const handleOpen=(files)=>{           
        setLightboxFile(files.files)
        setLightbox(true)        
    }
    
    const add_user_blocked = (status) => {
        // event.preventDefault();
   
        // console.log("status",status);
       
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/chat/block_users`;
            let sendData = { blocked_id:other_id,who_blocked_id:userdata.user_id , dstatus:status };
            // console.log("svv",sendData)
          
            // console.log("sccc",sendData);
            Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
              if(res && res.data != undefined){
                       
                //toast.configure()
               // toast(res.data.message)
                //let otherUserDetail = otherUser;
                // console.log("otherUser",otherUser);
               // otherUserDetail['chat_status'] = status;
               // console.log("otherUserDetail",otherUserDetail);
               let chatStatus = (status == 2) ? 3 : 1;
               let message = { sender: other_id, receiver: userdata.user_id, status: chatStatus };
               socket.current.emit('BLOCK_CONTACT', message);
               
              
               
                setOtherUser({...otherUser,chat_status:status})
            //    console.log("ssss",res);
               
              }
              
            }).catch((e) => {
              
        
              toast.configure();
              toast.error("Some thing went wrong");
            //   console.log("----error:   ", e);
            });
         
          };

     const delete_message=()=>{
     setModals({show: true })
  }   
  const handleClose = (message) => {
   
    socket.current.emit('DELETE_FOR_EVERYONE_CHAT', message);
    //   console.log("handleClose  ",message);
   
    setModals({ show: false })
  }
  const handleClose1 = () => {
    setModals({ show: false })
  }
  const goback = () => {
   navigate("/chat-box/")
  }

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
   function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }
  
    const { height, width } = useWindowDimensions();
    return (
         <div >
             
            <Header />
            
                

            { (lightbox)?          
                <Lightbox           
                medium={"https://shippxcdn.fniix.com/web/"+lightboxFile}
                large={"https://shippxcdn.fniix.com/web/" + lightboxFile}           
                onClose={() => setLightbox(false)} />
                    :""
            }
           
<div>
{chats.map((value)=>(
<Modal show={modals.show} onHide={handleClose1}>

      <Modal.Header closeButton>
        <Modal.Title>Remove Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you really want to remove message ?</Modal.Body>
     
    
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>setModals({show:false})}>
        Cancel
        </Button>
        <Button variant="primary" onClick={()=>handleClose(value)}>
          Remove
        </Button>
      </Modal.Footer>
      
    
    </Modal>
))}
</div>


       
            <div style={styles.body}>
                {(notification.body != "") ?
                    <NotificationComponent noti={notification} /> : ""}
                <div className="container" >

                    <section>
                        <div className="container pt-3">

                            <div className="row">
                                <div className="col-md-12">

                                    <div className="card chat_window" id="chat3" >
                                        <div className="card-body12">

                                            <div className="row chat_window_inner">
                                                <div className={ (width<=766 && otherUser=="")?"col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0 overflow-auto bg-light chat_window_left":(width<=766 && otherUser!=="")?"d-block d-none":"col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0 overflow-auto bg-light chat_window_left"}>

                                                    <div className="p-3 ">
                                                        <section className="avatar_header_main  pb-3 ">
                                                            <div className="d-flex ">
                                                                <div className="p-2 ">
                                                         {logUser.loggedUser.profile_image=="" || logUser.loggedUser.profile_image==undefined? <img src={"https://dummyimage.com/600x400/f0739d/ffffff&text="+nameFormat(logUser.loggedUser.full_name)} className="" />:<img src={"https://shippxcdn.fniix.com/web/"+logUser.loggedUser.profile_image} className="" />}
                                                                </div>
                                                                <div className="p-2 w-50">
                                                                    <h5>{logUser.loggedUser.full_name}</h5>
                                                                    <p className="text-muted"><span className="text-success"><i className="fa fa-circle" aria-hidden="true"></i> Online</span></p>
                                                                </div>
                                                                <div className="p-2 ">
                                                                    <div className="text-end">
                                                                        <span><a href="" className="head_icon" title="Go to home"><i className="fa fa-home " aria-hidden="true"></i></a></span> &nbsp;
                                                                        <span><a href="" className="head_icon" title="logout"><i className="fa fa-power-off" aria-hidden="true"></i></a></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </section>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="input-group rounded mb-3 border">
                                                                    <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search"
                                                                        aria-describedby="search-addon" onChange={(e) => searchUser(e)} />
                                                                    <span className="input-group-text border-0" id="search-addon">
                                                                        <i className="fa fa-search"></i>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div data-mdb-perfect-scrollbar="true" style={{ position: "relative", height: "470px" }}>
                                                            {(state.leftList == "chatList") ?
                                                                <ul className="list-unstyled mb-0">
                                                                    {userList.map((item, index) =>
                                                                        <li className={"p-2 border-bottom" + (item.receiver == state.otheruserId ? " active2" : "")} key={index} onClick={() => changeUser(item)}>
                                                                            <a onClick={()=>changeUser(item)} style={{cursor:"pointer"}} className="d-flex justify-content-between">
                                                                                <div className="d-flex flex-row">
                                                                                    <div className="pt-1">
                                                                                   {item.profile_image==""|| item.profile_image==undefined?     <img  src={"https://dummyimage.com/600x400/000/ffffff&text="+nameFormat(item.full_name)} alt="avatar" className="d-flex align-self-center me-3 avatar2" width="" />:<img  src={"https://shippxcdn.fniix.com/web/"+item.profile_image} alt="avatar" className="d-flex align-self-center me-3 avatar2" width="" />}
                                                                                        <span className="badge bg-success badge-dot"></span>
                                                                                    </div>
                                                                                    <div className="pt-1">
                                                                                        <p className="fw-bold mb-0" style={{cursor:"pointer"}}>{item.full_name}</p>
                                                                                        {(item.message_type == 1) ? 
                                                                                        <p className="small text-muted" style={{cursor:"pointer"}}>{item.message}</p> : ""}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="pt-1">
                                                                                    <p className="small text-muted mb-1">{userListdateTime(item.created)}</p>
                                                                                    {(item.sender != userdata.user_id && item.unread_message > 0) ?
                                                                                        <span className="badge bg-danger rounded-pill float-end">{item.unread_message}</span> : ""}
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    )}

                                                                </ul>
                                                                : <ul className="list-unstyled mb-0">

                                                                    {searchList.map((item, index) =>
                                                                        <li className={"p-2 border-bottom" + (item.receiver == state.otheruserId ? " active2" : "")} key={index} onClick={() => changeUserSearch(item)}>
                                                                            <a onClick={() => changeUserSearch(item)} style={{cursor:"pointer"}} className="d-flex justify-content-between">
                                                                                <div className="d-flex flex-row">
                                                                                    <div className="pt-1">
                                                                                    {item.profile_image=="" || item.profile_image==undefined? 
                                                                                        <img
                                                                                            src={"https://dummyimage.com/600x400/000/fff&text="+nameFormat(item.full_name)}  alt="avatar" className="d-flex align-self-center me-3 avatar2" width="" />:<img  src={"https://shippxcdn.fniix.com/web/"+item.profile_image} alt="avatar" className="d-flex align-self-center me-3 avatar2" width="" />}
                                                                                        <span className="badge bg-success badge-dot"></span>
                                                                                    </div>
                                                                                    <div className="pt-1">
                                                                                        <p className="fw-bold mb-0" style={{cursor:"pointer"}}>{item.full_name}</p>
                                                                                        <p className="small text-muted" style={{cursor:"pointer"}}>{item.message}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="pt-1">
                                                                                    <p className="small text-muted mb-1">{item.created}</p>
                                                                                    {(item.sender != userdata.user_id && item.unread_message > 0) ?
                                                                                        <span className="badge bg-danger rounded-pill float-end">{item.unread_message}</span> : ""}
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                                      
                                                <div className={(width <= 766 && otherUser==""?" d-block d-none":"col-md-6 col-lg-7 col-xl-8")}>
                                                    {otherUser==""?"":
                                                    <section className="avatar_header border-bottom pb-1 pt-1">
                                                        <div className="row">
                                                        <div className="d-flex">
                                                            {width<=766?
                                                       <a href="" onClick={()=>goback()}> <i className= "fa fa-arrow-left fa-2x mt-3"></i></a>:""}
                                                            <div className="col-md-1">
                                                           
                                                            <div className="p-2" style={(width<=766 ? {marginLeft:"0.5em"}:{marginLeft:"0em"} )} >
                                                               
                                                             { otherUser.profile_image==undefined || otherUser.profile_image==""? 
                                                            <img src={"https://dummyimage.com/600x400/719bf0/ffffff&text="+nameFormat(otherUser.full_name)}  />:<img src={"https://shippxcdn.fniix.com/web/"+otherUser.profile_image} className="" />}
                                                            </div>
                                                            </div>
                                                            <div className="col-md-9" style={{alignContent:"flex-start"}}>
                                                            <div className="p-2 ">
                                                                <h5>{otherUser.full_name}</h5>
                                                                <p className="text-muted">{(otherUser.last_seen != "online") ? <LastSeen time={otherUser.last_seen} /> : <span className="text-success"><i className="fa fa-circle" aria-hidden="true"></i> Online</span>}</p>
                                                            </div>
                                                            </div>
                                                           
                                                            <div className="col-md-2 mt-2" >
                                                           
                                                                 <nav className="justify-content-end d-flex flex-row">
                                                                 <i class="fa-solid fa-ellipsis-vertical d-flex flex-row justify-content-start mt-2" style={{ position: "absolute", top: "12px", right: "18px"}}  id="navbarDropdown"  data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                                <ul className="dropdown-menu"  aria-labelledby="navbarDropdown">
       
                                                                {(otherUser.chat_status == 1)? <li><a className="dropdown-item" onClick={() => add_user_blocked(2)}>Block</a></li>
                                                                 :(otherUser.chat_status == 2) ? <li><a className="dropdown-item" onClick={() => add_user_blocked(1)}>Unblock</a></li>:"" }
                                                                  </ul>
                                                                     </nav>                                   
                                                               
                                                                </div>
                                                        </div>
                                                        </div>
                                                    </section>
                                                        }
                                                    {otherUser==""?
                                                 <div>
                                                     <section className="avatar_header_main  pb-3 ">
                                                            <div className="d-flex " style={{marginLeft:"250px"}}>
                                                                <div className="p-2 " style={{marginTop:"260px"}}>
                                                                {logUser.loggedUser.profile_image==""|| logUser.loggedUser.profile_image==undefined? <img src={"https://dummyimage.com/600x400/f0739d/ffffff&text="+nameFormat(logUser.loggedUser.full_name)} className="" />:<img src={"https://shippxcdn.fniix.com/web/"+logUser.loggedUser.profile_image} className="" />}
                                                                </div>
                                                                <div className="p-2 w-50" style={{marginTop:"250px"}}>
                                                                    <h3>Welcome!</h3>
                                                                    <h4 className="">{logUser.loggedUser.full_name}</h4>
                                                                  
                                                                </div>
                                                                
                                                            </div>

                                                        </section>
                                                 </div>
                                                    :
                                                    <div className="pt-3 pe-3 overflow-auto" data-mdb-perfect-scrollbar="true"
                                                        style={{ position: "relative", height: "470px" }}>
                                                        {chats.map((value, index) =>
                                                            (value.sender != userdata.user_id) ?
                                                                <div className="d-flex flex-row justify-content-start" key={index}>
                                                                   {value.profile_image==undefined || value.profile_image==""? <img src={"https://dummyimage.com/600x400/719bf0/ffffff&text="+nameFormat(value.full_name)}
                                                                        alt="avatar 1" className="avatar" style={{marginRight:"10px"}} />:<img src={"https://shippxcdn.fniix.com/web/"+value.profile_image}alt="avatar 1" className="avatar" style={{marginRight:"10px"}} />}

                                                              

                                                                    <div className="chat_msg " style={{marginTop:"10px",marginBottom:"10px"}}>
                                                                    {(value.message_type == 1 ) ? 
                                                                    <>
                                                                        <p className="small p-2 me-3 mb-1 text-dark msgbody_light " style={{marginLeft:"12px"}}><MessageView value={value.message} /></p>  
                    
              </>:"" }
    
                                                                    {(value.message_type == 3 ) ?
                                                                    <div style={{} } >
                                                                        <>
                                                                       <Url_view value={value.message}/>
                                                                      
                                                                       </>
                                                                       </div>:"" }
                                                                      

                                                                        {(value.files.length > 0) ?    
                                                                        
                                                                        value.files.map((file, index) =>                                               <div style={{display:"flex"}}><>
<img src={"https://shippxcdn.fniix.com/web/" + file} style={{width:"20%",float:"left", marginBottom:"10px",marginLeft:"12px"}} onClick={() => handleOpen({ files:file })} height="120px" />

</></div>
                                                                        )
: ""}

              <p className="small ms-3 mb-3 rounded-3 text-muted  msg_left"><Moment format="hh:mm | MMM DD" unix>{value.created}</Moment> </p>                                                      </div>
                                                                </div>
                                                                :
                                                                <div className="d-flex flex-row justify-content-end" key={index}>
                                                                    <div className="chat_msg">
                                                                        {(value.message_type == 1) ?
                                                                        <>
                                                                            <p className="small p-2 me-3 mb-1 text-white msgbody_dark"><MessageView value={value.message} /></p>
                                                                            <nav className="justify-content-end d-flex flex-row">
                                                                             {/* <i class="fa-solid fa-ellipsis-vertical d-flex flex-row justify-content-start mt-2"  id="navbarDropdown"  data-bs-toggle="dropdown" aria-expanded="false"></i> */}
                                                                            <ul className="dropdown-menu"  aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" ><i class="fa-solid fa-copy" style={{marginRight:"5px"}}></i>Copy</a></li>
                    <li><a className="dropdown-item" onClick={() => delete_message()}><i class="fa-solid fa-trash-can" style={{marginRight:"5px"}}></i>Remove</a></li>
                    <li><a className="dropdown-item" ><i class="fa-solid fa-share"  style={{marginRight:"5px"}}></i>Forward</a></li>
                    {/* <li><a className="dropdown-item"></a></li>
                    <li></li> */}
                  
                  </ul>
                                 </nav>                                           </>: ""}


                                                                    {(value.message_type == 3 ) ? 
                                                                    <>
                                                                    <div style={{display:"flex",justifyContent:"flex-end" }} >
                                                                        
                                                                       <Url_view value={value.message }  />
                                                                       </div> <nav className="justify-content-end d-flex flex-row">
                                                                      
                                                                        {/* <i class="fa-solid fa-ellipsis-vertical d-flex flex-row justify-content-end" style={{marginLeft:"115px"}}  id="navbarDropdown"  data-bs-toggle="dropdown" aria-expanded="false" ></i> */}
                                                                        <ul className="dropdown-menu"  aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" ><i class="fa-solid fa-copy" style={{marginRight:"5px"}}></i>Copy</a></li>
                    <li><a className="dropdown-item" onClick={() => delete_message()}><i class="fa-solid fa-trash-can" style={{marginRight:"5px"}}></i>Remove</a></li>
                    <li><a className="dropdown-item" ><i class="fa-solid fa-share"  style={{marginRight:"5px"}}></i>Forward</a></li>
                    {/* <li><a className="dropdown-item"></a></li>
                    <li></li> */}
                  
                  </ul></nav>
                                                                        </>:"" }
                                                                           
                                                                        
         
                                                                            
                                                                       
                                                                        {(value.files.length > 0) ?
                                                                         value.files.map((file, index) =>  


                                                                     <div style={{display:"flow-root"}}>    <> 
<img src={"https://shippxcdn.fniix.com/web/" + file} style={{ width: "20%", float: "right" ,marginBottom:"10px",marginRight:"15px" }} height="120px" onClick={() => handleOpen({ files:file })}/>
<nav className="justify-content-end d-flex flex-row">
{/* <i class="fa-solid fa-ellipsis-vertical " style={{marginBottom:"7px",marginTop:"130px",marginRight:"-160px" }}  id="navbarDropdown"  data-bs-toggle="dropdown" aria-expanded="false"></i> */}
<ul className="dropdown-menu"  aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" ><i class="fa-solid fa-copy" style={{marginRight:"5px"}}></i>Copy</a></li>
                    <li><a className="dropdown-item" onClick={() => delete_message()} ><i class="fa-solid fa-trash-can" style={{marginRight:"5px"}}  ></i>Remove</a></li>
                    <li><a className="dropdown-item" ><i class="fa-solid fa-share"  style={{marginRight:"5px"}}></i>Forward</a></li>
                    {/* <li><a className="dropdown-item"></a></li>
                    <li></li> */}
                  
                  </ul></nav>
</>

</div>  
                                                                         )
: ""}
 <div> 
 <p className="small me-3 mb-3 rounded-3 text-muted float-end msg_right"><Moment format="hh:mm | MMM DD" unix>{value.created}</Moment>  <i className={"fa-solid fa-check-double" + (value.message_status == 2 ? " message_seen" : "")}></i></p>
 </div>
                                                                    </div>
                                                                    {value.profile_image=="" || value.profile_image==undefined?<img src={"https://dummyimage.com/600x400/f0739d/ffffff&text="+nameFormat(value.full_name)} alt="avatar 1" className="avatar" />:<img src={"https://shippxcdn.fniix.com/web/"+value.profile_image} alt="avatar 1" className="avatar" />}
                                                                </div>
                                                        )}


                                                        <div id="!" ref={messagesEnd}></div>


                                                    </div>
                                                    }
                                                      {(otherUser && otherUser.chat_status == 1) ?
                                                    <section className="send_msg" >
                                                        <div>
                                                        {progress && <ProgressBar now={progress} label={`${progress}%`} />
                                                            } 
                                                   {addimg.uploadImage.map((image,index) => 
                                                        
                                                             
                                                            
                                                                <img src={image} style={{ width: "37px", marginRight:"5px"}} className="mt-3" key={index}/>
                                                            
                                                        
                                                        )}
                                                            </div>
                                                        <span style={{ fontSize: "15px", color: "#719bf0", display: "block", position: "inherit" }}>{state.typing}</span>


                                                        <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-1 mt-2 border-top">
                                                            <img src={"https://dummyimage.com/600x400/f0739d/ffffff&text="+nameFormat(userdata.full_name)}
                                                                alt="avatar 3" className="avatar" />
                                                            <input type="text" className="form-control form-control-lg w-75" id="" name="message" onChange={(e) => handleChange(e)} value={state.message}
                                                                onKeyDown={(e) => handleKeyDown(e)} placeholder="Type message" />
                                                            {(state.inputFile) ?
                                                                <label>
                                                                    <i className="fa fa-paperclip" htmlFor="file-input" style={{cursor:"pointer"}}>
                                                                    </i>
                                                                    <input type="file" onChange={(e) => inputHandleChange(e)} name="selectedFiles" id="file-input" style={{ display: "none" }} multiple />
                                                                </label> : ""}
                                                            <a className="ms-3 text-muted" href="#!"><i className="fa fa-smile-o" onClick={() => setState({ ...state, emojis: (state.emojis) ? false : true })}></i></a>

                                                            <a className="ms-3" href="#!"><i className="fa fa-paper-plane" onClick={() => sendMessage()}></i></a>
                                                        </div>

                                                        {(state.emojis) ? <div style={{ float: "right", marginTop: "7px" }}> <Picker onEmojiClick={onEmojiClick} /></div> : ""}

                                                    </section>
                                        :(otherUser.chat_status == 2) ? <div><p> Unblock to chat..!</p></div>:(otherUser.chat_status == 3)? <div><p> User has blocked you..</p></div>:"" }
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                    {width<=768?"":
                    <footer className="footer_chat   text-muted pt-4 text-end mb-4">
                        <div className="row">
                            <div className="col">
                                <p>Copyright @ 2022 | Powered by <a href="">Eagle Tech</a></p>
                            </div>
                        </div>
                    </footer>}
                </div>
            </div>
        </div>
    )

}

export default Chat_box;