import React, { useState, useEffect } from "react";
import Axios from "axios";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate ,Link} from "react-router-dom";
import { useCookies } from 'react-cookie';
import ReactLoading from 'react-loading';

import exportValue from "../apiconfig";
import Header from "./Header";
import MenuGuest from './MenuGuest';
import Footer from "./Footer";


const Login_view = () => {
  let navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['user_session']);

  const [state, setState] = useState({
    email: "",
    password: "",
    remember_me:true,
    loggedIn: false,
    isLoading: false,
    forgotEmail:"",
    user_id:""
  });
  const[toggleForgot,setToggleForgot] =   useState(true)
  const [isLoading,setIsloading] = useState(false)
  useEffect(() => {
    //dispatch(tagsListAction());
  }, []);


  const handleChange = (event) => {
    //console.log(event);
    if(event.target.type == "checkbox"){
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });

    }else{
        setState({
          ...state,
          [event.target.name]: event.target.value,
        });
      }
  };

  const axios_get_api = (event) => {
    event.preventDefault();
    setState({...state,isLoading:true})
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/login`;
    let sendData = {
      email: state.email,
      password: state.password,
    };    
    console.log(sendData);
    if (localStorage.getItem('firebaseToken')) {
      sendData.token = localStorage.getItem('firebaseToken');   
     }
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log(res);

      if (res.data.output.length == 1) {
        setState({...state,user_id:res.data.output[0].user_id})
        setIsloading(true)
        
        if(state.remember_me) {
          setCookie('_user', JSON.stringify(res.data.output[0]));
        
        }
        //sessionStorage.setItem("loggedIns", JSON.stringify(res.data.output[0]));
        localStorage.setItem('loggedIn', JSON.stringify(res.data.output[0]));
        console.log("---------------------------");
        if(res.data.output[0].user_type == 2 && res.data.output[0].transporter_detail == undefined) {
          setTimeout(
            redirectCarrier,3000
          )
        }else {
        setTimeout(
          redirectf,3000
        )
        }

      }else {
        
        toast.configure();
        toast.error(res.data.message);
        setState({...state,isLoading:false})
      }
      //state.isLoading = false;
      // setUserDataCount(res.data.count);
      //setUserData(res.data.categoryList);
    })
      .catch((e) => {
        setState({...state,isLoading: false});

        toast.configure();
        toast.error("Some thing went wrong");
        console.log("----error:   ", e);
      });


  };

  const redirectf = () => {
    
    navigate('/')
  }
  const redirectCarrier = () => {    
    navigate('/profile_update_carrier')
  }
  const togglefun = () => {
    if(toggleForgot) {
      setToggleForgot( false)
    }else {
      setToggleForgot( true)
    }
  }

  const forgotPasswordRequest = (event) => {
    event.preventDefault();
   // console.log("cicked");

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/forgot_password`;
    let sendData = {
      email_id: state.forgotEmail
      };
    //console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      // console.log(res);
    if(res.data.status == "success") {
      toast.configure();
        toast.success(res.data.message);
        navigate('/forgot_otp_verify',{state:{email:sendData.email_id}})

    }else {
      toast.configure();
        toast.error(res.data.message);
    }
    })
  }

  return (
    <>
      {/* <MenuGuest /> */}

      <section className=" bg-light pad_custom ">
        <div className="container ">
          <div className="row">
           
            <div className="col-12 col-md-12 bg-white p-5">
              <h3 className="text-uppercase">Login</h3>
              <div className="row">
                <div className="col">
                  <form onSubmit={(e) => axios_get_api(e)}>
                    <div className="form-floating mb-3 mt-3">
                    <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"12px"}}>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        name="email"
                        required
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                      />
                      <label htmlFor="email" className='d-none d-sm-block'>Email</label>
                    </div>
                    <div className="form-floating mb-3 mt-3">
                    <label htmlFor="email"className='d-block d-sm-none' style={{fontSize:"12px"}}>Password</label>

                      <input
                        type="password"
                        className="form-control"
                        id="email"
                        placeholder="Enter password"
                        name="password"
                        required
                        autoComplete="new-password"
                        onChange={(e) => handleChange(e)}
                      />
                      <label htmlFor="email"className='d-none d-sm-block'>Password</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="check1"
                        name="remember_me"
                        checked={state.remember_me}
                        onChange={(e)=>handleChange(e)}
                        
                      />
                      <label className="form-check-label">
                        <small className="text-muted">Remember me</small>
                      </label>
                    </div>
                    {isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
                    <div className="row">
                    <div className="col-6 col-md-2 mt-3">
                      <div class="d-grid gap-2">
                      <Button variant="primary" type="submit"> Login</Button>
</div>
                    </div>
                  
                    <div className="col-6 col-md-7 mt-3">
                      <Link to="/registration"><Button variant="warning">Registration</Button></Link>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                   <Button variant="light" onClick={()=>togglefun()} > Forgot password</Button>
                    </div>
                    </div>
}
                  </form>
                  {/* <div className="col mt-3"><a className="nav-link" style={{cursor:"pointer"}} onClick={()=>togglefun()}>Forgot password</a></div> */}

                  <div id="forgot_pass" className={(toggleForgot ? "collapse":"")} >
                  <form onSubmit={(e) => forgotPasswordRequest(e)}>
                    <div className="form-floating mb-3 mt-3">
                    <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"12px"}}> Please enter your registered email address</label>
                      
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        name="forgotEmail"
                        required
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                      />
                      <label htmlFor="email" className='d-none d-sm-block'>
                        Please enter your registered email address
                      </label>
                    </div>
                    <div className="col ">
                      <Button variant="primary" type="submit">Submit </Button>


                    </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
     
    </>
  );
};

export default Login_view;