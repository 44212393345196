import React from 'react';
import Header from './Header';
import Footer from './Footer';
import exportValue from "../apiconfig";
import axios from 'axios';
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useParams } from 'react-router-dom'; 

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




const Edit_vehicle = () => {
    const navigate=useNavigate();
    const { vehicle_id } =  useParams();
  
    const [stateedit, seteditState] = useState({})
   
    const[state,setState] = useState({category_attributes:[]})
    console.log("state is ",state);
  
    const[vehicleCategory,setVehicleCategory] = useState({vehicleNumber:""})
    const vehicle_detail = () =>{
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/vehicle/vehicle_category_list`;
      let sendData = {};
  
      axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        setState({...state,category_attributes:res.data.output});
        console.log("response", res);
      }).catch((e) => {
        // toast.configure()
        // toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }
  
    React.useEffect(() => {
         vehicle_detail();
         vehiclecat_detail(vehicle_id)
    }, [])
  
    const changeSelectedData = (e) => {
         console.log("name is ",e.target.name)
         console.log("value is ",e.target.value)
         let names = e.target.name;
         let value = e.target.value
      seteditState({...stateedit,[names]:value})
    }
  
   
  
    const vehiclecat_detail = (vehicle_id) => {

        let state_data = { vehicle_id:vehicle_id}

        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/vehicle/vehicle_detail`, state_data , { headers: exportValue.headers }).then((result)=>{
          console.log("result is ",result);
            state.isLoading = false;
            if(result.data.output.length > 0){
                let state_details = result.data.output[0].description[0];
                console.log("state_details is ",state_details);
                seteditState(state_details)

               
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("submit1");
        state.isLoading = true;
        console.log("state detailes is  hhh",stateedit);
        let state_detailes = {vehicle_id:vehicle_id,description:stateedit}

       
        
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/vehicle/edit_vehicle`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
                console.log("result is i hhh ",result);
                state.isLoading = false
                if(result && result.data != undefined){
                    toast.configure()
                    toast(result.data.message)                  
                    navigate('/vehicle_list');
                    }
     
            })
    }

const handleChange2 = (event) => {
        event.persist()
        seteditState({
            ...stateedit,
            [event.target.name]: event.target.value,
        })
    }

    const { vehicleNumber,vehicle_type,vehicle_model,vehicle_wheel } = stateedit

    console.log("ssssssssssss",stateedit)
   
   

  return (
    <div>
    <Header/><section class="bg-light p-5">
<div class="container">
  <div class="row">
    <div class="col-12 col-md-2">&nbsp;</div>
    <div class="col-12 col-md-8 bg-white p-5">
      <h3 class="text-uppercase">Edit Vehicle</h3>

      <hr/>
      <section>
        <form >
        <div class="form-floating mb-3 mt-3">
          <input
            type="text"
            class="form-control"
            name="vehicleNumber"
            value={vehicleNumber||""}
            placeholder="Enter Vehicle Number"
            onChange={handleChange2}
            required
          />
          <label for="email" className='d-none d-sm-block'>Enter Vehicle Number</label>
        </div>

        {state.category_attributes.map((sub)=>( 
            <div class="mt-3">
               <label for="email" class="form-label">{sub.category_name}</label> 
              <select class="form-select form-select-lg" name={sub.category_name} onChange={(e)=>{changeSelectedData(e)}}>
              <option value="">Select</option>
                {(sub.subcategory != undefined && sub.subcategory.length > 0) ? sub.subcategory.map((subscriber) => {
                  return( <>
                   <option value={subscriber.subcategory_name} selected={subscriber.subcategory_name == stateedit[sub.category_name]}>{subscriber.subcategory_name}</option>
                   </>
                  )
                }) 
                :""}
                
              </select>
            </div>
           ))} 


        {/* <div class="mt-3">
          <label for="email" class="form-label">Select Modal</label>
          <select class="form-select form-select-lg" value={vehicle_model} name="vehicle_model" onChange={(event)=>{changeSelectedData(event)}}>
            {(state.category_model != undefined && state.category_model.length > 0) ? state.category_model.map((subscriber) => {
              return( <>
               <option  onChange={handleChange2} >{subscriber.subcategory_name}</option>
               </>
              )
            }) 
            :""}
            
          </select>
        </div>

        <div class="mt-3">
          <label for="email" class="form-label">Select Type</label>
          <select class="form-select form-select-lg" value={vehicle_type} name="vehicle_type" onChange={(event)=>{changeSelectedData(event)}}>
            {(state.category_type != undefined && state.category_type.length > 0) ? state.category_type.map((subscriber) => {
              return( <>
               <option onChange={handleChange2}  >{subscriber.subcategory_name}</option>
               </>
              )
            }) 
            :""}
            
          </select>
        </div>

        <div class="mt-3">
          <label for="email" class="form-label">Select Wheel</label>
          <select class="form-select form-select-lg" value={vehicle_wheel} name="vehicle_wheel" onChange={(event)=>{changeSelectedData(event)}}>
            {(state.category_wheel != undefined && state.category_wheel.length > 0) ? state.category_wheel.map((subscriber) => {
              return( <>
               <option   onChange={handleChange2}>{subscriber.subcategory_name}</option>
               </>
              )
            }) 
            :""}
            
          </select>
        </div> */}
        
        <div class="mt-3">
           <Button variant="primary" type="submit" onClick={(event)=>handleSubmit(event)}>Update Vehicle</Button>
        </div>
        </form>
      </section>
      
        

     
    </div>
    <div class="col-12 col-md-2">&nbsp;</div>
  </div>
</div>
</section>
<Footer/>
</div>
  )
}

export default Edit_vehicle