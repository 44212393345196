import React, { useEffect, useState } from "react";
import Header from './Header';
import { useLocation,useParams, Link } from "react-router-dom";

import MenuCarrier from './MenuCarrier';
import Footer from './Footer';
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Moment from 'react-moment';
import moment from 'moment';
import ReactStarsRating from 'react-awesome-stars-rating';
import ReactLoading from 'react-loading';



const ProfileCarrier = () => {
  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log("userData  -  ",userdata);
    //navigate('/home') 
  }
    const {transporter_id} = useParams();
  const [state, setState] = React.useState({
    transporter_detail: [],
    dstatus: 1,
    isLoading:true
      })


  console.log("pp", state)

  const[countstate,setCountState]= React.useState({count_customer_review:[],count_shipments:[]})
  const[likecountstate,setLikeCountState]= React.useState({count_customer_likes:[]})
  const[notlikecountstate,setnotLikeCountState]= React.useState({count_customer_notlikes:[]})


  

  


      const axios_get_api = () => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/transporter_detail`;
        let sendData = {
          transporter_id: transporter_id,
          dstatus: 1,
          
    
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setState({ ...state,  transporter_detail: res.data.output , review:res.data.review , customer_review:res.data.customer_review ,isLoading:false});
          console.log("response", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }
    
      const review_count = () => {
    
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/review_count_api`;
        let sendData = {
        
          dstatus: 1,
          transporter_id:transporter_id
    
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setCountState({ ...countstate, count_customer_review:res.data.count_customer_review , count_shipments:res.data.count_shipments,count_completed_shipments:res.data.count_completed_shipments});
          console.log("response_count", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }

      const like_count = () => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/liked_count_api`;
        let sendData = {
        
          isLiked: 1,
          transporter_id:transporter_id
    
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setLikeCountState({ ...likecountstate, count_customer_likes:res.data.count_customer_review});
          console.log("response_count_like", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }

      const notlike_count = () => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/notliked_count_api`;
        let sendData = {
        
          notLiked: 1,
          transporter_id:transporter_id
    
        };
    
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          setnotLikeCountState({ ...notlikecountstate, count_customer_notlikes:res.data.count_customer_review});
          console.log("response_count_notlike", res);
        }).catch((e) => {
    
          console.log("----error:   ", e);
        })
      }

      
    
    
      React.useEffect(() => {
        axios_get_api()
        review_count()
        like_count()
        notlike_count()
      }, [])
    
    
        const logincheck = () => {
            console.log("->< ", localStorage.getItem('loggedIn'));
            if (localStorage.getItem('loggedIn')) {
              let userDetail = JSON.parse(localStorage.getItem('loggedIn'));
              setState(userDetail);
              console.log("-> ", userDetail);
            }
          }
  
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

 function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

  const { height, width } = useWindowDimensions();

  

    return (
        <>
        <MenuCarrier />
  {state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
       
            <section className="bg-light p-5" style={width<=768?{marginLeft:"-45px",marginRight:"-45px"}:{margin:"0px"}}>
           
                <div className="container">
               
                    <div className="row">
                        <div className="col-12 col-md-2"></div>
                        <div className="col-12 col-md-8">
                        {state.transporter_detail.map((sub)=>(
 <section>
  { sub.dstatus==3   ?
       
       <div className='  mb-4'><div class="alert alert-danger text-center" role="alert" >
       Your Account is not Verified
       
   </div>
     </div>:""}
  </section>
  ))}
                      
                        { state.transporter_detail.map((subscriber,index) => (
                           <Link  to={`/carrier/${subscriber.transporter_id}`}> <h3 className="text-uppercase mb-5 text-dark">{subscriber.branch_name}</h3></Link>))}
                            <section className="pb-4">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="card">
                                            <div className="card-body shadow-sm">
                                                <h5 className="mb-4">Transporter Information</h5>
                                                <table className="table">
                                                { state.transporter_detail.map((subscriber,index) => (
                                                    <tbody>
                                                        <tr>
                                                            <td>Joined Since</td>
                                                            <td><Moment format="MMMM DD YYYY" unix>{subscriber.created}</Moment></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shipments Recieved</td>
                                                            <td>{countstate.count_shipments}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shipments Delivered</td>
                                                            <td> {countstate.count_completed_shipments}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Percent Positive</td>
                                                            <td>{likecountstate.count_customer_likes!==0 &&countstate.count_shipments!==0?parseInt(likecountstate.count_customer_likes/countstate.count_shipments*100):"0"}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Positive</td>
                                                            <td>{likecountstate.count_customer_likes}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Negative</td>
                                                            <td>{notlikecountstate.count_customer_notlikes}</td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8" style={width<=768?{marginTop:"20px"}:{margin:"0px"}}>
                                        <div className="card">
                                            <div className="card-body shadow-sm">
                                                <h5 className="mb-4">Transporter History</h5>
                                                { state.transporter_detail.map((subscriber,index) => (
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Total</th>
                                                            {/* <th>1 mo</th>
                                                            <th>6 mo</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-success">Positive</td>
                                                            <td>{likecountstate.count_customer_likes}</td>
                                                            {/* <td>0</td>
                                                            <td>0</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-muted">Neutral</td>
                                                            <td>{  countstate.count_shipments   -  (likecountstate.count_customer_likes+notlikecountstate.count_customer_notlikes ) }</td>
                                                            {/* <td>0</td>
                                                            <td>0</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-danger">Negative</td>
                                                            <td>{notlikecountstate.count_customer_notlikes}</td>
                                                            {/* <td>0</td>
                                                            <td>0</td> */}
                                                        </tr>
                                                        {/* <tr>
                                                            <td>Cancellations</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
     
                            </section>
                            
{/* {customerstate.customer_review.map((sub)=>( */}
                            <div className="col-12 col-md-12">
                            <div className="card">
                                <div className ="card-body shadow-sm">
                                <div className ="row">
                                <div className ="col" >
                                <ul className ="nav nav-pills ">
                               
                                </ul>

                                <div className ="tab-content">
                                <div className ="tab-pane container active" id="fb_rec">
                                <section class=" profile_box_reviews">
            

              
                  { state.transporter_detail.map((subscriber,index) => (
                    <h1 className="pt-1">REVIEWS | {subscriber.total_reviews}</h1>
                  ))}
                    <hr/>

                   {  state.customer_review!==undefined && state.customer_review.length>0 ?
                   
                     state.customer_review.map((sub,index) => (
                  
                    <section>
                    
                      <div class="d-flex justify-content-between">
                        <div class=" ">
                          <h3>{sub.full_name}</h3>
                          <p class="text-primary"><ReactStarsRating value={sub.rating} isEdit={false}/></p>
                          <p></p>
                        </div>
                        <div class=" ">
                          <span class="text-muted"><Moment format="MMMM DD YYYY" unix>{sub.created}</Moment></span>
                        </div>
                      </div>
                      <h2>{sub.title}</h2>
                    
                      <p>{sub.review_description}</p>
                      <p class="text-end text-muted"><small> Was this helpful? <i class="fa fa-thumbs-o-up text-primary" aria-hidden="true"></i> {sub.isLiked?(sub.isLiked):"0"} &nbsp;&nbsp;&nbsp; <i class="fa fa-thumbs-o-down text-primary" aria-hidden="true"></i> {sub.notLiked?(sub.notLiked):"0" }</small></p>
                    
                      {sub.transporter_review.map((subscriber)=>(
                      <div class="p-3 ml-30 mb-5 ml-5 bg-light  border-start border-primary border-5">
                        

                        { state.transporter_detail.map((subscriber,index) => (
                        <h3>{subscriber.branch_name}</h3>
                        ))}
                        <small >{subscriber.transporter_review_description}</small>
                        
                        </div>
                       ))} 
                       
                    </section>
                     ))
                    
                     :<h4 className='text-muted'>NO REVIEWS </h4>}
                    <hr/>
                   
                    
                  
                
              </section>
                                
                                {/* <div className ="tab-pane container fade" id="fb_left">...s</div> */}
                             </div>
                             </div>
                                </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            {/* ))} */}
                        </div>
                        <div className="col-12 col-md-2"></div>
                    </div>
                </div>
            </section>
}
            <Footer />
                    </>
                )
    }
            
export default ProfileCarrier;