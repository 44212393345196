import React from 'react'
import Header from './Header';
import { useLocation,useParams, Link } from "react-router-dom";

import MenuCustomer from './MenuCustomer';
import Footer from './Footer';
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Moment from 'react-moment';
import moment from 'moment';

const Carrier_terms = () => {
  
    const {transporter_id} = useParams();

  const [state, setState] = React.useState({
    transporter_detail: [],
    dstatus: 1,
      })
  const axios_get_api = () => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/transporter_detail`;
    let sendData = {
      transporter_id: transporter_id,
      dstatus: 1,
      

    };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setState({ ...state,  transporter_detail: res.data.output , review:res.data.review});
     
    }).catch((e) => {

   
    })
  }

  React.useEffect(() => {
    axios_get_api()
  }, [])
  return (
    <div>
      <Header/>
      <section class="sub_heading p-5 text-black">
        {state.transporter_detail.map((subscriber)=>(

        
    <div class="container">
      <div class="row">
        <div class="col">
          <h5 className='text-uppercase '><Link to={`/carrier/${subscriber.transporter_id}`}>{subscriber.branch_name}</Link> TERMS AND CONDITIONS</h5>
        </div>
      </div>
    </div>
    ))}
  </section>
  
  
  <section class="bg-light p-5">
    {state.transporter_detail.map((subscriber)=>(
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="nav nav-pills mb-3">
            <li class="nav-item">
              <a class="nav-link " href="#policy">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tc">Terms & Conditions</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#pay">Payment Terms</a>
            </li>
          </ul>
          <div class="card">
            <div class="card-body shadow-sm">
              <section class="mb-5" id="#policy">
                <h3>Policy</h3>
                <p>{subscriber.policy}</p>
              </section>
  
              <section class="mb-5" id="#tc">
                <h3>Terms & Conditions </h3>
                <p>{subscriber.term_conditions}</p>
              </section>
  
              <section class="mb-5" id="#pay">
                <h3>Payment Terms</h3>
                <p>{subscriber.payment_terms}</p>
              </section>
  
            </div>
          </div>
        </div>
      </div>
    </div>))}
  </section>
  <Footer/>
  </div>
  )
}

export default Carrier_terms