import React, { useEffect, useState } from "react";
import context from "react-bootstrap/esm/AccordionContext";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import exportValue from "../apiconfig";

const MenuCustomer = (props) => {
  const [cookies, setCookie] = useCookies(['user_session']);
  //console.log("cookies  ", cookies._user);
 let sessionGet =  localStorage.getItem('loggedIn')
  //console.log("sessionGet   ",sessionGet);
  let navigate = useNavigate();
  const [state, setState] = useState({});
  useEffect(() => {
    logincheck()
  }, [])
  let userDetail = {};
  const logincheck = () => {
    //console.log("->< ", localStorage.getItem('loggedIn'));
    if (localStorage.getItem('loggedIn')) {
       userDetail = JSON.parse(localStorage.getItem('loggedIn'));
      setState(userDetail);
      //console.log("-> ", userDetail);
    } else {
      logout()
    }
  }

  const logout = (event) => {
    //console.log("log out");
    if (localStorage.getItem('loggedIn') && localStorage.getItem('firebaseToken')) {
      userDetail = JSON.parse(localStorage.getItem('loggedIn'));
      let token = localStorage.getItem('firebaseToken')
      firebaseTokenUpdate(token);
    }
    
    localStorage.removeItem('loggedIn');
  
    window.location.href = '/';
    // setTimeout(
    //   redirectf,3000
    // )
   // window.location.reload('/');
    //event.preventDefault();

  }
  const firebaseTokenUpdate = (token) =>{
    let userdata = { token: token,updateType:2}
    exportValue.headers.usertuid = userDetail.user_id;
    Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/chat/user_token_update`, userdata, { headers: exportValue.headers }).then((res) => {
     // console.log("firebaseTokenUpdate", res)
    
    }).catch((e) => {    
      toast.configure()
      toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const redirectf = () => {
    navigate('/')
  }
  return (
    <>
       
      <header className="nav-item_header shadow-sm ">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-sm nav_custom">
            <Link to="/">  <img src={__dirname+"img/logo.png"} className="img-fluid logo" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
              <span className="navbar-toggler-icon"><i className="fa fa-bars" aria-hidden="true"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul className="navbar-nav me-auto">
                <li className="nav-item"><Link to="/myaccount_customer">
                  <a className="nav-link">My Shipment</a>
                </Link>
                </li>
                <li className="nav-item">
                  <Link to="/ship">  <a className="nav-link">Ship Now</a></Link>
                </li>
              </ul>

              <ul className="navbar-nav justify-content-center">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle d-none d-sm-block" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>  {state.full_name}
                  </a>
                  {/* for mobile  */}
                  <a className="nav-link dropdown-toggle d-block d-sm-none mx-3" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>  {state.full_name}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to={"/profile/"+state.user_id}><a className="dropdown-item" >My Profile</a></Link></li>
                     <li><Link to="/inbox"><a className="dropdown-item">Inbox</a></Link></li> 
                    <li><Link to="/payments"><a className="dropdown-item" >Payments</a></Link></li>
                    <li><Link to="/settings"><a className="dropdown-item">Settings</a></Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" onClick={(e) => logout(e)}>Logout</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                 <Link to="/create_ticket"><a className="nav-link" >Support</a></Link> 
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>


    </>
  )
}

export default MenuCustomer;
