import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import Footer from "./Footer";
import MenuCustomer from './MenuCustomer';
import Header from "./Header";
import Axios from "axios";
import ReactLoading from 'react-loading';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Moment from 'react-moment';
import { Typography } from "@material-ui/core";
// import 'bootstrap/dist/css/bootstrap.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactStarsRating from 'react-awesome-stars-rating';

const Shipment_tracking = () => {

  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log("userData  -  ",userdata);
    exportValue.headers.usertuid = userdata.user_id;   
    //navigate('/home') 
  }
  //console.log("Myaccount_customer");

  const [state, setState] = useState({ shipmentList: {review_detail:[]}, searchValue: undefined, dialogOpen: false, isLoading: true });
  const [shipmentstate, setshipStatus] = useState({ shipment_status: null });
  console.log("shipmentstate",shipmentstate)
  // const [reviewstate, setReviewState] = useState({invoice_id:"",transporter_id:"",review_description:"",rating:0,user_id:""});
  const [likestate, setLikeState] = useState({isLiked:0,invoice_id:"",transporter_id:''});
  const[notlikestate,setnotLikeState]=useState({transporter_id:"",notLiked:0,invoice_id:""})
  const[dimensionState,setdimensionState] = useState({companyDetail:[]})

  useEffect(() => {
    userShipmentList(2)
  }, [])

  const userShipmentList = (status) => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/customer_shipments`;
    let sendData = { status: status,current_shipment_status:8 };
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("response",res);
      setState({ ...state, dialogOpen: false, shipmentList: res.data.output, isLoading: false })
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };

  



// const {rating}=reviewstate
  return (<>
    <Header />
         
      
   

    <section className="pt-1 pb-3 bg-light ">
      <div className="container">
        <section>
          <div className="row">
           

         
         

              
          </div>
        </section>
        <hr />
        {state.isLoading == true ? <ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} /> :
          state.shipmentList.length > 0 ?
            <section className="shipment_list_table mb-3">
       
              <div className="row shipment_list_table_heading mb-2">
                <div className="col-4 col-md-2 ">PICKUP</div>
                <div className="col-4 col-md-2 ">DELIVERY</div>
                <div className="col-3 col-md-3  d-none d-sm-block ">GOODS</div>

                <div className="col-4 col-md-1 ">Shipment Id. #</div>

               
                <div className="col-4 col-md-2   d-none d-sm-block">INFO</div>
                <div className="col-1 col-md-1  d-none d-sm-block">Status</div>

              </div>
                 
                
              


              {state.shipmentList.map((subscriber, index) => (

                // for desktop 
               
                <div className="card" key={index}>
                  <div className="card-body">
                  <Link  to={"/tracking/" + subscriber.invoice_id}>
                    <div className="row">
                      <div className="col-12 col-md-2 ">
                        <h5 ><i className="fa fa-map-marker text-muted " aria-hidden="true"></i>
                         <a >{subscriber.pickup_address}</a>
                        </h5>
                        <small className="text-dark"><Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_to}</Moment></small>
                      </div>
                      <div className="col-12 col-md-2 ">
                        <h5><i className="fa fa-map-marker text-muted " aria-hidden="true"></i>
                          <Link to={{ pathname: "/tracking/" + subscriber.invoice_id, state: { nationality: "Icelandic" } }} > <a >{subscriber.delivery_address}</a></Link>
                        </h5>
                        <small className="text-dark" ><Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_to}</Moment> </small>
                      </div>
                      <div className="col-12 col-md-3 text-dark"><p>{subscriber.title}</p></div>
                      <div className="col-12 col-md-1 text-dark ">
                        <p>{subscriber.invoice_id}</p>
                      </div>
                      <div className="col-12 col-md-2 text-dark"><p><a href="">{subscriber.total_quotes + "(Quote)"}</a> {dimensionState.companyDetail.map((sub)=>(<small>{sub.set_currency}{subscriber.lowest_quote} low</small>))}</p>
                      </div>
                      <div className="col-12 col-md-1 ">
                        {(subscriber.status < 1 )?
                        <span className={subscriber.dstatus == 1 ?"badge rounded-pill bg-success text-light ":"badge rounded-pill bg-danger text-light"}>{(subscriber.dstatus == 1) ? "Active" : "Inactive"}</span> :""}
                        {/* {(subscriber.status > 0 )?
                        <span className="badge rounded-pill bg-warning text-light ">{(subscriber.current_shipment_status != 5 && subscriber.status == 0) ? "Pending" : (subscriber.current_shipment_status != 5 && subscriber.status == 1 )?"Assigned":(subscriber.current_shipment_status != 5 && subscriber.status == 2)? "Completed":(subscriber.current_shipment_status != 5 && subscriber.status == 3)?"Cancelled":""}</span>                        :""} */}
                        <span className="badge rounded-pill bg-primary text-light ">{(subscriber.current_shipment_status == 1) ? "Booked" : (subscriber.current_shipment_status == 2) ? "Pickup In Progress":(subscriber.current_shipment_status == 3) ? "Picked Up":(subscriber.current_shipment_status == 4) ? "In Transit":(subscriber.current_shipment_status == 5) ? "Delivered":(subscriber.current_shipment_status == 6) ? "Returned":(subscriber.current_shipment_status == 7) ? "Cancelled": (subscriber.current_shipment_status == 8) ? "On The Way":""}</span>

                      </div>
              
                    </div>
</Link>

                   
                    

   
                  </div>
                </div>
                

              ))}
             
 
            </section>
            
            :
            <section className="text-center p-5 h-100">
              <h4 className="text-muted fw-bold">NO SHIPMENTS</h4>
              <p className="pb-5">You do not currently have any shipments in this status. Find what you're looking for in another tab or...</p>
              <Link to="/ship"><a className="btn btn-outline-primary btn-lg">Start a new shipment</a></Link>
            </section>
        }
        
      </div>
     
    </section>


        

     

    <Footer />
  </>)
}

export default Shipment_tracking;
