import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Axios from 'axios'
import exportValue from '../apiconfig'
import { useState } from 'react'
import Moment from 'react-moment';
import moment from 'moment';
import ReactLoading from 'react-loading'
import { Link } from 'react-router-dom'
const Quote_list_carrier = () => {
  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log("userData  -  ",userdata);
     
  }
  const [state, setState] = React.useState({
    quotes_lists: [],
    dstatus: 1,
    status:"",
    searchValue:undefined,
    isloading:true
  })
  const [quotestate, setquoteStatus] = useState({ quote_status:null});
  console.log("pp", state)

  const[dimensionState,setdimensionState] = useState({companyDetail:[]})


  const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/get_company_info_api`;
    let sendData = {};
  
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  React.useEffect(() => {
    dimension_detail();
  }, [])

  const axios_get_api = (status) => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/quotes/quotes_list`;
    let sendData = {
      quotes_lists: {},
      dstatus: 1,
      transporter_id:userdata.transporter_id,
        status:status,
    };
    // if(state.title){
    //   sendData.search_title = state.title
    // }
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setState({ ...state, quotes_lists: res.data.output ,isloading:false});
      console.log("response", res);
    }).catch((e) => {

      // toast.configure()
      // toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const shipmentFilter = (status) => {
    console.log("action ", status);
    setquoteStatus({...quotestate,quote_status : status})
    axios_get_api(status)
  }

  function searchtitle() {
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/quotes/quotes_find_detail`;
            
    let shipment_data= { offset: 0, limit: 50 };
        console.log("pv",state.quotes_id)
        if ( state.searchValue) {
            shipment_data.quotes_id = state.quotes_id;
        }
            
         Axios.post(full_api,shipment_data, { headers: exportValue.headers }).then(res => {        
              console.log( "response",res);
              setState({ ...state, quotes_lists: res.data.output });
      
        }).catch((e) => {
      
            console.log("----error:   ", e);
        })          
}
      
     const stateHandler = (key, value) => {
         state[key] = value
         setState({ ...state, quotes_id:state.searchValue })
     }

     

  React.useEffect(() => {
    axios_get_api()
  }, [])

  

  return (
    <div>
        <Header/><section className="pt-1 pb-3 bg-light">
    <div className="container">
      <section>
        <div className="row">
          <div className="col-12 col-md-8">
            <ul className="nav nav-pills pt-2 pb-3">
              <li className="nav-item">
                <a className={(quotestate.quote_status == null) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}}  onClick={()=>shipmentFilter(null)} >All</a>
              </li>
              <li className="nav-item">
                <a className={(quotestate.quote_status == 1) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={()=>shipmentFilter(1)} >Pending</a>
              </li>
              <li className="nav-item">
                <a className={(quotestate.quote_status == 3) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={()=>shipmentFilter(3)} >Declined</a>
              </li>
              <li className="nav-item">
                <a className={(quotestate.quote_status == 2) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={()=>shipmentFilter(2)} >Confirmed</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#">Archived</a>
              </li> */}
            </ul>
          </div>

         
          <div className="col-10  col-md-3 mt-1 ">
            <div className="form-floating">
              <input type="text" className="form-control " id="Search" placeholder="Enter Search" name="Search" value={state.searchValue} onChange={({ target }) => {
                                stateHandler('searchValue', target.value)

                            }} />
              <label htmlFor="email">Search shipment</label>
            </div>
          </div>
          <div className="col-2 col-md-1 mt-3 " >
            <i onClick={(e)=>searchtitle(state.searchValue)}  className="fa-solid fa-magnifying-glass fa-2x">  </i>
            </div>

           


        </div>
      </section>
      <hr/>
  {state.isloading==true?<ReactLoading type="bubbles" color="#0d6efd"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
     (state.quotes_lists.length>0)?
      <section className="shipment_list_table">
              <div className="row shipment_list_table_heading mb-2">

                

                  <div className="col-4 col-md-2 ">Shipment ID#</div>
                  <div className="col-4 col-md-2 ">Quote</div>
                  <div className="col-2 col-md-2   d-none d-sm-block">PICKUP</div>
                  <div className="col-2 col-md-2   d-none d-sm-block">DELIVERY</div>
                  <div className="col-4 col-md-2  ">GOODS</div>
                  
                  <div className="col-3 col-md-1  d-none d-sm-block">Status</div>

                  <div className="col-2 col-md-1  d-none d-sm-block">MORE</div>
              </div>
              { state.quotes_lists.map((subscriber,index) => (
                <div className="card">
                  <div className="card-body">

                   
                      <div className="row">

                        <div className="col-12 col-md-2 "><a href=""><Link to={`/shipment/${subscriber.invoice_id}`}> {subscriber.invoice_id}</Link></a> 
                         
                        </div>
                        {dimensionState.companyDetail.map((sub)=>(
                          <div className="col-12 col-md-2 ">
                            <h4 className="text-dark" >{sub.set_currency}{subscriber.cost} <small className="text-muted" data-bs-toggle="tooltip" title="Your submitted quote"><i className="fa fa-info-circle" aria-hidden="true"></i></small></h4>
                            <p><a href="">({subscriber.total_quotes})quote</a> 
                <small>{sub.set_currency}{subscriber.lowest_quote}</small> 
                </p>
                        </div>))}
                          <div className="col-12 col-md-2 ">
                            <h5><i className="fa fa-map-marker text-muted" aria-hidden="true"></i><a href=""><Link to={`/shipment/${subscriber.invoice_id}`}> {subscriber.pickup_address}</Link></a>
                            </h5>
                            <small><Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_to}</Moment></small>
                          </div>
                          <div className="col-12 col-md-2 ">
                            <h5><i className="fa fa-map-marker text-muted" aria-hidden="true"></i> <a href="">{subscriber.delivery_address}</a>
                            </h5>
                            <small><Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_to}</Moment></small>
                            
                          </div>
                          <div className="col-12 col-md-2 "><p>{subscriber.title}</p></div>

                          
                          <div className="col-6 col-md-1 "><span className={(subscriber.status==1)?"badge rounded-pill bg-warning text-light":(subscriber.status==2)?"badge rounded-pill bg-success text-light":"badge rounded-pill bg-danger text-light"}>{(subscriber.status)==1? "Pending":(subscriber.status)==2?"Confirmed":"Declined"}</span></div>

                          <div className="col-6 col-md-1 text-end "><a href="" data-bs-toggle="collapse" data-bs-target={`#info_box_${index}`}><i className="fa fa-chevron-circle-down" aria-hidden="true"></i></a>
                        </div>
                        </div>

                       

  
                        <div className="row collapse" id={`info_box_${index}`}>
                          <div className="card bg-light border-0">
                            <div className="card-body">
                              <div className="row"> 
                                <div className="col-12 col-md-4">
                                  <h6 className="pt-3 pb-2">LOCATION DETAILS</h6>
                                  <p className="m-0">Pickup: <span className=" ">{subscriber.pickup_address_type}</span></p>
                                  <p className="m-0">Delivery: <span className="">{subscriber.delivery_address_type}</span></p>
                                </div>
                                <div className="col-12 col-md-4">
                                  <h6 className="pt-3 pb-2">DETAILS</h6>
                                  <p className="m-0">Items: <span className=" ">{subscriber.qty}</span></p>
                                  <p className="m-0">Trailer Type: <span className="">{subscriber.service_type}</span></p>
                                  <p className="m-0">Listed By: <span className="">Shipping Customer</span></p>
                                </div>
                                <div className="col-12 col-md-4 border-start text-center">
                                  {(subscriber.lowest_quote != undefined)?
                                  <>
                                  <h6 className="pt-3 pb-2">LOW QUOTE</h6>
                                  {dimensionState.companyDetail.map((sub)=>(<p className="shipment_list_table_price m-0">{sub.set_currency}{subscriber.lowest_quote}</p>))}
                                  <p className="m-0 d-grid"><a href={`/shipment/${subscriber.invoice_id}`} className="btn btn-primary btn-block">View</a></p>
                                  </>:
                                  <>
                                  <p className="m-0 d-grid"><a href={`/shipment/${subscriber.invoice_id}`} className="btn btn-primary btn-block">View</a></p>
                                  </>
                                  }
                                  </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                    </div>
                    
                </div>
              ))}
  
    </section> 
    :
              
      <section className="text-center p-5 h-100">
        <h4 className="text-muted fw-bold">NO QUOTATIONS</h4>
       
        
      </section>
}
    </div>
  </section>
  <Footer/>
  </div>
  )
}

export default Quote_list_carrier