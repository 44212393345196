  import React, { useEffect, useState } from "react";
  import { useLocation,useParams, Link } from "react-router-dom";
  import Axios from "axios";
  import { toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import exportValue from "../apiconfig";
  import Footer from "./Footer";
  import Header from "./Header";
  import Map from "./components/Map";
  import Moment from 'react-moment';

  import Mapsecond from "./components/Mapsecond";

  // import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';




  const ShipmentDetail = () => {
    
    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
      console.log("userData  -  ",userdata);
      //navigate('/home') 
    }

    const [disable, setDisable] = React.useState({dis:false,foruseinfinity:true });

    if(userdata.transporter_id == undefined && disable.foruseinfinity == true){
      setDisable({...disable,dis:true,foruseinfinity:false})
    } 
    const {invoice_id} = useParams();
    const [state, setState] = useState({ shipmentDetail: { item_imgs: [] }, questions: [], quotes: [], isLoading: true })

    const [stateSave, setSaveState] = useState({transporter_id:"",pickup_date:6,delivery_date:6,cost:0,service_type:"Open Transport",payment_accepted_on:"At Pickup",transport_mode:"Sea"});

    console.log("state save is ",stateSave);
    
    
    useEffect(() => {
      shipmentDetail();
    }, [])
    const shipmentDetail = () => {

      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/shipment_detail`;
      let sendData = { invoice_id: invoice_id };
      console.log(sendData);
      Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res -> ",res);
        if (res.data.output.length == 1) {
          setState({ ...state, shipmentDetail: res.data.output[0], questions: res.data.questions, quotes: res.data.quotes })
        }
      }).catch((e) => {
        //setState({...state,isLoading: false});

        toast.configure();
        toast.error("Some thing went wrong");
        console.log("----error:   ", e);
      });
    };

    const handleChange = (event) => {
      console.log(event); 
    
      setSaveState({
          ...stateSave,
          [event.target.name]: event.target.value,
        });
        //console.log("setSaveState is ",setSaveState);

    }

    const addNewshipmentQuote = () => {
  console.log(stateSave);

      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/shipment_new_quotes`;
      if(stateSave.cost != 0 ){
        let sendData = { invoice_id: invoice_id,transporter_id:"",pickup_date:stateSave.pickup_date,delivery_date:stateSave.delivery_date,cost:stateSave.cost ,service_type:stateSave.service_type};
        if(userdata.transporter_id) {
          sendData.transporter_id = userdata.transporter_id;
        }
        console.log("vvvv",sendData);
        console.log("vv",stateSave.cost);

        Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          console.log("lll",res);
          // if(res.data.output.quotes_id) {
            toast.configure();
            toast.success(res.data.message);
            shipmentDetail();
        // }
        }).catch((e) => {
        //setState({...state,isLoading: false});
        toast.configure();
        toast.error("Some thing went wrong");
        console.log("----error:   ", e);
      });

    } else {
      toast.configure();
      toast.error("Please insert cost");
    }
    };

      return (<>

  <Header/>
  <section className=" p-5 bg-light">
    <div className="container ">

    <section>
      <div className="card">
        <div className="card-body ad_card_top shadow-sm">
          <div className="row">
              <div className="col-12 col-md-3 text-center">
                <img src="../../img/cat_icon/vehicles_desktop2x.png" className="img-fluid " />
              </div>
              <div className="col-12 col-md-6">
                  <h4>HAVE SOMETHING SIMILAR TO SHIP?</h4>
                  <p className="text-muted">Start by getting quotes from our network of trusted service providers.</p>
              </div>
              <div className="col-12  col-md-2 align-middle m-3">
                <a  className="btn btn-outline-success btn-block">Get Started <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
              </div>
          </div>
        </div>
      </div>
    </section>
  <section>
      <div className="row">
        <div className="col">
          <div className="row itme_detail_head p-3 ">
                <div className="col-12 col-md-6">
                  <h2>{state.shipmentDetail.title}</h2>
                  <p className="text-muted"><a href="">Mister C.</a> ID #{state.shipmentDetail.invoice_id}</p>
                </div>
                <div className="col-12 col-md-6 text-end">
                  <div><span className="badge bg-success fw-normal "></span></div>
                  <p><small>2 days, 16 hours left | Listed Mar 31, 2022</small></p>
                </div>
          </div>

          <div className="row">
            <div className="col itme_detail_box ">
              <div className="card">
                <div className="card-body p-4 shadow-sm">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h6 className="text-muted pb-2">Pickup</h6>
                            <div className="d-flex pb-4">
                              <div className="flex-shrink-0">
                                <i className="fa fa-arrow-right rotate_40 text-muted" aria-hidden="true"></i>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>{state.shipmentDetail.pickup_address}</h3>
                                <p className="text-muted ">{state.shipmentDetail.pickup_address_type}</p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <i className="fa fa-clock-o rotate_40 text-muted" aria-hidden="true"></i>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3>Between <Moment format="MMMM DD" unix>{state.shipmentDetail.pickup_datetime_from}</Moment> - <Moment format="MMMM DD" unix>{state.shipmentDetail.pickup_datetime_to}</Moment></h3>
                              </div>
                            </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <h6 className="text-muted pb-2">Delivery</h6>
                        <div className="d-flex pb-4">
                          <div className="flex-shrink-0">
                            <i className="fa fa-arrow-down rotate_40 text-muted" aria-hidden="true"></i>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h3>{state.shipmentDetail.delivery_address}</h3>
                            <p className="text-muted ">{state.shipmentDetail.delivery_address_type}</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <i className="fa fa-clock-o rotate_40 text-muted" aria-hidden="true"></i>
                          </div>
                          <div className="flex-grow-1 ms-3">
                          <h3>Between <Moment format="MMMM DD" unix>{state.shipmentDetail.delivery_datetime_from}</Moment> - <Moment format="MMMM DD" unix>{state.shipmentDetail.delivery_datetime_to}</Moment></h3>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div className="row pt-3 ">
                    <div className="col">
                    
                    {(state.shipmentDetail.toLocCord != undefined && state.shipmentDetail.toLocCord.length > 0) ?   <Mapsecond data={state.shipmentDetail.toLocCord[0]}/>:""}
                      {/* <Map/> */}
                      {/* <img src="img/staticmap.png" className="img-fluid"/> */}
                      {/* <Map google={this.props.google} zoom={14}>
  
          <Marker onClick={this.onMarkerClick}
                  name={'Current location'} />
  
          <InfoWindow onClose={this.onInfoWindowClose}>
              <div>
                <h1>{this.state.selectedPlace.name}</h1>
              </div>
          </InfoWindow>
        </Map> */}
                      </div>
                  </div>

                  <div className="row p-4 text-center">
                    <div className="col-4 col-md-4 border-end">
                      <span className=""><i className="fa fa-2x fa-map-marker text-muted" aria-hidden="true"></i></span>
                      <span className="fs-2 p-1">333</span>
                      <span className="text-uppercase crack">total km</span>
                    </div>
                    <div className="col-4 col-md-4 border-end">
                      <span className=""><i className="fa fa-2x fa-th-large text-muted" aria-hidden="true"></i></span>
                      <span className="fs-2 p-1">{state.shipmentDetail.qty}</span>
                      <span className="text-uppercase crack">total Items</span>
                    </div>
                    <div className="col-4 col-md-4 ">
                      <span className=""><i className="fa fa-2x fa-cube text-muted" aria-hidden="true"></i></span>
                      <span className="fs-2 p-1">{state.shipmentDetail.item_weight}</span>
                      <span className="text-uppercase crack">total weight</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          
        <section className="mt-4 shadow-sm shipment_info_box">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body p-4">
                  <h2 className="fs-5 text-uppercase">Shipment details</h2>
                  <hr/>
                  <div className="row">
                    <div className="col-3 text-muted">
                      Description
                    </div>
                    <div className="col">
                      <div className="row">
                        <h5 className=" f-w-500 pb-4">{state.shipmentDetail.description}</h5>
                        <div className="col-6 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>category</small></h6>
                          <p className=" f-w-500">{state.shipmentDetail.category_name}</p>
                        </div>
                        <div className="col-6 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>TOTAL WEIGHT</small></h6>
                          <p className=" f-w-500">{state.shipmentDetail.item_weight} kg</p>
                        </div>
                        <div className="col-6 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>TOTAL QUANTITY</small></h6>
                          <p className=" f-w-500">{state.shipmentDetail.qty}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                

                  <div className="row">
                
                    <div className="col-3 text-muted">
                      Item
                    </div>
                    <div className="col">
                      <div className="row">
                        <h6 className=" f-w-500 pb-4">Item 1: {state.shipmentDetail.title}</h6>
                        <div className="col-6 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>BODY TYPE</small></h6>
                          <p className=" f-w-500">Coupe</p>
                        </div>
                        <div className="col-6 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>CONDITION</small></h6>
                          <p className=" f-w-500">Operable</p>
                        </div>
                        <div className="col-6 col-md-4">
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>EST. WEIGHT</small></h6>
                          <p className=" f-w-500">{state.shipmentDetail.item_length} x {state.shipmentDetail.item_width} x {state.shipmentDetail.item_height} in</p>
                        </div>
                        {state.quotes.map((item,index)=>
                        <div className="col-6 col-md-4">
                          
                          <h6 className="fw-normal text-uppercase text-muted fs-m"><small>EST. value</small></h6>
                  
                        
                          <p className=" f-w-500">{item.cost+100-300}$</p>
                    
                        </div>
                        )}
                      </div>
                    </div>
                  
                  </div>
                  
                  <hr/>
                  <div className="row">
                    <div className="col-3 text-muted">
                      Photos
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <a href=""><img src={`https://shippxcdn.fniix.com/web/${state.shipmentDetail.item_imgs[0]}`} style={{width:"150px",height:"120px"}} className="img-fluid "/></a>
                          {/* <a href=""><img src="img/shipment/1.jpg" className="mb-2 img-thumbnail"/></a>
                          <a href=""><img src="img/shipment/2.jpg" className="mb-2 img-thumbnail"/></a>
                          <a href=""><img src="img/shipment/3.jpg" className="mb-2 img-thumbnail"/></a>
                          <a href=""><img src="img/shipment/4.jpg" className="mb-2 img-thumbnail"/></a> */}
                        </div>
                      </div>
                      <h6 className=" f-w-500 pb-4 text-muted">No Photos Uploaded</h6>
                    </div>
                  </div>

                  <hr/>
                  <div className="row">
                    <div className="col-3 text-muted">
                      Item
                    </div>
                    <div className="col">
                      <div>
                        <h6 className="fw-normal text-uppercase text-muted fs-m"><small>REQUESTED SERVICE TYPE(S)</small></h6>
                        <p className=" f-w-500">{state.shipmentDetail.service_type}</p>
                      </div>
                      <div>
                        <h6 className="fw-normal text-uppercase text-muted fs-m"><small>SPECIAL INSTRUCTIONS
                        </small></h6>
                        <p className="">{state.shipmentDetail.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-4 shadow-sm ">
          <div className="card  ">
            <div className="card-body">
              
              <section className="p-3  bg-light  border-start border-primary border-5 add_quote">
              <h2 className="fs-5 text-uppercase text-primary">Submit Quote</h2>
              <div className="row">
                <div className="col-12 col-md-2"></div>
                <div className="col col-md-8">
                  <section className="text-center">
                    <label htmlFor="" className="form-label">Enter Bid Value</label>
                    <div className="input-group input-group-lg mb-3 text-center">
                      <span className="input-group-text " id="basic-addon1"
                        ><i className="fa fa-usd" aria-hidden="true"></i
                      ></span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="enter your quote amount"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="cost"
                        onChange={(e)=>handleChange(e)}
                      />
                      <button className="btn btn-primary" type="button" disabled={disable.dis}  onClick={(e)=>addNewshipmentQuote(e)}  >Send <span className="d-none d-sm-block">Quote</span></button>
                    </div>
                  </section>
                </div>
                <div className="col-12 col-md-2"></div>
              </div>
                <hr/>
                <div className="row">
                  <div className="col">
                    <h6 className="mt-2">TIME FRAME</h6>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <p>Pickup Within: </p></div>
                      <div className="">
                        <div className="input-group">
                          <select className="form-select form-select-sm" name="pickup_date" onChange={(e)=>handleChange(e)}>
                            <option value="6">6 </option>
                            <option value="5">5 </option>
                            <option value="4">4 </option>
                            <option value="3">3 </option>
                            <option value="2">2 </option>
                            <option value="1">1 </option>
                          </select>
                          <span className="input-group-text">days of booking</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <p>Delivery Within: </p></div>
                      <div className="">
                        <div className="input-group">
                          <select className="form-select form-select-sm" name="delivery_date" onChange={(e)=>handleChange(e)}>
                            <option value="6">6 </option>
                            <option value="5">5 </option>
                            <option value="4">4 </option>
                            <option value="3">3 </option>
                            <option value="2">2 </option>
                            <option value="1">1 </option>
                          </select>
                          <span className="input-group-text">days of booking</span>
                        </div>
                      </div>
                    </div>
                    
                    <hr/>
                    <div className="d-flex justify-content-between">
                      <div className=" ">
                        <h6 className="mt-2">VEHICLE TYPE</h6></div>
                      <div className=" ">
                        <select className="form-select form-select-sm" name="">
                          <option>Multi-car open trailer</option>
                        </select>
                      </div>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-between">
                      <div className=" ">
                        <h6 className="mt-2">PAYMENT METHOD</h6></div>
                      <div className=" ">
                        <select className="form-select form-select-sm" name="">
                          <option>Cash Payments</option>
                          <option>Paypal </option>
                          <option>Stripe </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-between">
                      <div className=" ">
                        <h6 className="mt-2">TRANSPORT MODE</h6></div>
                      <div className=" ">
                        <select className="form-select form-select-sm" name="transport_mode" onChange={(e)=>handleChange(e)}>
                          <option value="Trucking" >Trucking</option>
                          <option value="Sea">Sea </option>
                          <option value="Air">Air </option>
                        </select>
                      </div>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-between">
                      <div className=" ">
                        <h6 className="mt-2">SERVICE TYPE</h6></div>
                      <div className=" ">
                        <select className="form-select form-select-sm" name="service_type" onChange={(e)=>handleChange(e)}>
                          <option value="Open Transport">Open Transport</option>
                          <option value="Close Transport">Close Transport </option>
                        </select>
                      </div>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-between">
                      <div className=" ">
                        <h6 className="mt-2">PAYMENT ACCEPTED</h6></div>
                      <div className=" ">
                        <select className="form-select form-select-sm" name="payment_accepted_on" onChange={(e)=>handleChange(e)}>
                          <option value="At Pickup">At Pickup</option>
                          <option value="At Delivery">At Delivery </option>
                        </select>
                      </div>
                    </div>
                  </div>
              </div>
              </section>
            </div>
          </div>
        </section>

        <section className="mt-4 shadow-sm">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h2 className="fs-5 text-uppercase">Quotes</h2>
                  <hr/>
                  <section>
                    {state.quotes.map((item,index)=>
                    <div className="card transporter_item mb-3" key={index}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6 col-md-2">
                            <img src={"https://shippxcdn.fniix.com/web/"+item.logo} className="img-fluid rounded-circle border" />
                          </div>
                          <div className="col-6 col-md-4 border-end">
                            <h2><a href="" className="text-dark"><Link to={`/profile-carrier/${item.transporter_id}`}>{item.branch_name}</Link></a></h2>
                            <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                            <span><i className="fa fa-star text-success" aria-hidden="true"></i></span>
                            <small><a href="" className=""> </a></small>
                          </div>
                          <div className="col-6 col-md-3">
                            <h6 className="text-muted pb-3 fw-light">{state.shipmentDetail.service_type}</h6>
                            <h3 className=" pb-1">Estimated Pickup</h3>
                            <p className="fw-light">Within 5 days of booking</p>
                          </div>
                          <div className="col-6 col-md-3 text-end">
                            <h1>${item.cost}</h1>
                            <small class="text-muted"></small>
                          </div>
                        </div>
                        <hr/>
                        <div className="row">
                          <div className="col-6 col-md-2">
                            <a href="" className="text-muted" data-bs-toggle="tooltip" title="Feedback Score"><i className="fa fa-star" aria-hidden="true"></i> 2983</a> 
                          </div>
                          <div className="col-6 col-md-2">
                            <a href="" className="text-muted" data-bs-toggle="tooltip" title="Positive Feedback "><i className="fa fa-check" aria-hidden="true"></i> 29%</a>
                          </div>
                          <div className="col-6 col-md-2">
                            <a href="" className="text-muted" data-bs-toggle="tooltip" title="Cancellation Rate"><i className="fa fa-times" aria-hidden="true"></i> 11%</a>
                          </div>
                          <div className="col-6 col-md-2">
                            <a href="" className="text-muted" data-bs-toggle="tooltip" title="Question"><i className="fa fa-question-circle" aria-hidden="true"></i> 0</a>
                          </div>
                          <div className="col-12 col-md-4 text-end">
                            <a href="" className="text-uppercase" data-bs-toggle="collapse" data-bs-target={`#quote_detail_box_${index}`}>Show Quote Details</a>
                          </div>

                          <div id={`quote_detail_box_${index}`} className="collapse">
                        
                          <section className="mt-3 quote_detail_box"  id="demo" >
                          
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#details_1">Details</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#questions_1">Questions</a>
                              </li>
                            </ul>
                            <div className="tab-content mt-3">
                              <div className="tab-pane container active" id="details_1">
                                <p className="fs-12 text-end text-muted">Quote Placed Apr 1, 2022, 7:23 PM<br/>
                                  Quote Expires Apr 7, 2022, 7:21 PM<br/>
                                  Quote ID: {item.quotes_id}</p>
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <h6>TIME FRAME</h6>
                                    <p>Pickup: Within 5 days of booking</p>
                                    <p>Delivery: Within 7 days of pickup</p>
                                    <hr/>
                                    <h6>TRANSPORT MODE</h6>
                                    <p>{item.transport_mode}</p>
                                    <hr/>
                                    <h6>VEHICLE TYPE</h6>
                                    <p>Multi-car open trailer</p>
                                    <hr/>
                                    <h6>SERVICE TYPE</h6>
                                    <p>{item.service_type}</p>
                                    <hr/>
                                    <h6>PAYMENT METHOD</h6>
                                    <p>Cash Payments</p>
                                    <hr/>
                                    <h6>PAYMENT ACCEPTED</h6>
                                    <p>{item.payment_accepted_on}</p>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <h6>NOTE TO SHIPPING CUSTOMER</h6>
                                    <p className="mt-3">{item.note} </p>
                                    <hr/>
                                    <p className="mt-3 text-end"><a href="">View Our Profile</a></p>
                                  </div>
                              </div>
                              
                              </div>
                              <div className="tab-pane container fade" id="questions_1">
                                <div className="d-flex p-3">
                                  <div className="p-2 text-muted">
                                    <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
                                  </div>
                                  {state.questions.map((item,index)=>
                                  <div className="p-2">
                                    <h6 className="m-0">{item.full_name}</h6>
                                    <small className="text-muted"><Moment format="MMMM DD, YYYY hh:mm:ss" unix>{item.created}</Moment></small>
                                    <p className="pt-3">{item.question}</p>
                                  </div>
                                  )}
                                </div>
                                <hr/>

                              </div>
                            </div>
                          </section>
                          </div>

                        </div>
                      </div>
                    </div>
  )}
                  
                    </section>
                  </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-4 shadow-sm">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h2 className="fs-5 text-uppercase">Questions</h2>
                  <hr/>
                  <section>
                    {state.questions.map((item,index)=>
                    <>
                    <div className="d-flex p-3" key={index}>
                      <div className="p-2 text-muted">
                        <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
                      </div>
                      <div className="p-2">
                        <h6 className="m-0">{item.full_name}</h6>
                        <small className="text-muted"><Moment format="MMMM DD, YYYY hh:mm:ss" unix>{item.created}</Moment></small>
                        <p className="pt-3">{item.question}</p>
                      </div>
                    </div>
                    
                    <hr/>
                    </>
                    )}
                    {/* <div className="d-flex p-3">
                      <div className="p-2 text-muted">
                        <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
                      </div>
                      <div className="p-2">
                        <h6 className="m-0">Service Provider</h6>
                        <small className="text-muted">March 31, 2022 8:41 AM</small>
                        <p className="pt-3">Hello, may I ask if you will load any additional personal items in the vehicle or just shipping the vehicle as is? I can move it for 899. Have a great day!</p>
                      </div>
                    </div> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>

        </div>
        
        <div className="col-3">
          <section className="pt-2">
            <div className="card shadow-sm mb-4">
              <div className="card-body text-center">
                <small>Photos</small>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <a href=""><img src={`https://shippxcdn.fniix.com/web/${state.shipmentDetail.item_imgs[0]}`} style={{width:"150px",height:"120px"}}  className="mb-2 img-thumbnail"/></a>
                    {/* <a href=""><img src="img/shipment/1.jpg" className="mb-2 img-thumbnail"/></a>
                    <a href=""><img src="img/shipment/2.jpg" className="mb-2 img-thumbnail"/></a>
                    <a href=""><img src="img/shipment/3.jpg" className="mb-2 img-thumbnail"/></a>
                    <a href=""><img src="img/shipment/4.jpg" className="mb-2 img-thumbnail"/></a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card shadow-sm">
              <div className="card-body">
                <p className="text-center">Want to submit a quote or ship something similar?</p>
                <div className="d-grid pb-3">
                  <a className="btn btn-success btn-block">Sing In</a>
                </div>
                <div className="d-grid">
                  <a className="btn  btn-outline-success btn-block">Join Now</a>
                </div>

                <div className="d-flex justify-content-between p-3">
                  <div className="p-2 flex-fill"><small>LOWEST <br/>QUOTE</small></div>
                  <div className="p-2 flex-fill fs-2 f-w-500">${state.shipmentDetail.lowest_quote}</div>
                </div>
                <hr/>
                <div className="d-flex justify-content-between p-3 text-center">
                  <div className="p-2 flex-fill border-end">
                    <h6><small className="text-muted fw-light">Watching</small></h6>
                    <p className="text-center text-primary"><i className="fa fa-eye " aria-hidden="true"></i> 6 </p>
                  </div>
                  <div className="p-2 flex-fill">
                    <h6><small className="text-muted fw-light">Quotes</small></h6>
                    <p className="text-center text-primary"><i className="fa fa-first-order " aria-hidden="true"></i> 6 </p>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    </div>
  </section>
  <Footer/>
  </>)
  }

  export default ShipmentDetail;
