import React, { useState, useEffect } from "react";
import Map from "./components/Map";
import Mapsecond from "./components/Mapsecond";
const SamplePage = () => {

    return (<div>
        <Mapsecond/>
    </div>
    )

}
export default SamplePage