import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom'


import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import { useNavigate } from 'react-router-dom';

const Edit_driver = () => {
   const navigate=useNavigate();
    const { driver_id } =  useParams();
    const [state, setState] = useState({driver_id:"",license_number:"",driver_name:"",driver_email:"",mobile_number:"",altmobile_number:"",address:"",govid_number:""})
   


    console.log("state is ghj ",state);
   // const [countries,setCountries] = useState();
    useEffect(() => {
       driver_detail(driver_id)
    }, [])

    const driver_detail = (driver_id) => {

        let state_data = { driver_id:driver_id}

        Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/driver/driver_detail`, state_data , { headers: exportValue.headers }).then((result)=>{
            state.isLoading = false;
            if(result.data.output.length > 0){
                let state_details = result.data.output[0];
                console.log("state_details is ",state_details);
                setState({driver_id:state_details.driver_id,license_number:state_details.license_number,driver_name:state_details.driver_name,driver_email:state_details.driver_email,mobile_number:state_details.mobile_number,altmobile_number:state_details.altmobile_number,address:state_details.address,govid_number:state_details.govid_number,driver_password:""})

               
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("submit1");
        state.isLoading = true
        let state_detailes = {driver_id:state.driver_id,license_number:state.license_number,driver_name:state.driver_name,driver_email:state.driver_email,mobile_number:state.mobile_number,altmobile_number:state.altmobile_number,address:state.address,govid_number:state.govid_number,driver_password:state.driver_password}
        console.log("state detailes is ",state_detailes);
        
        Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/driver/edit_driver`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
                console.log("result is i ",result);
                state.isLoading = false
                if(result && result.data != undefined){
                   
                        toast.configure()
                        toast(result.data.message) 
                        navigate('/driver_list');
                    }
     
            })
    }

const handleChange = (event) => {
        event.persist()
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const { license_number,driver_name,driver_email,mobile_number,altmobile_number,address,govid_number,driver_password } = state
   


  return (
    <div>
         <Header/>
        
        <section className="bg-light p-5">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-2">&nbsp;</div>
        <div className="col-12 col-md-8 bg-white p-5">
          <h3 className="text-uppercase">Edit Driver</h3>
  
          <hr/>
          <section>
          <form >
  
            <div className="form-floating mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="license_number"
                value={license_number||""}
                onChange={handleChange}
                required
              />
              <label htmlFor="email" className='d-none d-sm-block'>Enter Driver License Number</label>
            </div>
  
            <div className="form-floating mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="driver_name"
                 value={driver_name || ""}               
                 onChange={handleChange}
                required
              />
              <label htmlhtmlFor="email" className='d-none d-sm-block'>Enter Driver Name</label>
            </div>
  
            <div className="form-floating mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="driver_email"
                 value={driver_email||""}
                 onChange={handleChange}
                required
              />
              <label htmlhtmlFor="email" className='d-none d-sm-block'>Enter Driver Email</label>
            </div>
            <div className="form-floating mb-3 mt-3  ">
            <label htmlhtmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}> Enter Driver Password</label>
              <input
                type="password"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="driver_password"
                value={driver_password||""}
                onChange={handleChange}
                required
              />
              <label htmlhtmlFor="email" className='d-none d-sm-block'> Enter Driver Password</label>
            </div>
  
            <div className="form-floating mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="mobile_number"
                 value={mobile_number||""}
                onChange={handleChange}
                required
              />
              <label htmlhtmlFor="email" className='d-none d-sm-block'>Enter Driver Mobile no.</label>
            </div>
  
            <div className="form-floating mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="altmobile_number"
                 value={altmobile_number||""}
                 onChange={handleChange}
                required
              />
              <label htmlFor="email" className='d-none d-sm-block'>Enter Alt. Mobile no.</label>
            </div>
  
  
  
  
            <div className=" mb-3 mt-3">
              <div className="form-floating">
                 <textarea className="form-control h-25" id="comment" name="address" rows="5" cols=""  placeholder="address" value={address||""} onChange={handleChange}></textarea> 
                <label htmlFor="comment" className='d-none d-sm-block'>Address</label>
              </div>
            </div>
  
  
  
            <div className="form-floating mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="govid_number"
                value={govid_number||""}
                 onChange={handleChange}
                required
              />
              <label htmlFor="email" className='d-none d-sm-block'>Enter Driver Gov. ID no.</label>
            </div>
  
            
            <div className="mt-3">
              <a href="" className="btn btn-primary" onClick={(event)=>handleSubmit(event)}  >Edit Driver</a> 
            </div>
            </form>
          </section>
          
            
  
         
        </div>
        <div className="col-12 col-md-2">&nbsp;</div>
      </div>
    </div>
  </section>
  <Footer/>
  </div>
  )
}

export default Edit_driver