import { combineReducers } from "redux";
import { ShipmentReducer,CatAttributeReducer,AddShipmentAttributeDetail,AddShipmentLocationDetail} from './ShipmentReducer'


const RootReducer = combineReducers({
    shipmentReducer : ShipmentReducer,
    catAttributeReducer : CatAttributeReducer,
    addShipmentAttributeDetail : AddShipmentAttributeDetail,
    addShipmentLocationDetail: AddShipmentLocationDetail
})

export default RootReducer