import React,{useEffect,useState} from 'react'
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import exportValue from "../apiconfig";
import axios from 'axios';
import ReactLoading from 'react-loading';

const Vehicle_list = () => {

    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
      console.log("userData  -  ",userdata);
      //navigate('/home') 
    }

    const[state,setState] = useState({isLoading:true,description:[]})
    console.log("stat",state)

    const[dstatusState,setdstatusState] = useState({dstatus:1,varified:0,activeStatus:0})

    const[stateSearchValue,setStateSearchValue] = useState({searchValue:undefined})
    console.log("dstatusState is ",dstatusState);
    const transporter_vehicle_detail = (e) =>{
     
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/vehicle/vehicle_transporter_list`;
      let sendData = {transporter_id:userdata.transporter_id};
      if(dstatusState.dstatus == 2){
        sendData.dstatus = 2
        sendData.vehicle_id = dstatusState.vehicle_id
      }
      if(dstatusState.varified == 1 || dstatusState.varified == 2){
        sendData.varified = dstatusState.varified
        sendData.vehicle_id = dstatusState.vehicle_id
      }

      if(dstatusState.activeStatus){
        sendData.activeStatus = dstatusState.activeStatus
      }

      if(stateSearchValue.vehicle_number){
        sendData.search_vehicle_number = stateSearchValue.vehicle_number
      }
      
     console.log("sendData is ",sendData);
      axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
       // setState(res.data.output);
       
            setState(res.data.output,{isLoading:false})
       
       
      }).catch((e) => {
        // toast.configure()
        // toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }

    useEffect(() => {
        transporter_vehicle_detail();
    }, [dstatusState])

   const delete_vehicle = (e,vehicle_id) => {
    e.preventDefault()
    setdstatusState({dstatus:2,vehicle_id:vehicle_id})
   }

   const handleActiveChange = (e,varified,vehicle_id) => {
    e.preventDefault()
    if(varified == 0 || varified == 2){
      varified = 1
    } else {
      varified = 2
    }
    console.log("verified is ",varified);
    setdstatusState({varified:varified,vehicle_id:vehicle_id})
   }

   const fetchActiveInactive = (e,status) => {
    e.preventDefault()
    setdstatusState({activeStatus:status})
   }

   function search_vehicle_name(e) {
           e.preventDefault();
           transporter_vehicle_detail()
   }
      
    const stateHandler = (key, value) => {
        stateSearchValue[key] = value
        setStateSearchValue({  vehicle_number:stateSearchValue.searchValue })
    }


  return (
    <div>
        <Header/>
        <section class="pt-1 pb-3 bg-light">
  <div class="container">
    <section>
      <div class="row">
        <div class="col-8 col-md-6">
          <ul class="nav nav-pills pt-2 pb-3">
            <li class="nav-item">
              <a class={(dstatusState.activeStatus == 0) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={(e)=>fetchActiveInactive(e,0)}>All</a>
            </li>
            <li class="nav-item">
              <a className={(dstatusState.activeStatus == 1) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={(e)=>fetchActiveInactive(e,1)}>Active</a>
            </li>
            <li class="nav-item">
              <a class={(dstatusState.activeStatus == 2) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={(e)=>fetchActiveInactive(e,2)}>Inactive</a>
            </li>
          </ul>
        </div>

        <div class="col-4 col-md-2 pt-2">
         <Link to = "/add_vehicle"> <a class="btn btn-outline-primary">Add Vehicle</a></Link>
        </div>
         
         
        <div class="col-10  col-md-3 ">
          <div class="form-floating">
            <input type="text" class="form-control " id="Search" placeholder="Enter Search" name="Search" value={stateSearchValue.searchValue} onChange={({ target }) => {
                                stateHandler('searchValue', target.value)

                            }}/>
            <label for="email">Search vehicle</label>
          </div>
        </div>
       <div className="col-2 col-md-1 mt-3 "><i onClick={(e)=>search_vehicle_name(e)}  className="fa-solid fa-magnifying-glass fa-2x">  </i></div> 
                        
      

                        
      
</div>

    </section>
    <hr/>
    {state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:

    (state != undefined && state.length > 0) ?
    <section class="shipment_list_table">
            <div class="row shipment_list_table_heading mb-2">
                <div class="col-2 col-md-2  d-none d-sm-block">Vehicle ID</div>
                {/* <div class="col-3 col-md-2 ">Vehicle #</div> */}
                <div class="col-3 col-md-8 " style={{textAlign:"center"}}>Description</div>
                {/* <div class="col-3 col-md-2 ">Type</div>
                <div class="col-3 col-md-2 ">Wheel</div> */}
                <div class="col-4 col-md-1  d-none d-sm-block">Status</div>
                <div class="col-4 col-md-1  d-none d-sm-block">MORE</div>
            </div>
        {(state != undefined && state.length > 0) ? 
        state.map((item,index)=>{
          return(
          <>

          
            <div class="card">
        <div class="card-body">
           

                
              <div class="row">
              <div class="col-12 col-md-2 "><a href="">{item.vehicle_id}</a> 
              </div>
              

            {item.description.map((sub)=>(
                <div class="col-12 col-md-8" style={{textAlign:"center"}}>
                 
                 {item.description.map((sub)=>(
               
                  Object.keys(sub).map((val, k) => {
    const theValue = sub[val];

    return (<>
       {theValue}    ,   
    {/* <h4 key={k}>{val}   {theValue}</h4> */}
    </> )
    })

                
                  
                
           ))}

              </div>
           
           ))}
           
               

                           

                <div class="col-6 col-md-1 "><span class="badge rounded-pill bg-success text-light">{(item.verified == 1 || item.verified == 0 ) ? "Active" : "Inactive"}</span></div>
            
            
            
                <div class="col-6  col-md-1 text-end "><a href="" data-bs-toggle="collapse" data-bs-target={`#info_box_${index}`}><i class="fa fa-chevron-circle-down" aria-hidden="true"></i></a>
              </div>
              </div>

              <div class="row collapse" id={`info_box_${index}`}>
                <div class="card bg-light border-0">
                  <div class="card-body">
                    <div class="d-flex flex-row-reverse p-3">
                      <div class="p-2 ">
                      <a type="button" class="btn btn-outline-danger btn-sm" onClick={(e) => delete_vehicle(e,item.vehicle_id)}>Delete</a>
                      </div>
                      <div class="p-2 ">
                      <a type="button" class="btn btn-outline-warning btn-sm" onClick={(e) => handleActiveChange(e,item.verified,item.vehicle_id)} >{(item.verified == 2 ) ? "Active" : "Inactive"}</a>
                      </div>
                      <div class="p-2 ">
                      <Link to={`/edit_vehicle/${item.vehicle_id}`}><a type="button" class="btn btn-outline-secondary btn-sm">Edit</a></Link>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
          </div>
      </div>
          </>
          )
        })
        
 
        
        :""}
             
          

  </section>  
  :
    
    <section class="text-center p-5 h-100">
      <h4 class="text-muted fw-bold">NO Vehicle</h4>
      <p class="pb-5">You do not currently have any Vehicle in this status.</p>
      <Link to = "/add_vehicle"><a class="btn btn-outline-primary btn-lg">Add a new Vehicle</a></Link>
    </section>
}
  </div>
</section>
<Footer/>
    </div>
  )
}

export default Vehicle_list;