import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import Axios from 'axios'
import exportValue from '../apiconfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';
import moment from 'moment'
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import ReactStarsRating from 'react-awesome-stars-rating';


const Myaccount_carrier = () => {

  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
    console.log("userData  -  ",userdata);
     
  }
  const [state, setState] = React.useState({
    myshipment_lists: [], myshipment_driver: [], transporter_vehicle_detail: [],isLoading:true })

  const [shipState,setShipState] = useState({driver_id:"",vehicle_id:""})
let newDate = new Date();
  const [currentStatus,setCurrentStatus] = useState({current_Status:"1",current_shipment_status:null,Current_Location:"",Current_Date:moment(newDate).format("YYYY-MM-DD"),Current_min_Date:moment(newDate).format("YYYY-MM-DD")})
  const [searchData,setSearchData] = useState({searchKeyword:""})
  console.log("pp", currentStatus)
  //console.log("state is user list is ", state);
  const [reviewstate, setReviewState] = useState({invoice_id:"",transporter_id:"",transporter_review_description:"",user_id:""});

  const axios_get_api = (current_shipment_status) => {

    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/transporter_myshipment_list`;
    let sendData = {
      dstatus: 1,
      transporter_id:userdata.transporter_id,
      current_shipment_status:current_shipment_status
    };

    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      console.log("-----res-----  ",res);
      setState({ ...state, myshipment_lists: res.data.output,myshipment_driver: res.data.transporter_driver_detail, transporter_vehicle_detail: res.data.transporter_vehicle_detail ,isLoading:false });
      console.log("response", res);

      

    }).catch((e) => {

      // toast.configure()
      // toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const assign_driver_api = (invoice_id) => {
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/add_assign_driver`;
    let sendData = {
      dstatus: 1,
      transporter_id:userdata.transporter_id,
      invoice_id: invoice_id,
      driver_id: shipState.driver_id,
     vehicle_id : shipState.vehicle_id
    };
    
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      toast.configure()
      toast.success("Shipment Driver Assign SuccessFully")
      console.log("response",res);
      axios_get_api()
    }).catch((e) => {

      // toast.configure()
      // toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const update_current_status = (invoice_id) => {
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/update_shipment_position_api`;
    let sendData = {
      dstatus: 1,
      transporter_id:userdata.transporter_id,
      invoice_id: invoice_id,
      current_Status: currentStatus.current_Status,
      Current_Location: currentStatus.Current_Location,
      Current_Date: currentStatus.Current_Date
    };
    
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      if(res.data.status==200){
        toast.configure()
        toast.success(res.data.message)
      }
      else if(res.data.status==201){
        toast.configure()
        toast.error(res.data.message)
      }
      else{
        toast.configure()
        toast.error(res.data.message)
      }
     
      console.log("response",res);
      axios_get_api()
      
    }).catch((e) => {

      toast.configure()
      toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const shipmentFilter = (current_shipment_status,e) => {
    console.log("action ", current_shipment_status);
    setCurrentStatus({...currentStatus,current_shipment_status : current_shipment_status})
    axios_get_api(current_shipment_status)
  }

  React.useEffect(() => {
    console.log("hi vikram singh shekhawta")
    axios_get_api()
  }, [])

  const handleChange = (e) => {
       e.preventDefault();
      let name = e.target.name;
       let value = e.target.value
      
       shipState[name] = value
       setShipState({...shipState})
  }

  const handleUpdateChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
     let value = e.target.value
    
     currentStatus[name] = value
     setCurrentStatus({...currentStatus})
  }

  const AssignShipmentSubmit = (e,invoice_id) => {
      e.preventDefault();
      assign_driver_api(invoice_id)
  }

  const UpdateCurrentStatus = (e,invoice_id) => {
    e.preventDefault();
    update_current_status(invoice_id)

  }

  const searchOnchange = (e) => {
    console.log("e  -   ",e.target.value);
    setSearchData({...searchData,searchKeyword:e.target.value})
    
  }
   const searchButton = ()=> {
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/shipment_find_detail`;

    let shipment_data = { offset: 0, limit: 50,dstatus:1};
  
    if (searchData.searchKeyword) {
      shipment_data.title = searchData.searchKeyword;
    }
    console.log("pv", shipment_data)
    Axios.post(full_api, shipment_data, { headers: exportValue.headers }).then(res => {
      console.log("response", res);
      setState({ ...state, myshipment_lists:res.data.output})
     // setState({ ...state, dialogOpen: false, shipmentList: res.data.output })

    }).catch((e) => {

      console.log("----error:   ", e);
    })
      
   }


   const handleSubmit = (invoice_id,user_id) => {
    
    console.log("submit1");
    //state.isLoading = true
    let state_detailes = {transporter_review_description:reviewstate.transporter_review_description,transporter_id:"",invoice_id:invoice_id, user_id:user_id}
    if(userdata.transporter_id) {
      state_detailes.transporter_id = userdata.transporter_id;
    }
    
    console.log("state detailes is ",state_detailes);
    
    Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/transporter_review_create`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
            console.log("result is i ",result);
           // state.isLoading = false
            if(result && result.data != undefined){
               
                    toast.configure()
                    toast(result.data.message) 
                    axios_get_api()
                }
 
        })
}
const handleChange4 = (event) => {
  console.log(event);  
  setReviewState({
      ...reviewstate,
      [event.target.name]: event.target.value,
    });
  
}
  return (
    <div>
        <Header/><section class="pt-1 pb-3 bg-light">
    <div class="container">
      <section>
        <div class="row">
          <div class="col-12 col-md-8">
            <ul class="nav nav-pills pt-2 pb-3">
              <li class="nav-item">
                <a class={(currentStatus.current_shipment_status == null) ? 'nav-link active' :'nav-link' } style={{cursor:"pointer"}} onClick={()=>shipmentFilter(null)}>All</a>
              </li>
              <li class="nav-item">
                <a class={(currentStatus.current_shipment_status == 1) ? 'nav-link active' :'nav-link' }  style={{cursor:"pointer"}} onClick={()=>shipmentFilter(1)}>Booked</a>
              </li>
              <li class="nav-item">
                <a class={(currentStatus.current_shipment_status == 2) ? 'nav-link active' :'nav-link' }  style={{cursor:"pointer"}}  onClick={()=>shipmentFilter(2)}>Pickup in Progress</a>
              </li>
              <li class="nav-item">
                <a class={(currentStatus.current_shipment_status == 3) ? 'nav-link active' :'nav-link' }  style={{cursor:"pointer"}} onClick={()=>shipmentFilter(3)}>Picked Up</a>
              </li>
              <li class="nav-item">
                <a class={(currentStatus.current_shipment_status == 4) ? 'nav-link active' :'nav-link' }  style={{cursor:"pointer"}} onClick={()=>shipmentFilter(4)}>In Transit</a>
              </li>
              <li class="nav-item">
                <a class={(currentStatus.current_shipment_status == 5) ? 'nav-link active' :'nav-link' }  style={{cursor:"pointer"}} onClick={()=>shipmentFilter(5)}>Delivered</a>
              </li>
              {/* <li class="nav-item">
                <a class={(currentStatus.current_shipment_status == 6) ? 'nav-link active' :'nav-link' }  onClick={()=>shipmentFilter(6)}>Cancelled</a>
              </li>
              <li class="nav-item">
                <a class={(currentStatus.current_shipment_status == 7) ? 'nav-link active' :'nav-link' }  onClick={()=>shipmentFilter(7)}>Returned</a>
              </li> */}
            </ul>
          </div>
          
          <div class="col-10 col-md-3 ">
            <div class="form-floating">
              <input type="text" class="form-control " id="Search" placeholder="Enter Search" name="searchKeyword" onChange={(e)=>searchOnchange(e)}/>
              <label for="email">Search shipment</label>
            </div>
          </div>
          <div class="col-2 col-md-1 " style={{"padding-top":"14px"}}>
          <i class="fa-solid fa-magnifying-glass fa-2x"onClick={()=>searchButton()}/>  
          </div>

         


        </div>
      </section>
      <hr/>
      {state.isLoading==true?<ReactLoading type="bubbles" color="#0d6efd"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
    (state.myshipment_lists.length>0)?    
      <section class="shipment_list_table">
              <div class="row shipment_list_table_heading mb-2 ">
                  <div class="col-4 col-md-2 ">Track #</div>
                  <div class="col-4  col-md-2 ">PICKUP</div>
                  <div class="col-4 col-md-2 ">DELIVERY</div>
                  <div class="col-4  col-md-2 d-none d-sm-block ">GOODS</div>
                  <div class="col-4  col-md-2 d-none d-sm-block">Shipment ID #</div>
                  <div class="col-2 col-md-1 d-none d-sm-block">Status</div>
                  <div class="col-2 col-md-1 d-none d-sm-block">MORE</div>
              </div>
        

             { (state.myshipment_lists.length>0)? state.myshipment_lists.map((subscriber,index) => {
                return(
                  <>
                 
                <div class="card " key={index}>
                
                  <div class="card-body">
                  <Link to={`/shipment/${subscriber.invoice_id}`}>
                      <div class="row">
                          <div class="col-12 col-md-2  "><p><a href="">{subscriber.title}</a> </p>
                        </div>
                          <div class="col-12 col-md-2 ">
                            <h5><i class="fa fa-map-marker text-muted" aria-hidden="true"></i> <a href="">{subscriber.pickup_address}</a>
                            </h5>
                            <small className='text-dark' ><Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.pickup_datetime_to}</Moment></small>
                          </div>
                          <div class="col-12 col-md-2 ">
                            <h5><i class="fa fa-map-marker text-muted" aria-hidden="true"></i> <a href="#">{subscriber.delivery_address}</a>
                            </h5>
                            <small className='text-dark'><Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_from}</Moment> - <Moment format="YYYY/MM/DD" unix>{subscriber.delivery_datetime_to}</Moment></small>
                          </div>
                          <div class="col-12 col-md-2 "><p className='text-dark'>{subscriber.title}</p></div>
                          <div class="col-12 col-md-2 ">
                            <p className='text-dark'>#{subscriber.invoice_id}</p>
                          </div>
                          <div class="col-6 col-md-1 "> {subscriber.current_shipment_status==1? <span class="badge rounded-pill bg-success text-light">Booked</span>:subscriber.current_shipment_status==2?<span class="badge rounded-pill bg-primary text-light">Pickup In Progress</span>:subscriber.current_shipment_status==3?<span class="badge rounded-pill bg-info text-light">Picked Up</span>:subscriber.current_shipment_status==4?<span class="badge rounded-pill bg-primary text-light">In Transit</span> :subscriber.current_shipment_status==5?<span class="badge rounded-pill bg-warning text-light">Delieverd</span>:subscriber.current_shipment_status==6?<span class="badge rounded-pill bg-primary text-light">Returned</span>:subscriber.current_shipment_status==7?<span class="badge rounded-pill bg-primary text-light">Cancelled</span>:subscriber.current_shipment_status==8?<span class="badge rounded-pill bg-danger text-light">Out For Delivery</span>:<span class="badge rounded-pill bg-dark text-light">Not Booked</span>}</div>
                          <div class="col-6 col-md-1 text-end "><a href="" data-bs-toggle="collapse" data-bs-target={`#info_box_${index}`}><i class="fa fa-chevron-circle-down" aria-hidden="true"></i>{subscriber.status==2 && subscriber.review_detail.length>0 && subscriber.transporter_review.length==0 ?<small >Click here to reply review</small>:""}</a>
                        </div>
                        </div>
 </Link>
                        <div class="row collapse" id={`info_box_${index}`}>
                          <div class="card bg-light border-0">
                            <div class="card-body">
                              <div class="row"> 
                                <div class="col-12 col-md-4">
                                  <h6 class="pt-3 pb-2">LOCATION DETAILS</h6>
                                  <p class="m-0">Pickup: <span class=" ">{subscriber.pickup_address_type}</span></p>
                                  <p class="m-0">Delivery: <span class="">{subscriber.delivery_address_type}</span></p>
                                  <hr/>
                                  <h6 class="pt-3 pb-2">DETAILS</h6>
                                  <p class="m-0">Items: <span class=" ">{subscriber.qty}</span></p>
                                  <p class="m-0">Trailer Type: <span class="">{subscriber.service_type}</span></p>
                                  <p class="m-0">Listed By: <span class="">Shipping Customer</span></p>
                                  <Link style={{ color: 'white' }} to={"/tracking/" + subscriber.invoice_id}><p className=" d-grid" style={{marginTop:'70px'}}><a target="_blank" className="btn btn-primary btn-block">Tracking</a></p></Link>
                                </div>
                                <div class="col-12 col-md-4 border-start">
                                  <h6 class="pt-3 pb-2">ASSIGN SHIPMENT</h6>
                                 
                                  <div class="mb-3">
                                 
                                    <label for="email" class="form-label">Choose Vehicle</label>
                                    {(subscriber.driver_detail.length==0) ? 
                                    <select class="form-select" name="vehicle_id"  onChange={(e) => handleChange(e)}>
                                      
                                    <option>Select</option>
                                      {(state.transporter_vehicle_detail.length>0)? state.transporter_vehicle_detail.map((subscrib,index) => {
                                         return(
                                        <option value={subscrib.vehicle_id}>{subscrib.description[0].vehicleNumber}</option>
                                         )
                                      }) : ""}
                                    </select>
                                    :state.transporter_vehicle_detail.map((subscrib,index) => {
                                       return(
                                         (subscrib.vehicle_id == subscriber.driver_detail[0].vehicle_id)?
                                        <h3> {subscrib.description[0].vehicleNumber}</h3>
                                        :""
                                       )
                                     })
                                     }
                                    </div>
                                  <div class="mb-3">
                                    <label for="email" class="form-label">Choose Driver</label>
                                    {(subscriber.driver_detail.length==0) ? 
                                    <select class="form-select" name="driver_id"  onChange={(e) => handleChange(e)}>
                                      <option>Select</option>
                                      {(state.myshipment_driver.length>0)? state.myshipment_driver.map((subs,index) => {
                                        return(
                                        <option value={subs.driver_id}>{subs.driver_name} - {subs.mobile_number}</option>
                                        )
                                      }) : ""}
                                    </select>
                                    :state.myshipment_driver.map((driver,index) => {
                                      return(
                                        (driver.driver_id == subscriber.driver_detail[0].driver_id)?
                                       <h3> {driver.driver_name} - {driver.mobile_number}</h3>
                                       :""
                                      )
                                    })
                                    }
                                  </div>
                                  {(subscriber.driver_detail.length==0) ? 
                                  
                                  <p class="m-0 d-grid"><a  class="btn btn-outline-primary btn-block" onClick={(e) => AssignShipmentSubmit(e,subscriber.invoice_id)} >Assign Shipment</a></p>
                                  
                                       : ""}
                                </div>
                                <div class="col-12  col-md-4 border-start">
                                  <h6 class="pt-3 pb-2">UPDATE STATUS</h6>
                                  <div class="mb-3">
                                    <label for="email" class="form-label">New Status</label>
                                    <select class="form-select" name="current_Status" onChange={(e)=>handleUpdateChange(e)}>
                                     {(subscriber.current_shipment_status <=1 ) ? <option value="1">Booked</option> : ""}
                                     {(subscriber.current_shipment_status <=2 ) ? <option value="2">Pickup in Progress</option>: ""}
                                     {(subscriber.current_shipment_status <= 3) ? <option value="3">Picked Up</option>: ""}
                                     {(subscriber.current_shipment_status <= 4) ? <option value="4">In Transit</option>: ""}
                                     {(subscriber.current_shipment_status <= 4 || subscriber.current_shipment_status==8) ?<option value="8">Out For Delivery</option>: ""}
                                     {(subscriber.current_shipment_status<=8 )?<option value="5">Delivered</option>: ""}
                                     {/* {(subscriber.current_shipment_status <= 6) ?   <option value="6">Returned</option>: ""}
                                     {(subscriber.current_shipment_status <= 7) ?   <option value="7">Cancelled</option>: ""} */}
                                    </select>
                                  </div>
                                  <div class="mb-3">
                                    <label for="email" class="form-label">New Location</label>
                                    <input type="text" class="form-control" id="" name="Current_Location" value={currentStatus.Current_Location} onChange={(e)=>handleUpdateChange(e)}  placeholder="Location or city name"  required=""/>
                                  </div>
                                  <div class="mb-3">
                                    <label for="email" class="form-label">Date/Time</label>
                                    <input type="date" class="form-control" placeholder="" name="Current_Date"  value={currentStatus.Current_Date} onChange={(e)=>handleUpdateChange(e)} min={currentStatus.Current_min_Date} required=""/>
                                  </div>
                                  <p class="m-0 d-grid">
                                   { (subscriber.driver_detail.length > 0 ) ?
                                    <a  class="btn btn-primary btn-block" onClick={(e) => UpdateCurrentStatus(e,subscriber.invoice_id)}>Update Status</a>:""}
                                    </p>
                                </div>
                              </div>  
                            </div>

                         
                            {subscriber.current_shipment_status==5  &&      subscriber.review_detail!==undefined && subscriber.review_detail.length>0?

                            <section class="p-2 profile_box_reviews">
            

                <div class="card">
                  <div class="card-body">
                    <h1>REVIEWS | </h1>
                    <hr/>
                   
                    {subscriber.review_detail.map((sub)=>(
              
                    <section>
                     
                  
                      <div class="d-flex justify-content-between">
                     
                        <div class=" ">
                          <h3>{subscriber.full_name}</h3>
                          <p class="text-primary"><ReactStarsRating value={sub.rating} isEdit={false} id={`info_box_${index}`}/></p>
                          <p></p>
                        </div>
                        
                        
                      </div>
                    
                      <h2>{subscriber.title}</h2>
                    
                      <p>{sub.review_description}</p>
                      
                    
                     { subscriber.transporter_review!==undefined && subscriber.transporter_review.length>0?
                    <div>
                      {subscriber.transporter_review.map((sub)=>(
                  

                      <div class="p-3 ml-30 mb-5 ml-5 bg-light  border-start border-primary border-5">
                        

                        
                        <h3></h3>
                       
                        <small>{sub.transporter_review_description}</small>
                        
                        </div>
                    ))}
                      </div>
                      :
<>
                        <textarea placeholder="type your reply here" className="form-control mt-2" rows="3" cols="3" name="transporter_review_description" onChange={handleChange4}></textarea>
                          <button className="btn btn-primary mt-2" onClick={()=>handleSubmit(subscriber.invoice_id,subscriber.user_id)}>Submit</button> 
                        
</>
                      
                      
                      }
                      
                    </section>
                    ))}
                    </div></div></section>
                          :""}
                      
                          </div>
  
                        </div>
                    </div>
                </div>
                
                </>
                )
              }
            ) : ""}
  
    </section>  
      :
      <section class="text-center p-5 h-100">
        <h4 class="text-muted fw-bold">NO SHIPMENTS</h4>
        {/* <p class="pb-5">You do not currently have any shipments in this status. Find what you're looking for in another tab or...</p>
        <a href="ship.php" class="btn btn-outline-primary btn-lg">Start a new shipment</a> */}
      </section>
}
    </div>
  </section>
  <Footer/>
  </div>
  )
}

export default Myaccount_carrier