import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import { useState ,useEffect} from 'react';
import { useNavigate } from "react-router-dom";


const Add_driver = () => {
  let navigate = useNavigate();

  let userdata = {};
  if (localStorage.getItem('loggedIn')) {
    userdata = JSON.parse(localStorage.getItem('loggedIn'));
   
     
  }

  const [state, setState] = useState({transporter_id:"",license_number:"",driver_name:"",driver_email:"",mobile_number:"",altmobile_number:"",address:"",govid_number:""});
 
  const[detailstate,setDetailState]=useState({transporter_detail:[]})

  const shipmentDetail = () => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/transporter_detail`;
    let sendData = { transporter_id:userdata.transporter_id};
  
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
    
      if (res.data.output.length == 1) {
        setDetailState({ ...detailstate, transporter_detail:res.data.output, detailFound:true,isLoading:false })
      
     
    }
    else {
      setDetailState({...detailstate,isLoading:false})
    }
    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      
    });
  };
  useEffect(() => {
    shipmentDetail();
  }, [])






  const adddriver = (event) => {
    event.preventDefault();
let transporter= userdata.transporter_id;
if(transporter!==undefined){
    
    if(userdata.transporter_id != "" && state.license_number != "" && state.driver_name != "" && state.driver_email != "" &&  state.mobile_number != "" &&  state.altmobile_number != "" &&  state.govid_number != "" && state.address != ""){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/driver/driver_create`;
        let sendData = { transporter_id:"",license_number:state.license_number,driver_name:state.driver_name,driver_email:state.driver_email,mobile_number:state.mobile_number,altmobile_number:state.altmobile_number,address:state.address,govid_number:state.govid_number,driver_password:state.driver_password };
    
        if(userdata.transporter_id) {
          sendData.transporter_id = userdata.transporter_id;
        }
     
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          if(res && res.data != undefined){
                   
            toast.configure()
            toast(res.data.message)
         
           navigate('/driver_list')
          }
          
        }).catch((e) => {
          
    
          toast.configure();
          toast.error("Some thing went wrong");
         
        });
     
      } else {
        toast.configure();
          toast.error("Please Fill All Input");
          
      }
    }
    else{
     toast.configure();
     toast.error("You are not allowed yet! Please First verify your Transporter account ");
     navigate("/profile_update_carrier")

    }
      };
      const handleChange = (event) => {
      
        setState({
            ...state,
            [event.target.name]: event.target.value,
          });
        
      }
      const { license_number,driver_name,driver_email,driver_password,mobile_number,altmobile_number,address,govid_number } = state

  return (
    <div>
    <Header/>
  
    <section className="bg-light p-5">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-2">&nbsp;</div>
        <div className="col-12 col-md-8 bg-white p-5">{detailstate.transporter_detail.map((sub)=>(
            sub.dstatus==3?<div class="alert alert-danger text-center" role="alert">
       Your Account is not Verified
          </div>
        :""  ))}
          <h3 className="text-uppercase">Add Driver</h3>
  
          <hr/>
          <section>
          <form >
                
               
            <div className="form-floating mb-3 mt-3 ">
            <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}>Enter Driver License Number</label>
              <input
                type="text"
                className="form-control "
                id="email"
                placeholder="Enter phone"
                name="license_number"
                value={license_number || ""}
                onChange={handleChange}
                required
              />
              <label htmlFor="email" className='d-none d-sm-block'>Enter Driver License Number</label>
            </div>
  
            <div className="form-floating mb-3 mt-3  ">
            <label htmlFor="email"className='mb-5 d-block d-sm-none' style={{fontSize:"10px"}}>Enter Driver Name</label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="driver_name"
                value={driver_name || ""}               
                onChange={handleChange}
                required
              />
              <label htmlFor="email"className='mb-5 d-none d-sm-block'>Enter Driver Name</label>
            </div>
  
            <div className="form-floating mb-3 mt-3  ">
            <label htmlhtmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}> Enter Driver Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="driver_email"
                value={driver_email||""}
                onChange={handleChange}
                required
              />
              <label htmlhtmlFor="email" className='d-none d-sm-block'> Enter Driver Email</label>
            </div>

            <div className="form-floating mb-3 mt-3  ">
            <label htmlhtmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}> Enter Driver Password</label>
              <input
                type="password"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="driver_password"
                value={driver_password||""}
                onChange={handleChange}
                required
              />
              <label htmlhtmlFor="email" className='d-none d-sm-block'> Enter Driver Password</label>
            </div>
  
            <div className="form-floating mb-3 mt-3  ">
            <label htmlhtmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}} >Enter Driver Mobile no.</label>
              <input
                type="number"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="mobile_number"
                value={mobile_number||""}
                onChange={handleChange}
                required
              />
              <label htmlhtmlFor="email" className='d-none d-sm-block'>Enter Driver Mobile no.</label>
            </div>
  
            <div className="form-floating mb-3 mt-3 ">
            <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}>Enter Alt. Mobile no.</label>
              <input
                type="number"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="altmobile_number"
                value={altmobile_number||""}
                onChange={handleChange}
                required
              />
              <label htmlFor="email" className='d-none d-sm-block'>Enter Alt. Mobile no.</label>
            </div>
  
  
  
  
            <div className=" mb-3 mt-3  ">
              <div className="form-floating">
              <label htmlFor="comment" className='d-block d-sm-none' style={{fontSize:"10px"}}>Address</label>
                <textarea className="form-control h-25" id="comment" name="address" rows="5" cols=""  placeholder="address" value={address||""} onChange={handleChange}></textarea>
                <label htmlFor="comment" className='d-none d-sm-block'>Address</label>
              </div>
            </div>
  
  
  
            <div className="form-floating mb-3 mt-3 ">
            <label htmlFor="email" className='d-block d-sm-none' style={{fontSize:"10px"}}>Enter Driver Gov. ID no.</label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter phone"
                name="govid_number"
                value={govid_number||""}
                onChange={handleChange}
                required
              />
              <label htmlFor="email" className='d-none d-sm-block'>Enter Driver Gov. ID no.</label>
            </div>
                   

                   
         
  


            
            <div className="mt-3">
           <a href="" className="btn btn-primary" onClick={(event)=>adddriver(event)}  >Add Driver</a> 
                 </div>
            </form>
          </section>
          
            
  
         
        </div>
        <div className="col-12 col-md-2">&nbsp;</div>
      </div>
    </div>
  </section>
  <Footer/>
  </div>
  )
}

export default Add_driver