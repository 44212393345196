import React, { useState, useEffect, useRef } from "react";
import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
import { Link } from "react-router-dom";
import Axios from "axios";
import exportValue from "../apiconfig";


const Url_view = (value) => {
   // console.log("value- url ------->  ",value);
    const [urlMsg, setUrlMsg] = useState({ urlTitle: "", urlDescription: "",url:"",urlImg:"",urlFavicons:"" })
    useEffect(() => {
        MessageUrlView()
    },[])
    const MessageUrlView = () => {
       // console.log("messing url =>",value);
        let msg = value.value;
        var res = msg.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if (res != null) {
            let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/chat/chat_url_detail`;
            let sendData = {message:msg};
            Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
                 // console.log("chat list", res);
                  setUrlMsg(res.data.output);
                   
              }).catch((e) => {
                  // toast.configure()
                  //toast.error("Some thing went wrong")
                  console.log("----error:   ", e);
              })
            // getLinkPreview(msg).then((data) => {
            //     console.log("datas->>     ", data)
            //     let linkMsg = {};
            //     linkMsg['url'] = msg;
            //     linkMsg['urlTitle'] = (data.title) ? data.title : "";
            //     linkMsg['urlDescription'] = (data.description) ? data.description : ""; 
            //     linkMsg['urlFavicons'] =  (data.favicons && data.favicons.length > 0) ? data.favicons[0] : ""; 

            //     linkMsg['urlImg'] = (data.images && data.images.length > 0) ? data.images[0] : ""; 
            //     setUrlMsg(linkMsg)
            //         console.log("datas->>     ", data)                
            //         console.log("msg->>     ", linkMsg)
            //         //data.value = linkMsg;
            //         return linkMsg;
                
            // });
        }
    }

    return (
        
        <>
       <a href={urlMsg.url} target="_blank" style={{color:"#0C0C0B"}} >
        <div class="card" style={{width:"18rem",marginTop:"15px",marginBottom:"15px",marginRight:"15px",marginLeft:"12px"}}>
  <img src={urlMsg.urlImg} class="card-img-top" alt="..." style={{height:"120px"}}/>
  <div class="card-body" style={{backgroundColor:" #F9F8F7"}}>
    <h5 class="card-title mt-2" style={{textColor:"#0C0C0B",fontWeight:"bold"}}>{urlMsg.urlTitle}</h5>
    <p class="card-text text-muted" style={{fontSize:"12px"}}>{urlMsg.urlDescription}</p>
   {/* <p className="card-url " style={{fontSize:"13px"}}><img src={urlMsg.urlFavicons} style={{height:"20px"}}></img>{urlMsg.url}</p> */}
  </div>
</div>
       </a>  
       
        
        </>
        )

}

export default Url_view;