import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import exportValue from '../apiconfig';
import  Axios  from 'axios';
import { toast } from "react-toastify";
import { Link, useNavigate ,useParams} from 'react-router-dom';
import Moment from 'react-moment';
import ReactLoading from 'react-loading';

import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalTitle } from "react-bootstrap";


const Tickets_details = () => {
  const { ticket_id } = useParams();

    let userdata = {};
    if (localStorage.getItem('loggedIn')) {
      userdata = JSON.parse(localStorage.getItem('loggedIn'));
      console.log("userData  -  ",userdata);
       
    }

    const[state,setState]=React.useState({ticket_detail:[],ticket_shipment:[],shipment_driver:[],shipment_vehicle:[],reply_detail:[],isLoading:true})
const [reply, setReply] = useState({  reply_content:"",reply_id:"",ticket_id:ticket_id })
   
const[modals,setModals] = useState({show: false})



    const axios_get_api = () => {
 
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/support/ticket_detail`;
        
        let sendData = {
          dstatus: 1,
          ticket_id:ticket_id,
          
        }
       
        Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          console.log("-----res-----  ",res);
         setState({...state,ticket_detail:res.data.output,reply_detail:res.data.output2,ticket_shipment:res.data.output2,shipment_driver:res.data.driver,shipment_vehicle:res.data.vehicle,isLoading:false})
          console.log("response", res);
        
    
        }).catch((e) => {
    
          // toast.configure()
          // toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])

      const handleSupportReply = () => {
    
        console.log("submit1");
       
        let state_detailes = {reply_content:reply.reply_content,reply_id:"", ticket_id:ticket_id}
        if(userdata.user_type==1){
        if(userdata.user_id) {
          state_detailes.reply_id = userdata.user_id;
        }}
        else{
            if(userdata.transporter_id) {
                state_detailes.reply_id = userdata.transporter_id;
              }
        }
      
        console.log("report detailes is ",state_detailes);
       
        Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/support/add_reply`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
                console.log("result is i ",result);
               
                if(result && result.data != undefined){
                   
                        toast.configure()
                        toast(result.data.message)
                        setReply({...reply, reply_content:""}) 
                        axios_get_api()
                        
                    }
     
             }).catch((e) => {
           
     
              toast.configure();
              toast.error("Some thing went wrong");
              console.log("----error:   ", e);
            });}

            const closeTicket = () => {
    
              console.log("submit1");
             
              let state_detailes = {ticket_id:ticket_id}
             
            
              console.log("report detailes is ",state_detailes);
             
              Axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/support/close_ticket`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
                      console.log("result is i ",result);
                     
                      if(result && result.data != undefined){
                         
                              toast.configure()
                              toast(result.data.message)
                              setModals({show:false}) 
                              axios_get_api()
                              toast.configure();
                              
                              
                          }
           
                   }).catch((e) => {
                 
           
                    toast.configure();
                    toast.error("Some thing went wrong");
                    console.log("----error:   ", e);
                  });}
          
    
    
    const handleChange = (event) => {
      console.log(event);  
      setReply({
          ...reply,
          [event.target.name]: event.target.value,
        });
      
    }
    const nameFormat = (name)=> {
        
      if(name) {
          let nameArray =  name.split(" ");
         // console.log("nameArray  ",nameArray);
          if(nameArray.length > 1) {
              let n1 = nameArray[0].substring(0,1).toUpperCase();
              let n2 = nameArray[1].substring(0,1).toUpperCase();
              name = n1+n2;
          }else {
              name = nameArray[0].substring(0,2).toUpperCase();
          }
      }else{
          name = "NO";
      }
      return name;
  }
  const handleClick=()=>{
    setModals({show:false})
  }
  return (
    
    <div>
<Header/>
<Modal show={modals.show} >
        <Modal.Header closeButton onClick={() => handleClick()}>
          <ModalTitle>
            <h4>Close Ticket</h4>
          </ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>
          <h6>Do you really want to close the ticket ?</h6>
            
          </div>
        
         
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={() => closeTicket()} >
            Yes
          </Button>
          <Button variant="primary" onClick={() => handleClick()} >
            No
          </Button>
        </Modal.Footer>
      </Modal>
{state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
<section class="bg-light p-5">
    {state.ticket_detail.map((sub)=>(
  <div class="container ">


    <section class="bg-white p-5 mb-2">
      <div class="text-muted">
         <p><Link to="/tickets"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</Link>
        <strong class="ms-2 me-2">Ticket# <span class=" fw-bold">{sub.ticket_id}</span></strong>
        <span class={sub.status==0?"badge bg-warning ms-2 me-2":sub.status==1?"badge bg-success ms-2 me-2":"badge bg-danger ms-2 me-2"}>{sub.status==0?"Pending":sub.status==1?"Answered":"Closed"}</span>
        </p>
      </div>
      <div class="row pt-1">
        <div class="col-md-8">
             
             <div className='row'>
              <div className='col-6'>
              <section class="mt-3 mb-5">
              <h2>{sub.subject}</h2>
            </section>
              </div>
              <div className='col-6'>
                {sub.status!=2?
              <section className='text-end'>
            <p  class=" text-primary " style={{marginTop:"25px",cursor:"pointer"}} onClick={() => setModals({ show: true ,})} >Close Ticket</p>
            </section>
            :""}

              </div>
             </div>
           

            
           {state.reply_detail.map((item)=>(
            <section>
            {item.reply_id==userdata.user_id? 
                <div class="card shadow-sm mb-3">
                  <div class="card-body">
                    <div class="d-flex p-3 ">
                      <div class="p-2">
                        <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(userdata.full_name)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
                      </div>
                      <div class="p-2">
                        <p>{item.reply_content}</p>
                        <div class="">
                        <small class="text-muted"><Link to={"/profile/"+ userdata.user_id}>{userdata.full_name}</Link> (Customer) </small><br/>
                        <small class="text-muted">{item.date} {item.time}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             :item.reply_id==userdata.transporter_id?
                <div class="card shadow-sm mb-3">
                  <div class="card-body">
                    <div class="d-flex p-3 ">
                      <div class="p-2">
                        <p>{item.reply_content}</p>
                        <div class="text-end">
                          <small class="text-muted"><Link to={"/profile-carrier/" + userdata.transporter_id}>{userdata.full_name} ({sub.ticket_branch_name})</Link> (Transporter) </small><br/>
                          <small class="text-muted">{item.date} {item.time}</small>
                        </div>
                      </div>
                      <div class="p-2">
                        <img src={"https://dummyimage.com/50/004ba0/ffffff&text="+nameFormat(userdata.full_name)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
                      </div>
                    </div>
                  </div>
                </div> :
                item.reply_id!=userdata.user_id && item.reply_id!=userdata.transporter_id?
               
                <div class="card shadow-sm mb-3">
                  <div class="card-body">
                    <div class="d-flex p-3 ">
                      <div class="p-2">
                        <p>{item.reply_content}</p>
                        <div class="text-end">
                          <small class="text-muted"><a href="">{item.full_name} </a> (System Admin) </small><br/>
                          <small class="text-muted">{item.date} {item.time}</small>
                        </div>
                      </div>
                      <div class="p-2">
                        <img src={"https://dummyimage.com/50/63a4ff/ffffff&text="+nameFormat(item.full_name)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
                      </div>
                    </div>
                  </div>
                </div>
                
               :""}
            </section>
            ))}
           
           {sub.status!=2?
            <section class="bg-light p-5">
              <label for="comment">Reply:</label>
              <textarea class="form-control" rows="5" id="comment" name="reply_content" placeholder="Please enter details" onChange={(e) => handleChange(e)} value={reply.reply_content}></textarea>
              <button type="button" class="btn btn-primary mt-3" onClick={()=>handleSupportReply({ticket_id:sub.ticket_id})}>Reply</button>
            </section>
            :""}




            
        </div>
        {sub.shipment_id!="" && sub.shipment_id!=undefined?
       
        <div class="col-md-4 bg-light">
          <section class="ps-4 pt-3">
            <h3 class="fs-5 pb-3">Basic information</h3>
            <div class="row">
              <div class="col-md-6">
                <small class="text-muted">Shipment Tracking #</small>
                <h4 class="fs-5">{sub.invoice_id}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">PO/Refernce #</small>
                <h4 class="fs-5">293848</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Pickup</small>
                <h4 class="fs-6">{sub.pickup_address}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Delivery</small>
                <h4 class="fs-6">{sub.delivery_address}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Shipper</small>
                <h4 class="fs-5"><Link to={"/profile/" + sub.user_id}>{sub.full_name}</Link></h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Item(s)</small>
                <h4 class="fs-5">{sub.qty}</h4>
              </div>
              <small class="text-muted">Noted: {sub.description} </small>
            </div>
          </section>

          <hr class="m-4"/>


          <section class="ps-4">
            <h3 class="fs-5 pb-3">Order details</h3>
            <div class="row">
              <div class="col-md-6">
                <small class="text-muted">Carrier</small>
                <h4 class="fs-5 text-primary">{sub.branch_name!="" && sub.branch_name!=undefined? <Link to={"/carrier/" + sub.transporter_id}>{sub.branch_name}</Link>:"Not Booked Yet"}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Order on</small>
                <h4 class="fs-5"><Moment format="MMMM DD , YYYY" unix>{sub.created}</Moment>
                <small class="text-muted">{sub.time}</small></h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Total Price #</small>
                <h4 class="fs-5">${sub.item_value}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Total Weight</small>
                <h4 class="fs-5">{sub.item_weight}KG</h4>
              </div>
            </div>
          </section>

          <hr class="m-4"/>

          <section class="ps-4">
            <h3 class="fs-5 pb-3">Shipping Items</h3>
            <div class="row">
              <div class="col-md-12">
                <h4 class="fs-6">{sub.title}</h4>
                <small class="text-muted">{sub.item_weight} KG - {sub.item_length}x{sub.item_height}x{sub.item_width} m</small>
              </div>
             
              
            </div>
          </section>
          
          <hr class="m-4"/>

          <section class="ps-4">
            <h3 class="fs-5 pb-3">Shipping Equipments</h3>
            <div class="row">
              <div class="col-md-6">
                <small class="text-muted">Truck Type</small>
                <h4 class="fs-6">{sub.service_type}</h4>
              </div>
              {
              state.shipment_vehicle.map((subs)=>(
              <div class="col-md-6">
               
                <small class="text-muted">Truck Number</small>
                <h4 class="fs-6">{subs.vehicle_number}</h4>
              </div>))}
              {
              state.shipment_vehicle.map((subscriber)=>(
              <div class="col-md-6 ">
                <small class="text-muted">Driver Name</small>
                <h4 class="fs-6">{subscriber.driver_name}</h4>
              </div>))}
              {
              state.shipment_vehicle.map((subscriber)=>(
              <div class="col-md-6 ">
                <small class="text-muted">Driver Mobile Number</small>
                <h4 class="fs-6">{subscriber.mobile_number}</h4>
              </div>))}
               
            </div>
          </section>

        </div>
        :""}
      </div>
    </section>

    
  </div>
  ))}
</section>
}
<Footer/>
    </div>
  )
}

export default Tickets_details