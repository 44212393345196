import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';

import "react-toastify/dist/ReactToastify.css";
import exportValue from "../apiconfig";
import Footer from "./Footer";
import Header from "./Header";
import Map from "./components/Map";
import Mapsecond from "./components/Mapsecond";
import Image_uploader from './Image_uploader';



const Payment = () => {
  let navigate = useNavigate();
  const location = useLocation();
  console.log("location==>",location)

  const { invoice_id } = useParams();
  const [state, setState] = useState({ shipmentDetail: {}, questions: [], quote: (location.state)?location.state.quotation:[],transporter_commission:0,admin_commission:0, isLoading: true, detailFound: false,service_charge: 0, booking_percent: 20 })
  console.log("state",state)
  
  const [payState, setPayState] = useState({ pay_now: "", pay_later: "" });
  const [quetState, setQuetState] = useState();
  const [paymentModeState, setPaymentModeState] = useState([]);
  const[dimensionState,setdimensionState] = useState({companyDetail:[]})

  const [paid, setPaid] = useState({onpaid:false,order:""});
  const [error, setError] = useState(null);
  
  const paypalRef =useRef()


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
   function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }
  
    const { height, width } = useWindowDimensions();
  

  const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user/get_company_info_api`;
    let sendData = {};
  
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const payment_modes = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment/shipment_payment_setting`;
    let sendData = {};
  
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setPaymentModeState(res.data.output)
      console.log("res=> ", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  React.useEffect(() => {
    dimension_detail();
    payment_modes();
  }, [])


  const shipmentDetail = () => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/shipment_detail`;
    let sendData = { invoice_id: invoice_id ,quotes_id:state.quote.quotes_id};
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res -> ", res);

      if (res.data.output.length == 1) {
        console.log("res ->->-> ");
        setState({ ...state, shipmentDetail: res.data.output[0], questions: res.data.questions,transporter_commission:res.data.transporter_commission,admin_commission:res.data.admin_commission,service_charge:res.data.service_charge, detailFound: true, isLoading: false })
      
        Booknow_amount(res.data.transporter_commission,res.data.admin_commission, res.data.service_charge)
          
      }
      else {
        setState({ ...state, isLoading: false })
      }
    }).catch((e) => {
      
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });
  };
  useEffect(() => {
    console.log(location);
   
    shipmentDetail();
  }, [])
  
  const handleQuotation = (data_array) => {

    console.log("this is second invoice is ", data_array);
    //setState({...state,invoice_id:invoice_id})


    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment/accept_decline_shipment_quote`;
    let sendData = data_array;
    sendData.payment_status = paid.order;
    sendData.paid_amount = payState.pay_now ;
    sendData.remaining_amount = payState.pay_later;
    sendData.service_charge = state.service_charge;
    sendData.admin_commission = state.admin_commission;
    sendData.transporter_commission = state.transporter_commission;
    sendData.quotes_id = state.quote.quotes_id;
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res issssd ", res);

      if (res && res.data.status == '200')
      if (data_array.status == 2) {
        let stateShipdetail = state.shipmentDetail;
        stateShipdetail.status = 1;
        setState({ ...state, shipmentDetail: stateShipdetail });
      }
     
   // setQuetState(res.data.output)
    // setPaid({...paid,onpaid:false})
     
  
      navigate(`/shipment/${state.shipmentDetail.invoice_id}`)
      toast.configure();
      toast.success("Payment Successfull ! Your Shipment has been Accepted ");
      // shipmentDetail()

    }).catch((e) => {
      //setState({...state,isLoading: false});
      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });

  }
  const Booknow_amount = (admin_commission,transporter_commission,service_charge) => {
    //if(state.pay_now == 0) {
    let total_amount = state.quote.cost + service_charge;
    console.log(total_amount);
    let now_pay =  admin_commission+transporter_commission +service_charge;
    console.log("now_pay",now_pay);
   
    let later_pay = total_amount - now_pay;
    setPayState({ ...payState, pay_now: now_pay, pay_later: later_pay })
    console.log(now_pay);
    console.log(later_pay);

    paypalDesign(now_pay)
    return now_pay
    

  }

  useEffect(() => {
      
        paypalDesign();
      }, [])

  const paypalDesign = (pp) => {
                              
                                    if(window.myButton) window.myButton.close();
                                   
                                    window.myButton =  window.paypal.Buttons({
                                        createOrder: (data, actions) => {
                                          return actions.order.create({
                                            intent: "CAPTURE",
                                            purchase_units: [
                                              {
                                                description: "",
                                                amount: {
                                                  currency_code: "USD",
                                                  value: pp
                                                },
                                              },
                                            ],
                                          });
                                        },
                                        onApprove: async (data, actions) => {
                                          const order = await actions.order.capture();
                                          setPaid({...paid,onpaid:true,order:order});
                                           console.log("onpaidstate",paid);
                                                   
                                        },
                                        onError: (err) => {
                                        //   setError(err),
                                          console.error(err);
                                        },
                                      })
                                
                                      window.myButton.render(paypalRef.current);
                                  }
                                
                                
                                  if (paid.onpaid==true) {
                                    console.log("adsasd")
                                    // setPaid({...paid,onpaid:false})
                                      handleQuotation({ invoice_id: state.shipmentDetail.invoice_id, transporter_id: state.quote.transporter_id, status: 2 })
                                    }
                                
                                  // If any error occurs
                                  if (error) {
                                    return <div className='text-center mt-5'><h3>Error Occurred in processing payment.! Please try again.</h3></div>;
                                  }
    
  

  


  // React.useEffect(() => {

  //   console.log("pp", payState.pay_now);
   

  // }, []);




  // if (paid) {
  //     handleQuotation({ invoice_id: state.shipmentDetail.invoice_id, transporter_id: state.quote.transporter_id, status: 2 })
  // }

  // // If any error occurs
  // if (error) {
  //   return <div>Error Occurred in processing payment.! Please try again.</div>;
  // }

  
  

  return (
    <div>
<Header/>
      <section className=" bg-light p-5 "  style={width<=768?{marginLeft:"-40px",marginRight:"-40px"}:{marginLeft:"0px"}}>

        <div className="container  p-5">
          <div className="row m-0 ">
            <div className="col-md-7 col-12">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="row box-right">
                    <div className="col-md-8 col-12 ps-0 ">
                      <p className=" fw-bold h6 mb-0">BOOKING AMOUNT</p>
                      <p className="h1 fw-bold d-flex"> <span className="  textmuted h6 align-text-top mt-1"></span>{dimensionState.companyDetail.map((sub)=>(<small className="text-primary">{sub.set_currency}</small>))} {payState.pay_now} </p>
                      <p className=" ">To confirm the booking</p>
                    </div>
                    <div className="col-md-2">
                      <p className="text-primary"> <span className="fa fa-circle"></span> Pay Now </p>
                      {dimensionState.companyDetail.map((sub)=>( <p className="fw-bold"> {sub.set_currency}{payState.pay_now  } </p>))}
                    </div>
                    <div className="col-md-2">
                      <p className="text-success"><span className="fa fa-circle pe-2"></span>Pay Later</p>
                      {dimensionState.companyDetail.map((sub)=>(<p className="fw-bold">{sub.set_currency}{payState.pay_later}</p>))}
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0 mb-4">
                  <div className="box-right">
                  {dimensionState.companyDetail.map((sub)=>( <p>You need to pay {sub.set_currency}{payState.pay_now} to confirm the booking. Rest of the amount will be paid later as per carrier terms & conditions</p>))}

                    <hr />
                    {/* <h4>Choose Payment Option</h4>
                    <form className="pb-3">
                      <div className="d-flex flex-row align-content-center">
                        <div className="pt-2 pr-2 m-1"><input type="radio" name="radio1" id="r1" checked /></div>
                        <div className="rounded border d-flex w-100 px-2">
                          <div className="pt-2"> <p><i className="fa fa-paypal text-primary pr-2"></i> Paypal</p></div>
                        </div>
                      </div>
                    </form>
                    <form className="pb-3">
                      <div className="d-flex flex-row align-content-center">
                        <div className="pt-2 pr-2 m-1"><input type="radio" name="radio1" id="r1" /></div>
                        <div className="rounded border d-flex w-100 px-2">
                          <div className="pt-2"> <p><i className="fa fa-cc-stripe text-primary pr-2"></i> Stripe</p></div>
                        </div>
                      </div>
                    </form> */}

                    <div className="">
                      <div>

                      {dimensionState.companyDetail.map((sub)=>(<h4>Total Amount {sub.set_currency}{payState.pay_now} /-</h4>))}
                      
                   
                          {paymentModeState.map((modes)=>(
                            <div>  { modes.pid=="165432255560" ? <div ref={paypalRef}></div>:""}
                             {/* {modes.pid=="16543219990" ? <div>wire</div> :""} */}
                            </div>
                            
                            
                          ))}

                      </div>



                      {/* <a className="btn btn-primary d-block" href=""> */}
                      {/* <Button onClick={() => handleQuotation({ invoice_id: state.shipmentDetail.invoice_id, transporter_id: state.quote.transporter_id, status: 2 })}>Pay ${payState.pay_now}</Button> */}
                      {/* </a> */}
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-12 ps-md-5 p-0 ">
            


                <p className="textmuted h8">Shipment Id : {state.shipmentDetail.invoice_id}</p>
                <p className="fw-bold h7">{state.shipmentDetail.full_name}</p>
                {/* <p className="textmuted h8">3897 Hickroy St, salt Lake city</p>
                <p className="textmuted h8 mb-2">Utah, United States 84104</p> */}

                <table className="table">
                  <thead>
                    <tr>
                      <th>Particular</th>
                      <th width="10%">Amount </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Quote amount</td>
                      {dimensionState.companyDetail.map((sub)=>( <td>{sub.set_currency}{state.quote.cost}</td>))}
                    </tr>
                    <tr>
                      <td>Service charge</td>
                      {dimensionState.companyDetail.map((sub)=>( <td>{sub.set_currency}{state.service_charge}</td>))}
                    </tr>
                    <tr className="fw-bold">
                      <td>Total</td>
                      {dimensionState.companyDetail.map((sub)=>(<td>{sub.set_currency}{state.quote.cost + state.service_charge}</td>))}
                    </tr>
                  </tbody>
                </table>

             
            </div>
          </div>
        </div>



      </section>
<Footer/>
    </div>
  )
}

export default Payment